import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '@context/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import LogoDark from '@assets/logo/exein-logo-dark_small.gif';
import Logo from '@assets/logo/Exein-logo_small.gif';

const Footer = () => {
  const { theme } = React.useContext(GlobalContext);

  const isDarkMode = theme === THEMES.DARK;
  const privacyPolicy = 'https://exein.io/privacy-policy';
  const terms = 'https://exein.io/terms';
  const contact = 'https://exein.io/contact-us';
  const pricing = 'https://exein.io/pricing';
  const exein = 'https://exein.io';

  return (
    <React.Fragment>
      <Container fluid className={'footer gx-0 d-lg-none d-xl-block'}>
        <Row className={'gx-0'}>
          <Col xs={12} md={12}>
            <Container fluid >
              <Row className={'align-items-center'}>
                <Col xs={12}>
                  <hr style={{ margin: '2rem 0' }}/>
                </Col>
                <Col xs={12} xl={2} className={'text-xl-start text-md-center text-sm-center pb-sm-3'}>&copy;Exein SpA</Col>
                <Col xs={12} xl={8}>
                  <Container fluid>
                    <Row className={'align-items-center'}>
                      <Col xs={12} xl={2} className={'text-center pb-sm-3'}>
                        <a href={terms} target={'_blank'} rel="noreferrer">
                          Terms
                        </a>
                      </Col>
                      <Col xs={12} xl={1} className={'text-center pb-sm-3'}>
                        <a href={privacyPolicy} target={'_blank'} rel="noreferrer">
                          Privacy
                        </a>
                      </Col>
                      <Col xs={12} xl={6} className={'text-center pb-sm-3'}>
                        <img
                          src={isDarkMode ? LogoDark : Logo}
                          alt="exein-logo"
                          style={{ height: 30 }}
                        />
                      </Col>
                      <Col xs={12} xl={1} className={'text-center pb-sm-3'}>
                        <a href={contact} target={'_blank'} rel="noreferrer">
                          Contact
                        </a>
                      </Col>
                      <Col xs={12} xl={2} className={'text-center pb-sm-3'}>
                        <a href={pricing} target={'_blank'} rel="noreferrer">
                          Pricing
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs={12} xl={2} className={'text-xl-end text-md-center text-sm-center pb-sm-3'}>
                  <a href={exein} target={'_blank'} rel="noreferrer">exein.io</a>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container fluid className={'footer gx-0 d-lg-block d-xl-none'}>
        <Row className={'gx-0'}>
          <Col xs={12} md={12}>
            <Container fluid >
              <Row className={'align-items-center gx-0'}>
                <Col xs={12}>
                  <hr style={{ margin: '2rem 0' }}/>
                </Col>
                <Col xs={12} className={'pb-3'}>
                  <Container fluid>
                    <Row className={'align-items-center gx-0'}>
                      <Col xs={3} className={'text-start pb-sm-3'}>
                        <a href={terms} target={'_blank'} rel="noreferrer">
                          Terms
                        </a>
                      </Col>
                      <Col xs={3} className={'text-center pb-sm-3'}>
                        <a href={privacyPolicy} target={'_blank'} rel="noreferrer">
                          Privacy
                        </a>
                      </Col>

                      <Col xs={3} className={'text-center pb-sm-3'}>
                        <a href={contact} target={'_blank'} rel="noreferrer">
                          Contact
                        </a>
                      </Col>
                      <Col xs={3} className={'text-end pb-sm-3'}>
                        <a href={pricing} target={'_blank'} rel="noreferrer">
                          Pricing
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs={4} className={'text-start pb-sm-3'}>&copy;Exein SpA</Col>
                <Col xs={4} className={'text-center pb-sm-3'}>
                  <img
                    src={isDarkMode ? LogoDark : Logo}
                    alt="exein-logo"
                    style={{ height: 30 }}
                  />
                </Col>
                <Col xs={4} className={'text-end pb-sm-3'}>
                  <a href={exein} target={'_blank'} rel="noreferrer">exein.io</a>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Footer;