import { useQueryClient, useQuery } from 'react-query';
import type { ReportFilesystemAnalysis } from '@utils/types/report';
import { getFilesystemAnalysis } from '@services/api';
import { noop } from 'lodash';
import useToken from '@hooks/useToken';
export const KEY = 'filesystem-analysis';

export default function useFilesystemAnalysis(
  id: string,
  path: string,
  onSuccess: typeof noop
) {
  const queryClient = useQueryClient();
  useToken();
  const splittedPath = path?.split('/');
  const lastPath = splittedPath?.splice(0, splittedPath.length - 1)?.join('/');

  return useQuery<ReportFilesystemAnalysis, Error>(
    [KEY, id, path],
    () => getFilesystemAnalysis(id, path),
    {
      retry: false,
      initialData: () =>
        queryClient.getQueryData<ReportFilesystemAnalysis>([KEY, id, lastPath]),
      onSettled: onSuccess,
    }
  );
}
