import React, { CSSProperties, ReactNode } from 'react';

type InputLeftProps = {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}
const InputLeft = ({ className = '', style = {}, children }: InputLeftProps) => {
  return (
    <span className={className} style={style}>{children}</span>
  );
};

export default InputLeft;