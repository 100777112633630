import { getCssVariable } from '@utils/index';
import ContentLoader from 'react-content-loader';

const SkeletonSidemenu = (props: any) => (
  <ContentLoader
    speed={2}
    width={400}
    height={200}
    viewBox="0 0 400 200"
    backgroundColor={getCssVariable('neutral100')}
    foregroundColor={getCssVariable('neutral200')}
    {...props}
  >
    <circle cx="10" cy="20" r="8" />
    <rect x="25" y="15" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="50" r="8" />
    <rect x="25" y="45" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="80" r="8" />
    <rect x="25" y="75" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="110" r="8" />
    <rect x="25" y="104" rx="5" ry="5" width="220" height="10" />
    <rect x="25" y="134" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="138" r="8" />
    <rect x="25" y="161" rx="5" ry="5" width="220" height="10" />
    <circle cx="10" cy="165" r="8" />
  </ContentLoader>
);

export default SkeletonSidemenu;
