import { CHIP_COLOR } from '@components/Chip';
import { REPORT_CODE_ANALYSIS_TYPES } from '@utils/types/report';

const GPL_LICENSE_GROUP = [
  'GPLv3/LGPLv3',
  'GPLv2',
  'LGPL',
  'GPLv2/LGPLv2',
  'GPLv2/LGPLv3',
  'GPLv3',
  'LGPLv2.1/GPL-2.0',
  'LGPLv2.1',
  'LGPL',
  'LGPLv2',
  'GPL/LGPL',
  'GPLv3/Apache 2.0',
  'LGPLv2.1/MIT/GPLv2',
];

const BSD_LICENSE_GROUP = ['BSD', 'BSD-3-Clause'];

const MIT_LICENSE_GROUP = ['MIT', 'MIT/X', 'MIT/X11'];

const OTHER_LICENSE_GROUP = [
  'Multi',
  'Apache 2.0',
  'OpenSSL',
  'Expat',
  'PUBLICDOMAIN',
  'zlib',
  'ISC',
  'MPLv2',
];

const getBomViewerChipColorByLicenseType = (license: string): CHIP_COLOR => {
  if (BSD_LICENSE_GROUP.includes(license)) {
    return CHIP_COLOR.GREEN;
  }

  if (MIT_LICENSE_GROUP.includes(license)) {
    return CHIP_COLOR.CYAN;
  }

  if (GPL_LICENSE_GROUP.includes(license)) {
    return CHIP_COLOR.VIOLET;
  }

  if (OTHER_LICENSE_GROUP.includes(license)) {
    return CHIP_COLOR.PINK;
  } else {
    return CHIP_COLOR.DEFAULT;
  }
};

const getCodeAnalysisChipColorByLabel = (label: string): CHIP_COLOR => {
  if (label === REPORT_CODE_ANALYSIS_TYPES.BUFFER) {
    return CHIP_COLOR.LIGHT_VIOLET;
  }
  if (label === REPORT_CODE_ANALYSIS_TYPES.CRYPTO) {
    return CHIP_COLOR.LIGHT_PINK;
  }
  if (label === REPORT_CODE_ANALYSIS_TYPES.FORMAT) {
    return CHIP_COLOR.CYAN;
  }
  if (label === REPORT_CODE_ANALYSIS_TYPES.RACE) {
    return CHIP_COLOR.VIOLET;
  }
  if (label === REPORT_CODE_ANALYSIS_TYPES.SHELL) {
    return CHIP_COLOR.PINK;
  }
  if (label === REPORT_CODE_ANALYSIS_TYPES.TMPFILE) {
    return CHIP_COLOR.BLUE;
  }
  return CHIP_COLOR.DEFAULT;
};

const getTabValueByActiveIndex = (activeIndex: string): string => {
  let dataType = 'high';
  switch (activeIndex) {
    case '0':
      dataType = 'high';
      break;
    case '1':
      dataType = 'medium';
      break;
    case '2':
      dataType = 'low';
      break;
    default:
      break;
  }
  return dataType;
};

const getTableDataHandler = (data: any, activeIndex: any) => {
  if (
    data &&
    data[getTabValueByActiveIndex(activeIndex)] &&
    data[getTabValueByActiveIndex(activeIndex)].preview
  ) {
    return [...data[getTabValueByActiveIndex(activeIndex)].preview];
  }
  return [];
};

const Utils = () => ({
  getBomViewerChipColorByLicenseType,
  getCodeAnalysisChipColorByLabel,
  getTableData: getTableDataHandler,
});

export default Utils();
