import { getTokenOnLocalstorage } from '@utils/index';
import axios from 'axios';
// import { getAuth } from 'firebase/auth';
import { ContactFormData } from '@components/LayoutOnBoarding/ContactForm';
import {
  normalizeAttackSurfaceFromRaw,
  normalizeBinaryFromRaw,
  normalizeBomViewerFromRaw,
  normalizeCodeFromRaw,
  normalizeConfigAnalyzerFromRaw,
  normalizeCryptoAnalyzerFromRaw,
  normalizeCveCheckFromRaw,
  normalizeFilesystemFromRaw,
  // normalizeKernelSecurityFromRaw,
  normalizeNvramFromRaw,
  normalizePasswordHashFromRaw,
  normalizePeimDxeFromRaw,
  normalizeReportListFromRaw,
  normalizeReportOverviewFromRaw,
  normalizeRtosFunctionsScanFromRaw,
  normalizeRtosSymbolsScanFromRaw,
  normalizeRtosTasksScanFromRaw,
  // normalizeRtosCapabilitiesScanFromRaw,
  normalizeSecureBootFromRaw,
  normalizeSecurityScanFromRaw,
  normalizeUefiAccessRightsScanFromRaw,
  normalizeUefiIntelBootGuardFromRaw,
  normalizeUefiSecurityScanFromRaw,
} from '@services/normalize';
import {
  OrganizationFullType,
  OrganizationType,
} from '@utils/types/organization';
import {
  Report,
  ReportBinaryAnalysis,
  ReportBomViewerAnalysis,
  // ReportRtosCapabilitiesAnalysis,
  ReportConfigAnalyzerAnalysis,
  ReportCreator,
  ReportCryptoAnalyzerAnalysis,
  ReportCveCheckAnalysis,
  ReportFilesystemAnalysis,
  // ReportKernelSecurityAnalysis,
  ReportNvramAnalysis,
  ReportOverview,
  ReportPasswordHashAnalysis,
  ReportPeimDxeAnalysis,
  ReportRtosCapabilitiesContentAnalysis,
  ReportRtosFunctionsAnalysis,
  ReportRtosSymbolsAnalysis,
  ReportRtosTasksAnalysis,
  ReportSecureBootAnalysis,
  ReportSecurityScanAnalysis,
  ReportStaticCodeAnalysis,
  ReportUefiAccessRightsAnalysis,
  ReportUefiAttackSurfaceAnalysis,
  ReportUefiIntelBootGuardAnalysis,
  ReportUefiSecurityScanAnalysis,
} from '@utils/types/report';
import jwt_decode from 'jwt-decode';
import { get } from 'lodash';
// import { FeedbackData } from '@components/LayoutOnBoarding';
import { CreateOrganizationFormData } from '@components/ModalCreateOrganization/ModalCreateOrganization';
import { User } from '@utils/types/user';

export const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`,
  timeout: process.env.NODE_ENV === 'production' ? 25000 : 40000,
  withCredentials: true,
});

const CancelToken = axios.CancelToken;
let cancelUploadReq: any;

// http.defaults.headers.common = {
//   Authorization: `Bearer ${getTokenOnLocalstorage()}`,
// };

export const getUser = async () => {
  try {
    const { data }: { data: User } = await http.get('/user', {
      headers: {
        Authorization: `Bearer ${getTokenOnLocalstorage()}`,
      },
    });
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
};

export async function getReportsListFromApi(token?: string): Promise<Report[]> {
  const organizationUID = localStorage.getItem('organizationUID');

  try {
    const { data }: { data: any[] } = await http.get(
      `/organizations/${organizationUID}/projects`,
      {
        headers: {
          Authorization: `Bearer ${token || getTokenOnLocalstorage()}`,
        },
      }
    );

    const { data: quicks }: { data: any[] } = await http.get(
      `/organizations/${organizationUID}/projects/quick_overview`,
      {
        headers: {
          Authorization: `Bearer ${token || getTokenOnLocalstorage()}`,
        },
      }
    );

    for (let index = 0; index < data.length; index++) {
      Object.assign(data[index], {
        quickOverview: quicks[index]['quick-overview'] || {},
      });
    }

    // for (const i in data) {
    //   const report = await getQuickReport(data[i].id);
    //   Object.assign(data[i], {
    //     quickOverview: report['quick-overview'] || {}
    //   });
    // }

    return data?.map(normalizeReportListFromRaw);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function createReportFromApi(
  ReportFormData: FormData<ReportCreator>,
  setUploadProgress: (progress: number) => void
): Promise<string | undefined> {
  cancelUploadReq = null;
  try {
    const organizationUID = localStorage.getItem('organizationUID');
    const { data } = await http.post(
      `/organizations/${organizationUID}/projects`,
      ReportFormData,
      {
        cancelToken: new CancelToken((cancel) => {
          cancelUploadReq = cancel;
        }),
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
          'Content-Type': 'multipart/form-data',
        },
        timeout: 18000000, // 5 mins
        onUploadProgress: (progressEvent: any) => {
          const percent = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress((percent as number) ?? 0);
        },
      }
    );
    return get(data, 'id', '');
  } catch (error: any) {
    if (axios.isCancel(error)) {
      console.log('request cancelled');
    } else {
      throw error?.response?.data?.error;
    }
  }
}

export async function deleteReportFromAPi(reportId: string): Promise<void> {
  try {
    await http.delete(`/projects/${reportId}`, {
      headers: {
        Authorization: `Bearer ${getTokenOnLocalstorage()}`,
      },
    });
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export type ReportPayload = {
  name: Report['name'];
  description?: Report['description'];
  notification?: Report['notification'];
  continuous_scan?: Report['continuousScan'];
  favourite?: Report['favourite'];
  archived?: Report['archived'];
};

export async function updateReportFromApi(
  reportId: string,
  payload: ReportPayload
): Promise<void> {
  try {
    await http.put(
      `/projects/${reportId}`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportFromApi(reportId: string): Promise<Report> {
  try {
    const { data }: { data: any } = await http.get(`/projects/${reportId}`, {
      headers: {
        Authorization: `Bearer ${getTokenOnLocalstorage()}`,
      },
    });
    return normalizeReportListFromRaw(data);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getQuickReport(reportId: string): Promise<any> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/quick_overview`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function createFeedback(body: string): Promise<any> {
  try {
    const { data }: { data: any } = await http.post(
      '/misc/support',
      {
        message: body,
        category: 'FEEDBACK',
        subject: '',
        severity: '',
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function createSupportRequest(
  body: ContactFormData
): Promise<any> {
  try {
    const { data }: { data: any } = await http.post('/misc/support', body, {
      headers: {
        Authorization: `Bearer ${getTokenOnLocalstorage()}`,
      },
    });
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function acceptOrganizationInvite(
  token: string
): Promise<any | Promise<any>> {
  try {
    const { data }: { data: any } = await http.get(
      `/organizations/verify/${token}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getOverviewReportFromApi(
  reportId: string
): Promise<ReportOverview> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/overview`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return normalizeReportOverviewFromRaw(data);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export function cancelUploadRequest() {
  cancelUploadReq && cancelUploadReq();
}

export async function getReportCveCheckAnalysis(
  reportId: string
): Promise<ReportCveCheckAnalysis> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/CveCheck`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return normalizeCveCheckFromRaw(data?.result ?? []);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportPasswordHashAnalysis(
  reportId: string
): Promise<ReportPasswordHashAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/PasswordHash`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data?.result?.map(normalizePasswordHashFromRaw) ?? [];
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportSecurityScanAnalysis(
  reportId: string
): Promise<ReportSecurityScanAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/SecurityScan`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data?.result?.map(normalizeSecurityScanFromRaw) ?? [];
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportNvRamAnalysis(
  reportId: string
): Promise<ReportNvramAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Nvram`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return data?.result?.map(normalizeNvramFromRaw) ?? [];
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportBomViewerAnalysis(
  reportId: string
): Promise<ReportBomViewerAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/SoftwareBOM`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return data?.result?.map(normalizeBomViewerFromRaw) ?? [];
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getKernelSecurityAnalysis(
  reportId: string
): Promise<any> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Kernel`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    // return data?.result?.map(normalizeKernelSecurityFromRaw) ?? [];
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportCryptoAnalyzerAnalysis(
  reportId: string
): Promise<ReportCryptoAnalyzerAnalysis> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Crypto`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return normalizeCryptoAnalyzerFromRaw(data?.result ?? []);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportBinaryAnalysis(
  reportId: string
): Promise<ReportBinaryAnalysis> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Hardening`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return normalizeBinaryFromRaw(data?.result ?? []);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportCodeAnalysis(
  reportId: string
): Promise<ReportStaticCodeAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/StaticCode`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return normalizeCodeFromRaw(data?.result ?? []);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getFilesystemAnalysis(
  reportId: string,
  path = ''
): Promise<ReportFilesystemAnalysis> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/FileSystem${
        path ? '?root=/' + path : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return normalizeFilesystemFromRaw(
      data?.result ?? [],
      path.length ? false : true
    );
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportUefiSecurityScanAnalysis(
  reportId: string
): Promise<ReportUefiSecurityScanAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/UefiSecurityScan`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return data?.result?.map(normalizeUefiSecurityScanFromRaw) ?? [];
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportUefiIntelBootGuardAnalysis(
  reportId: string
): Promise<ReportUefiIntelBootGuardAnalysis> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/IntelBootGuard`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return normalizeUefiIntelBootGuardFromRaw(data?.result);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportUefiAccessRightsAnalysis(
  reportId: string
): Promise<ReportUefiAccessRightsAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Access`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return data?.result?.map(normalizeUefiAccessRightsScanFromRaw) ?? [];
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportUefiAttackSurfaceAnalysis(
  reportId: string
): Promise<ReportUefiAttackSurfaceAnalysis> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Surface`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return normalizeAttackSurfaceFromRaw(data?.result ?? []);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportPeimDxeAnalysis(
  reportId: string
): Promise<ReportPeimDxeAnalysis> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/PeimDxe`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return normalizePeimDxeFromRaw(data?.result ?? []);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportSecureBootAnalysis(
  reportId: string
): Promise<ReportSecureBootAnalysis> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/SecureBoot`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return normalizeSecureBootFromRaw(data?.result ?? []);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportRtosFunctionsAnalysis(
  reportId: string
): Promise<ReportRtosFunctionsAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Functions`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data?.result?.map(normalizeRtosFunctionsScanFromRaw) ?? [];
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportRtosSymbolsAnalysis(
  reportId: string
): Promise<ReportRtosSymbolsAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Symbols`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return data?.result?.map(normalizeRtosSymbolsScanFromRaw) ?? [];
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportRtosTasksAnalysis(
  reportId: string
): Promise<ReportRtosTasksAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Tasks`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return data?.result?.map(normalizeRtosTasksScanFromRaw) ?? [];
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportRtosCapabilitiesAnalysis(
  reportId: string
): Promise<ReportRtosCapabilitiesContentAnalysis[]> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Capabilities`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data?.result || [];
    // return normalizeRtosCapabilitiesScanFromRaw(data?.result ?? []);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getReportConfigAnalyzerFroRaw(
  reportId: string
): Promise<ReportConfigAnalyzerAnalysis> {
  try {
    const { data }: { data: any } = await http.get(
      `/projects/${reportId}/analysis/Hardening`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );

    return normalizeConfigAnalyzerFromRaw(data?.result ?? []);
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getAllOrganizations(): Promise<OrganizationType[]> {
  try {
    const { data }: { data: any } = await http.get('organizations', {
      headers: {
        Authorization: `Bearer ${getTokenOnLocalstorage()}`,
      },
    });
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function inviteMemberToOrganization(
  organizationId: any,
  formData: any
): Promise<OrganizationType[]> {
  try {
    const { data }: { data: any } = await http.post(
      `organizations/${organizationId}/members`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getOrganization(
  organizationId: string
): Promise<OrganizationFullType> {
  try {
    const { data }: { data: any } = await http.get(
      `organizations/${organizationId}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function createOrganization(
  formData: CreateOrganizationFormData
): Promise<OrganizationFullType> {
  try {
    const { data }: { data: any } = await http.post('organizations', formData, {
      headers: {
        Authorization: `Bearer ${getTokenOnLocalstorage()}`,
      },
    });
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function setMemberRoleInOrganization(
  organizationId: string,
  memberId: string,
  role: string
): Promise<any> {
  try {
    const { data }: { data: any } = await http.put(
      `organizations/${organizationId}/members/${memberId}`,
      { role: role },
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function deleteOrganizationByOrgUID(
  organizationId: string
): Promise<any> {
  try {
    const { data }: { data: any } = await http.delete(
      `organizations/${organizationId}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function getApiToken(): Promise<any> {
  try {
    const { data }: { data: any } = await http.get('api_key', {
      headers: {
        Authorization: `Bearer ${getTokenOnLocalstorage()}`,
      },
    });
    return data;
  } catch (error: any) {
    throw error?.response?.data?.error;
  }
}

export async function regenerateApiToken(payload: any): Promise<any> {
  try {
    const { data }: { data: any } = await http.post(
      'api_key/regenerate',
      payload,
      {
        headers: {
          Authorization: `Bearer ${getTokenOnLocalstorage()}`,
        },
      }
    );
    return data;
  } catch (error: any) {
    throw error;
  }
}
