import { getUser } from '@services/api';
import { User } from '@utils/types/user';
import { useQuery } from 'react-query';
import useToken from './useToken';

export const KEY = 'user';

export default function useUser() {
  useToken();
  // const organizationUID = localStorage.getItem('organizationUID');

  const opts = {
    refetchOnWindowFocus: false,
    // enabled: !!organizationUID,
  };

  return useQuery<User, Error>(KEY, async () => getUser(), opts);
}
