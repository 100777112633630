import SkeletonAccountPlan from '@components/SkeletonAccountPlan';
import styles from '@components/UserSettings/styles.module.scss';
import { UserContext } from '@context/UserContext';
import usePlaceholders from '@hooks/usePlaceholders';
import { ROUTES } from '@routes/routes';
import { formatData } from '@utils/normalizers';
import { Invoice, Subscription } from '@utils/types/user';
import React, { useState } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AiOutlineEdit } from 'react-icons/all';
import { useHistory, useRouteMatch } from 'react-router';

const AccountPlan = () => {
  const { t } = useTranslation(['common', 'userSettings']);
  const history = useHistory();
  const { getUserPlaceholder } = usePlaceholders();

  const { url, setURL, setFile } = React.useContext<any>(UserContext);
  const pathPlans =
    ROUTES.BASE + ROUTES.USER_SETTINGS + ROUTES.SUBSCRIPTION + ROUTES.PLANS;
  const pathInvoices =
    ROUTES.BASE + ROUTES.USER_SETTINGS + ROUTES.SUBSCRIPTION + ROUTES.INVOICE;
  const { path } = useRouteMatch();
  const [isLoad, setLoading] = useState<boolean>(false);
  const [lastInvoice, setLastInvoice] = useState<Invoice | null | undefined>(
    null
  );
  const [subscription, setSubscription] = useState<
    Subscription | null | undefined
  >(null);

  // const init = async () => {
  //   await fetchData();
  // };
  // useEffect(() => {
  //   init();
  //   // eslint-disable-next-line
  // }, []);

  // const fetchData = async () => {
  //   try {
  //     setLoading(true);
  //     const sub = await getActiveCustomerSubscription();
  //     if (sub?.latest_invoice) {
  //       const last = await getInvoiceById(sub?.latest_invoice);
  //       setLastInvoice(last);
  //     } else {
  //       const last = await getInvoiceById(null);
  //       setLastInvoice(last);
  //     }
  //     setSubscription(sub);
  //     // ref.getDownloadURL().then((url) => {
  //     setFile(null);
  //     // setURL(url);
  //     // });
  //   } catch (e) {
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = get(e, 'target.files[0]', null);
    if (file) {
      setFile(file);
      // const uploadTask = ref.put(file);
      // uploadTask.on('state_changed', console.log, console.error, () => {
      // ref.getDownloadURL().then((url) => {
      setFile(null);
      // setURL(url);
      // });
      // });
    }
  };

  const getPlanInterval = (interval: string) => {
    if (interval === 'year') {
      return t('userSettings:planTypes.year');
    }

    return t('userSettings:planTypes.month');
  };

  return (
    <Row className={'gx-3'}>
      <Col xs={12} className={'ps-4 pe-4'}>
        {isLoad ? (
          <SkeletonAccountPlan />
        ) : (
          <Card className={'accountCard'}>
            <Container fluid>
              <Row className={'gx-0'}>
                <Col
                  xs={12}
                  md={12}
                  lg={1}
                  xl={1}
                  className={'text-center text-lg-center text-md-center'}
                >
                  <div className={'profileImageContainer'}>
                    <Image
                      className={'profileImage'}
                      src={url || getUserPlaceholder()}
                      thumbnail
                      roundedCircle
                    />
                    {!path.includes('/invoice') &&
                    !path.includes('/payments') ? (
                      <React.Fragment>
                        <input
                          id={'uploadPhoto'}
                          type="file"
                          accept=".jpg, .jpeg"
                          onChange={handleChange}
                          className={styles.inputField}
                        />

                        <label htmlFor={'uploadPhoto'} className={'labelBtn'}>
                          <AiOutlineEdit className={'editIcon'} />{' '}
                          {t('userSettings:buttons.photo')}
                        </label>
                      </React.Fragment>
                    ) : null}
                  </div>
                </Col>

                <Col xs={12} md={12} lg={11} xl={11}>
                  <Container fluid>
                    <Row>
                      {/* TODO: unhide once it's ready */}
                      {/* <Col xs={6} md={3}>
                        <div className={'accountInfo'}>
                          {subscription === null ||
                          subscription === undefined ? (
                            <React.Fragment>
                              <h6>{t('userSettings:planWidget.myPlan')}</h6>
                              <h4>
                                {t('userSettings:planTypes.monthly')}{' '}
                                <sup>$</sup>0
                              </h4>
                              <span className={'text-primary'}>{'FREE'}</span>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <h6>{t('userSettings:planWidget.myPlan')}</h6>
                              <h4>
                                {getPlanInterval(subscription?.plan?.interval)}{' '}
                                <sup>
                                  {getCurrency(subscription?.plan?.currency)}
                                </sup>
                                {formatPrice(subscription?.plan?.amount)}
                              </h4>
                              <span className={'text-primary'}>
                                {subscription?.plan?.key &&
                                  subscription.plan.key.toUpperCase()}
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                      </Col> */}
                      {/* <Col xs={6} md={2} className={'change-plan-button'}>
                        <Button
                          className={'mt-5'}
                          variant={'primary'}
                          onClick={() => history.push(pathPlans)}
                        >
                          {t('userSettings:buttons.changePlan')}
                        </Button>
                      </Col> */}
                      <Col xs={6} md={3}>
                        <div className={'accountInfo'}>
                          <h6>{t('userSettings:planWidget.renewal')}</h6>
                          {subscription ? (
                            <h4>
                              {formatData(subscription.current_period_end)}
                            </h4>
                          ) : (
                            <h4>-</h4>
                          )}
                        </div>
                      </Col>
                      {/* <Col xs={6} md={3}>
                        <div className={'accountInfo'}>
                          <h6>{t('userSettings:planWidget.lastInvoice')}</h6>
                          {lastInvoice ? (
                            <h4>
                              {formatData(lastInvoice.created)}{' '}
                              <a
                                className={'invoiceIcon'}
                                href={lastInvoice.invoice_pdf}
                                target={'_blank'}
                                rel="noreferrer"
                              >
                                <ImFilePdf />
                              </a>
                            </h4>
                          ) : (
                            <h4>-</h4>
                          )}
                          {!path.includes('/invoice') ? (
                            <Link to={pathInvoices}>
                              {t('userSettings:planWidget.allInvoices')}
                            </Link>
                          ) : null}
                        </div>
                      </Col> */}
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Card>
        )}
      </Col>
    </Row>
  );
};

export default AccountPlan;
