//@ts-nocheck
//@ts-ignore
import PdfFooterLogo from '@assets/img/pdf-footer-logo.png';
import PdfHeaderLogo from '@assets/img/pdf-header-logo.png';
import Button, { BUTTON_TYPE } from '@components/Button';
import { GlobalContext } from '@contexts/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import useReportOverview from '@hooks/useReportOverview';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import CodeAnalysisGraph from '@pages/Report/Linux/ReportOverviewLinux/Graphs/CodeAnalysisGraph';
import OverviewGraph from '@pages/Report/Linux/ReportOverviewLinux/Graphs/OverviewGraph';
import { getQuickReport } from '@services/api';
import { format } from 'date-fns';
import Html2Pdf from 'js-html2pdf';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import BinaryAnalysis from './BinaryAnalysis';
import BinaryAnalysisTable from './BinaryAnalysisTable';
import CveCheck from './CVECheck';
import CodeAnalysisAffectedFilesTable from './CodeAnalysisAffectedFilesTable';
import { default as CveCheckTable } from './CveCheckTable';
import OverviewGrade from './Overview';
import PasswordHash from './PasswordHash';
import SecurityScan from './SecurityScan';
import styles from './componentToPrint.module.scss';
import containerStyles from './overviewContainer.module.scss';

const breakPointsLeft = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const breakPointsRight = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
};

export const ComponentToPrint = () => {
  const ref = React.useRef();
  const [chartData, setChartData] = React.useState({});
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isError } = useReportOverview(reportId);
  const { theme } = React.useContext(GlobalContext);

  const isDarkMode = theme === THEMES.DARK;

  const getQuick = async () => {
    if (reportId) {
      const data = await getQuickReport(reportId);
      if (data && data['quick-overview']) {
        setChartData(data['quick-overview']);
      }
    }
  };

  React.useEffect(() => {
    getQuick();
  }, [reportId]);

  const handleDownload = useReactToPrint({
    onPrintError: (error) => console.log(error),
    content: () => ref.current,
    removeAfterPrint: true,
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      if (document) {
        const html = document.getElementById(
          'element-to-download-as-pdf'
        ).innerHTML;

        const exporter = new Html2Pdf(html, {
          filename: 'Exein Analyzer report.pdf',
          jsPDF: {
            format: 'a4',
            orientation: 'p',
          },
        });
        await exporter.getPdf(true);
      }
    },
  });

  return (
    <>
      <div className={styles.downloadReportButtonContainer}>
        <Button
          className={'mt-5'}
          type={BUTTON_TYPE.LIGHT_BORDER}
          onClick={handleDownload}
          style={{
            marginBottom: 20,
            backgroundColor: isDarkMode ? '#40454b' : '#fafafa',
            color: isDarkMode ? '#d0d7de' : 'black'
          }}
          disabled={isLoading || isError}
        >
          Download report
        </Button>
      </div>
      <div
        className={styles['container']}
        ref={ref}
        id="element-to-download-as-pdf"
      >
        <div className={styles['reportDate']}>
          submitted: {format(new Date(), 'd-MM-yyyy HH:MM')}
        </div>
        <div className={styles['reportTitle']}>Exein Analyzer</div>
        <div className={styles['reportSubtitle']}>
          {data?.originalName} report
        </div>
        <div className={styles['headerLogoContainer']}>
          <img src={PdfHeaderLogo} alt="header-logo" />
        </div>
        <div class="html2pdf__page-break" />

        <div
          className={styles['title']}
          style={{ fontSize: 36, marginBottom: 0 }}
        >
          Overview
        </div>
        <OverviewGrade grade={data?.grade as GRADE_TYPE} />
        <div className={styles['title']} style={{ fontSize: 36 }}>
          Security Overview Threat Level
        </div>
        <div className={containerStyles['overview-container']}>
          <SectionLayout className="px-0">
            <SectionContent
              breakPointsLeft={breakPointsLeft}
              breakPointsRight={breakPointsRight}
              style={{
                borderColor: '#d0d7de',
                marginLeft: 0,
                marginRight: 0,
              }}
            >
              <SectionContentLeft
                verticalMessage={'Overview'}
                verticalMessageColor="#05516f"
                style={{
                  borderRight: 'none',
                }}
              >
                <div className={containerStyles['overview-graph-container']}>
                  <OverviewGraph data={chartData} customTextColor="black" />
                </div>
              </SectionContentLeft>
            </SectionContent>
          </SectionLayout>
        </div>
        <div class="html2pdf__page-break" />
        <div className={styles['title']}>Cve check</div>
        <div className={styles['paragraph']}>
          The CVE Search analysis identifies known vulnerabilities in open
          source components found in your firmware, categorised by severity. For
          each CVE, Cosmo gives you information about its remediation
          instructions.
        </div>
        <CveCheck
          severities={{
            critical: data?.analysis?.cveCheck?.critical.severity,
            high: data?.analysis?.cveCheck?.high.severity,
            medium: data?.analysis?.cveCheck?.medium.severity,
            low: data?.analysis?.cveCheck?.low.severity,
          }}
        />
        <div class="html2pdf__page-break" />

        <div className={styles['title']} style={{ fontSize: 32 }}>
          Details
        </div>
        <div className={styles['paragraph']}>Vulnerabilities</div>
        {data?.analysis?.cveCheck && (
          <CveCheckTable data={[{}]} cveData={data?.analysis?.cveCheck} />
        )}
        <div class="html2pdf__page-break" />

        <div className={styles['title']}>Code Analysis</div>
        <div className={styles['paragraph']}>
          Look for potential buffer overflow or command injection
          vulnerabilities inside your firmware binary executables.
        </div>
        <div className={styles['divider']} />
        <div
          className={styles['subtitle']}
          style={{ fontSize: 32, marginTop: 10, color: '#05516f' }}
        >
          Files affected: {data?.analysis.code?.filesAffected || 0}
        </div>
        {data?.analysis.code?.filesAffected !== 0 ? (
          <SectionLayout className="px-0">
            <SectionContent
              breakPointsLeft={breakPointsLeft}
              breakPointsRight={breakPointsRight}
              style={{ borderColor: '#d0d7de', marginLeft: 0, marginRight: 0 }}
            >
              <SectionContentLeft
                verticalMessage={'Severities'}
                verticalMessageColor="#05516f"
                style={{ borderRight: 'none' }}
              >
                <div
                  className={containerStyles['code-analysis-graph-container']}
                >
                  <CodeAnalysisGraph
                    data={data?.analysis?.code || []}
                    itemTextColor="black"
                  />
                </div>
              </SectionContentLeft>
            </SectionContent>
          </SectionLayout>
        ) : (
          <div className={styles['paragraph']}>
            No binaries were found for this firmware, so Cosmo can't perform the
            Code Analysis.
          </div>
        )}
        <div class="html2pdf__page-break" />
        {data?.analysis.code?.filesAffected !== 0 && (
          <>
            <CodeAnalysisAffectedFilesTable />
            <div class="html2pdf__page-break" />
          </>
        )}

        <div className={styles['title']}>Kernel security analysis</div>
        <div className={styles['paragraph']}>
          Look for kernel security technologies installed in the firmware.
        </div>
        <div className={styles['paragraph-transparent']}>
          No kernel security modules were found. Consider using something like
          SeLinux or AppArmor to improve the security of your firmware.
        </div>
        {/* <div className={styles['divider']} /> */}
        <div className={styles['title']}>Binary Analysis</div>
        <div className={styles['paragraph']}>
          The Binary analysis checks that the compiler settings for all
          executables conform to secure coding practices (i.e. Stack Canaries,
          etc.).
        </div>

        <BinaryAnalysis data={data?.analysis?.binary || []} />
        <div class="html2pdf__page-break" />

        <div
          className={styles['title']}
          style={{ fontSize: 32, marginTop: 10 }}
        >
          Details
        </div>
        <BinaryAnalysisTable />
        <div class="html2pdf__page-break" />

        <SecurityScan />
        <PasswordHash />
        <div class="html2pdf__page-break" />

        <div className={styles['footerLogoContainer']}>
          <img src={PdfFooterLogo} alt="footer-logo" />
        </div>
        <div className={styles.companyLocation}>
          <div className={styles['title']} style={{ margin: '5px 0' }}>
            Rome
          </div>
          <div className={styles['subtitle']}>Piazzale Flaminio 19,</div>
          <div className={styles['subtitle']}>00196, Italy</div>
          <div className={styles['title']} style={{ margin: '70px 0 5px' }}>
            San Francisco
          </div>
          <div className={styles['subtitle']}>
            535 Mission St 14 th floor, CA 94 105
          </div>
        </div>
      </div>
    </>
  );
};

export const FunctionalComponentToPrint = React.forwardRef((props, ref) => {
  return <ComponentToPrint ref={ref} />;
});
