import styles from './styles.module.scss';
import { WithChildren } from '@utils/types/helpers';

export enum LAYOUT_TYPE {
  FULL = 'full',
  HALF = 'half',
  THIRD = 'third',
}
type Props = WithChildren<{
  spacer?: number;
  layoutType?: LAYOUT_TYPE;
}>;

export default function LayoutSection({
  layoutType = LAYOUT_TYPE.FULL,
  children,
  spacer = 24,
}: Props) {
  return (
    <div
      className={`${styles.container} ${styles[layoutType]}`}
      style={{ marginBottom: spacer + 'px' }}
    >
      {children}
    </div>
  );
}
