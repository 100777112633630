import { useQuery } from 'react-query';
import { getApiToken } from '@services/api';

// import { KEY as REPORTS_KEY } from './useReports';
export const KEY = 'api_token_get';

export default function useApiKey() {
  return useQuery<any, Error>([KEY], () => getApiToken(), {
    retry: false,
  });
}
