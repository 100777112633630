import { getGraphTheme, getGraphThemeProps } from '@components/Graph/theme';
import { GlobalContext } from '@context/GlobalContext';
import { ResponsiveBar } from '@nivo/bar';
import { getCssVariable } from '@utils/index';
import React from 'react';

type ReportBomViewerChartProps = {
  data: any;
  labels: string[];
}

const ReportBomViewerChart = ({ data, labels }: ReportBomViewerChartProps) => {
  const { theme } = React.useContext(GlobalContext);
  const graphTheme = getGraphTheme(theme);

  return <ResponsiveBar
    theme={graphTheme}
    data={data}
    keys={labels}
    indexBy='license'
    enableLabel={false}
    enableGridY={false}
    animate={false}
    margin={{ top: 50, right: 50, bottom: 50, left: 50 }}
    padding={0.98}
    valueScale={{ type: 'linear' }}
    indexScale={{ type: 'band', round: true }}
    colors={({ id, data }) => String(data[`${id}Color`])}
    tooltip={(props) => {
      const graphLabelProps = getGraphThemeProps(theme, props.color);
      if (props.id === 'FREE')
        return <div />;

      return <React.Fragment>
        <div style={graphLabelProps}>
          <div
            style={{
              height: 18,
              width: 18,
              border: `1px solid ${getCssVariable('neutral200')}`,
              backgroundColor: props.color,
              float: 'left',
              marginRight: 10
            }}
          >
          </div>
          <span style={{ marginRight: '.5rem' }}>{props.id}</span><strong>{props.value}</strong></div></React.Fragment>;
    }}
    reverse={true}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: '#38bcb2',
        size: 4,
        padding: 1,
        stagger: true
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: '#eed312',
        rotation: -45,
        lineWidth: 6,
        spacing: 10
      }
    ]}
    fill={[
      {
        match: {
          id: 'fries'
        },
        id: 'dots'
      },
      {
        match: {
          id: 'sandwich'
        },
        id: 'lines'
      }
    ]}
    borderColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          1.6
        ]
      ]
    }}
    axisTop={null}
    axisRight={null}
    // axisBottom={{
    //   tickSize: 5,
    //   tickPadding: 5,
    //   tickRotation: 0,
    //   // legend: 'license',
    //   legendPosition: 'middle',
    //   legendOffset: 32
    // }}
    axisLeft={null}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{
      from: 'color',
      modifiers: [
        [
          'darker',
          1.6
        ]
      ]
    }}
    // legends={[
    //   {
    //     dataFrom: 'keys',
    //     anchor: 'bottom-right',
    //     direction: 'column',
    //     justify: false,
    //     translateX: 120,
    //     translateY: 0,
    //     itemsSpacing: 2,
    //     itemWidth: 100,
    //     itemHeight: 20,
    //     itemDirection: 'left-to-right',
    //     itemOpacity: 0.85,
    //     symbolSize: 20,
    //     effects: [
    //       {
    //         on: 'hover',
    //         style: {
    //           itemOpacity: 1
    //         }
    //       }
    //     ]
    //   }
    // ]}
    role='application'
    ariaLabel='Bom Viewer Chart'
    barAriaLabel={function (e) {
      return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
    }}
  />;
};

export default ReportBomViewerChart;