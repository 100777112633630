import React from 'react';
import { getCssVariable } from '@utils/index';
import { RiSpamFill } from 'react-icons/all';
import { useTranslation } from 'react-i18next';

type BootGuardStatusProps = {
  enabled: boolean
}

const BootGuardStatus = ({enabled}: BootGuardStatusProps) => {
  const { t } = useTranslation(['pages', 'tables', 'emptyReports', 'common']);

  return (
    <div className='w-100 h-100 d-flex'>
      <div className='d-flex flex-row align-items-center'>
        <RiSpamFill
          style={{ marginRight: '1.2rem', fontSize: '3.5rem', fill: getCssVariable(enabled ? 'general-green' : 'general-red') }} />
        <div className='d-flex flex-column'>
          <h4
            className='h4 mb-2'>{t(enabled ? 'pages:overviewPage.sections.intelBootGuard.successTitle' : 'pages:overviewPage.sections.intelBootGuard.errorTitle')}</h4>
          <p className='font-monospace' style={{fontSize:'.8rem'}}>{t(enabled ? 'pages:overviewPage.sections.intelBootGuard.successMessage' : 'pages:overviewPage.sections.intelBootGuard.errorMessage')}</p>
        </div>
      </div>
    </div>
  );
};

export default BootGuardStatus;