import { getCssVariable } from '@utils/index';
import ContentLoader from 'react-content-loader';

export default function SkeletonAnalysisSimple(props: any) {
  return (
    <ContentLoader
      speed={2}
      width={1016}
      height={323}
      viewBox="0 0 1016 323"
      backgroundColor={getCssVariable('neutral100')}
      foregroundColor={getCssVariable('neutral200')}
      {...props}
    >
      <rect x="0" y="311" rx="4" ry="4" width="104" height="12" />
      <rect x="336" y="311" rx="4" ry="4" width="64" height="12" />
      <rect x="654" y="311" rx="4" ry="4" width="120" height="12" />
      <rect x="0" y="263" rx="4" ry="4" width="80" height="12" />
      <rect x="336" y="263" rx="4" ry="4" width="88" height="12" />
      <rect x="654" y="263" rx="4" ry="4" width="160" height="12" />
      <rect x="0" y="215" rx="4" ry="4" width="120" height="12" />
      <rect x="336" y="215" rx="4" ry="4" width="48" height="12" />
      <rect x="654" y="215" rx="4" ry="4" width="80" height="12" />
      <path d="M 0 196 h 1016 v 1 H 0 z M 0 244 h 1016 v 1 H 0 z M 0 292 h 1016 v 1 H 0 z" />
      <rect x="0" y="167" rx="4" ry="4" width="80" height="12" />
      <rect x="336" y="167" rx="4" ry="4" width="64" height="12" />
      <rect x="654" y="167" rx="4" ry="4" width="120" height="12" />
      <rect x="0" y="84" rx="4" ry="4" width="180" height="16" />
      <rect x="188" y="84" rx="4" ry="4" width="80" height="16" />
      <rect x="0" y="0" rx="4" ry="4" width="180" height="24" />
    </ContentLoader>
  );
}
