import React from 'react';
import { ProgressBar } from '@components/ProgressBar';
import { getCssVariable } from '@utils/index';
import { RiInformationLine } from 'react-icons/ri';
import styles from './styles.module.scss';

type TabsHeaderProps = {
  title: string,
  number: number,
  className?: string,
  // variation?: string,
  progressMin?: number,
  progressMax?: number,
  color?: string,
  tooltipMessage?: string
}

const TabsHeader = (props: TabsHeaderProps) => {
  const { title, number, className = '', progressMin = 0, progressMax = 100, color = 'inherit', tooltipMessage } = props;
  return <div className={`${styles['tab-header-wrapper']} ${className}`}>
    <div className={`${styles['tab-header-title-bar']}`}>
      <h6>{title}</h6>
      <h4 style={{ color: color ? color : 'inherit', fontSize: '2rem' }}>{`${number}`}</h4>
    </div>
    <ProgressBar color={color} value={number} min={progressMin} max={progressMax} />
    <div id='test' className={`${styles['tab-header-bottom-bar']}`}>
      {/*{variation && <span style={{*/}
      {/*  color: color,*/}
      {/*  fontWeight: 'bold'*/}
      {/*}}>{`${number >= 0 ? '+' : '-'}${variation}`}</span>}*/}
      {tooltipMessage && <span className={styles['tooltip']} data-tip={tooltipMessage}>
            <RiInformationLine color={getCssVariable('neutral400')} size={14} />
          </span>}
    </div>
  </div>;
};

export default TabsHeader;