import { IconBaseProps } from 'react-icons';

export default function AccessRightFill({
  color,
  size,
}: IconBaseProps): JSX.Element {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
        fill={color}
      >
        <g
          id="Icons/alert"
          transform="translate(-1.000000, -2.000000)"
          fill={color}
        >
          <path
            d="M18,8 L20,8 C20.5522847,8 21,8.44771525 21,9 L21,21 C21,21.5522847 20.5522847,22 20,22 L4,22 C3.44771525,22 3,21.5522847 3,21 L3,9 C3,8.44771525 3.44771525,8 4,8 L6,8 L6,7 C6,3.6862915 8.6862915,1 12,1 C15.3137085,1 18,3.6862915 18,7 L18,8 Z M16,8 L16,7 C16,4.790861 14.209139,3 12,3 C9.790861,3 8,4.790861 8,7 L8,8 L16,8 L16,8 Z M11,14 L11,16 L13,16 L13,14 L11,14 Z M7,14 L7,16 L9,16 L9,14 L7,14 Z M15,14 L15,16 L17,16 L17,14 L15,14 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
