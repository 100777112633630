import styles from './styles.module.scss';
import * as React from 'react';
import { Report, REPORT_TYPE, REPORT_STATUS } from '@utils/types/report';
import ReportCard from '@components/ReportCard';
import LinuxIcon from '@components/Icons/LinuxIcon';
import ContainerIcon from '@components/Icons/ContainerIcon';
import UefiIcon from '@components/Icons/UefiIcon';
import RtosIcon from '@components/Icons/RtosIcon';
import { getCssVariable } from '@utils/index';
import { ReportActionsContext } from '@contexts/ReportActionsContext';
import DeleteReportModal from '@components/DeleteReportModal';
import RenameReportModal from '@components/RenameReportModal';
import { noop } from 'lodash';
import { useRouteMatch, Link } from 'react-router-dom';
import { ROUTES } from '@routes/routes';
import ReportStatusModal from '@components/ReportStatusModal';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import { useTranslation } from 'react-i18next';
import ReportHeading from '@components/ReportHeading';
type Props = {
  list?: Report[];
};

export enum MODAL_TYPE {
  DELETE = 'delete',
  UPDATE = 'update',
  STATUS = 'status',
}

const renderReport =
  (cb: typeof noop, url: string) => (report: Report, key: number) => {
    let Icon = null;
    if (report.type === REPORT_TYPE.LINUX) {
      Icon = <LinuxIcon size={20} color={getCssVariable('neutral300')} />;
    } else if (report.type === REPORT_TYPE.CONTAINER) {
      Icon = <ContainerIcon size={20} color={getCssVariable('neutral300')} />;
    } else if (report.type === REPORT_TYPE.UEFI) {
      Icon = <UefiIcon size={24} color={getCssVariable('neutral300')} />;
    } else if (report.type === REPORT_TYPE.VXWORKS) {
      Icon = <RtosIcon size={24} color={getCssVariable('neutral300')} />;
    } else {
      Icon = <LinuxIcon size={24} color={getCssVariable('neutral300')} />;
    }
    const isPending =
      report?.status === REPORT_STATUS.PENDING ||
      report?.status === REPORT_STATUS.RUNNING;

    const RCard = (
      <ReportCard
        name={report?.name}
        grade={report?.grade}
        reportType={report?.type}
        date={report?.creationDate}
        icon={Icon}
        id={report.id}
        setModalType={cb}
        isPending={isPending}
      />
    );

    function setModalAsStatus(event: React.SyntheticEvent) {
      event.preventDefault();
      event.stopPropagation();
      cb({ id: report?.id }, MODAL_TYPE.STATUS);
    }

    return (
      <div className={styles.item} key={key}>
        <p>{isPending}</p>
        {isPending ? (
          <div onClick={isPending ? setModalAsStatus : noop}>{RCard}</div>
        ) : (
          <Link to={!isPending ? url + '/' + report.id + ROUTES.OVERVIEW : ''}>
            {RCard}
          </Link>
        )}
      </div>
    );
  };

export default function ReportList({ list }: Props) {
  const { url } = useRouteMatch();
  const [modalType, setModalType] = React.useState<MODAL_TYPE | null>(null);

  const { setReportData } = React.useContext<any>(ReportActionsContext);

  function handleSetModal(data: any, type: MODAL_TYPE) {
    setModalType(type);
    setReportData({ ...data });
  }

  const { t } = useTranslation(['common']);
  return (
    <>
      <LayoutPage>
        <LayoutSection>
          <ReportHeading title={t('common:generic.allReports')} />
        </LayoutSection>
        <LayoutSection>
          <div className={styles.container}>
            {list?.map(renderReport(handleSetModal, url))}
          </div>
        </LayoutSection>
      </LayoutPage>

      {modalType === MODAL_TYPE.DELETE ? (
        <DeleteReportModal onClose={handleSetModal} />
      ) : null}

      {modalType === MODAL_TYPE.UPDATE ? (
        <RenameReportModal onClose={handleSetModal} />
      ) : null}

      {modalType === MODAL_TYPE.STATUS ? (
        <ReportStatusModal onClose={handleSetModal} />
      ) : null}
    </>
  );
}
