import { useQuery } from 'react-query';
import type { ReportRtosFunctionsAnalysis } from '@utils/types/report';
import { getReportRtosFunctionsAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'rtos-functions';

export default function useRtosFunctionsAnalysis(id: string) {
  useToken();
  return useQuery<ReportRtosFunctionsAnalysis[], Error>([KEY, id], () =>
    getReportRtosFunctionsAnalysis(id),
    {
      retry: false
    }
  );
}
