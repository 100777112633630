import React, { CSSProperties } from 'react';
import styles from '../styles.module.scss';

type SectionContentFooterProps = {
  className?: string;
  children?: React.ReactNode;
  verticalMessage?: string;
  style?: CSSProperties;
};

const SectionContentFooter = ({
                              className = '',
                              children,
                              style = {}
                            }: SectionContentFooterProps) => {
  return (
    <div className={`${styles['content-footer']} ${className}`} style={style}>
      {children}
    </div>
  );
};

export default SectionContentFooter;
