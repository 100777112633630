import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import Input from '@components/InputSign';
import { RiMailLine, RiDoorLockBoxLine } from 'react-icons/ri';
import { emailRegex } from '@utils/index';
// import Button, { BUTTON_TYPE } from '@components/Button';
import Spinner, { SPINNER_TYPE, SPINNER_SIZE } from '@components/Spinner';
import { Col, Row } from 'react-bootstrap';

type FormProps = {
  email: string;
  password: string;
}

type Props = {
  isLoading: boolean;
  onSubmit: SubmitHandler<FormProps>;
};

export default function SignInForm({ isLoading, onSubmit }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({ mode: 'onSubmit' });

  const [isDirty, setIsDirty] = React.useState<{
    email: boolean;
    password: boolean;
  }>({
    email: false,
    password: false,
  });

  const { t } = useTranslation('forms');

  const { ref: emailRef, ...emailInputProps } = register('email', {
    required: t('errorsState.invalid.email'),
    pattern: {
      value: emailRegex,
      message: t('errorsState.invalid.email'),
    },
  });

  const { ref: passwordRef, ...passwordInputProps } = register('password', {
    required: t('errorsState.invalid.password'),

    minLength: {
      value: 6,
      message: t('errorsState.invalid.passwordMinLength'),
    },
  });

  function handleOnSubmit({
    email,
    password,
  }: FormProps) {
    setIsDirty({ email: email.length > 0, password: password.length > 0 });
    onSubmit({ email, password });
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} noValidate>
      <Row>
        <Col xs={12}>
          <Input
            leadingIcon={RiMailLine}
            hasValue={isDirty?.email ?? false}
            label={t('labels.emailAddress')}
            type="email"
            placeholder={'john.doe@exein.io'}
            inputRef={emailRef}
            {...emailInputProps}
            error={errors?.email?.message}
          />
        </Col>
        <Col xs={12}>
          <Input
            leadingIcon={RiDoorLockBoxLine}
            hasValue={isDirty?.password ?? false}
            label={t('labels.password')}
            type="password"
            placeholder={'••••••••'}
            inputRef={passwordRef}
            {...passwordInputProps}
            error={errors?.password?.message}
          />
        </Col>
        <Col xs={12}>
          <button className={styles.button}>
            {t('labels.signIn')}
            {isLoading && (
              <div className={styles.spinnerContainer}>
                <Spinner
                  type={SPINNER_TYPE.BLACK_GREEN_BKG}
                  size={SPINNER_SIZE.DEFAULT}
                />
              </div>
            )}
          </button>
        </Col>
      </Row>
    </form>
  );
}
