import OrganizationPlan from '@components/BSOrganizationPlan';
import { Input } from '@components/Forms/Input';
import ModalCreateOrganization from '@components/ModalCreateOrganization/ModalCreateOrganization';
import ModalWrapper from '@components/ModalWrapper';
import MemberChangeRole from '@components/Organizations/MemberChangeRole';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import { yupResolver } from '@hookform/resolvers/yup';
import useDialog from '@hooks/useDialog';
import { useSingleOrganization } from '@hooks/useOrganizations';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import usePlaceholders from '@hooks/usePlaceholders';
import OrganizationBillingInfoForm from '@pages/UserSettings/OrganizationBillingInfoForm';
import {
  deleteOrganizationByOrgUID,
  inviteMemberToOrganization,
  setMemberRoleInOrganization,
} from '@services/api';
import { getCssVariable, padNumber } from '@utils/index';
import React from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdSettings } from 'react-icons/all';
import {
  RiAddLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiSearch2Line,
  RiTeamLine,
} from 'react-icons/ri';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import styles from './styles.module.scss';

const validationSchema = yup.object({
  email: yup.string().required('Member email field is required'),
});

const OrganizationDetails = () => {
  // const { data: user } = useUser();
  const queryClient = useQueryClient();
  const urlParams: any = useParams();
  const history = useHistory();
  const { getOrganizationPlaceholder } = usePlaceholders();
  const [search, setSearch] = React.useState('');
  const [openModalMember, setOpenModalMember] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState<boolean>(false);
  const createOrganizationModal = useDialog('create-organization-modal');
  const { t } = useTranslation(['common', 'organizations', 'userSettings']);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { role: 'OWNER', email: '' },
    resolver: yupResolver(validationSchema),
  });

  const { data: organizationData, isLoading } = useSingleOrganization(
    urlParams?.organizationId
  );

  const memoizedMembers = React.useMemo(
    () => (organizationData?.members ? organizationData?.members : []),
    [isLoading]
  );

  const assignCheckClassName = (name: string) => {
    if (watch().role === name) {
      return 'ownable-checked';
    } else {
      return 'ownable-no-checked';
    }
  };

  const childrenColumns: any = React.useMemo(
    () => [
      {
        Header: 'People In Exeins Organization',
        accessor: 'user',
        width: 1000,
        Cell: ({ value, ...rest }: any) => {
          const member = rest.row.original;
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  paddingRight: '1.5rem',
                  paddingLeft: '.8rem',
                  opacity: '0.4',
                  fontWeight: 'bold',
                }}
              >
                {padNumber(rest.row.index)}
              </span>
              <img
                src={getOrganizationPlaceholder()}
                className={styles.organizationImage}
                alt="organization_image"
              />
              <span style={{ paddingRight: '.5rem', fontWeight: 'bold' }}>
                {member.name}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: 150,
        Cell: ({ value, ...rest }: any) => {
          const member = rest.row.original;
          return (
            <div className={'d-flex align-items-center'}>
              <span style={{ paddingRight: '1rem' }}>{value}</span>
              <OverlayTrigger
                rootClose={true}
                trigger="click"
                placement="top"
                overlay={
                  <Popover className={'show'}>
                    <Popover.Body className={'p-3'}>
                      <MemberChangeRole
                        value={value}
                        onChange={async (e) => {
                          await setMemberRoleInOrganization(
                            organizationData?.organization?.id,
                            member.id,
                            e.target.value
                          );
                          await queryClient.invalidateQueries([
                            `organizations/${organizationData?.organization?.id}`,
                          ]);
                        }}
                      />
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  style={{
                    padding: '.5rem',
                    border: '2px solid #e6e6e6',
                    borderRadius: '.25rem',
                  }}
                >
                  <MdSettings className={`${styles['icon-change-role']}`} />
                </button>
              </OverlayTrigger>
            </div>
          );
        },
      },
    ],
    []
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  const memoizedSearch = React.useMemo(() => search, [search]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: childrenColumns,
      data: memoizedMembers,
      initialState: { globalFilter: memoizedSearch },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  const onSubmitFormMember = async (data: any) => {
    await onInviteMemberToOrganization(data);
  };

  const onInviteMemberToOrganization = async (data: any) => {
    // const orgUid = localStorage.getItem('organizationUID');
    //
    try {
      await inviteMemberToOrganization(urlParams?.organizationId, data);
      toast.success(t('organizations:addMemberToOrganizationModal.success'));
      setOpenModalMember(false);
    } catch (error) {
      toast.error(t('organizations:addMemberToOrganizationModal.error'));
      console.log(error);
    }
  };

  const onDeleteOrganizationHandler = async () => {
    try {
      await deleteOrganizationByOrgUID(urlParams?.organizationId);
      setOpenDelete(false);
      toast.success(t('organizations:deleteOrganizationModal.success'));
      history.push('/reports/settings/organization/overview');
    } catch (error) {
      toast.error(t('organizations:deleteOrganizationModal.error'));
      console.log(error);
    }
  };

  return (
    <>
      {!isLoading && (
        <Container>
          <Row>
            <Col xs={12} className="border-bottom pb-3 px-0">
              <Row className="align-items-center justify-content-between ">
                <Col xs={6}>
                  <div className="d-flex">
                    <span className={styles.title}>Organization</span>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="d-flex justify-content-end">
                    <Button
                      variant={'primary'}
                      size={'sm'}
                      onClick={createOrganizationModal.open}
                    >
                      <RiAddLine />
                      Create Organization
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="px-0 my-3">
              <span className={`${styles['subTitle']}`}>YOUR ORGANIZATION</span>
            </Col>
            <Col xs={12} className="px-0">
              <OrganizationPlan
                editMode={false}
                organizationData={organizationData}
              />
            </Col>
          </Row>

          <Row className="mt-4">
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={3}
              style={{ paddingRight: '.5rem', paddingLeft: '0' }}
            >
              <Input label="Organization Name" />
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={3}
              style={{ padding: '0 .5rem' }}
            >
              <Input label="Organization Domain" />
            </Col>
            <Col
              xs={0}
              sm={0}
              md={0}
              lg={0}
              xl={6}
              style={{ paddingLeft: '.5rem', paddingRight: '0' }}
            />
          </Row>
          <Row>
            <Col xs={12} className="d-flex justify-content-end px-0">
              <div className="my-2 text-danger fw-bold">
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpenDelete(true)}
                >
                  Delete my organization
                </span>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="px-0 mb-3">
              <span className={`${styles['subTitle']}`}>BILLING INFO</span>
            </Col>
            <Col xs={12} className="px-0">
              <OrganizationBillingInfoForm />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="px-0">
              <div className="my-4 border-bottom" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="pb-2 px-0">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RiTeamLine
                  style={{
                    width: '30px',
                    height: '30px',
                    marginRight: '0.5rem',
                  }}
                />
                <p style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>
                  Members
                </p>
              </div>
            </Col>
            <Col xs={12} className="px-0">
              <Table
                tableInstance={tableInstance}
                className="border-0 mb-5"
                wrapperStyle={{
                  border: `1px solid ${getCssVariable('neutral300')}`,
                  padding: '0',
                }}
              >
                <Table.Header
                  style={{
                    justifyContent: 'flex-start',
                    width: '100%',
                    border: '0',
                  }}
                >
                  <div className={styles['input-container']}>
                    <div className={styles['input-wrapper']}>
                      <div>
                        <input
                          placeholder="Search"
                          value={search}
                          onChange={onSearchHandler}
                        />
                        <RiSearch2Line />
                      </div>
                    </div>
                    <Button
                      variant={'primary'}
                      size={'sm'}
                      onClick={() => setOpenModalMember(true)}
                    >
                      <RiAddLine
                        style={{
                          marginRight: '.5rem',
                          textTransform: 'uppercase',
                        }}
                      />
                      {t('organizations:addMemberToOrganizationModal.title')}
                    </Button>
                  </div>
                </Table.Header>
                <Table.Footer
                  style={{
                    border: `1px solid ${getCssVariable('neutral300')}`,
                    borderTop: 'none',
                  }}
                >
                  <p className={tableStyles['table__paginationState']}>
                    {paginationMessage}
                  </p>
                  <div className={tableStyles['table__paginationButtons']}>
                    <button
                      className={
                        tableInstance?.canPreviousPage
                          ? tableStyles['table__paginationActiveButton']
                          : ''
                      }
                      onClick={() => tableInstance.previousPage()}
                    >
                      <RiArrowLeftSLine size={20} />
                    </button>
                    <button
                      className={
                        tableInstance?.canNextPage
                          ? tableStyles['table__paginationActiveButton']
                          : ''
                      }
                      onClick={() => tableInstance.nextPage()}
                    >
                      <RiArrowRightSLine size={20} />
                    </button>
                  </div>
                  <TablePageSize
                    maxResultChunkSize={maxResultChunkSize}
                    pageSize={tableInstance?.state?.pageSize || 10}
                    onPageSizeChange={onPageSizeChange}
                  />
                </Table.Footer>
              </Table>
            </Col>
          </Row>
        </Container>
      )}
      {createOrganizationModal.opened && (
        <ModalCreateOrganization onClose={createOrganizationModal.close} />
      )}
      {openModalMember && (
        <ModalWrapper
          title={t('organizations:addMemberToOrganizationModal.title')}
          onClose={() => setOpenModalMember(false)}
        >
          <Container>
            <Form onSubmit={handleSubmit(onSubmitFormMember)}>
              <Row>
                <Col xs={12} className="px-0">
                  <Input
                    {...register('email')}
                    label="Member email"
                    placeholder="name@domain.com"
                  />
                  {errors && errors.email && (
                    <span style={{ fontSize: '0.7rem', color: 'red' }}>
                      {errors.email.message}
                    </span>
                  )}
                </Col>
                <Col xs={12} className="px-0 mb-2 mt-4">
                  <span style={{ fontWeight: 'bold' }}>Role</span>
                </Col>

                <Col xs={12}>
                  <Row>
                    <Col
                      xs={6}
                      style={{ padding: '0.5rem 0.8rem' }}
                      className={styles[assignCheckClassName('OWNER')]}
                    >
                      <Row className="py-3">
                        <Col xs={1}>
                          <Form.Check
                            {...register('role')}
                            name="role"
                            type="radio"
                            isValid
                            value="OWNER"
                          />
                        </Col>
                        <Col xs={10}>
                          <p style={{ fontSize: '0.9rem' }}>
                            {t(
                              'organizations:addMemberToOrganizationModal.form.fields.owner.head'
                            )}
                          </p>
                          {/* <span style={{ fontSize: '0.7rem' }}>
                            {t('organizations:addMemberToOrganizationModal.form.fields.owner.description')}
                          </span> */}
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={6}
                      style={{ padding: '0.5rem 0.8rem' }}
                      className={styles[assignCheckClassName('MEMBER')]}
                    >
                      <Row className="py-3">
                        <Col xs={1}>
                          <Form.Check
                            {...register('role')}
                            name="role"
                            type="radio"
                            value={'MEMBER'}
                            isValid
                          />
                        </Col>
                        <Col xs={10}>
                          <p style={{ fontSize: '0.9rem' }}>
                            {t(
                              'organizations:addMemberToOrganizationModal.form.fields.member.head'
                            )}
                          </p>
                          {/* <span style={{ fontSize: '0.7rem' }}>
                            {t('organizations:addMemberToOrganizationModal.form.fields.member.description')}
                          </span> */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '2rem',
                  }}
                  className="px-0"
                >
                  <button
                    className={styles.button}
                    style={{ textTransform: 'uppercase' }}
                  >
                    SEND INVITE
                  </button>
                </Col>
              </Row>
            </Form>
          </Container>
        </ModalWrapper>
      )}
      {openDelete && (
        <Modal
          show={openDelete}
          onHide={() => setOpenDelete(false)}
          onEscapeKeyDown={() => setOpenDelete(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title className={'fw-bold'}>
              <span className={styles['modal-change-plan']}>
                {t('organizations:deleteOrganizationModal.title')}
              </span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className={styles['modal-change-plan']}>
              {t('organizations:deleteOrganizationModal.message')}
            </p>
          </Modal.Body>
          <Modal.Footer className={'border-top-0 justify-content-start'}>
            <Button
              variant="danger"
              onClick={() => onDeleteOrganizationHandler()}
            >
              {t('userSettings:buttons.confirmDelete')}
            </Button>
            <Button
              variant={'link'}
              onClick={() => setOpenDelete(false)}
              className={'text-dark'}
            >
              <span className={styles['modal-change-plan']}>
                {t('userSettings:buttons.cancel')}
              </span>
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default OrganizationDetails;
