import * as React from 'react';
import styles from './styles.module.scss';
import ModalWrapper from '@components/ModalWrapper';
import UploadReport from '@components/UploadReport';
import SubmitReport from '@components/SubmitReport';
import { NewScanContext } from '@contexts/NewScanContext';
import { useTranslation } from 'react-i18next';
import useCreateReport from '@hooks/useCreateReport';
import AsciiProgressBar from '@components/AsciiProgressBar';
import FakeReportMessage from '@components/FakeReportMessage';
import { Alert } from 'react-bootstrap';

type IProps = {
  isModal: boolean
};

export default function NewScanModal({
  isModal = true
}: IProps) {
  const { resetStep, addStep, step } = React.useContext<any>(NewScanContext);
  const { t } = useTranslation('scanModal');

  const [file, setFile] = React.useState<any>();
  const [isSubmitted, setSubmitted] = React.useState<boolean>(false);

  const { data, mutate, cancelUpload, uploadProgress, isError, error } = useCreateReport();

  const handleModalClose = () => {
    // reset states and clear upload
    if (isSubmitted && uploadProgress < 100) {
      if (window.confirm(t('content.reportDetails.confirmClose'))) {
        resetStep();
        cancelUpload();
        setSubmitted(false);
        setFile(undefined);
      }
    } else {
      resetStep();
      setFile(undefined);
    }
  };

  const handleFileSelect = (selectedFile: any) => {
    setFile(selectedFile);
    addStep(isModal);
  };

  const handleSubmit = (data: {
    type: string;
    subtype: string;
    name: string;
  }) => {
    setSubmitted(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', data.name);
    //formData.append('description', '');
    formData.append('type', data.type);
    formData.append('subtype', data.subtype);
    mutate(formData);

    addStep(isModal);
  };

  function getContent() {
    return (
      <div className={styles.wrapper}>
        {step === 1 && <UploadReport onSelect={handleFileSelect} />}
        {step === 2 && (
          <SubmitReport
            fileName={file?.name}
            fileSize={file?.size}
            onChangeFile={(selectedFile) => setFile(selectedFile)}
            onSubmit={handleSubmit}
          />
        )}
        {step === 3 && (
          <>
            <p className={styles.message}>
              [ 0.420074 ] {t('content.uploadingAndScan.uploading')}:{' '}
              {file?.name}
            </p>
            <span className={styles.message}>
              <AsciiProgressBar percent={uploadProgress} />
            </span>

            {uploadProgress === 100 && data && (
              <FakeReportMessage reportId={data} />
            )}

            {isError && error ? (
              <Alert className={styles.error} variant={'danger'}>{error}</Alert>
            ) : null}
          </>
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
      {isModal ? (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <ModalWrapper
              onClose={handleModalClose}
              title={
                step === 1
                  ? t('titles.titleStep1')
                  : step === 2
                    ? t('titles.titleStep2')
                    : step === 3
                      ? t('titles.titleStep3')
                      : ''
              }
            >
              {getContent()}
            </ModalWrapper>
          </div>
        </div>
      ) : getContent()}
    </React.Fragment>
  );
}
