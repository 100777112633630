export const ROUTES = {
  BASE: '/reports',
  NOTIFICATIONS: '/notifications',
  SIGN_IN: '/sign-in',
  //TODO: will be returent in the future:
  // SIGN_UP: '/sign-up',
  // RESET_PASSWORD: '/reset-password',
  VERIFY_EMAIL: '/verify-email',
  NEW_PASSWORD: '/new-password',
  AUTH: '/auth',
  ARCHIVED: '/archived',
  FAVOURITES: '/favourites',
  REPORT_ID: '/:reportId',
  FIELD_ID: '/:fieldId',
  OVERVIEW: '/overview',
  CVE_CHECK: '/cve-check',
  PASSWORD_HASH: '/password-hash',
  CODE_ANALYSIS: '/code-analysis',
  SECURITY_SCAN: '/security-scan',
  UEFI_SECURITY_SCAN: '/uefi-security-scan',
  KERNEL_SECURITY: '/kernel-security',
  BINARY_ANALYSIS: '/binary-analysis',
  NVRAM_ANALYSIS: '/nvram-analysis',
  FILESYSTEM_EXPLORER: '/filesystem-explorer',
  CRYPTO_ANALYZER: '/crypto-analyzer',
  CONFIG_ANALYZER: '/config-analyzer',
  BOM_VIEWER: '/bom-viewer',
  PEIM_DXE: '/peim-dxe',
  SECURE_BOOT: '/secure-boot',
  INTEL_BOOT_GUARD: '/intel-boot-guard',
  ACCESS_RIGHT: '/access-right',
  ATTACK_SURFACE_ANALYZER: '/attack-surface-analyzer',
  RTOS_FUNCTIONS: '/functions-analysis',
  RTOS_CAPABILITIES: '/capabilities-analysis',
  RTOS_TASKS: '/tasks-analysis',
  RTOS_SYMBOLS: '/symbols-analysis',
  USER_SETTINGS: '/settings',
  PLANS: '/plans',
  INVOICE: '/invoice',
  PAYMENTS: '/payments',
  APPEARANCE: '/appearance',
  SUBSCRIPTION: '/subscription',
  ORGANIZATION: '/organization',
  ORGANIZATION_OVERVIEW: '/overview',
  ORGANIZATION_SETTINGS: '/:organizationId/edit',
};
