import { WithChildren } from '@utils/types/helpers';
import styles from './styles.module.scss';
import type { IconType } from 'react-icons';
import { getCssVariable } from '@utils/index';
import { noop } from 'lodash';

type Props = WithChildren<{
  title?: string;
  trailingIcon?: IconType;
  iconColor?: string;
  onTrailingIconClick?: () => void;
}>;

export default function Navigation({
  trailingIcon,
  iconColor = 'neutral300',
  title,
  children,
  onTrailingIconClick = noop,
}: Props) {
  const TrailingIcon = trailingIcon ? trailingIcon : null;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {title && <p>{title}</p>}
        {TrailingIcon && (
          <div className={styles.trailingIcon} onClick={onTrailingIconClick}>
            <TrailingIcon size={24} color={getCssVariable(iconColor)} />
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
