import styles from '../styles.module.scss';
import { WithChildren } from '@utils/types/helpers';
import type { IconType } from 'react-icons';
import { getCssVariable } from '@utils/index';
import { RiInformationLine } from 'react-icons/ri';

type Props = WithChildren<{
  leadingIcon?: IconType;
  title?: string;
  tooltipMessage?: string;
  additionalInfo?: string;
  bannerText?: string;
}>;

const SectionHeader = ({
  children,
  title,
  tooltipMessage = '',
  leadingIcon,
  additionalInfo = '',
  bannerText = '',
}: Props) => {
  const LeadingIcon = leadingIcon ? leadingIcon : null;
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.title}>
          {LeadingIcon && (
            <div className={styles.leadingIcon}>
              <LeadingIcon size={24} className="leading-icon" />
            </div>
          )}
          {title ? <p>{title}</p> : ''}
          {additionalInfo ? (
            <span className={styles['additionalInfo']}>{additionalInfo}</span>
          ) : null}
          {tooltipMessage ? (
            <span className={styles.tooltipOld} data-tip={tooltipMessage}>
              <RiInformationLine
                color={getCssVariable('neutral400')}
                size={14}
              />
            </span>
          ) : null}
        </div>
        <div className={styles.content}>{children}</div>
      </div>
      {bannerText && (
        <div className={styles['banner-text-header']}>
          <span>{bannerText}</span>
        </div>
      )}
    </div>
  );
};

export default SectionHeader;
