import TabsProvider from '@components/Tabs/TabsProvider';
import { TabsHeader } from '@components/TabsHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiSpamLine } from 'react-icons/ri';
// import CveCheckTable from '@pages/Report/Linux/ReportOverviewLinux/Tables/CveCheckTable';
import tabsStyles from '@components/TabsHeader/styles.module.scss';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionContentRight from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentRight';
import SectionContentRightBody from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentRightBody';
import SectionContentRightFooter from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentRightFooter';
import SectionContentRightHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentRightHeader';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import tabStyles from '@pages/Report/Linux/ReportOverviewLinux/styles.module.scss';
import PeimDxeGraph from '@pages/Report/Uefi/Overview/Graphs/PeimDxeGraph';
import { ROUTES } from '@routes/routes';
import { getCssVariable } from '@utils/index';
import { format } from 'date-fns';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import PeimTable from '../Tables/PeimTable';
import styles from '../styles.module.scss';

type PeimDxeContainerProps = {
  data: any;
  date?: string;
};

type PeimDxeData = {
  certificates: Array<any>;
  dependencies: Array<string>;
  filetype: string;
  format: string;
  machine: string;
  name: string;
  signed: boolean;
  uefiType: string;
  hasUnknown: boolean;
  loc?: number;
  color: string;
};

const PeimDxeContainer = (props: PeimDxeContainerProps) => {
  const { data, date } = props;

  const { reportId = '' }: { reportId: string } = useParams();
  const history = useHistory();
  const { t } = useTranslation(['common', 'pages']);
  const [activeIndex, setActiveIndex] = React.useState<string>('0');

  const dataType: any = React.useMemo(() => {
    return {
      '0': 'DXE driver',
      '1': 'PEI module',
    };
  }, []);

  const countElement = (dataArray: PeimDxeData[], index: string): number => {
    if (!dataArray || dataArray.length < 1) return 0;
    return dataArray.filter((el) => el.uefiType === dataType[index]).length;
  };

  const filterDataElement = (
    dataArray: PeimDxeData[],
    index: string
  ): PeimDxeData[] => {
    if (!dataArray || dataArray.length < 1) return [];
    return dataArray
      .filter((el) => el.uefiType === dataType[index])
      .slice(0, 4);
  };

  const tabsState = React.useMemo(
    () => ({
      id: 'controlled-tab',
      activeKey: activeIndex,
      className: `mb-3 ${tabStyles['section-content-right']}`,
      style: { backgroundColor: '#F6F8FA' },
      onSelect: (k: string) => setActiveIndex(k),
      tabs: [
        {
          eventKey: 0,
          tabClassName: `${tabsStyles['tab-header']} ${activeIndex === '0'
              ? tabsStyles['active-tab']
              : tabsStyles['inactive-tab']
            }`,
          title: (
            <TabsHeader
              title="PEIM"
              number={countElement(data, '1')}
              // variation={'5.21'}
              color={getCssVariable('cyan')}
              tooltipMessage="Since the last check"
            />
          ),
        },
        {
          eventKey: 1,
          tabClassName: `${tabsStyles['tab-header']} ${activeIndex === '1'
              ? tabsStyles['active-tab']
              : tabsStyles['inactive-tab']
            }`,
          title: (
            <TabsHeader
              title="DXE"
              number={countElement(data, '0')}
              // variation={'5.21'}
              color={getCssVariable('violet')}
              tooltipMessage="Since the last check"
            />
          ),
        },
      ],
    }),
    [activeIndex, data]
  );

  const handleActiveIndexDefault = (data: any): string => {
    if (!data) return '0';
    const firstTabL = countElement(data, '1');
    const secondTabL = countElement(data, '0');
    if (firstTabL > 0) {
      return '0';
    }
    if (secondTabL > 0) {
      return '0';
    }
    return '0';
  };

  React.useEffect(() => {
    setActiveIndex(handleActiveIndexDefault(data));
  }, [data]);

  return (
    <SectionLayout>
      <SectionHeader
        title={t('pages:overviewPage.sections.peimDxe.title')}
        leadingIcon={RiSpamLine}
        tooltipMessage={t('pages:overviewPage.sections.peimDxe.tooltipMessage')}
      ></SectionHeader>
      <SectionContent>
        <SectionContentLeft verticalMessage={t('pages:peimDxePage.title')}>
          <PeimDxeGraph data={data} />
        </SectionContentLeft>
        <SectionContentRight style={{ height: '100%' }}>
          {/* <SectionContentRightHeader
            className={styles['section-content-right-header']}
          >
            <TabsProvider state={tabsState} />
          </SectionContentRightHeader> */}
          <SectionContentRightBody
            className={styles['section-content-right-body']}
            style={{ height: 'auto' }}
          >
            <PeimTable data={filterDataElement(data, activeIndex)} />
          </SectionContentRightBody>
          <SectionContentRightFooter style={{ marginTop: 'auto' }}>
            <div
              className={`d-flex justify-content-between align-items-center w-100 px-3 pb-3 ${styles['section-content-footer']}`}
            >
              <span className={styles['sub-text']}>
                Last PEIM/DXE analysis{' '}
                {date ? format(new Date(date), 'dd/MM/yy HH:mm') : null}
              </span>
              <Button
                onClick={() =>
                  history.push(`${ROUTES.BASE}/${reportId}${ROUTES.PEIM_DXE}`)
                }
                className={tabStyles['button']}
              >
                VIEW ALL
              </Button>
            </div>
          </SectionContentRightFooter>
        </SectionContentRight>
      </SectionContent>
    </SectionLayout>
  );
};

export default PeimDxeContainer;
