import { getCssVariable } from '@utils/index';
import { useMemo } from 'react';
import SectionContent from '../Components/SectionContent';
import SectionContentLeft from '../Components/SectionContentLeft';
import SectionContentRight from '../Components/SectionContentRight';
import SectionContentRightBody from '../Components/SectionContentRightBody';
import SectionLayout from '../Components/SectionLayout';
import BinaryTable from '../Tables/BinaryTable';
import styles from '../styles.module.scss';

import GraphLegend from '@components/GraphLegend';
import { ROUTES } from '@routes/routes';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiTerminalBoxLine } from 'react-icons/ri';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import SectionHeader from '../Components/SectionHeader';
import BarGraph from '../Graphs/BarGraph';

type BinaryAnalysisTableContainerProps = {
  data: any;
};

const BinaryAnalysisTableContainer = (
  props: BinaryAnalysisTableContainerProps
) => {
  const { data } = props;
  const { reportId = '' }: { reportId: string } = useParams();
  const history = useHistory();
  const { t } = useTranslation(['common', 'pages']);


  // const breakPointsLeft = {
  //   xs: 12,
  //   sm: 12,
  //   md: 3,
  //   lg: 3,
  //   xl: 3,
  // };
  // const breakPointsRight = {
  //   xs: 12,
  //   sm: 12,
  //   md: 9,
  //   lg: 9,
  //   xl: 9,
  // };

  const affectedFilesCount = useMemo(() => data?.critical.severity + data?.high.severity + data?.medium.severity + data?.low.severity, [data]);

  const typeCounts = useMemo(() => [
    {
      'key': 'critical',
      'count': data?.critical.severity,
    }, {
      'key': 'high',
      'count': data?.high.severity,
    }, {
      'key': 'medium',
      'count': data?.medium.severity,
    }, {
      'key': 'low',
      'count': data?.low.severity,
    }], [data]);

  const legendData: any = useMemo(() => {
    const transformedObject: any = {};

    // Iterate through the array and assign key-value pairs to the transformed object
    typeCounts.forEach(item => {
      transformedObject[item.key] = item.count;
    });

    return transformedObject;

  }, [typeCounts]);

  const allData = useMemo(() => [...data?.critical.preview, ...data?.high.preview, ...data?.medium.preview, ...data?.low.preview], [data]);


  return (
    <SectionLayout>
      <SectionHeader
        title={t('pages:overviewPage.sections.binaryAnalysis.title')}
        leadingIcon={RiTerminalBoxLine}
        tooltipMessage={t(
          'pages:overviewPage.sections.binaryAnalysis.tooltipMessage'
        )}
      />
      <SectionContent
      // breakPointsLeft={breakPointsLeft}
      // breakPointsRight={breakPointsRight}
      >
        <SectionContentLeft>
          <div className={styles['left-title']}>
            <div>compiler settings security</div>
            <Button
              onClick={() =>
                history.push(`${ROUTES.BASE}/${reportId}${ROUTES.BINARY_ANALYSIS}`)
              }
              // @ts-ignore
              className={styles['button']}
            >
              VIEW ALL
            </Button>
          </div>
          <div className={styles.count}>{affectedFilesCount}</div>
          <GraphLegend items={legendData} colors={[getCssVariable('bs-purple'), getCssVariable('bs-red'), getCssVariable('bs-orange'), getCssVariable('bs-yellow')]} />
          <div style={{ position: 'relative', height: '250px' }}>
            <BarGraph data={typeCounts} hasBackground={false} />
          </div>
        </SectionContentLeft>
        <SectionContentRight >
          <SectionContentRightBody
            className={`${styles['section-content-right-body']} ${styles['section-content-right-body-binary']}`}
          >
            <BinaryTable data={allData} />
          </SectionContentRightBody>
        </SectionContentRight>
      </SectionContent>
    </SectionLayout>
  );
};

export default BinaryAnalysisTableContainer;
