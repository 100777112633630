import { useMutation, useQueryClient } from 'react-query';
import { updateReportFromApi } from '@services/api';
import type { Report } from '@utils/types/report';
import { noop } from 'lodash';
import type { ReportPayload } from '@services/api';
import { KEY as REPORTS_KEY } from './useReports';
import { KEY as REPORT_KEY } from './useReport';
import { KEY as REPORT_OVERVIEW_KEY } from './useReportOverview';
import useToken from './useToken';

export default function useCreateReport(onSuccess?: typeof noop) {
  type Payload = {
    id: string;
    payload: ReportPayload;
  };

  useToken();
  const queryClient = useQueryClient();
  return {
    ...useMutation<void, Error, Payload>(
      'create-report',
      async (reportPayload: Payload): Promise<void> => {
        await updateReportFromApi(reportPayload?.id, reportPayload?.payload);
      },
      {
        onMutate: async () => {
          await queryClient.cancelQueries([REPORTS_KEY]);
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _variables, context: any) => {
          if (context?.previousReports) {
            queryClient.setQueryData<Report[]>(
              [REPORTS_KEY],
              context.previousReports
            );
          }
          if (err) {
            console.log(err);
          }
        },
        onSuccess: (_: any, context) => {
          onSuccess && onSuccess();
          queryClient.invalidateQueries(REPORTS_KEY);
          queryClient.invalidateQueries([REPORT_OVERVIEW_KEY, context?.id]);
          queryClient.invalidateQueries([REPORT_KEY, context?.id]);
        },
      }
    ),
  };
}
