import React from 'react';

const BSMenuContext = React.createContext(false);

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}

const BSMenuProvider = ({ children } : IProps) => {
  const [ open, setOpen ] = React.useState(false);

  function toggleMenu(): void {
    setOpen((cur) => !cur);
  }

  return <BSMenuContext.Provider value={{ open, toggleMenu } as any}>
    {children}
  </BSMenuContext.Provider>;
};

export { BSMenuContext, BSMenuProvider };