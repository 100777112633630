import NewScanModal from '@components/NewScanModal';
import UserDropdown from '@components/UserDropdown';
import { NewScanContext } from '@contexts/NewScanContext';
import { ROUTES } from '@routes/routes';
import * as React from 'react';
import {
  RiAddFill,
  RiArrowDownSLine,
  RiBookOpenFill,
  RiMailLine,
} from 'react-icons/ri';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import styles from './styles.module.scss';
// import NotificationIconLine from '@components/Icons/NotificationIconLine';
import LogoDashboard from '@assets/img/static_illustrations/logo_dashboard.png';
import Breadcrumbs, { SIZE } from '@components/Breadcrumbs';
import Button, { BUTTON_SIZE, BUTTON_TYPE } from '@components/Button';
import ContextMenu from '@components/ContextMenu';
import DeleteReportModal from '@components/DeleteReportModal';
import { Textarea } from '@components/Forms/Textarea';
import ContactForm from '@components/LayoutOnBoarding/ContactForm';
import ModalCreateOrganization from '@components/ModalCreateOrganization/ModalCreateOrganization';
import ModalWrapper from '@components/ModalWrapper';
import RenameReportModal from '@components/RenameReportModal';
import { MODAL_TYPE } from '@components/ReportList';
import CustomTooltip from '@components/Tooltip/Tooltip';
import { CurrentReportContext } from '@contexts/CurrentReport';
import { ReportActionsContext } from '@contexts/ReportActionsContext';
import useCreateFeedback from '@hooks/support/useCreateFeedback';
import useDialog from '@hooks/useDialog';
import { useOrganizations } from '@hooks/useOrganizations';
import useReadCategories from '@hooks/useReadCategories';
import useUser from '@hooks/useUser';
import { getCssVariable, getTokenOnLocalstorage } from '@utils/index';
import { OrganizationType } from '@utils/types/organization';
import { get } from 'lodash';
import { ListGroup, Overlay } from 'react-bootstrap';
import { Item, useContextMenu } from 'react-contexify';
import { useTranslation } from 'react-i18next';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { IoIosArrowDown, IoMdSwitch } from 'react-icons/io';
import { RiChat3Line, RiQuestionFill } from 'react-icons/ri';

interface IProps {
  signOut: () => void;
}

export default function Header({ signOut }: IProps) {
  const { data: user } = useUser();
  // const { isPro = false }: any = user;
  const { modal, step, addStep } = React.useContext<any>(NewScanContext);
  const { t } = useTranslation(['common', 'pages']);
  const { report } = React.useContext(CurrentReportContext);
  const { show } = useContextMenu({ id: report?.id });
  const { show: showWorkSpace } = useContextMenu({ id: 'workSpaceId' });
  const { show: showAddMenu } = useContextMenu({ id: 'addMenu' });

  const buttonRef = React.useRef<any>();
  const { data: organizationsData, isLoading: isLoadingOrganizations } =
    useOrganizations();
  const { mutate: createFeedback } = useCreateFeedback();

  const addRef = React.useRef<any>();

  const createOrganizationModal = useDialog('create-organization-modal');

  const [selectedOrganization, setSelectedOrganization] =
    React.useState<OrganizationType | null>(null);

  const [modalType, setModalType] = React.useState<MODAL_TYPE | null>(null);
  const [subscription, setSubscription] = React.useState<boolean>(false);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [feedbackMessage, setFeedbackMessage] = React.useState<string>('');
  const categories = useReadCategories();
  const contactFormModal = useDialog('contact-form-modal');
  const triggerRef = React.useRef<any>();
  const match = useRouteMatch(`${ROUTES.BASE}`);
  const isReportF = useRouteMatch(`${ROUTES.BASE}${ROUTES.FAVOURITES}`);
  const isReportA = useRouteMatch(`${ROUTES.BASE}${ROUTES.ARCHIVED}`);
  const isUserSettings = useRouteMatch(`${ROUTES.BASE}${ROUTES.USER_SETTINGS}`);
  const isOrganization = useRouteMatch(
    `${ROUTES.BASE}${ROUTES.USER_SETTINGS}${ROUTES.ORGANIZATION}`
  );
  const isPlan = useRouteMatch(
    `${ROUTES.BASE}${ROUTES.USER_SETTINGS}${ROUTES.SUBSCRIPTION}${ROUTES.PLANS}`
  );
  const isInvoice = useRouteMatch(
    `${ROUTES.BASE}${ROUTES.USER_SETTINGS}${ROUTES.SUBSCRIPTION}${ROUTES.INVOICE}`
  );
  const isPayments = useRouteMatch(
    `${ROUTES.BASE}${ROUTES.USER_SETTINGS}${ROUTES.SUBSCRIPTION}${ROUTES.PAYMENTS}`
  );
  const isNotificationPage = useRouteMatch(ROUTES.BASE + ROUTES.NOTIFICATIONS);
  const history = useHistory();
  const token: string = getTokenOnLocalstorage();

  const shouldHideOrganization = React.useMemo(
    () => false,
    //TODO: unhide once it's ready
    // !!(
    //   customerSubData?.length === 0 ||
    //   customerSubData?.find((sub) => sub.plan.key === 'pro')
    // ),
    []
  );

  const onUpgrade = () => {
    history.push(
      `${ROUTES.BASE}${ROUTES.USER_SETTINGS}${ROUTES.SUBSCRIPTION}${ROUTES.PLANS}`
    );
  };
  const { show: showHelp } = useContextMenu({ id: 'helpDropdown' });
  //const { show: showFeedback } = useContextMenu({ id: 'feedbackDropdown' });
  const [showFeedbackModal, setShowFeedbackModal] =
    React.useState<boolean>(false);
  const helpRef = React.useRef<any>();
  const feedbackRef = React.useRef<any>();
  // useEffect(() => {
  //   if (token) {
  //     setLoading(true);
  // getActiveCustomerSubscription()
  //   .then((sub) => {
  //     if (sub === null) setSubscription(true);
  //   })
  //   .catch((e) => console.log)
  //   .finally(() => {
  //     setLoading(false);
  //   });
  //   }
  // }, [token]);

  const isValidRoute = () => {
    return (
      match?.isExact ||
      isNotificationPage?.isExact ||
      isReportA?.isExact ||
      isReportF?.isExact ||
      isUserSettings?.isExact ||
      isPayments?.isExact ||
      isPlan?.isExact ||
      isPlan?.isExact ||
      isOrganization ||
      isInvoice?.isExact
    );
  };

  function getMenuPosition() {
    const { left, bottom } = triggerRef.current.getBoundingClientRect();
    return { x: left + 12, y: bottom - 15 };
  }

  function handleContextMenu(event: any) {
    event.preventDefault();
    show(event, {
      position: { ...getMenuPosition() },
    });
  }

  const { setReportData } = React.useContext<any>(ReportActionsContext);

  function handleSetModal(data: any, type: MODAL_TYPE) {
    setModalType(type);
    setReportData({ ...data });
  }

  function handleDeleteReport({ event }: { event: React.SyntheticEvent }) {
    handleSetModal({ id: report?.id, name: report?.name }, MODAL_TYPE.DELETE);
    event.preventDefault();
  }

  function handleUpdateReport({ event }: { event: React.SyntheticEvent }) {
    handleSetModal({ id: report?.id, name: report?.name }, MODAL_TYPE.UPDATE);
    event.preventDefault();
  }

  function onDeleteSuccess() {
    history.push(ROUTES.BASE);
  }

  function getShowWorkSpace() {
    const { left, bottom } = buttonRef.current.getBoundingClientRect();
    return { x: left - 0, y: bottom + 0 };
  }

  const onShowWorkSpace = (e: any) => {
    e.preventDefault();
    showWorkSpace(e, {
      position: { ...getShowWorkSpace() },
    });
  };

  function getShowAddMenu() {
    const { left, bottom } = addRef.current.getBoundingClientRect();
    return { x: left - 190, y: bottom + 10 };
  }

  const onShowAddMenu = React.useCallback((e: any) => {
    e.preventDefault();
    showAddMenu(e, {
      position: { ...getShowAddMenu() },
    });
  }, []);

  const onInitOrganization = (organizationsData: any) => {
    const defaultOrgUID = localStorage.getItem('organizationUID');

    if (!defaultOrgUID) {
      console.log('organizationsData', organizationsData);
      setSelectedOrganization(
        organizationsData.find((org: OrganizationType) => org.built_in)
      );
    } else {
      setSelectedOrganization(() =>
        organizationsData.find(
          (org: OrganizationType) => org.id === defaultOrgUID
        )
      );
    }
  };

  const onSetOrganizationUidLocalStorage = (value: any) => {
    localStorage.setItem('organizationUID', value);
    history.push(ROUTES.BASE);
    window.location.reload();
  };

  const onRenderOrganizationsOption = () => {
    if (
      Array.isArray(organizationsData) &&
      organizationsData.length > 0 &&
      selectedOrganization
    ) {
      return organizationsData.map((org) => (
        <div
          className={styles['workspace-option']}
          style={{ padding: '0.5rem 0.8rem' }}
          key={org.id}
          onClick={() => onSetOrganizationUidLocalStorage(org.id)}
        >
          <p className={styles['workspace-name']}>{org.name}</p>
          <p className={styles['workspace-description']}>{org.description}</p>
        </div>
      ));
    }

    return [];
  };

  React.useEffect(() => {
    if (!isLoadingOrganizations && organizationsData) {
      onInitOrganization(organizationsData);
    }
  }, [organizationsData]);

  function getHelpMenuPosition() {
    const { left, bottom } = helpRef.current.getBoundingClientRect();
    return { x: left - 210, y: bottom + 30 };
  }

  /*function getFeedbackMenuPosition() {
    const { left, bottom } = feedbackRef.current.getBoundingClientRect();
    return { x: left - 210, y: bottom + 30 };
  }*/

  const onHelpClick = (e: any) => {
    e.preventDefault();
    showHelp(e, {
      position: { ...getHelpMenuPosition() },
    });
  };
  const onFeedbackClick = (e: any) => {
    e.preventDefault();
    setShowFeedbackModal(true);
    // showFeedback(e, {
    //   position: { ...getFeedbackMenuPosition() },
    // });
  };

  const handleCreateFeedback = async () => {
    try {
      await createFeedback(feedbackMessage);
    } catch (e) {
      console.log(e);
      setShowFeedbackModal(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.leftGroup}>
          <Link to={ROUTES.BASE}>
            <span className={styles.avatar}>
              <img src={LogoDashboard} alt={get(user, 'given_name', '')} />
            </span>
            {/*<span className={styles.avatar}>*/}
            {/*  {}*/}
            {/*</span>*/}
          </Link>
          {isValidRoute() && selectedOrganization ? (
            <>
              <ContextMenu id="workSpaceId" style={{ width: '250px' }}>
                {onRenderOrganizationsOption()}
              </ContextMenu>
              <div
                className={styles['button-workspace']}
                ref={buttonRef}
                onClick={onShowWorkSpace}
              >
                <IoMdSwitch
                  style={{
                    width: '25px',
                    height: '25px',
                    marginRight: '0.25rem',
                  }}
                />
                <span style={{ paddingRight: '0.5rem', fontWeight: 'bold' }}>
                  {selectedOrganization.name}
                </span>
                <IoIosArrowDown />
              </div>
            </>
          ) : (
            <>
              <span className={styles.separator}>›</span>
              <Breadcrumbs
                size={SIZE.SMALL}
                separator={'›'}
                paths={[
                  {
                    name: report?.name ?? '',
                  },
                ]}
              />
              <span
                className={styles.reportActions}
                onClick={handleContextMenu}
                ref={triggerRef}
              >
                <HiOutlineDotsHorizontal
                  size={18}
                  color={getCssVariable('neutral400')}
                />
              </span>
              <div
                style={{ position: 'absolute', top: -9999 }}
                className={'context-menu'}
              >
                <ContextMenu id={report?.id ?? ''}>
                  <Item onClick={handleUpdateReport}>
                    {t('common:reportActions.renameTitle')}
                  </Item>
                  <Item className={'is-crimson'} onClick={handleDeleteReport}>
                    {t('common:reportActions.deleteTitle')}
                  </Item>
                </ContextMenu>
              </div>
            </>
          )}
        </div>
        {/* <div className={styles.searchText}></div> */}
        <div className={styles.rightGroup}>
          <div className={styles.icons}>
            <button
              ref={helpRef}
              onClick={onHelpClick}
              className={styles['navbar-button']}
            >
              <RiQuestionFill />
              <span>{t('pages:authPage.helpButton')}</span>
            </button>
            <button
              ref={feedbackRef}
              onClick={onFeedbackClick}
              className={styles['navbar-button']}
            >
              <RiChat3Line />
              <span>{t('pages:authPage.feedbackButton')}</span>
            </button>
            {!isLoading && subscription ? (
              <Button
                onClick={onUpgrade}
                type={BUTTON_TYPE.GREEN}
                size={BUTTON_SIZE.EXTRA_SMALL}
              >
                Upgrade
              </Button>
            ) : null}
            {/*<Link to={ROUTES.BASE + ROUTES.NOTIFICATIONS}>
              <span className={styles.notification}>
                <span className={styles.notificationAlert}></span>
                <NotificationIconLine
                  size={24}
                  color={getCssVariable('neutral400')}
                />
              </span>
            </Link>*/}
            <ContextMenu id="addMenu">
              <div className="dropdown-panel">
                <ListGroup
                  className="font-monospace header-dropdown-action"
                  variant="flush"
                  as="ul"
                >
                  {/*<ListGroup.Item onClick={addStep} as='li' style={{color: getCssVariable('general-green')}}>{t('common:buttonLabels.newFirmware')}</ListGroup.Item>*/}
                  {/*<ListGroup.Item onClick={() => console.log} as='li'>{t('common:buttonLabels.newOrganization')}</ListGroup.Item>*/}
                  <ListGroup.Item
                    onClick={addStep}
                    as="li"
                    style={{
                      cursor: 'pointer',
                      border: '0',
                      color: getCssVariable('general-green'),
                    }}
                  >
                    {'Upload new Firmware'}
                  </ListGroup.Item>
                  {shouldHideOrganization ? (
                    <CustomTooltip
                      label="Available only in BUSINESS and ENTERPRISE plans"
                      placement="bottom"
                    >
                      <div className={styles.disabled}>
                        Create new Organization
                      </div>
                    </CustomTooltip>
                  ) : (
                    <ListGroup.Item
                      onClick={createOrganizationModal.open}
                      as="li"
                      style={{
                        cursor: 'pointer',
                        border: '0',
                      }}
                    >
                      {'Create new Organization'}
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </div>
            </ContextMenu>
            <button ref={addRef} onClick={onShowAddMenu}>
              <RiAddFill size={24} color={getCssVariable('neutral400')} />
              <RiArrowDownSLine
                size={18}
                color={getCssVariable('neutral400')}
              />
            </button>
          </div>
          <UserDropdown logout={signOut} />
        </div>
      </div>
      {step !== 0 && <NewScanModal isModal={modal} />}
      {createOrganizationModal.opened && (
        <ModalCreateOrganization onClose={createOrganizationModal.close} />
      )}
      {modalType === MODAL_TYPE.DELETE ? (
        <DeleteReportModal
          onSuccess={onDeleteSuccess}
          onClose={handleSetModal}
        />
      ) : null}

      {modalType === MODAL_TYPE.UPDATE ? (
        <RenameReportModal onClose={handleSetModal} />
      ) : null}

      <Overlay
        rootClose={true}
        target={feedbackRef.current}
        show={showFeedbackModal}
        placement="bottom"
      >
        <div className={styles['navbar-feedback-message-container']}>
          <h6 className={styles['navbar-menu-title']}>
            {t('pages:authPage.feedback.feedbackTitle')}
          </h6>
          <Textarea
            // className={styles['navbar-feedback-message']}
            rows={6}
            onChange={(e: any) => {
              setFeedbackMessage(e.target.value);
            }}
            value={feedbackMessage}
          />
          <div className="d-flex flex-row justify-content-between w-100 mb-4">
            <button
              onClick={() => setShowFeedbackModal(false)}
              className={'btn btn-outline-secondary btn-sm'}
            >
              {t('pages:authPage.feedback.feedbackCancelButton')}
            </button>
            <button
              className={'btn btn-primary btn-sm'}
              onClick={handleCreateFeedback}
            >
              {t('pages:authPage.feedback.feedbackSendButton')}
            </button>
          </div>
          {/* <div className={styles['navbar-feedback-info-container']}>
            <span>Lorem ipsum dolor sit amet</span>
          </div> */}
        </div>
      </Overlay>
      <ContextMenu id="helpDropdown">
        <div className="d-flex flex-column justify-content-center align-items-center p-3">
          <div className="d-flex flex-column justify-content-center  pb-2 w-100">
            <h6 className={styles['navbar-menu-title']}>
              {t('pages:authPage.help.helpTitle')}
            </h6>
            {/* <p className={styles['navbar-menu-message']}>
              {t('pages:authPage.help.helpMessage')}
            </p> */}
            <div className="d-flex flex-row">
              <button
                className={styles['navbar-menu-button']}
                onClick={contactFormModal.open}
              >
                <RiMailLine />
                <span style={{ marginLeft: '.5rem' }}>
                  {t('pages:authPage.help.contactSupportButton')}
                </span>
              </button>
              <a
                className={styles['navbar-menu-button']}
                style={{ backgroundColor: '#eaeaea', marginLeft: '.75rem' }}
                href="https://docs.exein.io/cosmo/introduction"
                rel="noopener noreferrer"
                target="_blank"
              >
                <RiBookOpenFill style={{ color: 'inherit' }} />
                <span style={{ marginLeft: '.5rem', color: 'inherit' }}>
                  {t('pages:authPage.help.docsButton')}
                </span>
              </a>
            </div>
          </div>
          {/* <div className="d-flex flex-column justify-content-center pt-4 w-100">
            <h6 className={styles['navbar-menu-title']}>
              {t('pages:authPage.help.communityTitle')}
            </h6>
            <p className={styles['navbar-menu-message']}>
              {t('pages:authPage.help.communityMessage')}
            </p>
            <div
              className="d-flex align-items-center justify-content-center w-100"
              style={{ height: '100px' }}
            >
              IMAGE
            </div>
          </div> */}
        </div>
      </ContextMenu>
      {contactFormModal.opened ? (
        <ModalWrapper
          onClose={contactFormModal.close}
          title={t('pages:authPage.contact.contactTitle')}
          className="w-75"
        >
          <ContactForm
            onClose={contactFormModal.close}
            categories={categories}
          />
        </ModalWrapper>
      ) : null}
    </>
  );
}
