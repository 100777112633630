import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import Button, { BUTTON_TYPE } from '@components/Button';

import styles from './styles.module.scss';

type Props = {
  onSelect: (file: any) => void;
};

export default function UploadReport({ onSelect }: Props) {
  const { t } = useTranslation('scanModal');

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragActive,
  } = useDropzone({
    validator: (file: any) => {
      if (file.size >= 3096 * 1000000) {
        return {
          code: '500',
          message: t('content.dragNdrop.error'),
        };
      }
      return null;
    },
    onDropAccepted: (files: any[]) => {
      // wait for animation to execute then trigger callback
      setTimeout(() => onSelect(files[0]), 1200);
    },
    multiple: false,
    noClick: true,
  });

  return (
    <div className={styles.container}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <div
          className={`${styles.animation} ${
            acceptedFiles.length > 0 ? styles.droppped : ''
          } ${isDragActive ? styles.dragging : ''}`}
        >
          <svg width="36" height="38">
            <path
              d="M0 34h36v4H0v-4zM20 7.656V30h-4V7.656L3.858 19.8 1.03 16.972 18 0l16.97 16.97-2.828 2.828L20 7.66v-.004z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
        </div>
        <h3>{t('content.dragNdrop.title')}</h3>
        <p>{t('content.dragNdrop.subtitle')}</p>
        <p>
          {t('content.dragNdrop.instructionsPreLink')}{' '}
          <a
            href="https://docs.exein.io/cosmo/get_started/dashboard#firmware-image-upload"
            target="_blank"
            rel="noreferrer"
          >
            {t('content.dragNdrop.instructionsLink')}
          </a>{' '}
          {t('content.dragNdrop.instructionsPostLink')}
        </p>
        {acceptedFiles.length === 0 && (
          <Button type={BUTTON_TYPE.GREEN} onClick={open}>
            {t('content.dragNdrop.selectButton')}
          </Button>
        )}
        <p className={'mt-3'}>
          {fileRejections.length === 1 &&
            fileRejections.map(({ file, errors }) =>
              errors.map((e) => (
                <span className={'text-danger fw-bold'}>{e.message}</span>
              ))
            )}
        </p>
      </div>
      <p>
        {t('content.dragNdrop.agreeUpload')}{' '}
        <a href="https://www.exein.io/" target="_blank" rel="noreferrer">
          {t('content.dragNdrop.termsOfService')}
        </a>
      </p>
      <p>
        {t('content.dragNdrop.copy')}{' '}
        <a
          href="https://www.exein.io/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          {t('content.dragNdrop.findOutMore')}
        </a>
      </p>
    </div>
  );
}
