import { useQuery } from 'react-query';
import type { ReportStaticCodeAnalysis } from '@utils/types/report';
import { getReportCodeAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'code-analysis';

export default function useCodeAnalysis(id: string) {
  useToken();
  return useQuery<ReportStaticCodeAnalysis[], Error>([KEY, id], () =>
    getReportCodeAnalysis(id),
    {
      retry: false
    }
  );
}
