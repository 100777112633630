import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input from '@components/Input';
import Button, { BUTTON_TYPE } from '@components/Button';
import Spinner, { SPINNER_TYPE, SPINNER_SIZE } from '@components/Spinner';
import { emailRegex } from '@utils/index';
type Props = {
  isSending: boolean;
  successfullySent: boolean;
  userName: string;
  userEmail: string;
  closeModal: () => void;
  deleteUserAccount: () => void;
  onSubmit: ({
    newPassword,
    oldPassword,
    newName,
    newEmail,
    oldPassword2,
  }: {
    newPassword: string;
    oldPassword: string;
    newName: string;
    newEmail: string;
    oldPassword2: string;
  }) => void;
};

export default function UserSettingsForm({
  isSending,
  onSubmit,
  successfullySent,
  userName,
  closeModal,
  userEmail,
  deleteUserAccount,
}: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      newName: userName,
      newEmail: userEmail,
      oldPassword: '',
      newPassword: '',
      oldPassword2: '',
    },
  });

  const { t } = useTranslation(['forms', 'userDropdown']);
  const [showChangePassword, setShowChangePassword] =
    React.useState<boolean>(false);

  const [showChangeEmail, setShowChangeEmail] = React.useState<boolean>(false);

  const [isDirty, setIsDirty] = React.useState<{
    oldPassword: boolean;
    newPassword: boolean;
    newName: boolean;
    newEmail: boolean;
    oldPassword2: boolean;
  }>({
    oldPassword: false,
    newPassword: false,
    newName: false,
    newEmail: false,
    oldPassword2: false,
  });

  function showPass() {
    setShowChangePassword(true);
  }

  function showEmail() {
    setShowChangeEmail(true);
  }

  const { ref: newEmailRef, ...newEmailInputProps } = register('newEmail', {
    required: t('forms:errorsState.invalid.email'),
    pattern: {
      value: emailRegex,
      message: t('forms:errorsState.invalid.email'),
    },
  });

  const { ref: newNameRef, ...newNameInputProps } = register('newName', {
    required: t('forms:errorsState.required.name'),
  });

  const { ref: oldPasswordRef, ...oldPasswordInputProps } = register(
    'oldPassword',
    {
      minLength: {
        value: 6,
        message: t('forms:errorsState.invalid.passwordMinLength'),
      },
    }
  );

  const { ref: oldPasswordRef2, ...oldPasswordInputProps2 } = register(
    'oldPassword2',
    {
      minLength: {
        value: 6,
        message: t('forms:errorsState.invalid.passwordMinLength'),
      },
    }
  );

  const { ref: newPasswordRef, ...newPasswordInputProps } = register(
    'newPassword',
    {
      minLength: {
        value: 6,
        message: t('forms:errorsState.invalid.passwordMinLength'),
      },
    }
  );

  function handleOnSubmit({
    oldPassword,
    oldPassword2,
    newPassword,
    newName,
    newEmail,
  }: {
    oldPassword: string;
    oldPassword2: string;
    newPassword: string;
    newName: string;
    newEmail: string;
  }) {
    setIsDirty({
      newPassword: newPassword.length > 0,
      oldPassword: oldPassword.length > 0,
      oldPassword2: oldPassword2.length > 0,
      newName: newName.length > 0,
      newEmail: newEmail.length > 0,
    });
    onSubmit({ newPassword, oldPassword, oldPassword2, newName, newEmail });
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(handleOnSubmit)} noValidate>
        <div className={styles.wrapper}>
          <Input
            hasValue={isDirty?.newName ?? false}
            type="text"
            label={t('userDropdown:content.settings.name')}
            placeholder={'insert name'}
            inputRef={newNameRef}
            {...newNameInputProps}
            error={errors?.newName?.message}
          />
          <h2 className={styles.inputTitle}>
            {t('userDropdown:content.settings.email')}
          </h2>
          {!showChangeEmail ? (
            <Button type={BUTTON_TYPE.LIGHT_BORDER} onClick={showEmail}>
              {t('userDropdown:labels.changeEmail')}
            </Button>
          ) : (
            <>
              <div className={styles.passwordContainer}>
                <Input
                  hasValue={isDirty?.newEmail ?? false}
                  type="email"
                  placeholder={'insert email'}
                  inputRef={newEmailRef}
                  {...newEmailInputProps}
                  error={errors?.newEmail?.message}
                />
                <Input
                  hasValue={isDirty?.oldPassword2 ?? false}
                  type="password"
                  placeholder={'old password'}
                  inputRef={oldPasswordRef2}
                  {...oldPasswordInputProps2}
                  error={errors?.oldPassword2?.message}
                />
              </div>
            </>
          )}

          <h2 className={`${styles.inputTitle} ${styles.marginTop}`}>
            {t('userDropdown:content.settings.password')}
          </h2>
          {!showChangePassword ? (
            <Button type={BUTTON_TYPE.LIGHT_BORDER} onClick={showPass}>
              {t('userDropdown:labels.changePass')}
            </Button>
          ) : (
            <>
              <div className={styles.passwordContainer}>
                <Input
                  hasValue={isDirty?.oldPassword ?? false}
                  type="password"
                  placeholder={'old password'}
                  inputRef={oldPasswordRef}
                  {...oldPasswordInputProps}
                  error={errors?.oldPassword?.message}
                />

                <Input
                  hasValue={isDirty?.newPassword ?? false}
                  type="password"
                  placeholder={'new password'}
                  inputRef={newPasswordRef}
                  {...newPasswordInputProps}
                  error={errors?.newPassword?.message}
                />
              </div>
            </>
          )}
        </div>
        <div className={styles.buttons}>
          <div>
            <Button type={BUTTON_TYPE.GREEN}>
              <div className={!isSending ? styles.label : styles.hide}>
                {t('userDropdown:labels.update')}
              </div>
              {isSending && (
                <div className={styles.spinnerContainer}>
                  <Spinner
                    type={SPINNER_TYPE.BLACK_GREEN_BKG}
                    size={SPINNER_SIZE.DEFAULT}
                  />
                </div>
              )}
            </Button>
            <div className={styles.cancelBtn} onClick={closeModal}>
              {t('userDropdown:labels.cancel')}
            </div>
          </div>
          <div className={styles.deleteBtn} onClick={deleteUserAccount}>
            <p>{t('userDropdown:labels.deleteUserAccount')}</p>
          </div>
        </div>
      </form>
    </div>
  );
}
