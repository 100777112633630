import * as React from 'react';
import { WithChildren } from '@utils/types/helpers';
import { RiCloseFill } from 'react-icons/ri';

import styles from './styles.module.scss';

type Props = WithChildren<{
  title: string;
  className?: string;
  onClose?: () => void;
  navigation?: React.ReactNode;
}>;

export default function ModalWrapper({
  title,
  children,
  navigation,
  className = '',
  onClose,
}: Props) {
  React.useEffect(() => {
    document.body.classList.add('no-scroll');

    return function cleanup() {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  return (
    <div className={styles.overlay}>
      <div
        className={`
          ${!navigation
            ? styles.container
            : `${styles.container} ${styles.withSidebar}`}
          ${className}
          `
        }
      >
        {navigation && <div className={styles.sidebar}>{navigation}</div>}
        <div className={styles.contentWrapper}>
          <div className={styles.header}>
            <span className={styles.title}>{title}</span>
            <button className={styles.close} onClick={onClose}>
              <RiCloseFill size={24} />
            </button>
          </div>
          <div
            className={
              !navigation ? styles.body : `${styles.body} ${styles.withSidebar}`
            }
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
