import { getReportsListFromApi } from '@services/api';
import type { Report } from '@utils/types/report';
import { useQuery } from 'react-query';
import useToken from './useToken';

export const KEY = 'reports';

export default function useReports(onSuccess?: any) {
  useToken();
  // const organizationUID = localStorage.getItem('organizationUID');

  const opts = {
    refetchOnWindowFocus: false,
    // enabled: !!organizationUID,
  };

  if (onSuccess) {
    Object.assign(opts, {
      onSuccess: onSuccess,
    });
  }

  return useQuery<Report[], Error>(
    KEY,
    async () => getReportsListFromApi(),
    opts
  );
}
