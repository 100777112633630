import UserSettingsForm from '@components/UserSettingsForm';
import { UserContext } from '@contexts/UserContext';
import useUser from '@hooks/useUser';
import React from 'react';
import { get } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';

type Props = {
  name: string;
  email: string;
  closeModal: () => void;
  signOut: () => void;
};

export default function UserSettings({
  name,
  email,
  closeModal,
  signOut,
}: Props) {
  const { t } = useTranslation('userDropdown');
  const { url, setURL, setFile } = React.useContext<any>(UserContext);
  const [error, setError] = React.useState<string>('');
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [successfullySent, setSuccessfullySent] =
    React.useState<boolean>(false);
  const { data: user } = useUser();

  const currentEmail = user?.email;
  const history = useHistory();
  // const deleteUserAccount = async () => {
  //   try {
  //     await db.collection('users').doc(user.uid).delete();

  //     await firebase.storage().ref(`users/${user.uid}/profile.jpg`).delete();

  //     auth && (await auth.delete());
  //     signOut();
  //   } catch (err) {
  //     if (err instanceof Error) {
  //       console.error('user', err.message);
  //     }
  //   }
  // };

  // const onSetNewUserSettings = async (
  //   newPassword: string,
  //   oldPassword: string,
  //   oldPassword2: string,
  //   newName: string,
  //   newEmail: string
  // ) => {
  //   const credential = firebase.auth.EmailAuthProvider.credential(
  //     currentEmail as string,
  //     oldPassword
  //   );

  //   const shoudlTriggerTheUpdatePassword =
  //     auth && newPassword !== '' && newPassword !== oldPassword;

  //   if (shoudlTriggerTheUpdatePassword) {
  //     try {
  //       await auth.reauthenticateWithCredential(credential);
  //       await auth.updatePassword(newPassword);
  //       setSuccessfullySent(true);
  //       setIsSending(false);
  //       setTimeout(() => {
  //         setSuccessfullySent(false);
  //       }, 3000);
  //     } catch (err) {
  //       if (err instanceof Error) {
  //         console.log(err);
  //         setError(err?.message);
  //         setSuccessfullySent(false);
  //         setIsSending(false);
  //       }
  //     }
  //   }

  //   if (auth) {
  //     try {
  //       await auth.updateProfile({ displayName: newName });
  //       await db.collection('users').doc(user.uid).update({
  //         firstname: newName,
  //       });
  //       setSuccessfullySent(true);
  //       setIsSending(false);
  //       setTimeout(() => {
  //         setSuccessfullySent(false);
  //       }, 3000);
  //     } catch (err) {
  //       if (err instanceof Error) {
  //         console.log(err);
  //         setError(err?.message);
  //         setTimeout(() => {
  //           setError('');
  //         }, 3000);
  //       }
  //     }
  //   }

  //   if (auth && newEmail !== currentEmail) {
  //     const credential = firebase.auth.EmailAuthProvider.credential(
  //       currentEmail as string,
  //       oldPassword2
  //     );

  //     try {
  //       await auth.reauthenticateWithCredential(credential);
  //       await auth.updateEmail(newEmail);
  //       await db.collection('users').doc(user.uid).update({
  //         email: newEmail,
  //       });
  //       await user.sendEmailVerification().then(() => {
  //         history.push(ROUTES.VERIFY_EMAIL);
  //       });
  //       setSuccessfullySent(true);
  //       setIsSending(false);
  //       setTimeout(() => {
  //         setSuccessfullySent(false);
  //       }, 3000);
  //     } catch (err) {
  //       if (err instanceof Error) {
  //         console.log(err);
  //         setError(err?.message);
  //         setTimeout(() => {
  //           setError('');
  //         }, 3000);
  //       }
  //     }
  //   }
  // };

  function onSubmitNewUserSettingsForm({
    newPassword,
    oldPassword,
    oldPassword2,
    newName,
    newEmail,
  }: {
    newPassword: string;
    oldPassword: string;
    oldPassword2: string;
    newName: string;
    newEmail: string;
  }): void {
    setIsSending(true);
    // onSetNewUserSettings(
    //   newPassword,
    //   oldPassword,
    //   oldPassword2,
    //   newName,
    //   newEmail
    // );
  }

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = get(e, 'target.files[0]', null);
  //   if (file) {
  //     setFile(file);
  //     const ref = firebase.storage().ref(`users/${user.uid}/profile.jpg`);
  //     const uploadTask = ref.put(file);
  //     uploadTask.on('state_changed', console.log, console.error, () => {
  //       ref.getDownloadURL().then((url) => {
  //         setFile(null);
  //         setURL(url);
  //       });
  //     });
  //   }
  // };

  const roboAvatar = `https://robohash.org/${
    get(user, 'displayName[0]', '') + get(user, 'displayName[1]', '')
  }`;

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <h2>{t('content.settings.picture')}</h2>
        <div>
          <div
            style={{
              backgroundImage: `url(${url || roboAvatar})`,
            }}
          ></div>
          <input
            id={'uploadPhoto'}
            type="file"
            accept=".jpg, .jpeg"
            // onChange={handleChange}
            className={styles.inputField}
          />
          <label htmlFor={'uploadPhoto'} className={styles.labelBtn}>
            {t('content.settings.uploadPhoto')}
          </label>
        </div>
      </div>
      <div className={styles.userInfo}>
        <div className={styles.container}>
          <UserSettingsForm
            onSubmit={onSubmitNewUserSettingsForm}
            // onSubmit={onSubmitNewUserSettingsForm}
            isSending={isSending}
            successfullySent={successfullySent}
            userName={name}
            userEmail={email}
            closeModal={closeModal}
            deleteUserAccount={() => ({})}
            // deleteUserAccount={deleteUserAccount}
          />

          {error.length ? <p className={styles.errorMessage}>{error}</p> : null}
          {successfullySent && (
            <p className={styles.successMessage}>
              {t('content.settings.dataUpdated')}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
