import React from 'react';
import { CgMenu, CgMenuLeftAlt } from 'react-icons/all';
import { BSMenuContext } from '@context/BSMenuContext';

type BSidebarProps = {
  children: React.ReactNode;
  style?: any;
};
const BSidebar = ({ children, style }: BSidebarProps) => {
  const { open, toggleMenu } = React.useContext<any>(BSMenuContext);

  return (
    <div className={'sidebar'} style={style}>
      <div className={'sidebar-menu-button'}>
        {open ? (
          <CgMenuLeftAlt size={26} onClick={toggleMenu} />
        ) : (
          <CgMenu size={26} onClick={toggleMenu} />
        )}
      </div>
      <div className={'sidebar-wrapper'}>{children}</div>
    </div>
  );
};

export default BSidebar;
