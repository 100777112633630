import { truncate } from '@utils/index';
import { slice } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { CSSProperties } from 'react';

type Path = {
  name: string;
  to?: string;
};

export enum SIZE {
  SMALL = 'small',
  BIG = 'big',
}

type Props = {
  paths: Path[];
  size?: SIZE;
  separator?: string;
  className?: string;
  style?: CSSProperties;
};

function removeEmpty(path: Path) {
  return path?.name?.length > 0;
}

export default function Breadcrumbs({
  paths,
  size = SIZE.BIG,
  separator = '/',
  className = '',
  style = {}
}: Props) {
  const renderElement = React.useCallback(
    (element: Path, key: number) => (
      <li key={key} data-separator={separator}>
        <Link to={element.to ?? ''}>{truncate(element?.name, 32)}</Link>
      </li>
    ),
    [separator]
  );
  const renderedElements = paths.filter(removeEmpty).map(renderElement);
  return (
    <ul className={`${styles.container} ${styles[size]} ${className}`} style={style}>
      {paths.length > 4 ? (
        <>
          {renderedElements[0]} <li style={{ cursor: 'initial' }}>...</li>{' '}
          {slice(
            renderedElements,
            renderedElements.length - 2,
            renderedElements.length
          )}
        </>
      ) : (
        renderedElements
      )}
    </ul>
  );
}
