import React, { ReactNode } from 'react';
import { TableProps } from 'react-bootstrap';

type TableHeaderProps = {
  children?: ReactNode;
  parentProps?: TableProps;
  className?: string;
  style?: React.CSSProperties;
};

const TableHeader = (props: TableHeaderProps) => {
  const { children, className, style= {} } = props;
  return <div className={className} style={style}>{children}</div>;
};

export default TableHeader;
