import GenericError from '@components/ErrorState';
import InfoBoxWrapper from '@components/InfoBoxWrapper/InfoBoxWrapper';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import ModalReportVxWorksDetails from '@components/ModalReportVxWorksDetails';
import OverviewTab from '@components/OverviewTab';
import ReportHeading from '@components/ReportHeading';
import SkeletonOverview from '@components/SkeletonOverview';
import useRtosCapabilitiesAnalysis from '@hooks/analysis/useRtosCapabilitiesAnalysis';
import useReportOverview from '@hooks/useReportOverview';
import CapabilitiesContainer from '@pages/Report/VxWorks/Overview/Containers/CapabilitiesContainer';
import CapabilitiesTableContainer from '@pages/Report/VxWorks/Overview/Containers/CapabilitiesTableContainer';
import OverviewContainer from '@pages/Report/VxWorks/Overview/Containers/OverviewContainer';
import { ROUTES } from '@routes/routes';
import { getCssVariable } from '@utils/index';
import { noop } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { AiOutlineCheckSquare } from 'react-icons/ai';
import { BiGridAlt, BiSpreadsheet } from 'react-icons/bi';
import {
  RiFileList2Fill,
  RiFileList2Line,
  RiFunctionFill,
  RiFunctionLine,
  RiHomeFill,
  RiHomeLine,
  RiShieldFlashFill,
  RiShieldFlashLine,
  RiTaskFill,
  RiTaskLine
} from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

export default function ReportOverviewVxWorks() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading: overviewLoading, isError, error } = useReportOverview(reportId);
  const { data: capabilitiesData,
    isLoading: capabilitiesLoading,
    // isError: capabilitiesIsError,
    // error: capabilitiesError
  } = useRtosCapabilitiesAnalysis(reportId);

  const isLoading = overviewLoading || capabilitiesLoading;

  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const { t } = useTranslation(['common', 'pages']);
  // const overviewTitleAsString = `pages:overviewPage.sections.reportOverview.gradesStatus.${data?.grade.toString()}.title`;
  // const overviewDescriptionAsString = `pages:overviewPage.sections.reportOverview.gradesStatus.${data?.grade.toString()}.description`;
  const [activeTab, setActiveTab] = useState<string>('overview');

  function goTo(tab: string, section: string) {
    setActiveTab(tab);
    const element = document?.getElementById(section);
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  const titleTaskBox = t('pages:tasksAnalysisPage.title');
  const messageTaskBox = t('pages:tasksAnalysisPage.boxMessage');
  const funAnalysisTitle = t('pages:functionsAnalysisPage.title');
  const funAnalysisMessage = t('pages:functionsAnalysisPage.boxMessage');
  const symAnalysisTitle = t('pages:symbolsAnalysisPage.title');
  const symAnalysisMessage = t('pages:symbolsAnalysisPage.boxMessage');

  return (
    <LayoutPage>
      {isLoading ? (
        <SkeletonOverview />
      ) : isError ? (
        <GenericError error={error} />
      ) : (
        <>
          <Helmet>
            <title>Report {data?.name ?? ''}</title>
          </Helmet>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.experimental')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>

          <LayoutSection spacer={24}>
            <Nav
              className={'nav-cosmo nav-details'}
              activeKey={activeTab}
              variant='tabs'
            >
              <Nav.Item>
                <OverviewTab
                  defaultIcon={RiHomeLine}
                  activeIcon={RiHomeFill}
                  isActive={activeTab === 'overview'}
                  label={t('common:navigation.navigationLabels.overview')}
                  keyItem={'overview'}
                  onClick={() => goTo('overview', 'overview')}
                />
              </Nav.Item>
              <Nav.Item>
                <OverviewTab
                  defaultIcon={RiShieldFlashLine}
                  activeIcon={RiShieldFlashFill}
                  isActive={activeTab === 'capabilities'}
                  label={t('common:navigation.navigationLabels.capabilities')}
                  keyItem={'capabilities'}
                  onClick={() => goTo('capabilities', 'capabilities')}
                />
              </Nav.Item>
              <Nav.Item>
                <OverviewTab
                  defaultIcon={RiTaskLine}
                  activeIcon={RiTaskFill}
                  isActive={activeTab === 'tasks'}
                  label={t('common:navigation.navigationLabels.tasks')}
                  keyItem={'tasks'}
                  onClick={() => goTo('tasks', 'tasks')}
                />
              </Nav.Item>
              <Nav.Item>
                <OverviewTab
                  defaultIcon={RiFunctionLine}
                  activeIcon={RiFunctionFill}
                  isActive={activeTab === 'functions'}
                  label={t('common:navigation.navigationLabels.functions')}
                  keyItem={'tasks'}
                  onClick={() => goTo('functions', 'tasks')}
                />
              </Nav.Item>
              <Nav.Item>
                <OverviewTab
                  defaultIcon={RiFileList2Line}
                  activeIcon={RiFileList2Fill}
                  isActive={activeTab === 'symbols'}
                  label={t('common:navigation.navigationLabels.symbols')}
                  keyItem={'tasks'}
                  onClick={() => goTo('symbols', 'tasks')}
                />
              </Nav.Item>
            </Nav>
          </LayoutSection>
          <OverviewContainer data={data} />

          <div id={'capabilities'}>
            <CapabilitiesContainer data={capabilitiesData} />
            {/* <CapabilitiesTableContainer
              data={capabilitiesData}
            /> */}
          </div>
          <div id={'tasks'}>
            <Container fluid>
              <Row>
                <Col className='px-0' xs={12} sm={12} md={12} lg={4} xl={4}>
                  <InfoBoxWrapper
                    headerTitle={titleTaskBox}
                    leadingIcon={AiOutlineCheckSquare}
                    tooltipMessage=''
                    chartValue={data?.analysis?.tasks || 0}
                    boxMessage={messageTaskBox}
                    chartColor={'rgba(94,213,236 , 0.2)'}
                    chartLineColor={'rgb(94,213,236)'}
                    url={`${ROUTES.BASE}/${reportId}${ROUTES.RTOS_TASKS}`}
                  />
                </Col>
                <Col
                  className='px-2 md:px-0'
                  xs={12}
                  sm={12}
                  md={12}
                  lg={4}
                  xl={4}
                >
                  <InfoBoxWrapper
                    headerTitle={funAnalysisTitle}
                    leadingIcon={BiGridAlt}
                    tooltipMessage=''
                    chartValue={data?.analysis?.functions || 0}
                    boxMessage={funAnalysisMessage}
                    chartColor={'rgba(174,129,255 , 0.2)'}
                    chartLineColor={'rgb(174,129,255)'}
                    url={`${ROUTES.BASE}/${reportId}${ROUTES.RTOS_FUNCTIONS}`}
                  />
                </Col>
                <Col className='px-0' xs={12} sm={12} md={12} lg={4} xl={4}>
                  <InfoBoxWrapper
                    headerTitle={symAnalysisTitle}
                    leadingIcon={BiSpreadsheet}
                    tooltipMessage=''
                    boxMessage={symAnalysisMessage}
                    chartValue={data?.analysis?.symbols || 0}
                    chartColor={'rgba(255,120,203, 0.2)'}
                    chartLineColor={'rgb(255,120,203)'}
                    url={`${ROUTES.BASE}/${reportId}${ROUTES.RTOS_SYMBOLS}`}
                  />
                </Col>
              </Row>
            </Container>
          </div>

          <ReactTooltip
            border={true}
            arrowColor={getCssVariable('primary')}
            borderColor={getCssVariable('neutral300')}
            textColor={getCssVariable('secondary')}
            backgroundColor={getCssVariable('primary')}
            effect='solid'
            className={'tooltip'}
            multiline={true}
          />
        </>
      )}
      {shouldShowModalDetail ? (
        <ModalReportVxWorksDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...data?.reportDetails
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
