import React from 'react';
import ContentLoader from 'react-content-loader';
import { getCssVariable } from '@utils/index';

const SkeletonAccountPlan = (props: any) => (
  <ContentLoader
    speed={2}
    width={800}
    height={200}
    viewBox='0 0 800 200'
    backgroundColor={getCssVariable('neutral100')}
    foregroundColor={getCssVariable('neutral200')}
    {...props}
  >
    <rect x="123" y="22" rx="3" ry="3" width="718" height="119" />
    <circle cx="84" cy="82" r="81" />
  </ContentLoader>
);

export default SkeletonAccountPlan;