import styles from './styles.module.scss';
import { Menu } from 'react-contexify';

import { WithChildren } from '@utils/types/helpers';
import { noop } from 'lodash';
import React from 'react';

type Props = WithChildren<{
  id: string;
  onShown?: typeof noop;
  style?: React.CSSProperties;
}>;

export default function ContextMenu({
  id,
  children,
  style,
  onShown = noop,
}: Props) {
  return (
    <div className={styles.container}>
      <Menu animation={''} id={id} onShown={onShown} style={style}>
        {children}
      </Menu>
    </div>
  );
}
