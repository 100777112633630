import { useMutation, useQueryClient } from 'react-query';
import { deleteReportFromAPi } from '@services/api';
import type { Report } from '@utils/types/report';
import { noop } from 'lodash';
import { KEY as REPORTS_KEY } from './useReports';
import { KEY as REPORT_KEY } from './useReport';
import { KEY as REPORT_OVERVIEW_KEY } from './useReportOverview';
import useToken from './useToken';

export const KEY = 'delete-report';
export default function useDeleteReport(onSuccess?: typeof noop) {
  const queryClient = useQueryClient();
  useToken();
  return {
    ...useMutation<void, Error, string>(
      KEY,
      async (reportId: string): Promise<void> => {
        await deleteReportFromAPi(reportId);
      },
      {
        onMutate: async () => {
          await queryClient.cancelQueries(REPORTS_KEY);
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _variables, context: any) => {
          if (context?.previousReports) {
            queryClient.setQueryData<Report[]>(
              REPORTS_KEY,
              context.previousReports
            );
          }
          if (err) {
            console.log(err);
          }
        },
        onSuccess: (_: any, context: any) => {
          onSuccess && onSuccess();
          queryClient.invalidateQueries(REPORTS_KEY);
          queryClient.invalidateQueries([REPORT_OVERVIEW_KEY, context?.id]);
          queryClient.invalidateQueries([REPORT_KEY, context?.id]);
        },
      }
    ),
  };
}
