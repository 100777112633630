import { useQuery } from 'react-query';
import type { ReportRtosSymbolsAnalysis } from '@utils/types/report';
import { getReportRtosSymbolsAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'rtos-symbols';

export default function useRtosSymbolsAnalysis(id: string) {
  useToken();
  return useQuery<ReportRtosSymbolsAnalysis[], Error>([KEY, id], () =>
    getReportRtosSymbolsAnalysis(id),
    {
      retry: false
    }
  );
}
