import { getCssVariable } from '@utils/index';
import { WithChildren } from '@utils/types/helpers';
import { format, parse } from 'date-fns';
import * as React from 'react';
import { RiCheckboxCircleFill, RiSpam2Fill } from 'react-icons/ri';
import styles from './styles.module.scss';

export enum NOTIFICATION_TYPE {
  ERROR = 'error',
  WARNING = 'warning',
  GOOD = 'good',
}

type Props = WithChildren<{
  type: NOTIFICATION_TYPE;
  date: string;
}>;

export default function Notification({
  type = NOTIFICATION_TYPE.GOOD,
  children,
  date = '',
}: Props) {
  return (
    <li className={`${styles.container} ${styles[type]}`}>
      <div className={styles.content}>
        {type === NOTIFICATION_TYPE.ERROR ? (
          <RiSpam2Fill size={24} color={getCssVariable('crimson')} />
        ) : type === NOTIFICATION_TYPE.WARNING ? (
          <RiSpam2Fill size={24} color={getCssVariable('orange')} />
        ) : (
          <RiCheckboxCircleFill size={24} color={getCssVariable('green')} />
        )}
        <div className={styles.children}>{children}</div>
      </div>
      <span className={styles.date}>
        {format(parse('', '', new Date(`${date}`)), 'MMMM, dd yyyy')}
      </span>
    </li>
  );
}
