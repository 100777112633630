import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { createReportFromApi, cancelUploadRequest } from '@services/api';
import type { Report, ReportCreator } from '@utils/types/report';
import { noop } from 'lodash';
import { KEY as REPORTS_KEY } from './useReports';
import useToken from './useToken';
const KEY = 'create-report';
export default function useCreateReport(onSuccess?: typeof noop) {
  const [progress, setProgress] = React.useState(0);
  useToken();
  const uploadProgress = React.useCallback((progress: number) => {
    setProgress(progress);
  }, []);

  const cancelUpload = React.useCallback(() => {
    setProgress(0);
    cancelUploadRequest();
  }, []);

  const queryClient = useQueryClient();
  return {
    ...useMutation<string | undefined, Error, FormData<ReportCreator>>(
      KEY,
      async (report: FormData<ReportCreator>): Promise<string | undefined> => {
        return await createReportFromApi(report, uploadProgress);
      },
      {
        onMutate: async () => {
          await queryClient.cancelQueries(REPORTS_KEY);
        },
        // If the mutation fails, use the context returned from onMutate to roll back
        onError: (err, _variables, context: any) => {
          if (context?.previousReports) {
            queryClient.setQueryData<Report[]>(
              REPORTS_KEY,
              context.previousReports
            );
          }
          if (err) {
            console.log(err);
          }
        },
        onSuccess: () => {
          onSuccess && onSuccess();
          queryClient.invalidateQueries(REPORTS_KEY);
        },
      }
    ),
    cancelUpload,
    uploadProgress: progress,
  };
}
