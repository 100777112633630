import EmptyResults from '@components/EmptyResults';
import GenericError from '@components/ErrorState';
import LayoutPage from '@components/LayoutPage';
import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';
import Table from '@components/TableNew/Table';
import useBomViewerAnalysis from '@hooks/analysis/useBomViewerAnalysis';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import LayoutSection from '@components/LayoutSection';
import ModalReportLinuxDetails from '@components/ModalReportLinuxDetails';
import ReportHeading from '@components/ReportHeading';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import ReportBomViewerChart from '@pages/Report/Linux/BomViewer/ReportBomViewerChart';
import Utils from '@pages/Report/Linux/ReportOverviewLinux/Utils/Utils';
import { getCssVariable, getScanTime, padNumber } from '@utils/index';
import { format } from 'date-fns';
import { noop } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiSpamLine,
} from 'react-icons/ri';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import SectionHeader from '../ReportOverviewLinux/Components/SectionHeader';
import styles from './styles.module.scss';

// export enum BOM_VIEWER_PROPERTY {
//   FILENAME = 'filename',
//   LICENSE = 'license',
//   RESOLVE = 'resolve',
//   OCCURRENCES = 'occurrences',
// }

export default function BomViewer() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isError, error } = useBomViewerAnalysis(reportId);
  const { data: reportData } = useReportOverview(reportId);

  const { t } = useTranslation(['pages', 'emptyReports', 'tables', 'common']);

  const [search, setSearch] = React.useState('');
  const [chartData, setChartData] = React.useState<any>({
    data: [],
    labels: [],
    maxValue: 0,
  });
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  console.log({ data });

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'filename',
        Cell: ({ row, value }: { row: any; value: boolean }) => {
          return (
            <div className="d-flex flex-row align-items-center">
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400'),
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Resolve',
        accessor: 'resolve',
      },
      {
        Header: 'Occurrences',
        accessor: 'occurrences',
      },
      {
        Header: 'license',
        accessor: 'license',
        Cell: ({ value }: any) => {
          return (
            <div className="d-flex align-items-center w-100">
              <span
                style={{
                  padding: '.35rem',
                  marginRight: '1rem',
                  background: Utils.getBomViewerChipColorByLicenseType(value),
                  color: 'black',
                  textTransform: 'uppercase',
                  borderRadius: '4px',
                }}
              >
                {value}
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const cleanData = (data: any) => {
    if (!data || data.length < 1) return [];
    return data.filter((el: any) => el.license !== '');
  };

  const prepareLabelsChart = (data: any, key = 'license') => {
    const res = data.reduce((acc: any, b: any) => {
      const groupKey = b[key];
      if (!acc[groupKey]) {
        acc[groupKey] = 0;
      }
      acc[groupKey] = acc[groupKey] + b.occurrences;
      return acc;
    }, []);
    return res;
  };

  const prepareDataChart = (labels: any, maxValue = 100, key = 'license') => {
    const result = [] as any;
    Object.keys(labels).forEach((license: string, index: number) => {
      result[index] = {
        [key]: license,
        [license]: labels[license],
        [license + 'Color']: Utils.getBomViewerChipColorByLicenseType(
          String(license)
        ),
        FREE: maxValue - labels[license],
        FREEColor: getCssVariable('neutral200'),
      };
    });
    return result;
  };

  const memoizedSearch = React.useMemo(() => search, [search]);

  const memoizedData = React.useMemo(() => {
    if (!data || data?.length === 0) return [];
    return data;
  }, [data]);

  const getChart = async () => {
    const cleaned = cleanData(data);
    const rawLabels = prepareLabelsChart(cleaned);

    let maxValue = 0;
    const labels = ['FREE'];
    for (const key in rawLabels) {
      labels.push(key);
      if (maxValue < rawLabels[key]) maxValue = rawLabels[key];
    }
    const dataChart = prepareDataChart(rawLabels, maxValue);

    setChartData({
      data: dataChart,
      labels,
      maxValue,
    });
  };

  React.useEffect(() => {
    getChart();
  }, [data]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { globalFilter: memoizedSearch, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  // const breakPointsLeft = {
  //   xs: 12,
  //   sm: 12,
  //   md: 12,
  //   lg: 12,
  //   xl: 12,
  // };
  const scanTime = React.useMemo(
    () => (reportData ? getScanTime(reportData) : null),
    [reportData]
  );

  const calculateTypeCounts = (array: any) => {
    const typeCounts = {};

    // Iterate through the array and count occurrences of each type
    array?.forEach((item: any) => {
      // item.types.forEach((type: any) => {
      //@ts-ignore
      if (typeCounts[item.license]) {
        //@ts-ignore
        typeCounts[item.license]++;
      } else {
        //@ts-ignore
        typeCounts[item.license] = 1;
      }
      // });
    });

    // Convert typeCounts object into the desired output format
    const result = [];
    for (const type in typeCounts) {
      // @ts-ignore
      result.push({ key: type, count: typeCounts[type] });
    }

    return result;
  };


  const typeCounts = React.useMemo(() => calculateTypeCounts(memoizedData), [memoizedData]);

  const legendData: any = React.useMemo(() => {
    const transformedObject: any = {};

    // Iterate through the array and assign key-value pairs to the transformed object
    typeCounts.forEach(item => {
      transformedObject[item.key] = item.count;
    });

    return transformedObject;

  }, [typeCounts]);

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisSimple />}
      {!isLoading && !isError && !data && (
        <EmptyResults
          title={t('emptyReports:securityScan.title')}
          description={t('emptyReports:securityScan.subtitle')}
        //buttonLabel={t('emptyReports:securityScan.buttonLabel')}
        //onClick={console.log}
        />
      )}
      {isError && <GenericError error={error} />}
      {data && data?.length > 0 && !isLoading && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <SectionHeader
            title={t('pages:bomViewerPage.title')}
            leadingIcon={RiSpamLine}
            tooltipMessage={t('pages:bomViewerPage.buttonLabel')}
            bannerText={t('pages:bomViewerPage.bannerMessage')}
          />
          <Container fluid>
            <Row>
              <Col>
                {/* <GraphLegend items={legendData} colors={getColors()} direction='row'
                // customClassName={cveCheckReportStyles.graphLegend}  
                /> */}
                <div className={styles['left-title']}>
                  <div>vulnerabilities</div>
                </div>
                <div className={styles.count}>{memoizedData?.length || 0}</div>
                <div style={{ position: 'relative', height: 400 }}>
                  <ReportBomViewerChart
                    data={chartData.data}
                    labels={chartData.labels}
                  />
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid style={{
            border: '1px solid var(--neutral300)',
            padding: 0,
            borderRadius: '2px'
          }}>
            <Row>
              <Col>
                <div style={{ fontSize: 20, padding: '40px 40px 5px 40px' }}>BOM Viewer</div>
                <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px', textTransform: 'uppercase' }}>total: {memoizedData?.length || 0}</div>
                <Table tableInstance={tableInstance} borderless >
                  <Table.Footer>
                    <p className={tableStyles['table__paginationState']}>
                      {paginationMessage}
                    </p>
                    <div className={tableStyles['table__paginationButtons']}>
                      <button
                        className={
                          tableInstance?.canPreviousPage
                            ? tableStyles['table__paginationActiveButton']
                            : ''
                        }
                        onClick={() => tableInstance.previousPage()}
                      >
                        <RiArrowLeftSLine size={20} />
                      </button>
                      <button
                        className={
                          tableInstance?.canNextPage
                            ? tableStyles['table__paginationActiveButton']
                            : ''
                        }
                        onClick={() => tableInstance.nextPage()}
                      >
                        <RiArrowRightSLine size={20} />
                      </button>
                    </div>
                    <TablePageSize
                      maxResultChunkSize={maxResultChunkSize}
                      pageSize={tableInstance?.state?.pageSize || 10}
                      onPageSizeChange={onPageSizeChange}
                    />
                  </Table.Footer>
                </Table>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {reportData && shouldShowModalDetail ? (
        <ModalReportLinuxDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData.reportDetails,
            submitted: format(
              new Date(`${reportData.startDate ?? new Date()}`),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: reportData.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}


// <SectionContent
// // breakPointsLeft={breakPointsLeft}
// // breakPointsRight={breakPointsRight}
// style={{ border: 'none' }}
// >
// <SectionContentLeft verticalMessage={'CVE Check vulnerabilities'}>
//   <SectionContentLeft style={{ borderRight: 'none' }} hasGraph={false}>
//     <div className={styles['left-title']}>
//       <div>License</div>
//     </div>
//     <div className={styles.count}>{2}</div>
// {/* <GraphLegend items={legendData} colors={getColors()} direction='row'
//   customClassName={cveCheckReportStyles.graphLegend}
//  />  */}
//   </SectionContentLeft>
// </SectionContentLeft>
// <SectionContentRight
//   style={{ borderRight: 'none' }}
// >
//   <ReportBomViewerChart
//     data={chartData.data}
//     labels={chartData.labels}
//   />
// </SectionContentRight>
// </SectionContent>