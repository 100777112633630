import { useQuery } from 'react-query';
import type { ReportCveCheckAnalysis } from '@utils/types/report';
import { getReportCveCheckAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'cve-check-analysis';

export default function useCveCheckAnalysis(id: string) {
  useToken();
  return useQuery<ReportCveCheckAnalysis, Error>([KEY, id], () =>
    getReportCveCheckAnalysis(id),
    {
      retry: false
    }
  );
}
