import SecureBootLine from '@components/Icons/SecureBootFill';
import { GlobalContext } from '@contexts/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import InfoBox from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxMessage';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import SecureBootChart from '@pages/Report/Uefi/SecureBoot/SecureBootChart';
import { ROUTES } from '@routes/routes';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';

type SecureBootContainerProps = {
  data: any
}

const SecureBootContainer = ({ data }: SecureBootContainerProps) => {
  const { reportId = '' }: { reportId: string } = useParams();

  const history = useHistory();
  const { t } = useTranslation(['tables', 'pages', 'emptyReports', 'common']);
  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  return <><SectionHeader
    title={t('pages:overviewPage.sections.secureBoot.title')}
    leadingIcon={SecureBootLine}
    tooltipMessage={t('pages:overviewPage.sections.secureBoot.tooltipMessage')}
  />
    {/* <InfoBox style={{ height: 'auto', alignItems: 'center', justifyContent: 'center' }}>
      <InfoBoxContent style={{ width: '100%' }}>
        <SecureBootChart secureBootValue={data?.keys?.length || 0} certificateValue={data?.databaseCerts?.length || 0} hashesValue={data?.databaseHashes?.length || 0} />
        <InfoBoxMessage message={<div className='d-flex w-100 py-2 align-items-center justify-content-between'>
          <span>{t('pages:secureBoot.boxMessage')}</span>
          <Button onClick={() => history.push(`${ROUTES.BASE}/${reportId}${ROUTES.SECURE_BOOT}`)} className={styles['button']}>VIEW ALL</Button>
        </div>} />
      </InfoBoxContent>
    </InfoBox> */}

    <InfoBox style={{
      padding: 20,
      height: 'auto',
    }}>
      <InfoBoxContent style={{
        alignItems: 'flex-start', background: isDarkMode ? '#202226' : 'none', flexDirection: 'row', gap: 60, position: 'relative'
      }}>
        <div>
          <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <InfoBoxMessage
              message='secure boot keys'
              style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
            />
          </div>
          <div className={styles['info-box-count']}>{data?.keys?.length || 0}</div>
        </div>
        <div>
          <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <InfoBoxMessage
              message='certificates'
              style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
            />
          </div>
          <div className={styles['info-box-count']}>{data?.databaseCerts?.length || 0}</div>
        </div>
        <div>
          <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <InfoBoxMessage
              message='hashes'
              style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
            />
          </div>
          <div className={styles['info-box-count']}>{data?.databaseHashes?.length || 0}</div>
        </div>
        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          <Button onClick={() => history.push(`${ROUTES.BASE}/${reportId}${ROUTES.SECURE_BOOT}`)} className={styles['button']}>VIEW ALL</Button>
        </div>
      </InfoBoxContent>-
    </InfoBox>
  </>;
};

export default SecureBootContainer;