import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';
import useSecureBootAnalysis from '@hooks/analysis/useSecureBootAnalysis';
import * as React from 'react';

import Chip from '@components/Chip';
import GenericError from '@components/ErrorState';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import ModalReportUefiDetails from '@components/ModalReportUefiDetails';
import ReportHeading from '@components/ReportHeading';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import TabsProvider from '@components/Tabs/TabsProvider';
import { TabsHeader } from '@components/TabsHeader';
import tabsStyles from '@components/TabsHeader/styles.module.scss';
import { GlobalContext } from '@contexts/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import InfoBox from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxMessage';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import styles from '@pages/Report/Linux/ReportOverviewLinux/styles.module.scss';
import { getCssVariable, getScanTime } from '@utils/index';
import { format, parse } from 'date-fns';
import { noop } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiFolderLockLine,
} from 'react-icons/ri';
import { useParams } from 'react-router';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';

// export enum SECURE_BOOT_PROPERTY {
//   NAME = 'name',
//   DATABASE = 'database',
//   ISSUED_TO = 'issuedTo',
//   ISSUED_BY = 'issuedBy',
//   HASHES = 'hases',
//   SIGNATURE = 'signature',
// }

// const MIN_ROW_LENGTH = 10;

const breakPointsLeft = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const breakPointsRight = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
};

export default function SecureBoot() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data: reportData } = useReportOverview(reportId);
  const { data, isLoading, isError } = useSecureBootAnalysis(reportId);

  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  const { t } = useTranslation(['tables', 'pages', 'emptyReports', 'common']);

  const [activeIndex, setActiveIndex] = React.useState<string>('0');
  const [tableSwitchValue, setTableSwitchValue] = React.useState<any>('hashes');
  const [search, setSearch] = React.useState('');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const getDatabaseCertsLength = (data: any) => {
    if (!data) return 0;
    return data.databaseCerts.length;
  };
  const getDatabaseHashesLength = (data: any) => {
    if (!data) return 0;
    return data.databaseHashes.length;
  };
  const getDatabaseTotalLength = (data: any) => {
    if (!data) return 0;
    return getDatabaseCertsLength(data) + getDatabaseHashesLength(data);
  };
  const getSecureBootKeyLength = (data: any) => {
    if (!data) return 0;
    return data.keys.length;
  };

  const tabsState = React.useMemo(
    () => ({
      id: 'controlled-tab',
      activeKey: activeIndex,
      className: `mb-3 ${styles['section-content-right']}`,
      // style: { backgroundColor: '#F6F8FA' },
      onSelect: (k: string) => setActiveIndex(k),
      tabs: [
        {
          eventKey: 0,
          tabClassName: `${tabsStyles['tab-header']} 
          ${activeIndex === '0'
              ? tabsStyles['active-tab']
              : tabsStyles['inactive-tab']
            }
            `,
          title: (
            <TabsHeader
              title={t('pages:secureBoot.tableSectionTitle.secureBootKeys')}
              number={getSecureBootKeyLength(data)}
              color={getCssVariable('cyan')}
              tooltipMessage="Since the last check"
            />
          ),
        },
        {
          eventKey: 1,
          tabClassName: `${tabsStyles['tab-header']} ${activeIndex === '1'
            ? tabsStyles['active-tab']
            : tabsStyles['inactive-tab']
            }`,
          title: (
            <TabsHeader
              title={t('pages:secureBoot.tableSectionTitle.databaseCerts')}
              number={getDatabaseTotalLength(data)}
              color={getCssVariable('violet')}
              tooltipMessage="Since the last check"
            />
          ),
        },
      ],
    }),
    [activeIndex, data]
  );

  const columns: any = React.useMemo(() => {
    if (activeIndex === '1') {
      return [
        {
          Header: 'Database',
          accessor: 'database',
        },
        {
          Header: tableSwitchValue !== 'hashes' ? 'Issued by' : 'Hashes',
          accessor: tableSwitchValue !== 'hashes' ? 'issuedBy' : 'hashes',
        },
        {
          Header: tableSwitchValue !== 'hashes' ? 'Issued to' : 'Signature',
          accessor: tableSwitchValue !== 'hashes' ? 'issuedTo' : 'signature',
        },
        {
          Header: '',
          accessor: 'type',
          Cell: ({ value }: any) => (
            <Chip
              color={tableSwitchValue === 'certificates' ? 'violet' : 'pink'}
            >
              {tableSwitchValue === 'certificates' ? 'CERTIFICATES' : 'HASHES'}
            </Chip>
          ),
        },
      ];
    } else {
      return [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Issued By',
          accessor: 'issuedBy',
        },
        {
          Header: 'Issued to',
          accessor: 'issuedTo',
        },
      ];
    }
  }, [activeIndex, tableSwitchValue]);

  const memoizedSearch = React.useMemo(() => search, [search]);

  const memoizedData = React.useMemo(() => {
    if (!data) return [];

    if (activeIndex === '0') {
      return data['keys'];
    }

    if (activeIndex === '1') {
      if (tableSwitchValue === 'hashes') {
        return data['databaseHashes'];
      }
      if (tableSwitchValue === 'certificates') {
        return data['databaseCerts'];
      }
    }

    return [];
  }, [data, activeIndex, tableSwitchValue]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { globalFilter: memoizedSearch, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  const scanTime = React.useMemo(
    () => (reportData ? getScanTime(reportData) : null),
    [reportData]
  );

  const handleActiveIndexDefault = (data: any): string => {
    if (!data) return '0';
    if (
      getSecureBootKeyLength(data) > 0 &&
      getDatabaseCertsLength(data) + getDatabaseHashesLength(data) > 0
    ) {
      return '0';
    }
    if (getSecureBootKeyLength(data) > 0) {
      return '0';
    }
    if (getDatabaseCertsLength(data) + getDatabaseHashesLength(data) > 0) {
      return '1';
    }
    return '0';
  };
  React.useEffect(() => {
    setActiveIndex(handleActiveIndexDefault(data));
  }, [data]);
  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisSimple />}
      {/*{!isLoading && !isError && (!data) && (*/}
      {/*  <EmptyResults*/}
      {/*    title={t('emptyReports:secureBoot.title')}*/}
      {/*    description={t('emptyReports:secureBoot.subtitle')}*/}
      {/*    buttonLabel={t('emptyReports:secureBoot.buttonLabel')}*/}
      {/*    onClick={console.log}*/}
      {/*  />*/}
      {/*)}*/}
      {isError && <GenericError error={isError} />}
      {data && !isLoading && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              title={reportData?.name ?? ''}
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <SectionHeader
            title={t('pages:secureBoot.title')}
            leadingIcon={RiFolderLockLine}
            tooltipMessage={t('pages:secureBoot.buttonLabel')}
            bannerText={t('pages:secureBoot.bannerMessage')}
          />
          <Container fluid>
            <Row>
              <Col className="px-0">
                <InfoBox style={{
                  padding: 20,
                  height: 'auto',
                }}>
                  <InfoBoxContent style={{
                    alignItems: 'flex-start', background: isDarkMode ? '#202226' : 'none', flexDirection: 'row', gap: 60, position: 'relative'
                  }}>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='secure boot keys'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{data?.keys?.length || 0}</div>
                    </div>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='certificates'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{data?.databaseCerts?.length || 0}</div>
                    </div>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='hashes'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{data?.databaseHashes?.length || 0}</div>
                    </div>
                  </InfoBoxContent>-
                </InfoBox>
                <Container fluid style={{
                  border: '1px solid var(--neutral300)',
                  marginTop: 40,
                  padding: 0,
                  borderRadius: '2px'
                }}>
                  <Row>
                    <Col xs={12} >
                      <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>Secure Boot Analysis</div>
                      <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total: {memoizedData?.length || 0}</div>
                      <TabsProvider state={tabsState} />
                      <Table tableInstance={tableInstance} borderless>
                        <Table.Footer>
                          <p className={tableStyles['table__paginationState']}>
                            {paginationMessage}
                          </p>
                          <div className={tableStyles['table__paginationButtons']}>
                            <button
                              className={
                                tableInstance?.canPreviousPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.previousPage()}
                            >
                              <RiArrowLeftSLine size={20} />
                            </button>
                            <button
                              className={
                                tableInstance?.canNextPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.nextPage()}
                            >
                              <RiArrowRightSLine size={20} />
                            </button>
                          </div>
                          <TablePageSize
                            maxResultChunkSize={maxResultChunkSize}
                            pageSize={tableInstance?.state?.pageSize || 10}
                            onPageSizeChange={onPageSizeChange}
                          />
                        </Table.Footer>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {reportData && shouldShowModalDetail ? (
        <ModalReportUefiDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData?.reportDetails,
            submitted: format(
              parse('', '', new Date(`${reportData?.startDate ?? new Date()}`)),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: reportData?.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
