import { SelectHTMLAttributes } from 'react';
import { RefCallBack } from 'react-hook-form';
import { omit } from 'lodash';
import { RiArrowDownSLine } from 'react-icons/ri';

import styles from './styles.module.scss';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  placeholder?: string;
  error?: string;
  inputRef: RefCallBack;
  options: { id: string; label: string; disabled?: boolean }[];
  compact?: boolean;
}

export default function Select(props: SelectProps) {
  const { label, placeholder, error, options, compact = false } = props;

  return (
    <div className={`${styles.container} ${error ? styles.error : ''}`}>
      {label && <label>{label}</label>}
      <span className={`${styles.wrapper} ${compact ? styles.compact : ''}`}>
        <select
          tabIndex={0}
          ref={props.inputRef}
          {...omit(props, ['inputRef', 'label', 'error', 'options', 'compact'])}
        >
          <option value="">{placeholder}</option>
          {options.map((option) => (
            <option key={option.id} value={option.id} disabled={option.disabled ? true : false}>
              {option.label}
            </option>
          ))}
        </select>
        <RiArrowDownSLine size={20} />
      </span>
      {error && <p>{error}</p>}
    </div>
  );
}
