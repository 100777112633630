import GenericError from '@components/ErrorState';
import ChipIconFill from '@components/Icons/ChipIconFill';
import ConfigAnalyzerFill from '@components/Icons/ConfigAnalyzerFill';
import IntelBootGuardFill from '@components/Icons/IntelBootGuardFill';
import SecureBootFill from '@components/Icons/SecureBootLine';
import NavigationReportDynamic from '@components/NavigationReportDynamic';
import BSidebarWrapper from '@components/Sidebar/BSidebarWrapper';
import SkeletonOverview from '@components/SkeletonOverview';
import SkeletonSidemenu from '@components/SkeletonSidemenu';
import { BSMenuProvider } from '@context/BSMenuContext';
import { CurrentReportContext } from '@contexts/CurrentReport';
import useReport from '@hooks/useReport';
import ReportOverviewLinux from '@pages/Report/Linux/ReportOverviewLinux';
import SecureBoot from '@pages/Report/Uefi/SecureBoot';
import UpgradePlan from '@pages/UpgradePlan';
import { ROUTES } from '@routes/routes';
import { REPORT_TYPE } from '@utils/types/report';
import * as React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsExclamationOctagonFill } from 'react-icons/all';
import {
  RiDoorLockBoxFill,
  RiFileCodeFill,
  RiFileList2Fill,
  RiFileLockFill,
  RiFileShieldFill,
  RiFolderFill,
  RiFunctionFill,
  RiHomeFill,
  RiLockPasswordFill,
  RiScan2Fill,
  RiShieldCheckFill,
  RiShieldFlashFill,
  RiShieldKeyholeFill,
  RiStackFill,
  RiTaskFill,
  RiTerminalBoxFill,
} from 'react-icons/ri';
import { Redirect, Route, Switch, useParams } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import ReportBinary from './Linux/Binary';
import ReportBomViewer from './Linux/BomViewer';
import ReportCodeAnalysis from './Linux/CodeAnalysis';
import ReportConfigAnalyzer from './Linux/ConfigAnalyzer';
import ReportCryptoAnalyzer from './Linux/CryptoAnalyzer';
import ReportCveCheck from './Linux/CveCheck';
import ReportKernelSecurity from './Linux/KernelSecurity';
import ReportNvRam from './Linux/NvRam';
import ReportPasswordHash from './Linux/PasswordHash';
import ReportSecurityScan from './Linux/SecurityScan';
import ReportUefiAccessRights from './Uefi/AccessRights';
import ReportAttackSurface from './Uefi/AttackSurface';
import ReportUefiIntelBootGuard from './Uefi/IntelBootGuard';
import ReportOverviewUefi from './Uefi/Overview';
import ReportPeimDxe from './Uefi/PeimDxe';
import ReportUefiSecurityScan from './Uefi/SecurityScan';
import ReportRtosCapabilities from './VxWorks/Capabilities';
import ReportRtosFunctions from './VxWorks/Functions';
import ReportOverviewVxWorks from './VxWorks/Overview';
import ReportRtosSymbols from './VxWorks/Symbols';
import ReportRtosTasks from './VxWorks/Tasks';
import ReportFilesystem from './common/Filesystem';

type Props = {
  path: string;
};

export default function Report({ path }: Props) {
  const { t } = useTranslation('common');
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isError } = useReport(reportId);
  const { setReport } = React.useContext(CurrentReportContext);
  const baseRoute = ROUTES.BASE + ROUTES.REPORT_ID;
  const [isPro, setIsPro] = React.useState<boolean>(true);
  const isOverview = useRouteMatch(baseRoute + ROUTES.OVERVIEW);
  const isCveCheck = useRouteMatch(baseRoute + ROUTES.CVE_CHECK);
  const isPasswordHash = useRouteMatch(baseRoute + ROUTES.PASSWORD_HASH);
  const isCodeAnalysis = useRouteMatch(baseRoute + ROUTES.CODE_ANALYSIS);
  const isSecurityScan = useRouteMatch(baseRoute + ROUTES.SECURITY_SCAN);
  const isKernelSecurity = useRouteMatch(baseRoute + ROUTES.KERNEL_SECURITY);
  const isBinaryAnalysis = useRouteMatch(baseRoute + ROUTES.BINARY_ANALYSIS);
  const isNvramAnalysis = useRouteMatch(baseRoute + ROUTES.NVRAM_ANALYSIS);
  const isFileSystemExplorer = useRouteMatch(
    baseRoute + ROUTES.FILESYSTEM_EXPLORER
  );
  const isCryptoAnalyzer = useRouteMatch(baseRoute + ROUTES.CRYPTO_ANALYZER);
  const isBomViewer = useRouteMatch(baseRoute + ROUTES.BOM_VIEWER);
  const isConfigAnalyzer = useRouteMatch(baseRoute + ROUTES.CONFIG_ANALYZER);
  const isPeimDxe = useRouteMatch(baseRoute + ROUTES.PEIM_DXE);
  const isSecureBoot = useRouteMatch(baseRoute + ROUTES.SECURE_BOOT);
  const isIntelBootGuard = useRouteMatch(baseRoute + ROUTES.INTEL_BOOT_GUARD);
  const isAccessRights = useRouteMatch(baseRoute + ROUTES.ACCESS_RIGHT);
  const isAttackSurfaceAnalyzer = useRouteMatch(
    baseRoute + ROUTES.ATTACK_SURFACE_ANALYZER
  );
  const isCapabilities = useRouteMatch(baseRoute + ROUTES.RTOS_CAPABILITIES);
  const isTasks = useRouteMatch(baseRoute + ROUTES.RTOS_TASKS);
  const isFunction = useRouteMatch(baseRoute + ROUTES.RTOS_FUNCTIONS);
  const isSymbols = useRouteMatch(baseRoute + ROUTES.RTOS_SYMBOLS);
  const isUefiSecurityScan = useRouteMatch(
    baseRoute + ROUTES.UEFI_SECURITY_SCAN
  );

  React.useEffect(() => {
    setReport(data);
  }, [data, setReport]);

  function getBreadCrumbItem() {
    if (isOverview?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.overview')} <RiHomeFill />
        </Breadcrumb.Item>
      );
    }

    if (isCveCheck?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.cveCheck')}{' '}
          <BsExclamationOctagonFill />
        </Breadcrumb.Item>
      );
    }

    if (isPasswordHash?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.passwordHash')} <RiDoorLockBoxFill />
        </Breadcrumb.Item>
      );
    }

    if (isCodeAnalysis?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.codeAnalysis')} <RiFileCodeFill />
        </Breadcrumb.Item>
      );
    }

    if (isSecurityScan?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.securityScan')} <RiShieldCheckFill />
        </Breadcrumb.Item>
      );
    }

    if (isUefiSecurityScan?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.securityScan')} <RiShieldCheckFill />
        </Breadcrumb.Item>
      );
    }

    if (isKernelSecurity?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.kernelSecurity')}{' '}
          <RiShieldKeyholeFill />
        </Breadcrumb.Item>
      );
    }

    if (isBinaryAnalysis?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.binaryAnalysis')}{' '}
          <RiTerminalBoxFill />
        </Breadcrumb.Item>
      );
    }

    if (isNvramAnalysis?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.nvramAnalysis')} <ChipIconFill />
        </Breadcrumb.Item>
      );
    }

    if (isFileSystemExplorer?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.filesystemExplorer')} <RiFolderFill />
        </Breadcrumb.Item>
      );
    }

    if (isCryptoAnalyzer?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.cryptoAnalyzer')} <RiFileLockFill />
        </Breadcrumb.Item>
      );
    }

    if (isConfigAnalyzer?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.configAnalyzer')}{' '}
          <ConfigAnalyzerFill />
        </Breadcrumb.Item>
      );
    }

    if (isPeimDxe?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.peimDxe')} <RiScan2Fill />
        </Breadcrumb.Item>
      );
    }

    if (isBomViewer?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.bomViewer')} <RiFileShieldFill />
        </Breadcrumb.Item>
      );
    }
    if (isSecureBoot?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.secureBoot')} <SecureBootFill />
        </Breadcrumb.Item>
      );
    }

    if (isIntelBootGuard?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.intelBootGuard')}{' '}
          <IntelBootGuardFill />
        </Breadcrumb.Item>
      );
    }

    if (isAccessRights?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.accessRights')} <RiLockPasswordFill />
        </Breadcrumb.Item>
      );
    }

    if (isAttackSurfaceAnalyzer?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.attackSurfaceAnalyzer')}{' '}
          <RiStackFill />
        </Breadcrumb.Item>
      );
    }

    if (isCapabilities?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.capabilities')} <RiShieldFlashFill />
        </Breadcrumb.Item>
      );
    }

    if (isTasks?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.tasks')} <RiTaskFill />
        </Breadcrumb.Item>
      );
    }

    if (isFunction?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.functions')} <RiFunctionFill />
        </Breadcrumb.Item>
      );
    }

    if (isSymbols?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('navigation.navigationLabels.symbols')} <RiFileList2Fill />
        </Breadcrumb.Item>
      );
    }
  }

  return (
    <BSMenuProvider>
      <div className={'submenu-container submenu-container-report'}>
        <Breadcrumb className={'pt-2 pb-2 mb-0'}>
          <Breadcrumb.Item href={path}>Cosmo</Breadcrumb.Item>

          {data?.type === REPORT_TYPE.LINUX ? (
            <Breadcrumb.Item className={'not-clickable'}>LINUX</Breadcrumb.Item>
          ) : null}

          {data?.type === REPORT_TYPE.UEFI ? (
            <Breadcrumb.Item className={'not-clickable'}>UEFI</Breadcrumb.Item>
          ) : null}

          {data?.type === REPORT_TYPE.CONTAINER ? (
            <Breadcrumb.Item className={'not-clickable'}>
              CONTAINER
            </Breadcrumb.Item>
          ) : null}

          {data?.type === REPORT_TYPE.VXWORKS ? (
            <Breadcrumb.Item className={'not-clickable'}>
              VXWORKS
            </Breadcrumb.Item>
          ) : null}

          {getBreadCrumbItem()}
        </Breadcrumb>
      </div>
      {!isError ? (
        <BSidebarWrapper isReport={true}>
          <NavigationReportDynamic path={path}>
            <Switch>
              <Route exact path={baseRoute + ROUTES.OVERVIEW}>
                {data?.type === REPORT_TYPE.LINUX ||
                data?.type === REPORT_TYPE.CONTAINER ? (
                  <ReportOverviewLinux />
                ) : data?.type === REPORT_TYPE.UEFI ? (
                  <ReportOverviewUefi />
                ) : data?.type === REPORT_TYPE.VXWORKS ? (
                  <ReportOverviewVxWorks />
                ) : null}
              </Route>
              <Route exact path={baseRoute + ROUTES.CVE_CHECK}>
                {isPro ? <ReportCveCheck /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.PASSWORD_HASH}>
                {isPro ? <ReportPasswordHash /> : <UpgradePlan />}
              </Route>

              <Route path={baseRoute + ROUTES.CODE_ANALYSIS}>
                {isPro ? <ReportCodeAnalysis /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.SECURITY_SCAN}>
                {isPro ? <ReportSecurityScan /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.UEFI_SECURITY_SCAN}>
                {isPro ? <ReportUefiSecurityScan /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.PEIM_DXE}>
                {isPro ? <ReportPeimDxe /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.KERNEL_SECURITY}>
                {isPro ? <ReportKernelSecurity /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.INTEL_BOOT_GUARD}>
                {isPro ? <ReportUefiIntelBootGuard /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.ACCESS_RIGHT}>
                {isPro ? <ReportUefiAccessRights /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.SECURE_BOOT}>
                {isPro ? <SecureBoot /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.BINARY_ANALYSIS}>
                {isPro ? <ReportBinary /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.NVRAM_ANALYSIS}>
                {isPro ? <ReportNvRam /> : <UpgradePlan />}
              </Route>

              <Route path={baseRoute + ROUTES.FILESYSTEM_EXPLORER}>
                {isPro ? <ReportFilesystem /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.CRYPTO_ANALYZER}>
                {isPro ? <ReportCryptoAnalyzer /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.BOM_VIEWER}>
                {isPro ? <ReportBomViewer /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.ATTACK_SURFACE_ANALYZER}>
                {isPro ? <ReportAttackSurface /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.RTOS_FUNCTIONS}>
                {isPro ? <ReportRtosFunctions /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.RTOS_CAPABILITIES}>
                {isPro ? <ReportRtosCapabilities /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.RTOS_TASKS}>
                {isPro ? <ReportRtosTasks /> : <UpgradePlan />}
              </Route>

              <Route exact path={baseRoute + ROUTES.RTOS_SYMBOLS}>
                {isPro ? <ReportRtosSymbols /> : <UpgradePlan />}
              </Route>
              <Route exact path={baseRoute + ROUTES.CONFIG_ANALYZER}>
                {isPro ? <ReportConfigAnalyzer /> : <UpgradePlan />}
              </Route>

              <Redirect from={baseRoute} to={baseRoute + ROUTES.OVERVIEW} />
            </Switch>
          </NavigationReportDynamic>
        </BSidebarWrapper>
      ) : isError ? (
        <GenericError error={t('generic.error')} />
      ) : (
        <React.Fragment>
          <div className={'float-start pt-5 ps-3'}>
            <SkeletonSidemenu />
          </div>
          <div className={'pt-5'} style={{ paddingLeft: 280 }}>
            <SkeletonOverview />
          </div>
        </React.Fragment>

        // <Redirect to={ROUTES.BASE} /><Redirect to={ROUTES.BASE} />
        // <p>Something went wrong</p>
      )}
    </BSMenuProvider>
  );
}
