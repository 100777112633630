import NavigationLink from '@components/NavigationLink';
import useReport from '@hooks/useReport';
import { ROUTES } from '@routes/routes';
import { REPORT_TYPE } from '@utils/types/report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  RiDoorLockBoxFill,
  RiDoorLockBoxLine,
  RiFileCodeFill,
  RiFileCodeLine,
  RiFileList2Fill,
  RiFileList2Line,
  RiFileLockFill,
  RiFileLockLine,
  RiFileShieldFill,
  RiFileShieldLine,
  RiFolderFill,
  RiFolderLine,
  RiFunctionFill,
  RiFunctionLine,
  RiHomeFill,
  RiHomeLine,
  RiLock2Line,
  RiLockPasswordFill,
  RiLockPasswordLine,
  RiScan2Fill,
  RiScan2Line,
  RiShieldCheckFill,
  RiShieldCheckLine,
  RiShieldFlashFill,
  RiShieldFlashLine,
  RiShieldKeyholeFill,
  RiShieldKeyholeLine,
  RiStackFill,
  RiStackLine,
  RiTaskFill,
  RiTaskLine,
  RiTerminalBoxFill,
  RiTerminalBoxLine,
} from 'react-icons/ri';
import { useParams } from 'react-router-dom';

import Footer from '@components/Footer';
import ChipIconFill from '@components/Icons/ChipIconFill';
import ChipIconLine from '@components/Icons/ChipIconLine';
import ConfigAnalyzerFill from '@components/Icons/ConfigAnalyzerFill';
import ConfigAnalyzerLine from '@components/Icons/ConfigAnalyzerLine';
import IntelBootGuardFill from '@components/Icons/IntelBootGuardFill';
import IntelBootGuardLine from '@components/Icons/IntelBootGuardLine';
import SecureBootLine from '@components/Icons/SecureBootFill';
import SecureBootFill from '@components/Icons/SecureBootLine';
import BSidebar from '@components/Sidebar/BSidebar';
import { Col, Container, Nav, Row } from 'react-bootstrap';
import { BsExclamationOctagonFill, FiAlertOctagon } from 'react-icons/all';

type Props = {
  path: string;
  children: any;
};

export default function NavigationReportDynamic({ path, children }: Props) {
  const { t } = useTranslation('common');
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading } = useReport(reportId);
  const basePath = path + '/' + reportId;
  const [isPro, setIsPro] = React.useState<boolean>(true);

  function renderLinuxNavigation() {
    return (
      <React.Fragment>
        <Nav.Item as={'li'} className={'title-item'}>
          {t('navigation.primaryNavigation.insights')}
        </Nav.Item>
        <NavigationLink
          title={t('navigation.navigationLabels.overview')}
          activeIcon={RiHomeFill}
          leadingIcon={RiHomeLine}
          path={basePath + ROUTES.OVERVIEW}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.cveCheck')}
          activeIcon={BsExclamationOctagonFill}
          leadingIcon={FiAlertOctagon}
          path={basePath + ROUTES.CVE_CHECK}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.passwordHash')}
          activeIcon={RiDoorLockBoxFill}
          leadingIcon={RiDoorLockBoxLine}
          path={basePath + ROUTES.PASSWORD_HASH}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.codeAnalysis')}
          activeIcon={RiFileCodeFill}
          leadingIcon={RiFileCodeLine}
          path={basePath + ROUTES.CODE_ANALYSIS}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.securityScan')}
          activeIcon={RiShieldCheckFill}
          leadingIcon={RiShieldCheckLine}
          path={basePath + ROUTES.SECURITY_SCAN}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.kernelSecurity')}
          activeIcon={RiShieldKeyholeFill}
          leadingIcon={RiShieldKeyholeLine}
          path={basePath + ROUTES.KERNEL_SECURITY}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.binaryAnalysis')}
          activeIcon={RiTerminalBoxFill}
          leadingIcon={RiTerminalBoxLine}
          path={basePath + ROUTES.BINARY_ANALYSIS}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.nvramAnalysis')}
          activeIcon={ChipIconFill}
          leadingIcon={ChipIconLine}
          path={basePath + ROUTES.NVRAM_ANALYSIS}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <Nav.Item as={'li'} className={'title-item'}>
          {t('navigation.primaryNavigation.tools')}
        </Nav.Item>
        <NavigationLink
          title={t('navigation.navigationLabels.filesystemExplorer')}
          activeIcon={RiFolderFill}
          leadingIcon={RiFolderLine}
          trailingIcon={!isPro ? RiLock2Line : null}
          path={basePath + ROUTES.FILESYSTEM_EXPLORER}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.cryptoAnalyzer')}
          activeIcon={RiFileLockFill}
          leadingIcon={RiFileLockLine}
          trailingIcon={!isPro ? RiLock2Line : null}
          path={basePath + ROUTES.CRYPTO_ANALYZER}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.bomViewer')}
          activeIcon={RiFileShieldFill}
          leadingIcon={RiFileShieldLine}
          trailingIcon={!isPro ? RiLock2Line : null}
          path={basePath + ROUTES.BOM_VIEWER}
        />
      </React.Fragment>
    );
  }

  function renderContainerNavigation() {
    return (
      <React.Fragment>
        <Nav.Item as={'li'} className={'title-item'}>
          {t('navigation.primaryNavigation.insights')}
        </Nav.Item>
        <NavigationLink
          title={t('navigation.navigationLabels.overview')}
          activeIcon={RiHomeFill}
          leadingIcon={RiHomeLine}
          path={basePath + ROUTES.OVERVIEW}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.cveCheck')}
          activeIcon={BsExclamationOctagonFill}
          leadingIcon={FiAlertOctagon}
          path={basePath + ROUTES.CVE_CHECK}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.passwordHash')}
          activeIcon={RiDoorLockBoxFill}
          leadingIcon={RiDoorLockBoxLine}
          path={basePath + ROUTES.PASSWORD_HASH}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.codeAnalysis')}
          activeIcon={RiFileCodeFill}
          leadingIcon={RiFileCodeLine}
          path={basePath + ROUTES.CODE_ANALYSIS}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.securityScan')}
          activeIcon={RiShieldCheckFill}
          leadingIcon={RiShieldCheckLine}
          path={basePath + ROUTES.SECURITY_SCAN}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.binaryAnalysis')}
          activeIcon={RiTerminalBoxFill}
          leadingIcon={RiTerminalBoxLine}
          path={basePath + ROUTES.BINARY_ANALYSIS}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.nvramAnalysis')}
          activeIcon={ChipIconFill}
          leadingIcon={ChipIconLine}
          path={basePath + ROUTES.NVRAM_ANALYSIS}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <Nav.Item as={'li'} className={'title-item'}>
          {t('navigation.primaryNavigation.tools')}
        </Nav.Item>
        <NavigationLink
          title={t('navigation.navigationLabels.filesystemExplorer')}
          activeIcon={RiFolderFill}
          leadingIcon={RiFolderLine}
          trailingIcon={!isPro ? RiLock2Line : null}
          path={basePath + ROUTES.FILESYSTEM_EXPLORER}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.cryptoAnalyzer')}
          activeIcon={RiFileLockFill}
          leadingIcon={RiFileLockLine}
          trailingIcon={!isPro ? RiLock2Line : null}
          path={basePath + ROUTES.CRYPTO_ANALYZER}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.bomViewer')}
          activeIcon={RiFileShieldFill}
          leadingIcon={RiFileShieldLine}
          trailingIcon={!isPro ? RiLock2Line : null}
          path={basePath + ROUTES.BOM_VIEWER}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.configAnalyzer')}
          activeIcon={ConfigAnalyzerFill}
          leadingIcon={ConfigAnalyzerLine}
          trailingIcon={!isPro ? RiLock2Line : null}
          path={basePath + ROUTES.CONFIG_ANALYZER}
        />
      </React.Fragment>
    );
  }

  function renderUefiNavigation() {
    return (
      <React.Fragment>
        <Nav.Item as={'li'} className={'title-item'}>
          {t('navigation.primaryNavigation.insights')}
        </Nav.Item>
        <NavigationLink
          title={t('navigation.navigationLabels.overview')}
          activeIcon={RiHomeFill}
          leadingIcon={RiHomeLine}
          path={basePath + ROUTES.OVERVIEW}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.peimDxe')}
          activeIcon={RiScan2Fill}
          leadingIcon={RiScan2Line}
          path={basePath + ROUTES.PEIM_DXE}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.securityScan')}
          activeIcon={RiShieldCheckFill}
          leadingIcon={RiShieldCheckLine}
          path={basePath + ROUTES.UEFI_SECURITY_SCAN}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.secureBoot')}
          activeIcon={SecureBootFill}
          leadingIcon={SecureBootLine}
          path={basePath + ROUTES.SECURE_BOOT}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.intelBootGuard')}
          activeIcon={IntelBootGuardFill}
          leadingIcon={IntelBootGuardLine}
          path={basePath + ROUTES.INTEL_BOOT_GUARD}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.accessRights')}
          activeIcon={RiLockPasswordFill}
          leadingIcon={RiLockPasswordLine}
          path={basePath + ROUTES.ACCESS_RIGHT}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <Nav.Item as={'li'} className={'title-item'}>
          {t('navigation.primaryNavigation.tools')}
        </Nav.Item>
        <NavigationLink
          title={t('navigation.navigationLabels.filesystemExplorer')}
          activeIcon={RiFolderFill}
          leadingIcon={RiFolderLine}
          path={basePath + ROUTES.FILESYSTEM_EXPLORER}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.attackSurfaceAnalyzer')}
          activeIcon={RiStackFill}
          leadingIcon={RiStackLine}
          path={basePath + ROUTES.ATTACK_SURFACE_ANALYZER}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
      </React.Fragment>
    );
  }

  function renderRtosNavigation() {
    return (
      <React.Fragment>
        <Nav.Item as={'li'} className={'title-item'}>
          {t('navigation.primaryNavigation.insights')}
        </Nav.Item>
        <NavigationLink
          title={t('navigation.navigationLabels.overview')}
          activeIcon={RiHomeFill}
          leadingIcon={RiHomeLine}
          path={basePath + ROUTES.OVERVIEW}
        />
        <NavigationLink
          title={t('navigation.navigationLabels.capabilities')}
          activeIcon={RiShieldFlashFill}
          leadingIcon={RiShieldFlashLine}
          path={basePath + ROUTES.RTOS_CAPABILITIES}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.tasks')}
          activeIcon={RiTaskFill}
          leadingIcon={RiTaskLine}
          path={basePath + ROUTES.RTOS_TASKS}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.functions')}
          activeIcon={RiFunctionFill}
          leadingIcon={RiFunctionLine}
          path={basePath + ROUTES.RTOS_FUNCTIONS}
          trailingIcon={!isPro ? RiLock2Line : null}
        />

        <NavigationLink
          title={t('navigation.navigationLabels.symbols')}
          activeIcon={RiFileList2Fill}
          leadingIcon={RiFileList2Line}
          path={basePath + ROUTES.RTOS_SYMBOLS}
          trailingIcon={!isPro ? RiLock2Line : null}
        />
      </React.Fragment>
    );
  }

  function renderNavigationBasedOnReportType(type?: REPORT_TYPE) {
    if (type === REPORT_TYPE.LINUX) {
      return renderLinuxNavigation();
    }
    if (type === REPORT_TYPE.UEFI) {
      return renderUefiNavigation();
    }
    if (type === REPORT_TYPE.CONTAINER) {
      return renderContainerNavigation();
    }
    if (type === REPORT_TYPE.VXWORKS) {
      return renderRtosNavigation();
    } else {
      return <></>;
    }
  }

  return !isLoading ? (
    <React.Fragment>
      <BSidebar>
        <Nav as="ul">
          {renderNavigationBasedOnReportType(data?.type)}
          {/*activeKey={getExactPath()}    */}
        </Nav>
      </BSidebar>
      <div className="main-panel">
        <div className={'content'}>{children}</div>
        <Container fluid className={'pe-5'}>
          <Row>
            <Col xs={12}>
              <Footer />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  ) : null;
}
