import ModalWrapper from '@components/ModalWrapper';
import { ReportDetails } from '@utils/types/report';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
type Props = {
  details?: ReportDetails;
  onClose: typeof noop;
};

export default function ModalReportVxWorksDetails({
  details,
  onClose = noop,
}: Props) {
  const { t } = useTranslation(['common']);
  return (
    <div className={styles.container}>
      <ModalWrapper
        onClose={onClose}
        title={t('common:modalReportDetails.title')}
      >
        <ul className={styles.horizontalDetails}>
          <li>
            <strong>{t('common:modalReportDetails.os')}</strong>
            {details?.os}
          </li>
          <li>
            <strong>{t('common:modalReportDetails.kernel')}</strong>
            {details?.kernel}
          </li>

          <li>
            <strong>{t('common:modalReportDetails.arch')}</strong>
            {details?.arch}
          </li>
        </ul>
        <ul className={styles.verticalDetails}>
          <li>
            <strong>{t('common:modalReportDetails.endianness')}</strong>{' '}
            {details?.endianness}
          </li>
          <li>
            <strong>{t('common:modalReportDetails.wordSize')}</strong>{' '}
            {details?.wordSize}
          </li>
          <ul className={styles.complienceContainer}>
            <li>
              <strong>Nist Compliance Check</strong> Failed. Review CVE, Malware
              and Password analyses.
            </li>
            <li>
              <strong>CRA Compliance Check</strong>
              Failed. Review CVE, Malware and Password analyses.
            </li>
          </ul>
        </ul>
      </ModalWrapper>
    </div>
  );
}
