export default function ChipIconFill({
  color,
  size,
  ...rest
}: any): JSX.Element {
  return (
    <svg
      {...rest}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
        fill={color}
      >
        <g
          id="Icons/alert"
          transform="translate(-1.000000, -2.000000)"
          fill={color}
        >
          <path
            d="M14,20 L10,20 L10,22 L8,22 L8,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,16 L2,16 L2,14 L4,14 L4,10 L2,10 L2,8 L4,8 L4,5 C4,4.44771525 4.44771525,4 5,4 L8,4 L8,2 L10,2 L10,4 L14,4 L14,2 L16,2 L16,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,8 L22,8 L22,10 L20,10 L20,14 L22,14 L22,16 L20,16 L20,19 C20,19.5522847 19.5522847,20 19,20 L16,20 L16,22 L14,22 L14,20 Z M8,8 L8,12 L12,12 L12,8 L8,8 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
