import LayoutPage from '@components/LayoutPage';
import * as React from 'react';

import Table from '@components/TableNew/Table';
import useNvramAnalysys from '@hooks/analysis/useNvramAnalysys';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import EmptyResults from '@components/EmptyResults';

import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';

import GenericError from '@components/ErrorState';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiSpamLine
} from 'react-icons/ri';

import LayoutSection from '@components/LayoutSection';
import ModalReportLinuxDetails from '@components/ModalReportLinuxDetails';
import ReportHeading from '@components/ReportHeading';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import { GlobalContext } from '@contexts/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import usePaginationCounter, { UsePaginationCounterState } from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import { getScanTime } from '@utils/index';
import { format } from 'date-fns';
import { noop } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable
} from 'react-table';
import InfoBox from '../ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '../ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '../ReportOverviewLinux/Components/InfoBoxMessage';
import ramStyles from './styles.module.scss';

// const MIN_ROW_LENGTH = 50;

export enum NVRAM_PROPERTY {
  FUN = 'fun',
  EXE = 'exe',
  NAME = 'name',
}

export default function NvRam() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isError, error } = useNvramAnalysys(reportId);
  const { data: reportData } = useReportOverview(reportId);

  const { t } = useTranslation(['pages', 'tables', 'emptyReports', 'common']);

  const [search, setSearch] = React.useState('');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Exe',
        accessor: 'exe'
      },
      {
        Header: '',
        accessor: 'fun',
        Cell: (props: any) => {
          return (
            <div className={'d-flex align-items-center justify-content-end'}>
              <div
                className={`${ramStyles['nvRam-cell']} ${ramStyles[props.value]
                  }`}
              >
                <span>{`${props?.value?.toUpperCase()}`}</span>
              </div>
            </div>
          );
        }
      }
    ],
    []
  );

  const memoizedSearch = React.useMemo(() => search, [search]);

  const memoizedData = React.useMemo(() => {
    if (!data || data.length === 0) return [];
    return data;
  }, [data]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { globalFilter: memoizedSearch, pageSize: 10 }
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState = usePaginationCounter(tableInstance);

  // const breakPointsLeft = {
  //   xs: 12,
  //   sm: 12,
  //   md: 12,
  //   lg: 12,
  //   xl: 12,
  // };
  const getNumber = React.useMemo(() => data?.filter(d => d.fun === 'get').length || 0, [data]);
  const setNumber = React.useMemo(() => data?.filter(d => d.fun === 'set').length || 0, [data]);

  const scanTime = React.useMemo(() => reportData ? getScanTime(reportData) : null, [reportData]);

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisSimple />}
      {!isLoading && !isError && (!data || data.length < 1) && (
        <EmptyResults
          title={t('emptyReports:nvRam.title')}
          description={t('emptyReports:nvRam.subtitle')}
        //buttonLabel={t('emptyReports:nvRam.buttonLabel')}
        //onClick={console.log}
        />
      )}
      {isError && <GenericError error={error} />}
      {data && data?.length > 0 && !isLoading && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
            // onDescriptionButtonClick={onShowModalDetail}
            />
          </LayoutSection>
          <SectionHeader
            title={t('pages:nvramPage.title')}
            leadingIcon={RiSpamLine}
            tooltipMessage={t('pages:nvramPage.buttonLabel')}
            bannerText={t('pages:nvramPage.bannerMessage')}
          />
          <Container fluid>
            <Row>
              <Col className="px-0" xs={12}>
                <InfoBox style={{
                  padding: 20,
                  height: 'auto'
                }}>
                  <InfoBoxContent style={{
                    alignItems: 'flex-start', background: isDarkMode ? '#202226' : 'none', flexDirection: 'row', gap: 60
                  }}>
                    <div>
                      <div className={ramStyles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='get'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={ramStyles['info-box-count']}>{getNumber}</div>
                    </div>
                    <div>
                      <div className={ramStyles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='set'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={ramStyles['info-box-count']}>{setNumber}</div>
                    </div>
                  </InfoBoxContent>-
                </InfoBox>
                <Container fluid style={{
                  border: '1px solid var(--neutral300)',
                  marginTop: 40,
                  padding: 0,
                  borderRadius: '2px'
                }}>
                  <Row>
                    <Col xs={12} >
                      <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>NVRAM</div>
                      <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total: {getNumber + setNumber}</div>
                      <Table className="border-0" tableInstance={tableInstance} >
                        <Table.Footer>
                          <p className={tableStyles['table__paginationState']}>
                            {paginationMessage}
                          </p>
                          <div className={tableStyles['table__paginationButtons']}>
                            <button
                              className={
                                tableInstance?.canPreviousPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.previousPage()}
                            >
                              <RiArrowLeftSLine size={20} />
                            </button>
                            <button
                              className={
                                tableInstance?.canNextPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.nextPage()}
                            >
                              <RiArrowRightSLine size={20} />
                            </button>
                          </div>
                          <TablePageSize
                            maxResultChunkSize={maxResultChunkSize}
                            pageSize={tableInstance?.state?.pageSize || 10}
                            onPageSizeChange={onPageSizeChange}
                          />
                        </Table.Footer>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {reportData && shouldShowModalDetail ? (
        <ModalReportLinuxDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData.reportDetails,
            submitted: format(
              new Date(`${reportData.startDate ?? new Date()}`),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime:
              scanTime ? scanTime.value +
                ' ' +
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                t(`common:${scanTime.type as string}`) : '',
            hash: reportData.hash || ''
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
