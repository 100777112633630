import { useMutation, useQueryClient } from 'react-query';
import { createOrganization } from '@services/api';
import { CreateOrganizationFormData } from '@components/ModalCreateOrganization/ModalCreateOrganization';
import { toast } from 'react-toastify';

export const KEY = 'organizations';

export default function useCreateOrganization() {
  const queryClient = useQueryClient();
  return useMutation(
    [KEY],
    async (body: CreateOrganizationFormData) => await createOrganization(body),
    {
      onSuccess: () => queryClient.invalidateQueries(['organizations']),
      onError: (err: any): any => {
        if (!err) {
          return toast.error('Something went wrong');
        }
        return toast.error(err);
      },
    }
  );
}
