import React, { CSSProperties, ReactElement } from 'react';
import { Form } from 'react-bootstrap';
import formStyles from '../styles.module.scss';
import InputLeft from '@components/Input/InputLeft';
import InputRight from '@components/Input/InputRight';

interface InputForwardRef
  extends React.ForwardRefExoticComponent<
    InputProps & React.RefAttributes<HTMLInputElement>
  > {
  Left: typeof InputLeft;
  Right: typeof InputRight;
}

type InputProps = {
  label?: string;
  placeholder?: string;
  type?: string;
  value?: string | number;
  onChange?: any;
  readOnly?: boolean;
  className?: string;
  style?: CSSProperties;
  helper?: string;
  children?: ReactElement | ReactElement[];
  hint?: string;
};
const forwardRef = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    // const pre = 'input-';
    const {
      label,
      placeholder = '',
      type = 'text',
      readOnly = false,
      helper = '',
      hint = '',
      className = '',
      style = {},
      children = [],
      ...rest
    } = props;
    const { value, ...hookFormProps } = rest;

    const renderChildrensHandler = (compName: string, props: any) => {
      return React.Children.map(children, (child: ReactElement) => {
        if (child.type === InputLeft && compName === 'InputLeft') {
          return React.cloneElement(child, { ...child.props, ...props });
        }
        if (child.type === InputRight && compName === 'InputRight') {
          return React.cloneElement(child, { ...child.props, ...props });
        }
      });
    };

    return (
      <div className={`${className}`} style={style}>
        {label ||
          (helper && (
            <div className="d-flex flex-row justify-content-between align-items-center mb-3">
              {label && (
                <Form.Label className={formStyles['form-label']}>
                  {label}
                </Form.Label>
              )}
              {helper && (
                <span className={formStyles['form-mono']}>{helper}</span>
              )}
            </div>
          ))}
        <div className="mb-2 iconAddon">
          {/*{renderChildrensHandler('InputLeft', {})}*/}
          {/*{iconLeft && <span className='iconLeft'>{iconLeft}</span>}*/}
          <Form.Control
            className={`border-2 ${formStyles['input-control']}`}
            {...hookFormProps}
            value={value}
            ref={ref}
            placeholder={placeholder}
            readOnly={readOnly}
            type={type}
          />
          {renderChildrensHandler('InputRight', {})}
          {/*{iconRight && <span className='iconRight'>{iconRight}</span>}*/}
        </div>
        {hint && <span className={formStyles['form-hint']}>{hint}</span>}
      </div>
    );
  }
);

export default {
  ...forwardRef,
  Left: InputLeft,
  Right: InputRight,
} as InputForwardRef;
