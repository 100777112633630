import SectionContentFooter from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentFooter';
import React, { ReactNode } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SectionContent from './SectionContent';
import SectionHeader from './SectionHeader';

type SectionLayoutProps = {
  children: ReactNode;
  className?: string;
};

const SectionLayout = (props: SectionLayoutProps) => {
  const { children, className = '' } = props;
  const renderChildrenHandler = (name: string) => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === SectionHeader && name === 'SectionHeader') {
          return React.cloneElement(child);
        }
        if (child.type === SectionContent && name === 'SectionContent') {
          return React.cloneElement(child);
        }
        if (child.type === SectionContentFooter && name === 'SectionContentFooter') {
          return React.cloneElement(child);
        }
      }
    });
  };

  return (
    <Container fluid className={className} style={{ paddingBottom: '24px' }}>
      <Row style={{ height: '100%' }}>
        <Col className="px-0" xs={12} sm={12} md={12}>
          {renderChildrenHandler('SectionHeader')}
        </Col>
        <Col xs={12} sm={12} md={12}>
          {renderChildrenHandler('SectionContent')}
        </Col>
        <Col className="px-0" xs={12} sm={12} md={12}>
          {renderChildrenHandler('SectionContentFooter')}
        </Col>
      </Row>
    </Container>
  );
};

export default SectionLayout;
