import AuthPage from '@pages/AuthPage';
import Dashboard from '@pages/Dashboard';
import NewPassword from '@pages/NewPassword';
import PasswordReset from '@pages/PasswordReset';
import SignIn from '@pages/SignIn';
import PrivateRoute from '@routes/PrivateRoute';
import { ROUTES } from '@routes/routes';
import * as React from 'react';
// import UnloggedRoute from '@routes/UnloggedRoute';
import { GlobalProvider } from '@contexts/GlobalContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { CurrentReportProvider } from '@contexts/CurrentReport';
import { NewScanProvider } from '@contexts/NewScanContext';
import { UserProvider } from '@contexts/UserContext';
import SignUp from '@pages/SignUp';
import VerifyEmail from '@pages/VerifyEmail';
import { setupInterceptors } from '@utils/interceptors';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

const queryClient = new QueryClient();

function App() {
  const router = useHistory();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const handleOrganizationInvitation = async () => {
    const invitationToken =
      query.get('invite') ||
      localStorage.getItem('organizationInvitationToken');
    if (invitationToken) {
      localStorage.setItem('organizationInvitationToken', invitationToken);
    }
  };

  React.useEffect(() => {
    handleOrganizationInvitation();
  }, []);

  React.useEffect(() => {
    setupInterceptors();
  }, [router]);

  return (
    <GlobalProvider>
      <CurrentReportProvider>
        <QueryClientProvider client={queryClient}>
          {/* {status === 'success' && ( */}
          <Switch>
            <Route path={ROUTES.SIGN_IN}>
              <SignIn />
            </Route>
            {/* TODO: will be returent in the future: */}
            {/* <Route exact path={ROUTES.SIGN_UP}>
                <SignUp />
              </Route>
              <Route exact path={ROUTES.RESET_PASSWORD}>
                <PasswordReset />
              </Route> */}
            <Route exact path={ROUTES.NEW_PASSWORD}>
              <NewPassword />
            </Route>
            <Route exact path={ROUTES.AUTH}>
              <AuthPage />
            </Route>
            {/* <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}
            <PrivateRoute path={ROUTES.BASE}>
              <NewScanProvider>
                <UserProvider>
                  <Dashboard />
                </UserProvider>
              </NewScanProvider>
            </PrivateRoute>
            <Route path={ROUTES.VERIFY_EMAIL}>
              <VerifyEmail />
            </Route>
            <PrivateRoute path="/">
              <Redirect to={ROUTES.BASE} />
            </PrivateRoute>
            <Route path="*">
              <>
                <p>Page not found</p>
              </>
            </Route>
          </Switch>
          {/* )} */}
          {process.env.NODE_ENV !== 'production' && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </CurrentReportProvider>
    </GlobalProvider>
  );
}

export default App;
