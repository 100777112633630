import GenericError from '@components/ErrorState';
import ConfigAnalyzerLine from '@components/Icons/ConfigAnalyzerLine';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import ModalReportLinuxDetails from '@components/ModalReportLinuxDetails';
import ReportHeading from '@components/ReportHeading';
import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import TabsProvider from '@components/Tabs/TabsProvider';
import { TabsHeader } from '@components/TabsHeader';
import tabsStyles from '@components/TabsHeader/styles.module.scss';
import { GlobalContext } from '@contexts/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import tabStyles from '@pages/Report/Linux/ReportOverviewLinux/styles.module.scss';
import { getCssVariable, getScanTime, padNumber } from '@utils/index';
import { format } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiSearch2Line,
} from 'react-icons/ri';
import { useParams } from 'react-router';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import InfoBox from '../ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '../ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '../ReportOverviewLinux/Components/InfoBoxMessage';
import styles from './styles.module.scss';

export default function ConfigAnalyzer() {
  const { reportId = '' }: { reportId: string } = useParams();
  const {
    data: reportData,
    isLoading,
    isError,
    error,
  } = useReportOverview(reportId);
  const configAnalyzerData = reportData?.analysis;
  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  const { t } = useTranslation(['tables', 'pages', 'emptyReports', 'common']);

  const [activeIndex, setActiveIndex] = React.useState('0');
  const [search, setSearch] = React.useState('');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const tabsState = React.useMemo(
    () => ({
      id: 'controlled-tab',
      activeKey: activeIndex,
      className: `mb-3 ${tabStyles['section-content-right']} w-75`,
      onSelect: (k: string) => setActiveIndex(k),
      tabs: [
        {
          eventKey: 0,
          tabClassName: `${tabsStyles['tab-header']}
           ${activeIndex === '0'
              ? tabsStyles['active-tab']
              : tabsStyles['inactive-tab']
            }
            `,
          title: (
            <TabsHeader
              title="Environment variables"
              number={
                configAnalyzerData?.env ? configAnalyzerData?.env.length : 0
              }
              color={getCssVariable('light-violet')}
              tooltipMessage="Since the last check"
            />
          ),
        },
        {
          eventKey: 1,
          tabClassName: `${tabsStyles['tab-header']} ${activeIndex === '1'
            ? tabsStyles['active-tab']
            : tabsStyles['inactive-tab']
            }`,
          title: (
            <TabsHeader
              title={t('pages:configAnalyzerPage.tableSectionTitle.history')}
              number={
                configAnalyzerData?.history
                  ? configAnalyzerData?.history.length
                  : 0
              }
              color={getCssVariable('cyan')}
              tooltipMessage="Since the last check"
            />
          ),
        },
      ],
    }),
    [activeIndex, configAnalyzerData]
  );

  const columns: any = React.useMemo(
    () => [
      {
        Header: +activeIndex === 0 ? 'Name' : 'Created',
        accessor: +activeIndex === 0 ? 'name' : 'created',
        Cell: ({ row, value }: { row: any; value: boolean }) => {
          return (
            <div className="d-flex flex-row align-items-center">
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400'),
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {value}
            </div>
          );
        },
      },
      {
        Header: +activeIndex === 0 ? 'Value' : 'Created by',
        accessor: +activeIndex === 0 ? 'value' : 'createdBy',
      },
      {
        Header: +activeIndex === 0 ? ' ' : 'Empty layer',
        accessor: +activeIndex === 0 ? ' ' : 'emptyLayer',
        Cell: ({ row, value }: { row: any; value: boolean }) => {
          return (
            <div className="d-flex flex-row align-items-center">
              {value?.toString()}
            </div>
          );
        },
      },
    ],
    [activeIndex]
  );

  const memoizedSearch = React.useMemo(() => search, [search]);

  const memoizedData = React.useMemo(() => {
    if (
      !configAnalyzerData ||
      !configAnalyzerData.env ||
      !configAnalyzerData.history
    )
      return [];
    if (activeIndex === '0') {
      return configAnalyzerData.env;
    }
    if (activeIndex === '1') {
      return configAnalyzerData.history;
    }
    return [];
  }, [activeIndex, configAnalyzerData]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData as any,
      initialState: { globalFilter: memoizedSearch, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  const scanTime = React.useMemo(
    () => (reportData ? getScanTime(reportData) : null),
    [reportData]
  );

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisSimple />}
      {isError && <GenericError error={error} />}
      {reportData && configAnalyzerData && !isLoading && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <SectionHeader
            title={t('pages:configAnalyzerPage.title')}
            leadingIcon={ConfigAnalyzerLine}
            tooltipMessage={t('pages:configAnalyzerPage.buttonLabel')}
            bannerText={t('pages:configAnalyzerPage.bannerMessage')}
          />
          <Container fluid>
            <Row>
              <Col className="px-0">
                <InfoBox style={{
                  padding: 20,
                  height: 'auto',
                }}>
                  <InfoBoxContent style={{
                    alignItems: 'flex-start', background: isDarkMode ? '#202226' : 'none', flexDirection: 'row', gap: 60, position: 'relative'
                  }}>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='Environment variables'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{configAnalyzerData?.env ? configAnalyzerData?.env.length : 0}</div>
                    </div>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message={t('pages:configAnalyzerPage.tableSectionTitle.history')}
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{configAnalyzerData?.history ? configAnalyzerData?.history.length : 0}</div>
                    </div>
                  </InfoBoxContent>-
                </InfoBox>
                <Container fluid style={{
                  border: '1px solid var(--neutral300)',
                  marginTop: 40,
                  borderRadius: '2px'
                }}>
                  <Row>
                    <Col className="px-0" xs={12} >
                      <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>Config Analysis</div>
                      <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total: {memoizedData?.length || 0}</div>
                      <TabsProvider state={tabsState} />
                      <Table tableInstance={tableInstance} borderless>
                        <Table.Footer>
                          <p className={tableStyles['table__paginationState']}>
                            {paginationMessage}
                          </p>
                          <div className={tableStyles['table__paginationButtons']}>
                            <button
                              className={
                                tableInstance?.canPreviousPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.previousPage()}
                            >
                              <RiArrowLeftSLine size={20} />
                            </button>
                            <button
                              className={
                                tableInstance?.canNextPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.nextPage()}
                            >
                              <RiArrowRightSLine size={20} />
                            </button>
                          </div>
                          <TablePageSize
                            maxResultChunkSize={maxResultChunkSize}
                            pageSize={tableInstance?.state?.pageSize || 10}
                            onPageSizeChange={onPageSizeChange}
                          />
                        </Table.Footer>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {reportData && shouldShowModalDetail ? (
        <ModalReportLinuxDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData.reportDetails,
            submitted: format(
              new Date(`${reportData.startDate ?? new Date()}`),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: reportData.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
