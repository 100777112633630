import styles from './styles.module.scss';
import type { IconType } from 'react-icons';
import { getCssVariable } from '@utils/index';

type Props = {
  leadingIcon?: IconType;
  message: string;
  iconSize?: number;
  iconColor?: string;
};

export default function SuccessMessageOnBoarding({
  leadingIcon,
  message,
  iconSize = 20,
  iconColor = 'neutral400',
}: Props) {
  const LeadingIcon = leadingIcon ? leadingIcon : null;

  return (
    <div className={styles.container}>
      {LeadingIcon && (
        <LeadingIcon
          size={iconSize}
          color={getCssVariable(iconColor)}
          className={styles.icon}
        />
      )}
      <p>{message}</p>
    </div>
  );
}
