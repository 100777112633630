import React from 'react';
import styles from './styles.module.scss';
import OrganizationPlan from '@components/BSOrganizationPlan';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { RiAddLine } from 'react-icons/ri';
import { useHistory } from 'react-router';
import { ROUTES } from '@routes/routes';
//import { useRouteMatch } from 'react-router-dom';
import { useFullOrganizations } from '@hooks/useOrganizations';
import useDialog from '@hooks/useDialog';
import ModalCreateOrganization from '@components/ModalCreateOrganization/ModalCreateOrganization';

const Organization = () => {
  const history = useHistory();
  //const { path } = useRouteMatch();

  const createOrganizationModal = useDialog('create-organization-modal');
  const { data = [], isLoading } = useFullOrganizations();

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} className="border-bottom pb-3 px-0">
            <Row className="align-items-center justify-content-between ">
              <Col xs={6}>
                <div className="d-flex">
                  <span className={styles.title}>Organization</span>
                </div>
              </Col>
              <Col xs={6}>
                <div className="d-flex justify-content-end">
                  <Button
                    variant={'primary'}
                    size={'sm'}
                    onClick={createOrganizationModal.open}
                  >
                    <RiAddLine />
                    Create Organization
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className="pt-3 px-0">
            <div className="d-flex">
              <span className={styles.subTitle}>Your Organizations</span>
            </div>
          </Col>
          <Col xs={12}>
            {!isLoading &&
              data.length > 0 &&
              data.map(
                (organization) =>
                  !organization.organization.built_in && (
                    <Row key={organization?.organization?.id}>
                      <Col xs={12} className="py-3 px-0 border-bottom">
                        <OrganizationPlan
                          organizationData={organization}
                          editMode={organization.role === 'OWNER'}
                          showUpdate={organization.role === 'OWNER'}
                          onClickUpdate={() =>
                            history.push(
                              `${
                                ROUTES.BASE +
                                ROUTES.USER_SETTINGS +
                                ROUTES.ORGANIZATION
                              }/${organization?.organization?.id}/edit`
                            )
                          }
                        />
                      </Col>
                    </Row>
                  )
              )}
          </Col>
        </Row>
      </Container>
      {createOrganizationModal.opened && (
        <ModalCreateOrganization onClose={createOrganizationModal.close} />
      )}
    </>
  );
};

export default Organization;
