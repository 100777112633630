import React from 'react';
import { WithChildren } from '@utils/types/helpers';
import { noop } from 'lodash';

const ReportActionsContext = React.createContext({
  data: { id: '', name: '' },
  setReportData: noop,
  resetReportData: noop,
});

export type UpdateModalReportPayload = {
  id: string;
  name: string;
};

const ReportActionsProvider = ({ children }: WithChildren) => {
  const [data, setReportData] = React.useState<{ id: string; name: string }>({
    id: '',
    name: '',
  });
  function resetReportData(): void {
    setReportData({ id: '', name: '' });
  }

  return (
    <ReportActionsContext.Provider
      value={{ data, setReportData, resetReportData }}
    >
      {children}
    </ReportActionsContext.Provider>
  );
};

export { ReportActionsProvider, ReportActionsContext };
