import styles from './styles.module.scss';
import type { IconType } from 'react-icons';

import { getCssVariable } from '@utils/index';

type IProps = {
  leadingIcon?: IconType;
  activeIcon?: IconType;

  title: string;
  navIndex: number;
  activeIconColor?: string;
  onClick?: () => void;
  navStep: number;
  isActive?: boolean;
};

export default function UserSettingsNavigationLink({
  activeIcon,
  leadingIcon,
  onClick,
  title,
  navStep,
  isActive = false,
  navIndex,
  activeIconColor = 'neutral800',
}: IProps) {
  const LeadingIcon = leadingIcon ? leadingIcon : null;
  const ActiveIcon = activeIcon ? activeIcon : null;

  return (
    <div
      className={`${styles.linkItem} ${isActive && styles.isActive}`}
      onClick={onClick}
    >
      <div className={styles.wrap}>
        {LeadingIcon && navIndex !== navStep && (
          <div className={styles.leadingIcon}>
            <LeadingIcon size={24} />
          </div>
        )}
        {ActiveIcon && navIndex === navStep && (
          <div className={styles.leadingIcon}>
            <ActiveIcon size={24} color={getCssVariable(activeIconColor)} />
          </div>
        )}
        <p>{title}</p>
      </div>
    </div>
  );
}
