import InfoBox from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxMessage';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import styles from '@pages/Report/Linux/ReportOverviewLinux/styles.module.scss';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';

type InfoBoxWrapperProps = {
  headerTitle: string;
  leadingIcon: any;
  tooltipMessage: string;
  chartValue?: number;
  boxMessage: string;
  chartColor: string | null;
  chartLineColor: string | null;
  url: string;
};

const InfoBoxWrapper = (props: InfoBoxWrapperProps) => {
  const history = useHistory();
  const {
    headerTitle,
    leadingIcon,
    tooltipMessage,
    chartValue,
    boxMessage,
    chartColor = null,
    chartLineColor = null,
    url = '#'
  } = props;
  return (
    <>
      <SectionHeader
        title={headerTitle}
        leadingIcon={leadingIcon}
        tooltipMessage={tooltipMessage}
      />
      <InfoBox>
        <InfoBoxContent>
          <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <InfoBoxMessage
              message={boxMessage}
              style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
            />
            {chartValue ? <Button
              onClick={() =>
                history.push(
                  url
                )
              }
              className={styles['button']}
            >
              VIEW ALL
            </Button> : null}
          </div>
          <div className={styles['info-box-count']}>{chartValue}</div>
        </InfoBoxContent>
      </InfoBox>
    </>
  );
};

export default InfoBoxWrapper;
