import { getGraphTheme } from '@components/Graph/theme';
import { GlobalContext } from '@context/GlobalContext';
import { ResponsiveChord } from '@nivo/chord';
import { flattenDeep } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

const getUniqueLabels = (data: any): any => {
  if (data?.capabilitiesOccourrences?.length) {
    const rawLabels = [] as string[];
    for (const capability of data.capabilitiesOccourrences) {
      if (capability.caps && capability.caps.length === 2)
        rawLabels.push(capability.caps);
    }
    const final = new Set(flattenDeep(rawLabels));
    return Array.from(final);
  }
  return [];
};

const buildData = (data: any): any => {
  const labels = [...getUniqueLabels(data)];
  const capabilitiesOccourrences = data?.capabilitiesOccourrences
    ? [...data.capabilitiesOccourrences]
    : [];

  const result = [] as any;
  labels.forEach((_) => {
    result.push(new Array(labels.length).fill(0));
  });

  if (capabilitiesOccourrences?.length) {
    for (const f of capabilitiesOccourrences) {
      let sourceItem = '';
      let destItem = '';
      let sourceItemIndex = NaN;
      let destItemIndex = NaN;
      if (f?.caps?.length === 2) {
        sourceItem = f.caps[0];
        destItem = f.caps[1];
        sourceItemIndex = labels.indexOf(sourceItem);
        destItemIndex = labels.indexOf(destItem);
        if (sourceItemIndex > -1 && destItemIndex > -1)
          result[sourceItemIndex][destItemIndex] =
            result[sourceItemIndex][destItemIndex] + f.occourrence;
      }
    }
  }
  return result;
};

type BinaryAnalysisCapabilitiesGraphProps = {
  data: any;
  itemTextColor?: string;
};
const BinaryAnalysisCapabilitiesGraph = ({
  data,
  itemTextColor,
}: BinaryAnalysisCapabilitiesGraphProps) => {
  const { t } = useTranslation('common');
  const transformedData = buildData(data);
  const { theme } = React.useContext(GlobalContext);
  const graphTheme = getGraphTheme(theme);

  if (!transformedData || transformedData.length === 0)
    return <p>{t('noData')}</p>;
  return (
    <ResponsiveChord
      // width={100}
      theme={graphTheme}
      data={transformedData}
      keys={getUniqueLabels(data) || []}
      margin={{ top: 25, right: 200, bottom: 25, left: 25 }}
      enableLabel={false}
      // valueFormat='.2f'
      padAngle={0.02}
      innerRadiusRatio={0.96}
      innerRadiusOffset={0.02}
      inactiveArcOpacity={0.25}
      arcBorderColor={{
        from: 'color',
        modifiers: [['darker', 0.6]],
      }}
      activeRibbonOpacity={0.75}
      inactiveRibbonOpacity={0.25}
      ribbonBorderColor={{
        from: 'color',
        modifiers: [['darker', 0.6]],
      }}
      labelRotation={-90}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 1]],
      }}
      colors={{ scheme: 'nivo' }}
      motionConfig="stiff"
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 100,
          translateY: 0,
          itemWidth: 80,
          itemHeight: 20,
          itemsSpacing: 6,
          itemTextColor: itemTextColor || '#999',
          itemDirection: 'left-to-right',
          symbolSize: 12,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
};

export default BinaryAnalysisCapabilitiesGraph;
