import * as React from 'react';
// import BannerMessage from '@components/BannerMessage';
// import CodeAnalysisHighlight from '@components/CodeAnalysisHighlight';
// import DataHighlightChart from '@components/DataHighlightChart';
import EmptyResults from '@components/EmptyResults';

// import LayoutBox from '@components/LayoutBox';
import LayoutPage from '@components/LayoutPage';
// import LayoutSection from '@components/LayoutSection';
import usePeimDxeAnalysis from '@hooks/analysis/usePeimDxeAnalysis';
// import { RiInformationLine } from 'react-icons/ri';
import { useParams } from 'react-router';
// import { Link } from 'react-router-dom';
import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';
import { getCssVariable, getScanTime } from '@utils/index';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import styles from './styles.module.scss';
// import ReportHeading from '@components/ReportHeading';
// import DataHighlight, { SIZE } from '@components/DataHighlight';
import GenericError from '@components/ErrorState';
import TableModal from '@components/TableModal';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TabsProvider from '@components/Tabs/TabsProvider';
import tabsStyles from '@components/TabsHeader/styles.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
// import reportStyles from '@pages/Report/styles.module.scss';
import Chip, { CHIP_COLOR } from '@components/Chip';
import LayoutSection from '@components/LayoutSection';
import ModalReportUefiDetails from '@components/ModalReportUefiDetails';
import ReportHeading from '@components/ReportHeading';
import SkeletonAnalysisWithBlocks from '@components/SkeletonAnalysisWithBlocks';
import TablePageSize from '@components/TablePageSize';
import { TabsHeader } from '@components/TabsHeader';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import codeAnalysisStyles from '@pages/Report/Linux/CodeAnalysis/styles.module.scss';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionContentRight from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentRight';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import tabStyles from '@pages/Report/Linux/ReportOverviewLinux/styles.module.scss';
import TableSwitch from '@pages/Report/common/Components/TableSwitch/TableSwitch';
import { format, parse } from 'date-fns';
import { noop } from 'lodash';
import { MdClear, MdDone } from 'react-icons/md';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiScan2Fill,
} from 'react-icons/ri';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import PeimDxeGraph from '../Overview/Graphs/PeimDxeGraph';

// export enum PEIM_DXE_PROPERTY {
//   NAME = 'name',
//   UEFI_TYPE = 'uefiType',
//   FILETYPE = 'filetype',
//   FORMAT = 'format',
//   MACHINE = 'machine',
//   SIGNED = 'signed',
//   DEPENDENCIES = 'dependencies',
//   HAS_UNKNOW = 'hasUnknown',
// }
// type PeimDxeData = {
//   certificates: Array<any>;
//   dependencies: Array<string>;
//   filetype: string;
//   format: string;
//   machine: string;
//   name: string;
//   signed: boolean;
//   uefiType: string;
//   hasUnknown: boolean;
//   loc?: number;
//   color: string;
// };

function getColorFromType(type: string): CHIP_COLOR {
  const types: any = {
    'PEI module': CHIP_COLOR.PEI_MODULE,
    'PEI core': CHIP_COLOR.PEI_CORE,
    'DXE driver': CHIP_COLOR.DXE_DRIVER,
    'DXE core': CHIP_COLOR.DXE_CORE,
    'Combined PEI/DXE': CHIP_COLOR.COMBINED_PEI_DXE,
  };
  return types[type];
}

export default function ReportPeimDxe() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isSuccess, isError, error } =
    usePeimDxeAnalysis(reportId);
  const { data: reportData } = useReportOverview(reportId);
  const { t } = useTranslation(['tables', 'pages', 'emptyReports', 'common']);
  const [activeIndex, setActiveIndex] = React.useState('0');
  const [selectedRow, setSelectedRow] = React.useState<any>([]);
  const [selectedFilterTable, setSelectedFilterTable] =
    React.useState<any>('signatures');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const tableDataTypes: any = React.useMemo(() => {
    return {
      '0': 'PEI module',
      '1': 'DXE driver',
    };
  }, []);

  const countElement = (dataArray: any, index: string): number => {
    if (!dataArray || dataArray.length < 1) return 0;
    return dataArray.filter((el: any) => el.uefiType === tableDataTypes[index])
      .length;
  };

  const tabsState = React.useMemo(
    () => ({
      id: 'controlled-tab',
      activeKey: activeIndex,
      className: `mb-3 ${tabStyles['section-content-right']}`,
      style: { backgroundColor: '#F6F8FA' },
      onSelect: (k: string) => setActiveIndex(k),
      tabs: [
        {
          eventKey: 0,
          tabClassName: `${tabsStyles['tab-header']} ${activeIndex === '0'
            ? tabsStyles['active-tab']
            : tabsStyles['inactive-tab']
            }`,
          title: (
            <TabsHeader
              title="PEIM"
              number={countElement(data?.content, '0')}
              // variation={'5.21'}
              color={getCssVariable('cyan')}
              tooltipMessage="Since the last check"
            />
          ),
        },
        {
          eventKey: 1,
          tabClassName: `${tabsStyles['tab-header']} ${activeIndex === '1'
            ? tabsStyles['active-tab']
            : tabsStyles['inactive-tab']
            }`,
          title: (
            <TabsHeader
              title="DXE"
              number={countElement(data?.content, '1')}
              // variation={'5.21'}
              color={getCssVariable('violet')}
              tooltipMessage="Since the last check"
            />
          ),
        },
      ],
    }),
    [activeIndex, data]
  );

  const [modalCertificates, setModalCertificates] = React.useState<{
    title: string;
    issuers: string[];
    subjects: string[];
  } | null>();

  const [modalDependecies, setModalDependecies] = React.useState<{
    title: string;
    dependecies: string[];
  } | null>();

  function onCloseModalCertificates() {
    setModalCertificates(null);
  }

  function onCloseModalDependecies() {
    setModalDependecies(null);
  }

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'name',
      },
      {
        Header: 'File Type',
        accessor: 'filetype',
      },
      {
        Header: 'Format',
        accessor: 'format',
      },
      {
        Header: 'Machine',
        accessor: 'machine',
      },
      {
        Header: 'Signed',
        accessor: 'signed',
        Cell: (props: any) => {
          return props.value ? (
            <MdDone color="green" />
          ) : (
            <MdClear color="red" />
          );
        },
      },
      {
        Header: 'Dependecies',
        accessor: 'dependencies',
        Cell: (props: any) => {
          return (
            <div>
              <span>{props.value.length}</span>
            </div>
          );
        },
      },
      {
        Header: '',
        accessor: 'uefiType',
        Cell: (props: any) => {
          return (
            <Chip color={getColorFromType(props.value)}>{props.value}</Chip>
          );
        },
      },
    ],
    []
  );

  const childrenColumns: any = React.useMemo(() => {
    if (selectedFilterTable === 'signatures') {
      return [
        {
          Header: 'Signatures',
          accessor: 'signatures',
        },
      ];
    } else {
      return [
        {
          Header: 'Dependencies',
          accessor: 'dependencies',
        },
      ];
    }
  }, [selectedFilterTable]);

  const renderChildrenTableHeader = () => {
    if (selectedRow) {
      return selectedRow.name;
    }
    return '';
  };

  const memoizedChildrenData = React.useMemo(() => {
    if (selectedFilterTable === 'dependencies') {
      if (selectedRow.dependencies && selectedRow.dependencies.length > 0) {
        return selectedRow.dependencies.map((el: any) => ({
          dependencies: el,
        }));
      }
      return [];
    }
    if (selectedFilterTable === 'signatures') {
      if (selectedRow.signatures && selectedRow.signatures.length > 0) {
        return selectedRow.signatures.map((el: any) => ({
          signatures: el,
        }));
      }
      return [];
    }
    return [];
  }, [selectedRow, selectedFilterTable]);

  const childrenTableInstance = useTable(
    {
      columns: childrenColumns,
      data: memoizedChildrenData,
      initialState: { pageSize: 3 },
    },
    usePagination
  );

  const memoizedData = React.useMemo(() => {
    if (!data || !data.content || data.content.length === 0) return [];
    return data.content;
    // return data.content.filter(
    //   (el) => el.uefiType === tableDataTypes[activeIndex]
    // );
  }, [data, activeIndex]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);
  const {
    paginationMessage: childrenPaginationMessage,
  }: UsePaginationCounterState = usePaginationCounter(childrenTableInstance);

  React.useEffect(() => {
    if (data && data.content && data.content.length > 0) {
      setSelectedRow(data.content[0]);
    }
  }, [data]);

  const scanTime = React.useMemo(
    () => (reportData ? getScanTime(reportData) : null),
    [reportData]
  );

  const handleActiveIndexDefault = (data: any): string => {
    if (!data) return '0';
    const firstTabL = countElement(data.content, '0');
    const secondTabL = countElement(data.content, '1');
    if (firstTabL > 0) {
      return '0';
    }
    if (secondTabL > 0) {
      return '0';
    }
    return '0';
  };

  React.useEffect(() => {
    setActiveIndex(handleActiveIndexDefault(data));
  }, [data]);

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisWithBlocks />}
      {isError && <GenericError error={error} />}
      {/*{data && Object.keys(data).length === 0 && (*/}
      {/*  <EmptyResults*/}
      {/*    title={t('emptyReports:binaryScan.title')}*/}
      {/*    description={t('emptyReports:binaryScan.subtitle')}*/}
      {/*    // buttonLabel={t('emptyReports:binaryScan.buttonLabel')}*/}
      {/*    onClick={console.log}*/}
      {/*  />*/}
      {/*)}*/}
      {data?.content?.length ? (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <SectionLayout>
            <SectionHeader
              title={t('pages:peimDxePage.title')}
              leadingIcon={RiScan2Fill}
              tooltipMessage={t('pages:peimDxePage.buttonLabel')}
              bannerText={t('pages:peimDxePage.bannerMessage')}
            />
            <SectionContent>
              <SectionContentLeft
                verticalMessage={t('pages:peimDxePage.title')}
              >
                <PeimDxeGraph data={data?.content} />
              </SectionContentLeft>
              <SectionContentRight>
                <Table
                  className="h-100"
                  tableInstance={childrenTableInstance}
                  wrapperStyle={{ flex: '1 1 100%' }}
                >
                  <Table.Header style={{ height: 'auto', flex: 'auto' }}>
                    <div className={styles['table-header-wrapper']}>
                      <div
                        className="d-flex w-100 align-items-center justify-content-between"
                      >
                        <h6 className={codeAnalysisStyles['titleContainer']}>
                          <span className={codeAnalysisStyles['codeAnalysis']}>
                            {`${t('pages:peimDxePage.title')}/ `}
                          </span>
                          <span className={codeAnalysisStyles['fileName']}>
                            {renderChildrenTableHeader()}
                          </span>
                        </h6>
                        <div
                          style={{
                            padding: '0 1rem',
                          }}
                        >
                          {selectedRow && (
                            <Chip
                              color={getColorFromType(selectedRow.uefiType)}
                            >
                              {selectedRow.uefiType}
                            </Chip>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          padding: '0.5rem 0rem',
                          paddingRight: '1rem',
                        }}
                      >
                        <TableSwitch
                          options={[
                            { label: 'Signatures', value: 'signatures' },
                            { label: 'Dependencies', value: 'dependencies' },
                          ]}
                          onSelect={(v) => {
                            setSelectedFilterTable(v);
                            return v;
                          }}
                        />
                      </div>
                    </div>
                  </Table.Header>
                  <Table.Footer style={{ flex: 'auto' }}>
                    <p className={tableStyles['table__paginationState']}>
                      {childrenPaginationMessage}
                    </p>
                    <div className={tableStyles['table__paginationButtons']}>
                      <button
                        className={
                          childrenTableInstance?.canPreviousPage
                            ? tableStyles['table__paginationActiveButton']
                            : ''
                        }
                        onClick={() => childrenTableInstance.previousPage()}
                      >
                        <RiArrowLeftSLine size={20} />
                      </button>
                      <button
                        className={
                          tableInstance?.canNextPage
                            ? tableStyles['table__paginationActiveButton']
                            : ''
                        }
                        onClick={() => childrenTableInstance.nextPage()}
                      >
                        <RiArrowRightSLine size={20} />
                      </button>
                    </div>
                  </Table.Footer>
                </Table>
              </SectionContentRight>
            </SectionContent>
          </SectionLayout>
          <Container fluid style={{
            border: '1px solid var(--neutral300)',
            borderRadius: '2px'
          }}>
            <Row>
              <Col className="px-0" xs={12}>
                <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>PEIM/DXE Analysis</div>
                <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total: {memoizedData?.length || 0}</div>
                <Table
                  tableInstance={tableInstance}
                  onClickRow={(row: any) => {
                    setSelectedRow(row.original);
                  }}
                  rowStyle={{ cursor: 'pointer' }}
                >
                  {/* <Table.Header>
                    <TabsProvider state={tabsState} />
                  </Table.Header> */}
                  <Table.Footer>
                    <p className={tableStyles['table__paginationState']}>
                      {paginationMessage}
                    </p>
                    <div className={tableStyles['table__paginationButtons']}>
                      <button
                        className={
                          tableInstance?.canPreviousPage
                            ? tableStyles['table__paginationActiveButton']
                            : ''
                        }
                        onClick={() => tableInstance.previousPage()}
                      >
                        <RiArrowLeftSLine size={20} />
                      </button>
                      <button
                        className={
                          tableInstance?.canNextPage
                            ? tableStyles['table__paginationActiveButton']
                            : ''
                        }
                        onClick={() => tableInstance.nextPage()}
                      >
                        <RiArrowRightSLine size={20} />
                      </button>
                    </div>
                    <TablePageSize
                      maxResultChunkSize={maxResultChunkSize}
                      pageSize={tableInstance?.state?.pageSize || 10}
                      onPageSizeChange={onPageSizeChange}
                    />
                  </Table.Footer>
                </Table>
              </Col>
            </Row>
          </Container>

          <ReactTooltip
            border={true}
            arrowColor={getCssVariable('primary')}
            borderColor={getCssVariable('neutral300')}
            textColor={getCssVariable('secondary')}
            backgroundColor={getCssVariable('primary')}
            effect="solid"
            className={'tooltip'}
            multiline={true}
          />
        </>
      ) : !isLoading && isSuccess ? (
        <EmptyResults
          title={t('pages:peimDxePage.emptyState.title')}
          description={t('pages:peimDxePage.emptyState.subtitle')}
        // buttonLabel={t('pages:peimDxePage.emptyState.buttonLabel')}
        // onClick={console.log}
        />
      ) : !isLoading && isError ? (
        <GenericError error={error} />
      ) : (
        <SkeletonAnalysisSimple />
      )}
      {modalCertificates ? (
        <TableModal
          title={modalCertificates?.title}
          onClose={onCloseModalCertificates}
        >
          <div className={styles.certificates}>
            <ul>
              <li>
                <p>{t('pages:peimDxePage.modals.issuers')}</p>
              </li>
              {modalCertificates?.issuers?.map((issuer) => (
                <li>
                  <span>{issuer}</span>
                </li>
              ))}
            </ul>
            <ul>
              <li>
                <p>{t('pages:peimDxePage.modals.subjects')}</p>
              </li>
              {modalCertificates?.subjects?.map((subject) => (
                <li>
                  <span>{subject}</span>
                </li>
              ))}
            </ul>
          </div>
        </TableModal>
      ) : null}
      {modalDependecies ? (
        <TableModal
          title={modalDependecies?.title}
          onClose={onCloseModalDependecies}
        >
          <div className={styles.deps}>
            <ul>
              <li>
                <p>{t('pages:peimDxePage.modals.dependecies')}</p>
              </li>
              {modalDependecies?.dependecies?.map((dep) => (
                <li>
                  <span>{dep}</span>
                </li>
              ))}
            </ul>
          </div>
        </TableModal>
      ) : null}
      {reportData && shouldShowModalDetail ? (
        <ModalReportUefiDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData?.reportDetails,
            submitted: format(
              parse('', '', new Date(`${reportData?.startDate ?? new Date()}`)),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: reportData?.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
