export function getGraphThemeProps(type: string, color: string): any {
  const opts = {
    display: 'flex',
    alignItems: 'center',
    background: '#ffffff',
    color: '#333333',
    fontSize: 12,
    padding: 10,
    borderRadius: 3,
    fontWeight: 500,
  };

  if (type === 'dark') {
    Object.assign(opts, {
      background: '#000000',
      color: '#C9D1D9',
      fontSize: 12,
      border: '1px solid #2C3339'
    });
  }

  return opts;
}

export function getGraphTheme(type: string): any {
  const theme = {
    fontFamily: '"JetBrainsMono", monospace',
    fontSize: 8,
    background: 'transparent',
    textColor: '#333333',
    legends: {
      title: {
        text: {
          fontSize: 11,
          fill: '#333333'
        }
      },
      text: {
        fontSize: 11,
        fill: '#333333'
      },
      ticks: {
        line: {},
        text: {
          fontSize: 10,
          fill: '#333333'
        }
      }
    },
    annotations: {
      text: {
        fontSize: 13,
        fill: '#333333',
        outlineWidth: 2,
        outlineColor: '#ffffff',
        outlineOpacity: 1
      },
      link: {
        stroke: '#000000',
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: '#ffffff',
        outlineOpacity: 1
      },
      outline: {
        stroke: '#000000',
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: '#ffffff',
        outlineOpacity: 1
      },
      symbol: {
        fill: '#000000',
        outlineWidth: 2,
        outlineColor: '#ffffff',
        outlineOpacity: 1
      }
    },
    tooltip: {
      container: {
        background: '#ffffff',
        color: '#333333',
        fontSize: 12
      }
    },
  };

  if (type === 'dark') {
    Object.assign(theme, {
      ...theme,
      textColor: '#C9D1D9',
      tooltip: {
        container: {
          background: '#000000',
          color: '#C9D1D9',
          fontSize: 12,
          border: '1px solid #2C3339'
        }
      },
      legends: {
        title: {
          text: {
            fontSize: 11,
            fill: '#C9D1D9'
          }
        },
        text: {
          fontSize: 11,
          fill: '#C9D1D9'
        },
        ticks: {
          line: {},
          text: {
            fontSize: 10,
            fill: '#C9D1D9'
          }
        }
      }
    });
  }

  return theme;
}