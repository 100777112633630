import React from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

type TabsProviderProps = {
  state: any
}

const TabsProvider = (props: TabsProviderProps) => {
  const { state = {} } = props;
  const { tabs, ...rest } = state;

  return (
    <Tabs
      {...rest}
    >
      {tabs.map((tab: any, i: number) => (
        <Tab key={`tab-${i}`} {...tab} />
      ))}
    </Tabs>
  );
};

export default TabsProvider;