import { GlobalContext } from '@contexts/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import { ResponsiveBar } from '@nivo/bar';
import { getColors } from '@utils/index';
import { useContext } from 'react';

interface IProps {
  data: any;
  colors?: string[]
  margins?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  }
  hasBackground?: boolean
}

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const BarGraph = ({ data, colors = getColors(), margins, hasBackground = true }: IProps) => {
  const { theme } = useContext(GlobalContext);
  const isDarkMode = theme === THEMES.DARK;

  return (
    <ResponsiveBar
      data={data}
      //       tooltip={(props) => {
      //   const graphLabelProps = getGraphThemeProps(theme, props.color);
      //   if (props.id === 'FAKEDATA') return <div />;

      //   return (
      //     <React.Fragment>
      //       <div style={graphLabelProps}>
      //         <div
      //           style={{
      //             height: 20,
      //             width: 20,
      //             border: '1px solid transparent',
      //             backgroundColor: props.color,
      //             float: 'left',
      //             marginRight: 10,
      //           }}
      //         ></div>
      //         <span>{props.id} THREAT LEVEL</span>
      //       </div>
      //     </React.Fragment>
      //   );
      // }}
      keys={['count']}
      indexBy="key"
      margin={margins || { top: 50, right: 0, bottom: 20, left: 0 }}
      padding={0.98}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={colors}
      colorBy='indexValue'
      enableGridX={false}
      isInteractive={false}
      enableLabel={false}
      theme={
        {
          textColor: isDarkMode ? 'white' : 'black',
          grid: { line: { stroke: '#70707034' } },
          background: (hasBackground && isDarkMode) ? '#1f2226' : 'none',
        }
      }
      // defs={[
      //   {
      //     id: 'dots',
      //     type: 'patternDots',
      //     background: 'inherit',
      //     color: '#38bcb2',
      //     size: 4,
      //     padding: 1,
      //     stagger: true
      //   },
      //   {
      //     id: 'lines',
      //     type: 'patternLines',
      //     background: 'inherit',
      //     color: '#eed312',
      //     rotation: -45,
      //     lineWidth: 6,
      //     spacing: 10
      //   }
      // ]}
      // fill={[
      //   {
      //     match: {
      //       id: 'critical'
      //     },
      //     id: 'dots',

      //   },
      //   {
      //     match: {
      //       id: 'high'
      //     },
      //     id: 'lines'
      //   }
      // ]}
      // borderColor={{
      //   from: 'color',
      //   modifiers: [
      //     [
      //       'darker',
      //       1.6
      //     ]
      //   ]
      // }}
      axisTop={null}
      axisRight={null}
      axisLeft={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      role="application"
      ariaLabel="chart"
    />
  );
};


export default BarGraph;