import styles from './styles.module.scss';
import type { IconType } from 'react-icons';
import { getCssVariable } from '@utils/index';
export enum CHIP_SIZE {
  DEFAULT = 'default',
  SMALL = 'small',
}

export enum CHIP_COLOR {
  // Main
  DEFAULT = 'default',
  PRIMARY = 'primary',
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  // All colors
  CRIMSON = 'crimson',
  CYAN = 'cyan',
  PINK = 'pink',
  VIOLET = 'violet',
  YELLOW = 'yellow',
  ORANGE = 'orange',
  GREEN = 'green',
  LIGHT_PINK = 'light-pink',
  LIGHT_VIOLET = 'light-violet',
  LIME = 'lime',
  BLUE = 'blue',
  // Module
  DXE_MODULE = 'dxe-module',
  DXE_DRIVER = 'dxe-driver',
  DXE_CORE = 'dxe-core',
  PEI_MODULE = 'pei-module',
  PEI_CORE = 'pei-core',
  COMBINED_PEI_DXE = 'combined-pei-dxe ',
  // Capabilities
  FILE_FUNCTIONS = 'file-functions',
  TASK_MANIPULATION = 'task-manipulation',
  INFORMATION_GATHERING = 'information-gathering',
  NETWORK_FUNCTIONS = 'network-functions',
  SYSTEM_LOG = 'system-log',
  PACKET_SNIFF = 'packet-sniff',
  RANDOM_FUNCTIONS = 'random-functions',
  PIPE_FUNCTIONS = 'pipe-functions',
  SHELL = 'shell',
  ENVIRONMENT_VARIABLES = 'environmant-variables',
  PERMISSIONS = 'permissions',
  HOOKING = 'hooking',
}

type Props = {
  children: string;
  size?: CHIP_SIZE;
  color?: CHIP_COLOR | undefined | string;
  leadingIcon?: IconType;
};

export default function Chip({
  children,
  leadingIcon,
  size = CHIP_SIZE.DEFAULT,
  color = CHIP_COLOR.DEFAULT,
}: Props) {
  const LeadingIcon = leadingIcon ? leadingIcon : null;
  return (
    <div className={`${styles.container} ${styles[size]} ${styles[color]}`}>
      {LeadingIcon && (
        <div className={styles.leadingIcon}>
          <LeadingIcon size={12} color={getCssVariable('neutral400')} />
        </div>
      )}
      <span>{children}</span>
    </div>
  );
}
