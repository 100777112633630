import GradeBadge, { GRADE_SIZE, GRADE_TYPE } from '@components/GradeBadge';
import ModalRating from '@components/ModalRating';
import React, { CSSProperties } from 'react';
import styles from './styles.module.scss';

type OverviewGradeProps = {
  cta: any;
  title?: any;
  description?: any;
  grade: GRADE_TYPE;
  style?: CSSProperties;
  className?: CSSProperties;
};

const OverviewGrade = ({
  cta,
  title,
  description,
  grade,
  style = {},
  className = {},
}: OverviewGradeProps) => {
  const [shouldShowModalRating, setShouldShowModalRating] =
    React.useState<boolean>(false);
  return (
    <div className={'mt-5 grade-box'}>
      <div
        className={`${styles['badge-container']} ${className}`}
        style={style}
      >
        <div className="w-100 text-center">
          <GradeBadge grade={grade} size={GRADE_SIZE.BIG} />
        </div>
        <div className={styles['grade-content']}>
          {title && <p className={styles['grade-title']}>{title}</p>}
          {description && (
            <p className={styles['grade-description']}>{description}</p>
          )}
        </div>
        <p
          className={styles['grade-cta']}
          onClick={() => setShouldShowModalRating(true)}
        >
          {cta}
        </p>
        {shouldShowModalRating ? (
          <ModalRating onClose={() => setShouldShowModalRating(false)} />
        ) : null}
      </div>
    </div>
  );
};

export default OverviewGrade;
