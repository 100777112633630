import { useQuery } from 'react-query';
import type { ReportUefiAccessRightsAnalysis } from '@utils/types/report';
import { getReportUefiAccessRightsAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'uefi-access-right';

export default function useUefiAccessRightsAnalysis(id: string) {
  useToken();
  return useQuery<ReportUefiAccessRightsAnalysis[], Error>([KEY, id], () =>
    getReportUefiAccessRightsAnalysis(id),
    {
      retry: false
    }
  );
}
