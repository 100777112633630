import React, { ReactNode } from 'react';
import styles from '../styles.module.scss';

type InfoBoxContentProps = {
  children?: ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const InfoBoxContent = (props: InfoBoxContentProps) => {
  const { children, className = '', style = {} } = props;
  return <div className={`${styles['info-box-content']} ${className}`} style={style}>{children}</div>;
};

export default InfoBoxContent;
