import { WithChildren } from '@utils/types/helpers';
import type { IconType } from 'react-icons';
import styles from './styles.module.scss';

export enum BUTTON_TYPE {
  DEFAULT = 'default',
  GREEN = 'green',
  LIGHT_BORDER = 'lightBorder',
  NO_BORDER = 'noBorder',
  RED = 'crimson',
  PLAIN = 'plain',
}

export enum BUTTON_SIZE {
  DEFAULT = 'default',
  SMALL = 'small',
  EXTRA_SMALL = 'extra-small',
}
type Props = WithChildren<{
  leadingIcon?: IconType;
  trailingIcon?: IconType;
  type?: BUTTON_TYPE;
  onClick?: () => void;
  size?: BUTTON_SIZE;
  style?: any;
  disabled?: boolean;
}>;

export default function Button({
  children,
  onClick,
  leadingIcon,
  trailingIcon,
  style = {},
  type = BUTTON_TYPE.DEFAULT,
  size = BUTTON_SIZE.DEFAULT,
  disabled = false,
}: Props) {
  const LeadingIcon = leadingIcon ? leadingIcon : null;
  const TrailingIcon = trailingIcon ? trailingIcon : null;

  return (
    <button
      onClick={onClick}
      style={style}
      disabled={disabled}
      className={`${styles.container} ${styles[type]} ${styles[size]} ${
        leadingIcon ? styles.hasLeadingIcon : ''
      } ${trailingIcon ? styles.hasTrailingIcon : ''} `}
    >
      {LeadingIcon && (
        <div className={styles.leadingIcon}>
          <LeadingIcon size={24} />
        </div>
      )}
      <span>{children}</span>
      {TrailingIcon && (
        <div className={styles.trailingIcon}>
          <TrailingIcon size={24} />
        </div>
      )}
    </button>
  );
}
