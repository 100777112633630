import { useQuery } from 'react-query';
import { ReportOverview } from '@utils/types/report';
import useToken from './useToken';
import { getOverviewReportFromApi } from '@services/api';
export const KEY = 'overview-report';

export default function useReportOverview(reportId: string) {
  useToken();
  return {
    ...useQuery<ReportOverview, Error>([KEY, reportId], () =>
      getOverviewReportFromApi(reportId)
    ),
  };
}
