import { useQuery } from 'react-query';
import { getReportRtosCapabilitiesAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
import { ReportRtosCapabilitiesContentAnalysis } from '@utils/types/report';
export const KEY = 'rtos-capabilities';

export default function useRtosCapabilitiesAnalysis(id: string) {
  useToken();
  return useQuery<ReportRtosCapabilitiesContentAnalysis[], Error>([KEY, id], () =>
    getReportRtosCapabilitiesAnalysis(id),
    {
      retry: false
    }
  );
}
