import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Input from '@components/Forms/Input/Input';
import Select from '@components/Forms/Select/Select';
import styles from '@components/LayoutOnBoarding/styles.module.scss';
import Textarea from '@components/Forms/Textarea/Textarea';
import { Category, REPORT_SEVERITY } from '@utils/types/report';
import { RiMailLine } from 'react-icons/ri';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useCreateSupportRequest from '@hooks/support/useCreateSupportRequest';

type ContactFormProps = {
  categories: Category[] | undefined;
  initialValues?: ContactFormData;
  onClose: () => void;
};

export type ContactFormData = {
  category: string;
  // project: string;
  severity: string;
  subject: string;
  message: string;
};

const validationSchema = yup
  .object({
    category: yup
      .string()
      .trim()
      // .oneOf(Object.keys(categories).map((c: string) => categories[c].title))
      .required(),
    // project: yup.string().trim().oneOf(['1', '2', '3']).required(),
    severity: yup
      .string()
      .trim()
      .oneOf(Object.values(REPORT_SEVERITY))
      .required(),
    subject: yup.string().trim().required(),
    message: yup
      .string()
      .max(500, 'Message must be maximum 500 characters.')
      .trim()
      .required(),
  })
  .required();

const initialValues: ContactFormData = {
  category: '',
  // project: '',
  severity: REPORT_SEVERITY.LOW,
  subject: '',
  message: '',
};

const ContactForm = (props: ContactFormProps) => {
  const { categories, onClose } = props;

  const {
    register,
    watch,
    handleSubmit,
  } = useForm({
    defaultValues: props.initialValues || initialValues,
    resolver: yupResolver(validationSchema),
  });

  const { mutate: createSupportRequest } = useCreateSupportRequest();

  const { t } = useTranslation();

  const onSubmit = async (data: ContactFormData) => {
    try {
      await createSupportRequest(data);
      onClose();
    } catch (e) {
      console.log(e);
    }
  };

  const getMessageHelper = () => {
    return `${watch('message').length}/500 character limit`;
  };

  return (
    <Container
      as="form"
      className="p-0"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <Row className="gx-2">
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Select
            {...register('category')}
            className="mb-4"
            label="Category"
            options={
              categories?.map((c) => {
                return {
                  label: c.title,
                  value: c.title,
                };
              }) || []
            }
          />
        </Col>
        {/*<Col xs={12} sm={12} md={4} lg={4} xl={4}>*/}
        {/*  <Select {...register('project')} className='mb-4' label='Project' options={['1', '2', '3']}*/}
        {/*          hint='The project that is experiencing the problem.' />*/}
        {/*</Col>*/}
        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
          <Select
            {...register('severity')}
            className="mb-4"
            label="Severity"
            options={Object.values(REPORT_SEVERITY).map((s) => {
              return {
                label: s,
                value: s,
              };
            })}
          />
        </Col>
      </Row>
      <Input {...register('subject')} label="Subject" className="mb-4" />
      <Textarea
        {...register('message')}
        label="Message"
        className="mb-4"
        helper={getMessageHelper()}
        hint="Describe the issue you facing, with any relevant informations we may need."
      />
      <div className="d-flex flex-row justify-content-end">
        <Button className={styles['navbar-menu-button']} type="submit">
          <RiMailLine className="mr-2" />
          <span style={{marginLeft: '.5rem'}}>{t('pages:authPage.help.helpSendButton')}</span>
        </Button>
      </div>
    </Container>
  );
};

export default ContactForm;
