import { GlobalContext } from '@contexts/GlobalContext';
import { getCssVariable } from '@utils/index';
import * as React from 'react';
import styles from './styles.module.scss';
export enum GRADE_TYPE {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  UNKNOWN = 'UNKNOWN',
}

export enum GRADE_SIZE {
  EXTRASMALL = 'extrasmall',
  SMALL = 'small',
  BIG = 'big',
}

type Props = {
  grade: GRADE_TYPE;
  size?: GRADE_SIZE;
  disabled?: boolean;
  onClick?: () => void;
};

function renderGradeLetter(grade: GRADE_TYPE, size: GRADE_SIZE) {
  switch (grade) {
    case GRADE_TYPE.A:
      if (size === GRADE_SIZE.BIG) {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="A"
              transform="translate(-67.000000, -57.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <g id="Group-2" transform="translate(-0.000000, 0.000000)">
                <path
                  d="M107,67 L107,57 L77,57 L77,67 L107,67 Z M77,127 L77,97 L107,97 L107,127 L117,127 L117,67 L107,67 L107,87 L77,87 L77,67 L67,67 L67,127 L77,127 Z"
                  id="A"
                ></path>
              </g>
            </g>
          </g>
        );
      } else {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Score/Small/A"
              transform="translate(-16.000000, -14.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M25,16.25 L25,14 L18.25,14 L18.25,16.25 L25,16.25 Z M18.25,29.75 L18.25,23 L25,23 L25,29.75 L27.25,29.75 L27.25,16.25 L25,16.25 L25,20.75 L18.25,20.75 L18.25,16.25 L16,16.25 L16,29.75 L18.25,29.75 Z"
                id="A"
              ></path>
            </g>
          </g>
        );
      }
    case GRADE_TYPE.B:
      if (size === GRADE_SIZE.BIG) {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="B"
              transform="translate(-67.000000, -57.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M107,127 L107,117 L77,117 L77,97 L107,97 L107,87 L77,87 L77,67 L107,67 L107,57 L67,57 L67,127 L107,127 Z M117,87 L117,67 L107,67 L107,87 L117,87 Z M117,117 L117,97 L107,97 L107,117 L117,117 Z"
                id="B"
              ></path>
            </g>
          </g>
        );
      } else {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Score/Small/B"
              transform="translate(-16.000000, -14.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <g id="Group" transform="translate(-2.000000, -2.000000)">
                <path
                  d="M27,31.75 L27,29.5 L20.25,29.5 L20.25,25 L27,25 L27,22.75 L20.25,22.75 L20.25,18.25 L27,18.25 L27,16 L18,16 L18,31.75 L27,31.75 Z M29.25,22.75 L29.25,18.25 L27,18.25 L27,22.75 L29.25,22.75 Z M29.25,29.5 L29.25,25 L27,25 L27,29.5 L29.25,29.5 Z"
                  id="B"
                ></path>
              </g>
            </g>
          </g>
        );
      }
    case GRADE_TYPE.C:
      if (size === GRADE_SIZE.BIG) {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="C"
              transform="translate(-67.000000, -57.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M107,67 L107,57 L77,57 L77,67 L107,67 Z M77,117 L77,67 L67,67 L67,117 L77,117 Z M117,77 L117,67 L107,67 L107,77 L117,77 Z M117,117 L117,107 L107,107 L107,117 L117,117 Z M107,127 L107,117 L77,117 L77,127 L107,127 Z"
                id="C"
              ></path>
            </g>
          </g>
        );
      } else {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Score/Small/C"
              transform="translate(-16.000000, -14.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <g id="Group" transform="translate(-2.000000, -2.000000)">
                <path
                  d="M27,18.25 L27,16 L20.25,16 L20.25,18.25 L27,18.25 Z M20.25,29.5 L20.25,18.25 L18,18.25 L18,29.5 L20.25,29.5 Z M29.25,20.5 L29.25,18.25 L27,18.25 L27,20.5 L29.25,20.5 Z M29.25,29.5 L29.25,27.25 L27,27.25 L27,29.5 L29.25,29.5 Z M27,31.75 L27,29.5 L20.25,29.5 L20.25,31.75 L27,31.75 Z"
                  id="C"
                ></path>
              </g>
            </g>
          </g>
        );
      }
    case GRADE_TYPE.D:
      if (size === GRADE_SIZE.BIG) {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="D"
              transform="translate(-67.000000, -57.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <path
                d="M107,127 L107,117 L77,117 L77,67 L107,67 L107,57 L67,57 L67,127 L107,127 Z M117,117 L117,67 L107,67 L107,117 L117,117 Z"
                id="D"
              ></path>
            </g>
          </g>
        );
      } else {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Score/Small/D"
              transform="translate(-16.000000, -14.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <g id="Group" transform="translate(-2.000000, -2.000000)">
                <path
                  d="M27,31.75 L27,29.5 L20.25,29.5 L20.25,18.25 L27,18.25 L27,16 L18,16 L18,31.75 L27,31.75 Z M29.25,29.5 L29.25,18.25 L27,18.25 L27,29.5 L29.25,29.5 Z"
                  id="D"
                ></path>
              </g>
            </g>
          </g>
        );
      }
    case GRADE_TYPE.E:
      if (size === GRADE_SIZE.BIG) {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="E"
              transform="translate(-67.000000, -57.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <polygon
                id="E"
                points="117 127 117 117 77 117 77 97 107 97 107 87 77 87 77 67 117 67 117 57 67 57 67 127"
              ></polygon>
            </g>
          </g>
        );
      } else if (size === GRADE_SIZE.SMALL) {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Score/Small/E"
              transform="translate(-16.000000, -14.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <g id="Group" transform="translate(-2.000000, -2.000000)">
                <polygon
                  id="E"
                  points="29.25 31.75 29.25 29.5 20.25 29.5 20.25 25 27 25 27 22.75 20.25 22.75 20.25 18.25 29.25 18.25 29.25 16 18 16 18 31.75"
                ></polygon>
              </g>
            </g>
          </g>
        );
      } else {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Score/Small/E"
              transform="translate(-16.000000, -14.000000)"
              fill="#FFFFFF"
              fillRule="nonzero"
            >
              <g id="Group" transform="translate(-2.000000, -2.000000)">
                <polygon
                  id="E"
                  points="29.25 31.75 29.25 29.5 20.25 29.5 20.25 25 27 25 27 22.75 20.25 22.75 20.25 18.25 29.25 18.25 29.25 16 18 16 18 31.75"
                ></polygon>
              </g>
            </g>
          </g>
        );
      }

    default:
      if (size === GRADE_SIZE.BIG) {
        return (
          <g
            id="Score/Hihglight/None"
            transform="translate(-72.000000, -57.000000)"
            fill={getCssVariable('neutral400')}
            fillRule="nonzero"
          >
            <g id="Group-2" transform="translate(-0.000000, 0.000000)">
              <path
                d="M102,67 L102,57 L82,57 L82,67 L102,67 Z M82,77 L82,67 L72,67 L72,77 L82,77 Z M112,87 L112,67 L102,67 L102,87 L112,87 Z M102,97 L102,87 L92,87 L92,97 L102,97 Z M92,107 L92,97 L82,97 L82,107 L92,107 Z M92,127 L92,117 L82,117 L82,127 L92,127 Z"
                id="?"
              ></path>
            </g>
          </g>
        );
      } else {
        return (
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Score/Small/D"
              transform="translate(-17.000000, -17.000000)"
              fill={getCssVariable('neutral400')}
              fillRule="nonzero"
            >
              <g id="Group" transform="translate(-2.000000, -2.000000)">
                <path
                  d="M27,18 L27,15 L22,15 L22,18 L27,18 Z M22,20 L22,17 L19,17 L19,20 L22,20 Z M29,23 L29,18 L26,18 L26,23 L29,23 Z M27,26 L27,23 L24,23 L24,26 L27,26 Z M24,28 L24,25 L21,25 L21,28 L24,28 Z M24,33 L24,30 L21,30 L21,33 L24,33 Z"
                  id="?"
                ></path>
              </g>
            </g>
          </g>
        );
      }
  }
}

export default function GradeBadge({
  grade,
  size = GRADE_SIZE.BIG,
  disabled = false,
  onClick,
}: Props) {
  const isUnknown = grade === GRADE_TYPE.UNKNOWN;
  const noAnimation = grade === GRADE_TYPE.UNKNOWN && size === GRADE_SIZE.SMALL;
  const { theme } = React.useContext(GlobalContext);

  const getWidth = (value?: string) => {
    if (value === GRADE_SIZE.BIG) return '50px';
    if (value === GRADE_SIZE.SMALL) return '25px';
    if (value === GRADE_SIZE.EXTRASMALL) return '10px';
  };

  const getHeight = (value?: string) => {
    if (value === GRADE_SIZE.BIG) return '70px';
    if (value === GRADE_SIZE.SMALL) return '35px';
    if (value === GRADE_SIZE.EXTRASMALL) return '20px';
  };

  const getViewBox = (value?: string) => {
    if (value === GRADE_SIZE.BIG) return '0 0 50 70';
    if (value === GRADE_SIZE.SMALL) return '0 -8 11 30';
    if (value === GRADE_SIZE.EXTRASMALL) return '0 -7 11 30';
  };

  return (
    <div
      onClick={onClick}
      className={`${styles.container} ${styles[theme]} ${styles[size]} ${
        isUnknown ? styles.unknown : styles[grade]
      } ${noAnimation ? styles.noAnimation : ''} ${
        disabled ? styles.disabled : ''
      }`}
    >
      <div className={`${styles.wrapper} ${disabled ? styles.disabled : ''}`}>
        <span>
          <svg
            width={getWidth(size)}
            height={getHeight(size)}
            viewBox={getViewBox(size)}
            version="1.1"
          >
            <title>{isUnknown ? '?' : grade}</title>
            <g
              id="Symbols"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              {renderGradeLetter(grade, size)}
            </g>
          </svg>
        </span>
      </div>
    </div>
  );
}
