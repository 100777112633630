import { useState } from 'react';
import styles from './styles.module.scss';
import ModalWrapper from '@components/ModalWrapper';
import { useTranslation } from 'react-i18next';
import Sidebar from '@components/Sidebar';
import Navigation from '@components/Navigation';
import UserSettings from '@components/UserSettings';

import {
  RiContrast2Fill,
  RiContrast2Line,
  RiFileUserFill,
  RiFileUserLine,
} from 'react-icons/ri';
import UserSettingsNavigationLink from '@components/UserSettingsNavigationLink';
import UserAppearance, { SIZE } from '@components/UserAppearance';

type Props = {
  closeModal: () => void;
  email: string;
  name: string;
  signOut: () => void;
};

export default function UserModal({ closeModal, email, name, signOut }: Props) {
  const { t } = useTranslation('userDropdown');

  const [navStep, setNavStep] = useState<number>(0);

  function changeStep(n: number) {
    setNavStep(n);
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <ModalWrapper
          onClose={closeModal}
          title={
            navStep === 0
              ? t('accountSettings')
              : navStep === 1
              ? t('navigation.user.appearance')
              : ''
          }
          navigation={
            <Sidebar>
              <Navigation title={email ?? ''}>
                <div className={styles.navigationContainer}>
                  <UserSettingsNavigationLink
                    title={t('navigation.user.account')}
                    activeIcon={RiFileUserFill}
                    leadingIcon={RiFileUserLine}
                    navIndex={0}
                    navStep={navStep}
                    isActive={navStep === 0}
                    onClick={() => changeStep(0)}
                  />
                  <UserSettingsNavigationLink
                    title={t('navigation.user.appearance')}
                    activeIcon={RiContrast2Fill}
                    leadingIcon={RiContrast2Line}
                    navIndex={1}
                    navStep={navStep}
                    isActive={navStep === 1}
                    onClick={() => changeStep(1)}
                  />
                </div>
              </Navigation>
            </Sidebar>
          }
        >
          {navStep === 0 && (
            <UserSettings
              email={email}
              name={name}
              closeModal={closeModal}
              signOut={signOut}
            />
          )}
          {navStep === 1 && <UserAppearance size={SIZE.BIG} />}
        </ModalWrapper>
      </div>
    </div>
  );
}
