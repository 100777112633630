import Chip, { CHIP_COLOR, CHIP_SIZE } from '@components/Chip';
import { noop } from 'lodash';
import styles from './styles.module.scss';
import { Item, useContextMenu } from 'react-contexify';
import ContextMenu from '@components/ContextMenu';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportActionsContext } from '@context/ReportActionsContext';
import { MODAL_TYPE } from '@components/ReportList';
import { ROUTES } from '@routes/routes';
import { HiOutlineDotsHorizontal } from 'react-icons/hi';
import { getCssVariable } from '@utils/index';
import DeleteReportModal from '@components/DeleteReportModal';
import RenameReportModal from '@components/RenameReportModal';
import { useHistory } from 'react-router-dom';
import { CurrentReportContext } from '@context/CurrentReport';

type Props = {
  title?: string;
  chipLabel?: string;
  description?: string;
  onDescriptionButtonClick?: typeof noop;
  onButtonClick?: typeof noop;
  buttonLabel?: string;
  descriptionButtonLabel?: string;
  reportUrl?: string;
};
export default function ReportHeading({
                                        title = '',
                                        description = '',
                                        buttonLabel = '',
                                        descriptionButtonLabel = '',
                                        onDescriptionButtonClick = noop,
                                        onButtonClick = noop,
                                        chipLabel
                                      }: Props) {
  const { t } = useTranslation('common');
  const { report, description: reportDescription } = React.useContext(CurrentReportContext);
  const { show } = useContextMenu({ id: report?.id });
  const history = useHistory();
  const [modalType, setModalType] = React.useState<MODAL_TYPE | null>(null);
  const triggerRef = React.useRef<any>();

  function getMenuPosition() {
    const { left, bottom } = triggerRef.current.getBoundingClientRect();
    return { x: left + 12, y: bottom - 15 };
  }

  const { setReportData } = React.useContext<any>(ReportActionsContext);

  function handleSetModal(data: any, type: MODAL_TYPE) {
    setModalType(type);
    setReportData({ ...data });
  }

  function handleContextMenu(event: any) {
    event.preventDefault();
    show(event, {
      position: { ...getMenuPosition() }
    });
  }

  function handleDeleteReport({ event }: { event: React.SyntheticEvent }) {
    handleSetModal({ id: report?.id, name: report?.name }, MODAL_TYPE.DELETE);
    event.preventDefault();
  }

  function handleUpdateReport({ event }: { event: React.SyntheticEvent }) {
    handleSetModal({ id: report?.id, name: report?.name }, MODAL_TYPE.UPDATE);
    event.preventDefault();
  }

  function onDeleteSuccess() {
    history.push(ROUTES.BASE);
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrap}>
        <div className={styles.title}>
          <h1>{title || report?.name || ''}</h1>
          {chipLabel ? (
            <Chip color={CHIP_COLOR.PRIMARY} size={CHIP_SIZE.SMALL}>
              {chipLabel?.toUpperCase()}
            </Chip>
          ) : (
            ''
          )}
        </div>
      </div>
      <div>
        {description || reportDescription ? (
          <p>
            {description || reportDescription}
            {descriptionButtonLabel ? (
              <span>
                {' · '}
                <span onClick={onDescriptionButtonClick} className={styles.cta}>
                  {descriptionButtonLabel}
                </span>
              </span>
            ) : null}
          </p>
        ) : null}
        <span
          className={styles.reportActions}
          onClick={handleContextMenu}
          ref={triggerRef}
        >
            <HiOutlineDotsHorizontal
              size={18}
              color={getCssVariable('neutral400')}
            />
          </span>
        <ContextMenu id={report?.id ?? ''}>
          <Item onClick={handleUpdateReport}>
            {t('reportActions.renameTitle')}
          </Item>
          <Item className={'is-crimson'} onClick={handleDeleteReport}>
            {t('reportActions.deleteTitle')}
          </Item>
        </ContextMenu>
      </div>
      {modalType === MODAL_TYPE.DELETE ? (
        <DeleteReportModal
          onSuccess={onDeleteSuccess}
          onClose={handleSetModal}
        />
      ) : null}
      {modalType === MODAL_TYPE.UPDATE ? (
        <RenameReportModal onClose={handleSetModal} />
      ) : null}
      {/* <div className={styles.wrap}>
        {buttonLabel ? (
          <Button
            onClick={onButtonClick}
            type={BUTTON_TYPE.LIGHT_BORDER}
            size={BUTTON_SIZE.SMALL}
          >
            {buttonLabel}
          </Button>
        ) : null}
      </div> */}
    </div>
  );
}
