import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import styles from './styles.module.scss';

const breakPointsLeft = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const breakPointsRight = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
};

interface IProps {
  severities: {
    critical: number;
    high: number;
    medium: number;
    low: number;
  };
}

const CveCheck: React.FC<IProps> = ({ severities }) => {
  return (
    <SectionLayout className="px-0">
      <SectionContent
        breakPointsLeft={breakPointsLeft}
        breakPointsRight={breakPointsRight}
        style={{
          backgroundColor: 'white',
          minHeight: '250px',
          borderColor: '#d0d7de',
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        <SectionContentLeft
          verticalMessage={'CVE Check Vulnerabilities'}
          verticalMessageColor="#05516f"
          style={{ borderRight: 'none' }}
          className={styles['content']}
        >
          <div className={styles['severitiesContainer']}>
            <div>
              <div className={styles['severity']}>{severities.critical}</div>
              <span>Critical</span>
            </div>
            <div>
              <div className={styles['severity']}>{severities.high}</div>
              <span>High</span>
            </div>
            <div>
              <div className={styles['severity']}>{severities.medium}</div>
              <span>Medium</span>
            </div>
            <div>
              <div className={styles['severity']}>{severities.low}</div>
              <span>Low</span>
            </div>
          </div>
        </SectionContentLeft>
      </SectionContent>
    </SectionLayout>
  );
};

export default CveCheck;
