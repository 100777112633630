import Header from '@components/Header';
import React from 'react';
// import { ROUTES } from '@routes/routes';
// import { removeTokenOnLocalstorage } from '@utils/index';
import { ROUTES } from '@routes/routes';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

const Main = ({ children }: any) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const clearLocalStorage = async () => {
    await localStorage.removeItem('token');
    await localStorage.removeItem('organizationUID');
  };

  const signOut = async () => {
    await clearLocalStorage();
    queryClient.invalidateQueries(['reports']);
    history.push(ROUTES.SIGN_IN);
  };

  return (
    <>
      <Header signOut={signOut} />
      <div>{children}</div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
      />
    </>
  );
};

export default Main;
