import { useMutation } from 'react-query';
import { createFeedback } from '@services/api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const KEY = 'feedback';

export default function useCreateFeedback() {
  const {t} = useTranslation('pages');
  return useMutation([KEY], async (body: string) => await createFeedback(body), {
    onSuccess: () => {
      toast.success(t('authPage.feedback.success'));
    },
    onError: () => {
      toast.error(t('authPage.feedback.error'));
    }
  });
}
