import * as React from 'react';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import Notification, { NOTIFICATION_TYPE } from '@components/Notification';
import { Link } from 'react-router-dom';
import EmptyResults from '@components/EmptyResults';
import { Col, Container, Row } from 'react-bootstrap';

type NOTIFICATION = {
  type: NOTIFICATION_TYPE;
  date: string;
};

export default function NotificationPage() {
  const { t } = useTranslation(['common', 'pages']);
  const notifications: NOTIFICATION[] = [
    { type: NOTIFICATION_TYPE.ERROR, date: '2020/05/1' },
    { type: NOTIFICATION_TYPE.GOOD, date: '2019/12/21' },
    { type: NOTIFICATION_TYPE.WARNING, date: '2014/03/08' },
    // safari needs the / as date separator, don't use -
  ];

  const renderNotification = React.useCallback(
    (notification: any, key: number): JSX.Element => {
      return (
        <Notification
          key={key}
          date={notification?.date}
          type={notification.type}
        >
          {notification?.type === NOTIFICATION_TYPE.ERROR ? (
            <p>
              <strong>12</strong> new vulnerabilities found in{' '}
              <strong>
                <Link to="/">Eni Gas e Luce</Link>
              </strong>
            </p>
          ) : (
            ''
          )}

          {notification?.type === NOTIFICATION_TYPE.WARNING ? (
            <p>
              <strong>532</strong> issues found in found in{' '}
              <strong>
                <Link to="/">My first firmware</Link>
              </strong>
            </p>
          ) : (
            ''
          )}

          {notification?.type === NOTIFICATION_TYPE.GOOD ? (
            <p>
              <strong>Patch name</strong> now available
            </p>
          ) : (
            ''
          )}
        </Notification>
      );
    },
    []
  );
  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <LayoutPage>
            <LayoutSection spacer={24}>
              <h1 className={'sectionTitle'}>{t('pages:notifications.title')}</h1>
            </LayoutSection>
            <LayoutSection>
              {notifications.length > 0 ? (
                <div className={styles.notifications}>
                  {notifications?.map(renderNotification)}
                </div>
              ) : (
                <EmptyResults
                  title={t('pages:notifications.emptyState.title')}
                  description={t('pages:notifications.emptyState.subtitle')}
                  // buttonLabel={t('pages:notifications.emptyState.buttonLabel')}
                  onClick={console.log}
                />
              )}
            </LayoutSection>
          </LayoutPage>
        </Col>
      </Row>
    </Container>
  );
}
