import React from 'react';
import { Spinner as BSpinner } from 'react-bootstrap';
import styles from './styles.module.scss';

export enum SPINNER_TYPE {
  BLACK_GREEN_BKG = 'blackGreenBkg',
  GREEN = 'green',
  BLACK = 'black',
  WHITE_RED_BKG = 'whiteRedBkg',
}

export enum SPINNER_SIZE {
  DEFAULT = 'default',
  SMALL = 'small',
  BIG = 'big',
}

type Props = {
  type?: SPINNER_TYPE;
  size?: SPINNER_SIZE;
  fullScreen?: boolean,
  className?: string
};

export default function Spinner({
  type = SPINNER_TYPE.GREEN,
  size = SPINNER_SIZE.DEFAULT,
  fullScreen = false,
  ...rest
}: Props) {
  return (
    <React.Fragment>
      { fullScreen
        ?
        <div className={styles.fullScreen}>
          <BSpinner animation="border" variant={'success'} {...rest}/>
        </div>
        :
        <div className={`${styles.loader} ${styles[type]} ${styles[size]}`} {...rest}></div>
      }
    </React.Fragment>
  );
}
