import React from 'react';
import { Col, Container, Row, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '@context/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import { useLocalStorage } from 'beautiful-react-hooks';
import { GiMoon } from 'react-icons/all';

export default function Appearance() {
  const { t } = useTranslation(['common', 'userDropdown', 'userSettings']);
  const { setThemeDetection } = React.useContext(GlobalContext);
  const [localThemeSetting] = useLocalStorage<THEMES>('theme', THEMES.SYSTEM);
  const [ localTheme, setLocalTheme ] = React.useState(localThemeSetting);

  function onChangeThemeHandler(value: any) {
    setThemeDetection(value);
    setLocalTheme(value);
  }

  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <h1 className={'sectionTitleInverse'}><span className={'sectionTitleIcon'}><GiMoon /></span> Appearance</h1>
        </Col>
        <Col xs={12}>
          <p className={'mb-2'}>{t('userSettings:appearance.customize')}</p>
          <p>{t('userSettings:appearance.selectTheme')}</p>
        </Col>
        <Col xs={12} md={3}>
          <ToggleButton
            className="mb-2 mt-5 w-100"
            type="checkbox"
            variant="outline-primary"
            checked={localTheme === THEMES.SYSTEM}
            value={THEMES.SYSTEM}
            onClick={() => onChangeThemeHandler(THEMES.SYSTEM)}
          >
            {t('common:themes.system')}
          </ToggleButton>
        </Col>
        <Col xs={12} md={3}>
          <ToggleButton
            className="mb-2 mt-5 w-100"
            type="checkbox"
            variant="outline-primary"
            checked={localTheme === THEMES.LIGHT}
            value={THEMES.LIGHT}
            onClick={() => onChangeThemeHandler(THEMES.LIGHT)}
          >
            {t('common:themes.light')}
          </ToggleButton>
        </Col>
        <Col xs={12} md={3}>
          <ToggleButton
            className="mb-2 mt-5 w-100"
            type="checkbox"
            variant="outline-primary"
            checked={localTheme === THEMES.DARK}
            value={THEMES.DARK}
            onClick={() => onChangeThemeHandler(THEMES.DARK)}
          >
            {t('common:themes.dark')}
          </ToggleButton>
        </Col>
      </Row>
    </Container>
  );
}