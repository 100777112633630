import React from 'react';
// import tableStyles from '@components/Table/styles.module.scss';
// import { RiExternalLinkFill } from 'react-icons/ri';
import { getCssVariable } from '@utils/index';
import { useFilters, usePagination, useTable } from 'react-table';
import Table from '@components/TableNew/Table';

type PeimTableProp = {
  data?: any;
  columns?: any;
  hasPagination?: boolean;
  hasPageSize?: boolean;
  onClickRow?: any;
};

const PeimTable = (props: PeimTableProp) => {
  const { data, onClickRow = () => null } = props;
  const columns = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'name',
        Cell: (props: any) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  fontWeight: 'bold',
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400'),
                }}
              >
                {props.value}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'File Type',
        accessor: 'filetype',
      },
      {
        Header: 'Dependencies',
        accessor: 'dependencies',
        Cell: (props: any) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {props.value &&
                props.value.map((el: any) => (
                  <span
                    style={{
                      marginRight: '.5rem',
                      color: getCssVariable('neutral400'),
                      paddingBottom: '.25rem',
                    }}
                  >
                    {el}
                  </span>
                ))}
            </div>
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      data,
      columns,
      initialState: { pageSize: 4 },
    },
    useFilters,
    usePagination
  );

  return (
    <Table
      onClickRow={(row: any) => onClickRow(row)}
      className="border-0"
      tableInstance={tableInstance}
    />
  );
};

export default PeimTable;
