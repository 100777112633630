import GradeBadge, { GRADE_SIZE, GRADE_TYPE } from '@components/GradeBadge';
import ModalWrapper from '@components/ModalWrapper';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  onClose: () => void;
};

export default function ModalRating({ onClose }: Props) {
  const { t } = useTranslation('common');
  return (
    <ModalWrapper onClose={onClose} title={t('modalRating.title')}>
      <div className={styles.container}>
        <ul>
          <li>
            <div className={styles.grade}>
              <GradeBadge grade={GRADE_TYPE.A} size={GRADE_SIZE.SMALL} />
            </div>
            <div>
              <p>{t('modalRating.A.title')}</p>
              <span>{t('modalRating.A.message')}</span>
            </div>
          </li>
          <li>
            <div className={styles.grade}>
              <GradeBadge grade={GRADE_TYPE.B} size={GRADE_SIZE.SMALL} />
            </div>
            <div>
              <p>{t('modalRating.B.title')}</p>
              <span>{t('modalRating.B.message')}</span>
            </div>
          </li>
          <li>
            <div className={styles.grade}>
              <GradeBadge grade={GRADE_TYPE.C} size={GRADE_SIZE.SMALL} />
            </div>
            <div>
              <p>{t('modalRating.C.title')}</p>
              <span>{t('modalRating.C.message')}</span>
            </div>
          </li>
          <li>
            <div className={styles.grade}>
              <GradeBadge grade={GRADE_TYPE.D} size={GRADE_SIZE.SMALL} />
            </div>
            <div>
              <p>{t('modalRating.D.title')}</p>
              <span>{t('modalRating.D.message')}</span>
            </div>
          </li>
          <li>
            <div className={styles.grade}>
              <GradeBadge grade={GRADE_TYPE.E} size={GRADE_SIZE.SMALL} />
            </div>
            <div>
              <p>{t('modalRating.E.title')}</p>
              <span>{t('modalRating.E.message')}</span>
            </div>
          </li>
        </ul>
      </div>
    </ModalWrapper>
  );
}
