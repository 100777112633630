import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { THEMES } from '@hooks/useDarkMode';
import { RiArrowDropDownFill } from 'react-icons/ri';
import { useLocalStorage } from 'beautiful-react-hooks';
import { GlobalContext } from '@contexts/GlobalContext';
// import { Form } from 'react-bootstrap';
export enum SIZE {
  SMALL = 'small',
  BIG = 'big',
}

type IProps = {
  size: SIZE;
};

export default function UserAppearance({ size }: IProps) {
  const { t } = useTranslation(['common', 'userDropdown']);
  const { setThemeDetection } = React.useContext(GlobalContext);

  function onChangeThemeHandler(e: any) {
    const value = e.target.value;
    setThemeDetection(value);
  }

  const [localThemeSetting] = useLocalStorage<THEMES>('theme', THEMES.SYSTEM);
  return (
    <div
      className={
        size === SIZE.BIG
          ? styles.container
          : `${styles.container} ${styles.withMargin}`
      }
    >
      {size === SIZE.BIG && (
        <p className={styles.title}>
          {t('userDropdown:content.appearance.title')}
        </p>
      )}
      <div
        className={
          size === SIZE.BIG
            ? styles.themeSettings
            : `${styles.themeSettings} ${styles.small}`
        }
      >
        <select
          name="themeSettings"
          onChange={onChangeThemeHandler}
          id="selectTheme"
          defaultValue={localThemeSetting}
        >
          <option value={THEMES.SYSTEM}>
            {size === SIZE.BIG
              ? t('userDropdown:content.appearance.systemTheme')
              : t('common:themes.system')}
          </option>
          <option value={THEMES.LIGHT}>
            {size === SIZE.BIG
              ? t('userDropdown:content.appearance.lightTheme')
              : t('common:themes.light')}
          </option>
          <option value={THEMES.DARK}>
            {size === SIZE.BIG
              ? t('userDropdown:content.appearance.darkTheme')
              : t('common:themes.dark')}
          </option>
        </select>
        <RiArrowDropDownFill className={styles.dropDownIcon} size={20} />
      </div>
    </div>
  );
}
