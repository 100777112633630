import { REPORT_CAPABILITIES_TYPE } from '@utils/types/report';
import { CHIP_COLOR } from '@components/Chip';

const changeColorShade = (hexColor: string, magnitude: number) => {
  hexColor = hexColor.replace('#', '');
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

const getColorFromType = (type: REPORT_CAPABILITIES_TYPE): CHIP_COLOR => {
  if (type === REPORT_CAPABILITIES_TYPE.FILE_FUNCTIONS) {
    return CHIP_COLOR.FILE_FUNCTIONS;
  }
  if (type === REPORT_CAPABILITIES_TYPE.TASK_MANIPULATION) {
    return CHIP_COLOR.TASK_MANIPULATION;
  }
  if (type === REPORT_CAPABILITIES_TYPE.NETWORK_FUNCTIONS) {
    return CHIP_COLOR.NETWORK_FUNCTIONS;
  }
  if (type === REPORT_CAPABILITIES_TYPE.PACKET_SNIFF) {
    return CHIP_COLOR.PACKET_SNIFF;
  }
  if (type === REPORT_CAPABILITIES_TYPE.SYSTEM_LOG) {
    return CHIP_COLOR.SYSTEM_LOG;
  }
  if (type === REPORT_CAPABILITIES_TYPE.RANDOM_FUNCTIONS) {
    return CHIP_COLOR.RANDOM_FUNCTIONS;
  }
  if (type === REPORT_CAPABILITIES_TYPE.INFORMATION_GATHERING) {
    return CHIP_COLOR.INFORMATION_GATHERING;
  }
  if (type === REPORT_CAPABILITIES_TYPE.PIPE_FUNCTIONS) {
    return CHIP_COLOR.PIPE_FUNCTIONS;
  }
  if (type === REPORT_CAPABILITIES_TYPE.ENVIRONMENT_VARIABLES) {
    return CHIP_COLOR.ENVIRONMENT_VARIABLES;
  }
  if (type === REPORT_CAPABILITIES_TYPE.SHELL) {
    return CHIP_COLOR.SHELL;
  }
  if (type === REPORT_CAPABILITIES_TYPE.PERMISSIONS) {
    return CHIP_COLOR.PERMISSIONS;
  }
  if (type === REPORT_CAPABILITIES_TYPE.HOOKING) {
    return CHIP_COLOR.HOOKING;
  } else {
    return CHIP_COLOR.DEFAULT;
  }
};

export { changeColorShade, getColorFromType };