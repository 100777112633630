import useDarkMode, { THEMES } from '@hooks/useDarkMode';
import { noop } from 'lodash';
import React from 'react';

const GlobalContext = React.createContext({
  theme: THEMES.LIGHT,
  setThemeDetection: noop,
});

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}
const GlobalProvider = ({ children }: IProps) => {
  const { theme, setThemeDetection } = useDarkMode();

  return (
    <GlobalContext.Provider value={{ theme, setThemeDetection } as any}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalProvider, GlobalContext };
