import { useQuery } from 'react-query';
import type { ReportUefiSecurityScanAnalysis } from '@utils/types/report';
import { getReportUefiSecurityScanAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'uefi-security-scan-analysis';

export default function useUefiSecurityScanAnalysis(id: string) {
  useToken();
  return useQuery<ReportUefiSecurityScanAnalysis[], Error>([KEY, id], () =>
    getReportUefiSecurityScanAnalysis(id),
    {
      retry: false
    }
  );
}
