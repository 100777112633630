type Props = {
  color: string;
  size: number;
};
export default function RtosIcon({ color, size = 24 }: Props) {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`.toString()}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icons/rtos"
          transform="translate(-1.000000, -1.000000)"
          fill={color}
        >
          <path
            d="M7,1 L7,3 L9,3 L9,1 L7,1 Z M11,1 L11,3 L13,3 L13,1 L11,1 Z M15,1 L15,3 L17,3 L17,1 L15,1 Z M7,5 C5.9069372,5 5,5.9069372 5,7 L5,17 C5,18.093063 5.9069372,19 7,19 L17,19 C18.093063,19 19,18.093063 19,17 L19,7 C19,5.9069372 18.093063,5 17,5 L7,5 Z M1,7 L1,9 L3,9 L3,7 L1,7 Z M7,7 L17,7 L17,17 L7,17 L7,7 Z M21,7 L21,9 L23,9 L23,7 L21,7 Z M9,9 L9,10 L9,15 L15,15 L15,9 L9,9 Z M1,11 L1,13 L3,13 L3,11 L1,11 Z M11,11 L13,11 L13,13 L11,13 L11,11 Z M21,11 L21,13 L23,13 L23,11 L21,11 Z M1,15 L1,17 L3,17 L3,15 L1,15 Z M21,15 L21,17 L23,17 L23,15 L21,15 Z M7,21 L7,23 L9,23 L9,21 L7,21 Z M11,21 L11,23 L13,23 L13,21 L11,21 Z M15,21 L15,23 L17,23 L17,21 L15,21 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
