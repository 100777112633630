import React from 'react';
import styles from './styles.module.scss';
import genericError from '@assets/animations/Generic_Error_LIGHT.json';
import { GlobalContext } from '@contexts/GlobalContext';
import { useTranslation } from 'react-i18next';
import UpgradePlan from '@pages/UpgradePlan';
import Lottie from 'react-lottie';

export default function GenericError({
  error
}: any) {
  const { theme } = React.useContext<any>(GlobalContext);
  const { t } = useTranslation('common');
  const isFreePlanError = () => {
    if (!error) {
      return false;
    }

    if (error === 'Feature not available for FREE users') {
      return true;
    }

    return false;
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: theme === 'dark' ? genericError : genericError,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {!isFreePlanError() ? (
          <React.Fragment>
            <Lottie
              options={defaultOptions}
              height={400}
              width={400}
              isStopped={false}
              isPaused={false}
            />
            <h1>{t('generic.error')}</h1>
          </React.Fragment>
        ) : (
          <UpgradePlan />
        ) }
      </div>
    </div>
  );
}
