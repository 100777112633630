import EmptyResults from '@components/EmptyResults';
import * as React from 'react';
import styles from './styles.module.scss';
// import illustration from '@assets/img/static_illustrations/upgrade.svg';
// import illustrationDm from '@assets/img/static_illustrations/upgrade_dm.svg';
import { useTranslation } from 'react-i18next';
import { BUTTON_TYPE } from '@components/Button';
import Lottie from 'react-lottie';
import { THEMES } from '@hooks/useDarkMode';
// import { GlobalContext } from '@contexts/GlobalContext';
import proAnimationDark from '@assets/animations/Upgrade_Pro_DARK.json';
import proAnimationLight from '@assets/animations/Upgrade_Pro_LIGHT.json';
import { GlobalContext } from '@context/GlobalContext';
import { useHistory } from 'react-router';
import { ROUTES } from '@routes/routes';

export default function UpgradePlan() {
  const { t } = useTranslation(['common', 'pages']);
  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = theme === THEMES.DARK;
  const history = useHistory();

  const onClick = React.useCallback(() => {
    history.push(ROUTES.BASE + ROUTES.USER_SETTINGS + ROUTES.SUBSCRIPTION + ROUTES.PLANS);
  }, [history]);

  const animation = {
    loop: true,
    autoplay: true,
    animationData: isDarkMode ? proAnimationDark : proAnimationLight,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={styles.container}>
      <Lottie
        options={animation}
        height={400}
        width={400}
        isStopped={false}
        isPaused={false}
      />
      <div className={styles.emptyState}>
        <EmptyResults
          image={null}
          title={t('pages:upgradePlanPage.emptyState.title')}
          description={t('pages:upgradePlanPage.emptyState.subtitle')}
          buttonLabel={t('pages:upgradePlanPage.emptyState.buttonLabel')}
          buttonType={BUTTON_TYPE.GREEN}
          onClick={onClick}
        />
      </div>
    </div>
  );
}
