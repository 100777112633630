import * as React from 'react';
// import BannerMessage from '@components/BannerMessage';
import EmptyResults from '@components/EmptyResults';
import LayoutPage from '@components/LayoutPage';
import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';
import useFilesystemAnalysis from '@hooks/analysis/useFilesystemAnalysis';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import FilesystemExplorer from '@components/FilesystemExplorer';
import { last } from 'lodash';
import { ROUTES } from '@routes/routes';
// import Breadcrumbs from '@components/Breadcrumbs';
import GenericError from '@components/ErrorState';

function getLevelsArrayFromPathname(pathname: string, base: string): string[] {
  return last(pathname.split(base))?.split('/').filter(Boolean) || [];
}

export default function ReportFilesystem() {
  const { reportId = '' }: { reportId: string } = useParams();
  const BASE_ROUTE = ROUTES.BASE + '/' + reportId + ROUTES.FILESYSTEM_EXPLORER;
  const { pathname } = useLocation();
  const initialLevels = getLevelsArrayFromPathname(pathname, BASE_ROUTE);

  const [levels, setLevels] = React.useState<string[]>(initialLevels);
  const { data, isLoading, isFetching, isSuccess, isError, error } =
    useFilesystemAnalysis(reportId, levels.join('/'), onFetchSuccess);
  const { push } = useHistory();
  const { t } = useTranslation(['common', 'pages']);

  function onFetchSuccess() {
    document.querySelector('#scrollContainer')?.scrollTo(0, 0);
  }

  function onGoDown(label: string) {
    const newLevels = [...levels, label];
    pushToRightLevel(newLevels);
  }

  const [hadFirstLoad, setHadFirstLoading] = React.useState(false);

  React.useEffect(() => {
    if (isSuccess && !hadFirstLoad) {
      setHadFirstLoading(true);
    }
  }, [hadFirstLoad, isSuccess]);

  React.useEffect(() => {
    const newLevels = getLevelsArrayFromPathname(pathname, BASE_ROUTE);
    setLevels(newLevels);
  }, [BASE_ROUTE, pathname]);

  function pushToRightLevel(lvls: string[]) {
    lvls.length ? push(BASE_ROUTE + '/' + lvls.join('/')) : push(BASE_ROUTE);
  }

  function onGoUp() {
    const newLevels = levels.filter((d) => d !== last(levels));
    pushToRightLevel(newLevels);
  }

  // function getRouteByLevel(level: string) {
  //   const currentLevelIndex = levels.indexOf(level);
  //   const levelsUntilCurrentLevel = slice(levels, 0, currentLevelIndex + 1);
  //
  //   return (
  //     ROUTES.BASE +
  //     '/' +
  //     reportId +
  //     ROUTES.FILESYSTEM_EXPLORER +
  //     '/' +
  //     levelsUntilCurrentLevel.join('/')
  //   );
  // }

  return (
    <LayoutPage>
      {hadFirstLoad || (!isLoading && isSuccess) ? (
        <>
          {/*<LayoutSection spacer={24}>*/}
          {/*  <Breadcrumbs*/}
          {/*    paths={[*/}
          {/*      {*/}
          {/*        name: t('pages:filesystemPage.title'),*/}
          {/*        to: ROUTES.BASE + '/' + reportId + ROUTES.FILESYSTEM_EXPLORER*/}
          {/*      },*/}
          {/*      ...levels.map((level: string) => ({*/}
          {/*        name: level,*/}
          {/*        to: getRouteByLevel(level)*/}
          {/*      }))*/}
          {/*    ]}*/}
          {/*  />*/}
          {/*</LayoutSection>*/}
          {/*<LayoutSection spacer={24}>*/}
          {/*  <BannerMessage*/}
          {/*    leadingIcon={RiInformationLine}*/}
          {/*    message={t('pages:filesystemPage.bannerMessage')}*/}
          {/*  />*/}
          {/*</LayoutSection>*/}

          {/*<LayoutSection>*/}
          <FilesystemExplorer
            data={data?.content || []}
            currentLevel={
              last(levels) && isFetching ? last(levels) ?? '' : ''
            }
            goUp={onGoUp}
            isLoading={isFetching}
            goDown={onGoDown}
            isInAInnerLevel={!data?.isRoot}
          />
          {/*</LayoutSection>*/}
        </>
      ) : !isLoading && isSuccess ? (
        <EmptyResults
          title={t('pages:filesystemPage.emptyState.title')}
          description={t('pages:filesystemPage.emptyState.subtitle')}
        />
      ) : !isLoading && isError ? (
        <GenericError error={error} />
      ) : (
        <SkeletonAnalysisSimple />
      )}
    </LayoutPage>
  );
}
