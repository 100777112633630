import { useQuery } from 'react-query';
import type { ReportPasswordHashAnalysis } from '@utils/types/report';
import { getReportPasswordHashAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'password-hash-analysis';

export default function usePasswordHashAnalysis(id: string) {
  useToken();
  return useQuery<ReportPasswordHashAnalysis[], Error>([KEY, id], () =>
    getReportPasswordHashAnalysis(id),
    {
      retry: false
    }
  );
}
