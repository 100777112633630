import Chip, { CHIP_COLOR } from '@components/Chip';
import EmptyResults from '@components/EmptyResults';
import GenericError from '@components/ErrorState';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import ModalReportUefiDetails from '@components/ModalReportUefiDetails';
import ReportHeading from '@components/ReportHeading';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import { GlobalContext } from '@contexts/GlobalContext';
import useUefiAttackSurface from '@hooks/analysis/useUefiAttackSurface';
import { THEMES } from '@hooks/useDarkMode';
import usePaginationCounter, { UsePaginationCounterState } from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import InfoBox from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxMessage';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import { getCssVariable, getScanTime, padNumber } from '@utils/index';
import { REPORT_ATTACK_SURFACE_TYPE } from '@utils/types/report';
import { format, parse } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiArrowLeftSLine, RiArrowRightSLine, RiStackLine } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { usePagination, useTable } from 'react-table';
import styles from '../styles.module.scss';


export default function ReportAttackSurfaceScan() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isSuccess, isError, error } =
    useUefiAttackSurface(reportId);
  const { data: reportData } = useReportOverview(reportId);

  const { t } = useTranslation(['common', 'pages', 'tables', 'emptyReports']);
  const [activeIndex, setActiveIndex] = React.useState<any>('0');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  const filterMemoType: any = React.useMemo(() => {
    return {
      0: 'evsa',
      1: 'vss',
      2: 'nvar',
    };
  }, []);

  function getColorFromType(type: REPORT_ATTACK_SURFACE_TYPE): CHIP_COLOR {
    if (type === REPORT_ATTACK_SURFACE_TYPE.EVSA) {
      return CHIP_COLOR.VIOLET;
    }
    if (type === REPORT_ATTACK_SURFACE_TYPE.VSS) {
      return CHIP_COLOR.CYAN;
    }

    if (type === REPORT_ATTACK_SURFACE_TYPE.NVAR) {
      return CHIP_COLOR.LIGHT_VIOLET;
    } else {
      return CHIP_COLOR.DEFAULT;
    }
  }

  const memoizedData = React.useMemo(() => {
    if (!data || !data.content) return [];
    return data.content;
  }, [data]);

  const memoizedCols: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'name',
        Cell: ({ row, value }: { row: any; value: boolean }) => {
          return (
            <div className="d-flex flex-row align-items-center">
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400'),
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Guid',
        accessor: 'guid',
      },
      {
        Header: '',
        accessor: 'type',
        Cell: ({ value }: any) => (
          <span className="w-100 d-flex align-items-center justify-content-end">
            <Chip color={getColorFromType(value)}>{value}</Chip>
          </span>
        ),
      },
    ],
    []
  );

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: memoizedCols,
      data: memoizedData,
      initialState: { pageSize: 10 },
    },
    // useFilters,
    // useGlobalFilter,
    usePagination
  );

  const scanTime = React.useMemo(
    () => (reportData ? getScanTime(reportData) : null),
    [reportData]
  );

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  return (
    <LayoutPage>
      {/* {isLoading && <SkeletonAnalysisSimple />} */}
      {!isLoading && !isError && !data && !isSuccess && (
        <EmptyResults
          title={t('emptyReports:attackSurface.title')}
          description={t('emptyReports:attackSurface.subtitle')}
        // buttonLabel={t('emptyReports:attackSurface.buttonLabel')}
        // onClick={console.log}
        />
      )}
      {isError && <GenericError error={error} />}
      {data && isSuccess && !isError && !isLoading && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <SectionHeader
            title={t('pages:attackSurfacePage.title')}
            leadingIcon={RiStackLine}
            tooltipMessage={t('pages:attackSurfacePage.buttonLabel')}
            bannerText={t('pages:attackSurfacePage.bannerMessage')}
          />
          <Container fluid>
            <Row>
              <Col className="px-0" xs={12}>
                <InfoBox style={{
                  padding: 20,
                  height: 'auto'
                }}>
                  <InfoBoxContent style={{
                    alignItems: 'flex-start', background: isDarkMode ? '#202226' : 'none', flexDirection: 'row', gap: 60
                  }}>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='evsa'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{data?.overview?.evsa || 0}</div>
                    </div>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='vss'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{data?.overview?.vss || 0}</div>
                    </div>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='nvar'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{data?.overview?.nvar || 0}</div>
                    </div>
                  </InfoBoxContent>-
                </InfoBox>
                <Container fluid style={{
                  border: '1px solid var(--neutral300)',
                  marginTop: 40,
                  padding: 0,
                  borderRadius: '2px'
                }}>
                  <Row>
                    <Col xs={12} >
                      <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>Attack Surface</div>
                      <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total: {memoizedData?.length || 0}</div>
                      <Table className="border-0" tableInstance={tableInstance}>
                        <Table.Footer>
                          <p className={tableStyles['table__paginationState']}>
                            {paginationMessage}
                          </p>
                          <div className={tableStyles['table__paginationButtons']}>
                            <button
                              className={
                                tableInstance?.canPreviousPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.previousPage()}
                            >
                              <RiArrowLeftSLine size={20} />
                            </button>
                            <button
                              className={
                                tableInstance?.canNextPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.nextPage()}
                            >
                              <RiArrowRightSLine size={20} />
                            </button>
                          </div>
                          <TablePageSize
                            maxResultChunkSize={maxResultChunkSize}
                            pageSize={tableInstance?.state?.pageSize || 10}
                            onPageSizeChange={onPageSizeChange}
                          />
                        </Table.Footer>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {reportData && shouldShowModalDetail ? (
        <ModalReportUefiDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData?.reportDetails,
            submitted: format(
              parse('', '', new Date(`${reportData?.startDate ?? new Date()}`)),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: reportData?.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
