import { useQuery } from 'react-query';
import type { ReportCryptoAnalyzerAnalysis } from '@utils/types/report';
import { getReportCryptoAnalyzerAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'crypto-analyzer-analysis';

export default function useCryptoAnalyzerAnalysis(id: string) {
  useToken();
  return useQuery<ReportCryptoAnalyzerAnalysis, Error>([KEY, id], () =>
    getReportCryptoAnalyzerAnalysis(id),
    {
      retry: false
    }
  );
}
