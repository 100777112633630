import * as React from 'react';
import ModalWrapper from '@components/ModalWrapper';
import { ReportActionsContext } from '@contexts/ReportActionsContext';
import useReport from '@hooks/useReport';
import styles from './styles.module.scss';
import { noop } from 'lodash';
import AsciiProgressBar from '@components/AsciiProgressBar';
import FakeReportMessage from '@components/FakeReportMessage';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose?: typeof noop;
};

export default function ReportStatusModal({ onClose = noop }: Props) {
  const {
    data: { id },
    resetReportData,
  } = React.useContext(ReportActionsContext);
  const { t } = useTranslation(['common', 'scanModal']);
  const handleModalClose = () => {
    resetReportData();
    onClose(null);
  };

  const { data: report } = useReport(id);

  return (
    <ModalWrapper
      onClose={handleModalClose}
      className={styles.modal}
      title={t('scanModal:titles.titleStep3')}
    >
      <p className={styles.message}>
        [ 0.420074 ] {t('scanModal:content.uploadingAndScan.uploading')}:{' '}
        {report?.originalName}
      </p>
      <span className={styles.message}>
        <AsciiProgressBar percent={100} />
      </span>
      {report?.id ? (
        <div className={styles.allMessages}>
          <FakeReportMessage reportId={report?.id} />
        </div>
      ) : null}
    </ModalWrapper>
  );
}
