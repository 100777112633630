import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import ReportHeading from '@components/ReportHeading';
import { format, parse } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import GenericError from '@components/ErrorState';
import AccessRightFill from '@components/Icons/AccessRightFill';
import AccessRightLine from '@components/Icons/AccessRightLine';
import IntelBootGuardFill from '@components/Icons/IntelBootGuardFill';
import IntelBootGuardLine from '@components/Icons/IntelBootGuardLine';
import SecureBootLine from '@components/Icons/SecureBootFill';
import SecureBootFill from '@components/Icons/SecureBootLine';
import InfoBoxWrapper from '@components/InfoBoxWrapper/InfoBoxWrapper';
import ModalReportUefiDetails from '@components/ModalReportUefiDetails';
import OverviewTab from '@components/OverviewTab';
import SkeletonOverview from '@components/SkeletonOverview';
import usePeimDxeAnalysis from '@hooks/analysis/usePeimDxeAnalysis';
import useSecureBootAnalysis from '@hooks/analysis/useSecureBootAnalysis';
import useReportOverview from '@hooks/useReportOverview';
import InfoBox from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxFooter from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxFooter';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import styles from '@pages/Report/Linux/ReportOverviewLinux/styles.module.scss';
import BootGuardStatus from '@pages/Report/Uefi/IntelBootGuard/BootGuardStatus';
import OverviewContainer from '@pages/Report/Uefi/Overview/Containers/OverviewContainer';
import PeimDxeContainer from '@pages/Report/Uefi/Overview/Containers/PeimDxeContainer';
import SecureBootContainer from '@pages/Report/Uefi/Overview/Containers/SecureBootContainer';
import { ROUTES } from '@routes/routes';
import { getCssVariable, getScanTime } from '@utils/index';
import { useState } from 'react';
import { Button, Col, Container, Nav, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  RiHomeFill,
  RiHomeLine,
  RiLockPasswordLine,
  RiScan2Fill,
  RiScan2Line,
  RiShieldCheckFill,
  RiShieldCheckLine,
} from 'react-icons/ri';
import { useHistory } from 'react-router';
import ReactTooltip from 'react-tooltip';

export default function ReportOverviewUefi() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isError, error } = useReportOverview(reportId);
  const { data: PeimData } = usePeimDxeAnalysis(reportId);
  const { data: secureBootData } = useSecureBootAnalysis(reportId);

  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState(false);

  const history = useHistory();
  const { t } = useTranslation(['common', 'pages']);
  const [activeTab, setActiveTab] = useState('overview');

  function goTo(tab: string, section: string) {
    setActiveTab(tab);
    const element = document?.getElementById(section);
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  const scanTime = React.useMemo(
    () => (data ? getScanTime(data) : null),
    [data]
  );

  return (
    <LayoutPage>
      {isLoading ? (
        <SkeletonOverview />
      ) : isError ? (
        <GenericError error={error} />
      ) : (
        <>
          <Helmet>
            <title>Report {data?.name ?? ''}</title>
          </Helmet>
          <div className="pageBreaker">
            <LayoutSection spacer={32}>
              <ReportHeading
                chipLabel={t('common:generic.completed')}
                buttonLabel={t('common:generic.downloadReport')}
                onButtonClick={noop}
                descriptionButtonLabel={t('common:generic.viewDetails')}
                onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
              />
            </LayoutSection>
            <LayoutSection spacer={24}>
              <Nav
                className={'nav-cosmo nav-details justify-content-between'}
                activeKey={activeTab}
                variant="tabs"
              >
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={RiHomeLine}
                    activeIcon={RiHomeFill}
                    isActive={activeTab === 'overview'}
                    label={t('common:navigation.navigationLabels.overview')}
                    keyItem={'overview'}
                    onClick={() => goTo('overview', 'overview')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={RiScan2Line}
                    activeIcon={RiScan2Fill}
                    isActive={activeTab === 'peimDxe'}
                    label={t('common:navigation.navigationLabels.peimDxe')}
                    keyItem={'peimDxe'}
                    onClick={() => goTo('peimDxe', 'peimDxe')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={SecureBootLine}
                    activeIcon={SecureBootFill}
                    isActive={activeTab === 'secureBoot'}
                    label={t('common:navigation.navigationLabels.secureBoot')}
                    keyItem={'secureBoot'}
                    onClick={() => goTo('secureBoot', 'secureBoot')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={RiShieldCheckLine}
                    activeIcon={RiShieldCheckFill}
                    isActive={activeTab === 'securityScan'}
                    label={t('common:navigation.navigationLabels.securityScan')}
                    keyItem={'securityScan'}
                    onClick={() => goTo('securityScan', 'securityScan')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={AccessRightLine}
                    activeIcon={AccessRightFill}
                    isActive={activeTab === 'accessRights'}
                    label={t('common:navigation.navigationLabels.accessRights')}
                    keyItem={'accessRights'}
                    onClick={() => goTo('accessRights', 'securityScan')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={IntelBootGuardLine}
                    activeIcon={IntelBootGuardFill}
                    isActive={activeTab === 'intelBootGuard'}
                    label={t(
                      'common:navigation.navigationLabels.intelBootGuard'
                    )}
                    keyItem={'intelBootGuard'}
                    onClick={() => goTo('intelBootGuard', 'securityScan')}
                  />
                </Nav.Item>
              </Nav>
            </LayoutSection>

            <OverviewContainer data={data} />

            <div id="peimDxe">
              <PeimDxeContainer
                data={PeimData?.content}
                date={data?.analysisEndDate}
              />
            </div>
          </div>

          <div id="secureBoot" style={{ paddingBottom: '24px' }}>
            <SecureBootContainer data={secureBootData} />
          </div>
          <div id={'securityScan'}>
            <Container fluid>
              <Row>
                <Col className="px-0" xs={12} sm={12} md={12} lg={4} xl={4}>
                  <InfoBoxWrapper
                    headerTitle={t(
                      'pages:overviewPage.sections.uefiSecurityScan.title'
                    )}
                    leadingIcon={RiShieldCheckLine}
                    tooltipMessage=""
                    boxMessage={t(
                      'pages:overviewPage.sections.uefiSecurityScan.errorMessage'
                    )}
                    chartValue={data?.analysis?.securityScan || 0}
                    chartColor={null}
                    chartLineColor={null}
                    url={`${ROUTES.BASE}/${reportId}${ROUTES.UEFI_SECURITY_SCAN}`}
                  />
                </Col>
                <Col
                  className="px-2 md:px-0"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={4}
                  xl={4}
                >
                  <InfoBoxWrapper
                    headerTitle={t(
                      'pages:overviewPage.sections.accessRight.title'
                    )}
                    leadingIcon={RiLockPasswordLine}
                    tooltipMessage=""
                    boxMessage={t(
                      'pages:overviewPage.sections.accessRight.errorMessage'
                    )}
                    chartValue={data?.analysis?.accessRights || 0}
                    chartColor={null}
                    chartLineColor={null}
                    url={`${ROUTES.BASE}/${reportId}${ROUTES.ACCESS_RIGHT}`}
                  />
                </Col>
                <Col className="px-0" xs={12} sm={12} md={12} lg={4} xl={4}>
                  <>
                    <SectionHeader
                      title={t(
                        'pages:overviewPage.sections.intelBootGuard.title'
                      )}
                      leadingIcon={IntelBootGuardLine}
                      tooltipMessage={''}
                    />
                    <InfoBox>
                      <InfoBoxContent
                        style={{ paddingBottom: '1.2rem', position: 'relative' }
                        }>
                        <div style={{ position: 'absolute', top: 15, right: 20 }}>
                          <Button
                            onClick={() =>
                              history.push(
                                `${ROUTES.BASE}/${reportId}${ROUTES.INTEL_BOOT_GUARD}`
                              )
                            }
                            className={styles['button']}
                          >
                            VIEW ALL
                          </Button>
                        </div>
                        <div style={{ marginTop: 20 }}>
                          <BootGuardStatus
                            enabled={data?.analysis?.intelBootGuard || false}
                          />
                        </div>
                      </InfoBoxContent>

                    </InfoBox>
                  </>
                  {/*<InfoBoxWrapper*/}
                  {/*  headerTitle={t(*/}
                  {/*    'pages:overviewPage.sections.intelBootGuard.title'*/}
                  {/*  )}*/}
                  {/*  leadingIcon={RiShieldCheckLine}*/}
                  {/*  tooltipMessage=""*/}
                  {/*  boxMessage={''}*/}
                  {/*  chartValue={5635}*/}
                  {/*  chartColor={null}*/}
                  {/*  chartLineColor={null}*/}
                  {/*/>*/}
                </Col>
              </Row>
            </Container>
          </div>

          <ReactTooltip
            border={true}
            arrowColor={getCssVariable('primary')}
            borderColor={getCssVariable('neutral300')}
            textColor={getCssVariable('secondary')}
            backgroundColor={getCssVariable('primary')}
            effect="solid"
            className={'tooltip'}
            multiline={true}
          />
        </>
      )}
      {shouldShowModalDetail ? (
        <ModalReportUefiDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...data?.reportDetails,
            submitted: format(
              parse('', '', new Date(`${data?.startDate ?? new Date()}`)),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: data?.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
