import React from 'react';
import styles from './styles.module.scss';

type OptionsType = {
  label: string;
  value: string | number | boolean;
};

type TableSwitchProps = {
  options: Array<OptionsType>;
  onSelect?: (v: string | number | boolean) => string | number | boolean;
};

export const TableSwitch = (props: TableSwitchProps) => {
  const { options = [], onSelect = (v: string | number | boolean) => null } =
    props;

  const [selected, setSelected] = React.useState(() => {
    if (!options || options.length < 1) return false;
    return options[0].value;
  });

  React.useEffect(() => {
    if (props.onSelect) {
      onSelect(selected);
    }
  }, [selected]);

  return (
    <>
      {options.map(({ label, value }: any, index: number) => (
        <button
          key={`${label}-${index}`}
          onClick={() => setSelected(value)}
          className={
            selected === value ? styles['checked'] : styles['no-checked']
          }
        >
          {label}
        </button>
      ))}
    </>
  );
};

export default TableSwitch;
