import React, { ReactNode } from 'react';

import styles from '../styles.module.scss';
import InfoBoxContent from './InfoBoxContent';
import InfoBoxFooter from './InfoBoxFooter';

type InfoBoxProps = {
  children?: ReactNode;
  style?: React.CSSProperties;
};

const InfoBox = (props: InfoBoxProps) => {
  const { style = {} } = props;
  const renderChildrenHandler = (name: string) => {
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        if (child.type === InfoBoxContent && name === 'InfoBoxContent') {
          return React.cloneElement(child);
        }
        if (child.type === InfoBoxFooter && name === 'InfoBoxFooter') {
          return React.cloneElement(child);
        }
      }
    });
  };

  return (
    <div className={styles['info-box-container']} style={style}>
      {renderChildrenHandler('InfoBoxContent')}
      {renderChildrenHandler('InfoBoxFooter')}
    </div>
  );
};

export default InfoBox;
