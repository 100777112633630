import styles from './styles.module.scss';
type Props = {
  percent: number;
};
export default function AsciiProgressBar({ percent }: Props) {
  function renderElement() {
    return [...Array(50)].map((_, i) => {
      return percent < (i + 1) * 2 ? '-' : '#';
    });
  }

  return (
    <span
      className={`${styles.container} ${
        percent < 100 ? styles.isActive : null
      }`}
    >
      <p>
        <span>|{renderElement()}|</span> {percent}%
      </p>
    </span>
  );
}
