import Input from '@components/Forms/Input/Input';
import { regenerateApiToken } from '@services/api';
import React, { ChangeEvent } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import styles from './styles.module.scss';
// import { GiMoon } from 'react-icons/all';

export default function ApiToken(props: any) {
  const { data } = props;
  const { t } = useTranslation(['common', 'userDropdown', 'userSettings']);
  const queryClient = useQueryClient();
  console.log(data, 'data');
  const [apiTokeValue, setApiTokenValue] = React.useState(() => {
    if (data) return data;
    return '';
  });

  const onHandlerApiTokenValueChange = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    setApiTokenValue(e.target.value);
  };

  const onRevokeAndRegenerateTokenHandler = async () => {
    try {
      await regenerateApiToken(apiTokeValue);
      queryClient.invalidateQueries(['api_token_get']);
    } catch (error: any) {
      if (error?.response?.data) {
        return toast.error(error?.response?.data);
      }
      return toast.error('Something went wrong');
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="mt-4 mb-4 border-top px-0">
          <h1 className={styles.apiTokenTitle}>Api Token</h1>
        </Col>
        <Col className="border">
          <Row className="pt-4 pb-2">
            <Col xs={12} className="pb-4 border-bottom">
              <Row>
                <Col xs={12} className="pb-2">
                  <p className={styles.apiTokenAuth}>
                    {t('userSettings:apiToken.title')}
                  </p>
                </Col>
                <Col xs={12}>
                  <span className={styles.apiTokenSubtitle}>
                    {t('userSettings:apiToken.subtitle')}
                  </span>{' '}
                  <span className={styles.docs}>
                    {t('userSettings:apiToken.docs')}.
                  </span>
                </Col>
              </Row>
            </Col>
            <Col className="pt-4">
              <Row>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                  <p className={styles.apiTokenAuth}>Key</p>
                  <Input
                    value={apiTokeValue}
                    onChange={onHandlerApiTokenValueChange}
                  />
                </Col>
                <Col
                  className="d-flex flex-column justify-content-between pb-3"
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <p className={styles.apiTokenAuth}>Created</p>
                  <p className={styles.creationDate}>10 02 2022; 12:33:21</p>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  className="d-flex pb-2 align-items-center justify-content-end"
                >
                  <button
                    disabled={!apiTokeValue || apiTokeValue === ''}
                    className={`${styles.buttonRevoke} btn-primary mt-auto`}
                    onClick={onRevokeAndRegenerateTokenHandler}
                  >
                    Revoke & Regenerate
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
