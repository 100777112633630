import { getCssVariable } from '@utils/index';
import { noop } from 'lodash';
import { RiArrowDropDownLine } from 'react-icons/ri';
import styles from './styles.module.scss';

type Props = {
  options: string[] | number[];
  onChange: typeof noop;
  value: string | number;
  prefix?: string;
  suffix?: string;
  placeholder?: string;
};

export default function TableSelect({
  options,
  onChange,
  value,
  prefix = '',
  suffix = '',
  placeholder = '',
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.selectContainer}>
        <select value={value} onChange={onChange}>
          {placeholder ? (
            <option disabled value={''}>
              {placeholder}
            </option>
          ) : null}
          {options.map((option) => (
            <option key={option} value={option}>
              {prefix} {option} {suffix}
            </option>
          ))}
        </select>
        <RiArrowDropDownLine color={getCssVariable('neutral400')} size={20} />
      </div>
    </div>
  );
}
