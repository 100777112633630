import { getCssVariable } from '@utils/index';
import ContentLoader from 'react-content-loader';

export default function SkeletonAnalysisWithBlocks(props: any) {
  return (
    <ContentLoader
      speed={2}
      width={1018}
      height={337}
      viewBox="0 0 1018 337"
      backgroundColor={getCssVariable('neutral100')}
      foregroundColor={getCssVariable('neutral200')}
      {...props}
    >
      <rect x="2" y="325" rx="4" ry="4" width="104" height="12" />
      <rect x="338" y="325" rx="4" ry="4" width="64" height="12" />
      <rect x="656" y="325" rx="4" ry="4" width="120" height="12" />
      <rect x="2" y="277" rx="4" ry="4" width="80" height="12" />
      <rect x="338" y="277" rx="4" ry="4" width="88" height="12" />
      <rect x="656" y="277" rx="4" ry="4" width="160" height="12" />
      <rect x="2" y="229" rx="4" ry="4" width="120" height="12" />
      <rect x="338" y="229" rx="4" ry="4" width="48" height="12" />
      <rect x="656" y="229" rx="4" ry="4" width="80" height="12" />
      <path d="M 2 210 h 1016 v 1 H 2 z M 2 258 h 1016 v 1 H 2 z M 2 306 h 1016 v 1 H 2 z" />
      <rect x="2" y="181" rx="4" ry="4" width="80" height="12" />
      <rect x="338" y="181" rx="4" ry="4" width="64" height="12" />
      <rect x="656" y="181" rx="4" ry="4" width="120" height="12" />
      <rect x="338" y="117" rx="4" ry="4" width="180" height="16" />
      <rect x="336" y="72" rx="4" ry="4" width="56" height="24" />
      <rect x="2" y="117" rx="4" ry="4" width="180" height="16" />
      <rect x="0" y="72" rx="4" ry="4" width="56" height="24" />
      <rect x="2" y="0" rx="4" ry="4" width="180" height="24" />
    </ContentLoader>
  );
}
