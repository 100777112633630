import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, Container, Row } from 'react-bootstrap';
import Input from '../../components/Forms/Input/Input';
import styles from '@components/LayoutOnBoarding/styles.module.scss';
import * as yup from 'yup';

type OrganizationBillingInfoFormProps = {
  initialValues?: OrganizationBillingInfoFormData;
};

type OrganizationBillingInfoFormData = {
  name: string;
  address: string;
  city: string;
  cap: number;
  province: string;
  country: string;
  vat: number;
};

const validationSchema = yup
  .object({
    name: yup.string().trim().required(),
    address: yup.string().trim().required(),
    city: yup.string().trim().required(),
    cap: yup.number().required(),
    province: yup.string().trim().required(),
    country: yup.string().trim().required(),
    vat: yup.number().required(),
  })
  .required();

const initialValues: OrganizationBillingInfoFormData = {
  name: '',
  address: '',
  city: '',
  cap: NaN,
  province: '',
  country: '',
  vat: NaN,
};

const OrganizationBillingInfoForm = (
  props: OrganizationBillingInfoFormProps
) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: props.initialValues || initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data: OrganizationBillingInfoFormData) => {
    console.log('errors', errors);
    console.log(data);
  };

  return (
    <Container
      as="form"
      className="p-0"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <Row className="gx-3">
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <Input {...register('name')} label="Name" className="mb-4" />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <Input {...register('address')} label="Address" className="mb-4" />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={2}>
          <Input {...register('city')} label="City" className="mb-4" />
        </Col>
        <Col xs={12} sm={12} md={2} lg={2} xl={1}>
          <Input
            {...register('cap')}
            label="CAP"
            className="mb-4"
            type="number"
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <Input
            {...register('province')}
            label="Province/State"
            className="mb-4"
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <Input {...register('country')} label="Country" className="mb-4" />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} xl={3}>
          <Input
            {...register('vat')}
            label="VAT"
            className="mb-4"
            type="number"
          />
        </Col>
      </Row>
      <div className="d-flex flex-row justify-content-end">
        <button className={styles['navbar-menu-button']} type="submit">
          <span className={styles['update-text-org']}>UPDATE</span>
        </button>
      </div>
    </Container>
  );
};

export default OrganizationBillingInfoForm;
