import useKernelSecurityAnalysis from '@hooks/analysis/useKernelSecurityAnalysis';
import { ROUTES } from '@routes/routes';
import React, { useMemo } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsShieldCheck } from 'react-icons/bs';
import { RiDoorLockBoxLine } from 'react-icons/ri';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import InfoBox from '../Components/InfoBox';
import InfoBoxChart from '../Components/InfoBoxChart';
import InfoBoxContent from '../Components/InfoBoxContent';
import InfoBoxFooter from '../Components/InfoBoxFooter';
import InfoBoxMessage from '../Components/InfoBoxMessage';
import SectionHeader from '../Components/SectionHeader';
import KernelSecurityGraph from '../Graphs/KernelSecurityGraph2';
import styles from '../styles.module.scss';

type BoxesContainerProps = {
  passwordHashData?: number;
  securityScanData?: number;
  reportType?: string;
};

const BoxesContainer = (props: BoxesContainerProps) => {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data } =
    useKernelSecurityAnalysis(reportId);

  const history = useHistory();
  const { passwordHashData, securityScanData } = props;
  const { t } = useTranslation(['common', 'pages']);

  const isLinuxReport = props.reportType && props.reportType === 'linux';
  const colSizesValue = isLinuxReport ? 4 : 6;

  const onRenderPassHashMessage = (value: any): any => {
    if (value > 0) {
      return t(
        'pages:overviewPage.sections.reportOverview.severityHighlightMessages.passwordHash.found'
      );
    }
    return t(
      'pages:overviewPage.sections.reportOverview.severityHighlightMessages.passwordHash.empty'
    );
  };
  const onRenderSecMessage = (value: any): any => {
    if (value > 0) {
      return t(
        'pages:overviewPage.sections.reportOverview.severityHighlightMessages.securityScan.found'
      );
    }
    return t(
      'pages:overviewPage.sections.reportOverview.severityHighlightMessages.securityScan.empty'
    );
  };

  const kernelSecurityData = useMemo(() => {
    let count = 0;
    if (data?.result?.length > 0) {
      count = data.result.filter((r: any, i: number) => r && r.enabled).length;
    }
    const message =
      count && count > 0
        ? t('pages:kernelSecurityPage.messages.hasModules')
        : t('pages:kernelSecurityPage.messages.hasntModules');

    return {
      count,
      message
    };
  }, [data, t]);

  return (
    <Container fluid style={{ paddingBottom: '24px' }}>
      <Row>
        <Col
          className='px-0'
          xs={12}
          sm={12}
          md={12}
          lg={colSizesValue}
          xl={colSizesValue}
        >
          <SectionHeader
            title={t('pages:overviewPage.sections.passwordHash.title')}
            leadingIcon={RiDoorLockBoxLine}
            tooltipMessage={t(
              'pages:overviewPage.sections.passwordHash.tooltipMessage'
            )}
          />
          <InfoBox>
            <InfoBoxContent>
              <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <InfoBoxMessage
                  message={onRenderPassHashMessage(passwordHashData)}
                  style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                />
                {passwordHashData ? <Button
                  onClick={() =>
                    history.push(
                      `${ROUTES.BASE}/${reportId}${ROUTES.PASSWORD_HASH}`
                    )
                  }
                  className={styles['button']}
                >
                  VIEW ALL
                </Button> : null}
              </div>
              <div className={styles['info-box-count']}>{passwordHashData}</div>
            </InfoBoxContent>
          </InfoBox>
        </Col>
        <Col
          className='px-2 md:px-0'
          xs={12}
          sm={12}
          md={12}
          lg={colSizesValue}
          xl={colSizesValue}
        >
          <SectionHeader
            title={t('pages:overviewPage.sections.securityScan.title')}
            leadingIcon={RiDoorLockBoxLine}
            tooltipMessage={t(
              'pages:overviewPage.sections.securityScan.tooltipMessage'
            )}
          />
          <InfoBox>
            <InfoBoxContent>
              <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <InfoBoxMessage
                  message={onRenderSecMessage(securityScanData)}
                  style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                />
                {securityScanData ? <Button
                  onClick={() =>
                    history.push(
                      `${ROUTES.BASE}/${reportId}${ROUTES.SECURITY_SCAN}`
                    )
                  }
                  className={styles['button']}
                >
                  VIEW ALL
                </Button> : null}
              </div>
              <div className={styles['info-box-count']}>{securityScanData}</div>
            </InfoBoxContent>
          </InfoBox>
        </Col>
        {isLinuxReport && (
          <Col className='px-0' xs={12} sm={12} md={12} lg={4} xl={4}>
            <SectionHeader
              title={t('pages:overviewPage.sections.kernelSecurity.title')}
              leadingIcon={BsShieldCheck}
              tooltipMessage={t(
                'pages:overviewPage.sections.kernelSecurity.tooltipMessage'
              )}
            />
            <InfoBox>
              <InfoBoxContent>
                <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <InfoBoxMessage
                    message={kernelSecurityData.message}
                    style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                  />
                  {kernelSecurityData.count ? <Button
                    onClick={() =>
                      history.push(
                        `${ROUTES.BASE}/${reportId}${ROUTES.KERNEL_SECURITY}`
                      )
                    }
                    className={styles['button']}
                  >
                    VIEW ALL
                  </Button> : null}
                </div>
                <div className={styles['info-box-count']}>{kernelSecurityData.count}</div>
              </InfoBoxContent>
            </InfoBox>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default BoxesContainer;
