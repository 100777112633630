import React, { CSSProperties, ReactNode } from 'react';

type SectionContentRightFooterProps = {
  children?: ReactNode;
  style?: CSSProperties;
};

const SectionContentRightFooter = (props: SectionContentRightFooterProps) => {
  const { children, style = {} } = props;
  return (
    <div className="w-100" style={style}>
      {children}
    </div>
  );
};

export default SectionContentRightFooter;
