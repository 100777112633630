import SkeletonReportList from '@components/SkeletonReportList';
import styles from '@pages/Dashboard/styles.module.scss';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
// import { KEY } from '@hooks/useReports';
import BSFavourite from '@components/BSFavourite';
import GradeBadge, { GRADE_SIZE, GRADE_TYPE } from '@components/GradeBadge';
import { NewScanContext } from '@context/NewScanContext';
import {
  DataTypeProvider,
  Table as DeTable,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-bootstrap4';
import {
  AnalysisStatusProvider,
  DateTypeProviderReport,
  GradeTypeProvider,
  ImageTypeProvider,
  QuickReportProvider,
} from '@utils/Providers';
import { REPORT_STATUS, Report } from '@utils/types/report';
import { format, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { RiAddFill } from 'react-icons/ri';
// import ScanFirmware from '@components/NewScanModal/ScanFirmware';
import archAnimationDark from '@assets/animations/Archived_DARK.json';
import archAnimationLight from '@assets/animations/Archived_LIGHT.json';
import favAnimationDark from '@assets/animations/Favorite_DARK.json';
import favAnimationLight from '@assets/animations/Favorite_LIGHT.json';
import reportHomeDark from '@assets/animations/Reports_Home_DARK.json';
import reportHomeLight from '@assets/animations/Reports_Home_LIGHT.json';
import BSArchived from '@components/BSArchived';
import { BUTTON_TYPE } from '@components/Button';
import EmptyResults from '@components/EmptyResults';
import Footer from '@components/Footer';
import { MODAL_TYPE } from '@components/ReportList';
import ReportStatusModal from '@components/ReportStatusModal';
import { GlobalContext } from '@context/GlobalContext';
import { ReportActionsContext } from '@context/ReportActionsContext';
import { THEMES } from '@hooks/useDarkMode';
import { ROUTES } from '@routes/routes';
import { VscSearch } from 'react-icons/all';
import Lottie from 'react-lottie';
import { Link, useHistory } from 'react-router-dom';
import formStyles from '../Forms/styles.module.scss';

type Props = {
  reports: Report[] | undefined;
  isLoading: boolean;
  isSuccess: boolean;
  reportType: string;
  updateReport: any;
  isFetching: boolean;
};

const BSReportList = ({
  reports,
  isLoading,
  isSuccess,
  isFetching,
  reportType,
  updateReport,
}: Props) => {
  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = theme === THEMES.DARK;
  const history = useHistory();
  const { t } = useTranslation([
    'common',
    'emptyReports',
    'forms',
    'tables',
    'pages',
  ]);
  const { addStep } = React.useContext<any>(NewScanContext);
  const [pageSizes] = React.useState([5, 10, 15]);
  const [grades, setGrades] = React.useState({
    A: true,
    B: true,
    C: true,
    D: true,
    E: true,
  });

  const [type, setType] = React.useState('');
  const [search, setSearch] = React.useState('');
  const [modalType, setModalType] = React.useState<MODAL_TYPE | null>(null);
  const { setReportData } = React.useContext<any>(ReportActionsContext);

  const favAnimation = {
    loop: true,
    autoplay: true,
    animationData: isDarkMode ? favAnimationDark : favAnimationLight,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const reportHome = {
    loop: true,
    autoplay: true,
    animationData: isDarkMode ? reportHomeDark : reportHomeLight,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const archAnimation = {
    loop: true,
    autoplay: true,
    animationData: isDarkMode ? archAnimationDark : archAnimationLight,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  function handleSetModal(data: any, type: MODAL_TYPE) {
    setModalType(type);
    setReportData({ ...data });
  }

  const getPageTitle = () => {
    if (reportType === 'all') {
      return t('common:navigation.primaryNavigation.allReports');
    }

    if (reportType === 'favourites') {
      return t('common:navigation.primaryNavigation.favourites');
    }

    if (reportType === 'archived') {
      return t('common:navigation.primaryNavigation.archived');
    }
  };

  const [columns] = useState([
    { name: 'actions', title: ' ' },
    { name: 'favourite', title: ' ' },
    { name: 'grade', title: t('tables:rating') },
    { name: 'name', title: t('tables:reportName') },
    { name: 'analysis_status', title: ' ' },
    { name: 'type', title: t('tables:reportType') },
    { name: 'creationDate', title: t('tables:created') },
    { name: 'archived', title: ' ' },
    { name: 'quickReport', title: t('tables:quickReport') },
  ]);

  const [tableColumnExtensions] = useState([
    {
      columnName: 'actions',
      width: 70,
      align: 'center' as DeTable.ColumnExtension['align'],
    },
    { columnName: 'grade', width: 100 },
    //{ columnName: 'name', width: 350 },
    { columnName: 'type', width: 150 },
    { columnName: 'creationDate', width: 120 },
    {
      columnName: 'quickReport',
      width: 250,
      align: 'center' as DeTable.ColumnExtension['align'],
    },
    { columnName: 'analysis_status', width: 110 },
    { columnName: 'favourite', width: 50 },
  ]);

  const filterData = (values: any[] | undefined) => {
    if (!values) {
      return [];
    }

    //temporary filter reports with DOCKER-LITE subtype
    let result: any[] = values.filter((el) => el.subType !== 'DOCKER-LITE');

    if (values) {
      if (type) {
        result = result.filter(
          (report) => report.type.toUpperCase() === type.toUpperCase()
        );
      }

      if (search) {
        result = result.filter((report) => {
          if (report.creationDate) {
            const date = format(
              parse('', '', new Date(report.creationDate)),
              'dd MM yyyy'
            );
            if (date.toUpperCase().includes(search.toUpperCase())) return true;
          }

          if (report.name) {
            if (report.name.toUpperCase().includes(search.toUpperCase()))
              return true;
          }

          return false;
        });
      }

      if (!grades.A || !grades.B || !grades.C || !grades.D || !grades.E) {
        result = result.filter((report) => {
          if (!grades.A && report.grade === GRADE_TYPE.A) {
            return true;
          }

          if (!grades.B && report.grade === GRADE_TYPE.B) {
            return true;
          }

          if (!grades.C && report.grade === GRADE_TYPE.C) {
            return true;
          }

          if (!grades.D && report.grade === GRADE_TYPE.D) {
            return true;
          }

          if (!grades.E && report.grade === GRADE_TYPE.E) {
            return true;
          }
          return false;
        });
      }
    }

    return result.map((row, index) =>
      Object.assign(row, { rowNum: index + 1 })
    );
  };

  function refreshData(id: string, data: any) {
    updateReport(id, data);
  }

  return (
    <div className={'wrapper'}>
      <Container fluid className={'reportTable'}>
        <Row>
          <Col xs={12}>
            {isLoading && !isSuccess ? (
              <SkeletonReportList />
            ) : (!reports || reports?.length === 0) && !isFetching ? (
              <div>
                {reportType === 'all' ? (
                  <div className={styles.container}>
                    <Lottie
                      options={reportHome}
                      height={300}
                      width={300}
                      isStopped={false}
                      isPaused={false}
                    />
                    <div className={styles.emptyState}>
                      <EmptyResults
                        image={null}
                        title={t('emptyReports:dashboard.title')}
                        description={t('emptyReports:dashboard.subtitle')}
                        buttonLabel={t('emptyReports:dashboard.buttonLabel')}
                        buttonType={BUTTON_TYPE.GREEN}
                        onClick={addStep}
                      />
                    </div>
                  </div>
                ) : reportType === 'favourites' ? (
                  <div className={styles.container}>
                    <Lottie
                      options={favAnimation}
                      height={300}
                      width={300}
                      isStopped={false}
                      isPaused={false}
                    />
                    <div className={styles.emptyState}>
                      <EmptyResults
                        image={null}
                        title={t('pages:noFavs.title')}
                        description={t('pages:noFavs.subtitle')}
                        buttonLabel={t('pages:noFavs.buttonLabel')}
                        buttonType={BUTTON_TYPE.GREEN}
                        onClick={() => history.push(ROUTES.BASE)}
                      />
                    </div>
                  </div>
                ) : reportType === 'archived' ? (
                  <div className={styles.container}>
                    <Lottie
                      options={archAnimation}
                      height={300}
                      width={300}
                      isStopped={false}
                      isPaused={false}
                    />
                    <div className={styles.emptyState}>
                      <EmptyResults
                        image={null}
                        title={t('pages:noArch.title')}
                        description={t('pages:noArch.subtitle')}
                        buttonLabel={t('pages:noArch.buttonLabel')}
                        buttonType={BUTTON_TYPE.GREEN}
                        onClick={() => history.push(ROUTES.BASE)}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : reports && reports.length > 0 ? (
              <Container fluid>
                <Row className={'g-3 pb-3'}>
                  <Col xs={12}>
                    <p className={'pageTitle'}>{getPageTitle()}</p>
                  </Col>
                  <Col xs={12} md={3}>
                    <div className={'iconAddon'}>
                      <Form.Control
                        className={`d ${formStyles['input-control']}`}
                        size={'sm'}
                        type="text"
                        placeholder={t('forms:placeholder.search')}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <span className={'icon'}>
                        <VscSearch />
                      </span>
                    </div>
                  </Col>
                  <Col xs={12} md={2}>
                    <Form.Select
                      className={`d ${formStyles['select-control']}`}
                      size={'sm'}
                      aria-label="By type"
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value={''}>{t('common:reportTypes.all')}</option>
                      <option value={t('common:reportTypes.linux')}>
                        {t('common:reportTypes.linux')}
                      </option>
                      <option value={t('common:reportTypes.container')}>
                        {t('common:reportTypes.container')}
                      </option>
                      <option value={t('common:reportTypes.rtos')}>
                        {t('common:reportTypes.rtos')}
                      </option>
                      <option value={t('common:reportTypes.uefi')}>
                        {t('common:reportTypes.uefi')}
                      </option>
                      <option value={t('common:reportTypes.generic')}>
                        {t('common:reportTypes.generic')}
                      </option>
                      <option value={t('common:reportTypes.buildroot')}>
                        {t('common:reportTypes.buildroot')}
                      </option>
                      <option value={t('common:reportTypes.openwrt')}>
                        {t('common:reportTypes.openwrt')}
                      </option>
                      <option value={t('common:reportTypes.yocto')}>
                        {t('common:reportTypes.yocto')}
                      </option>
                      <option value={t('common:reportTypes.docker')}>
                        {t('common:reportTypes.docker')}
                      </option>
                      <option value={t('common:reportTypes.lxc')}>
                        {t('common:reportTypes.lxc')}
                      </option>
                      <option value={t('common:reportTypes.vxworks')}>
                        {t('common:reportTypes.vxworks')}
                      </option>
                    </Form.Select>
                  </Col>
                  <Col xs={12} md={4}>
                    <div className={'rating-filter'}>
                      {t('common:buttonLabels.byRating')}
                      <GradeBadge
                        grade={GRADE_TYPE.A}
                        size={GRADE_SIZE.EXTRASMALL}
                        disabled={grades[GRADE_TYPE.A]}
                        onClick={() => {
                          setGrades({
                            ...grades,
                            [GRADE_TYPE.A]: !grades[GRADE_TYPE.A],
                          });
                        }}
                      />

                      <GradeBadge
                        grade={GRADE_TYPE.B}
                        size={GRADE_SIZE.EXTRASMALL}
                        disabled={grades[GRADE_TYPE.B]}
                        onClick={() => {
                          setGrades({
                            ...grades,
                            [GRADE_TYPE.B]: !grades[GRADE_TYPE.B],
                          });
                        }}
                      />

                      <GradeBadge
                        grade={GRADE_TYPE.C}
                        size={GRADE_SIZE.EXTRASMALL}
                        disabled={grades[GRADE_TYPE.C]}
                        onClick={() => {
                          setGrades({
                            ...grades,
                            [GRADE_TYPE.C]: !grades[GRADE_TYPE.C],
                          });
                        }}
                      />

                      <GradeBadge
                        grade={GRADE_TYPE.D}
                        size={GRADE_SIZE.EXTRASMALL}
                        disabled={grades[GRADE_TYPE.D]}
                        onClick={() => {
                          setGrades({
                            ...grades,
                            [GRADE_TYPE.D]: !grades[GRADE_TYPE.D],
                          });
                        }}
                      />
                      <GradeBadge
                        grade={GRADE_TYPE.E}
                        size={GRADE_SIZE.EXTRASMALL}
                        disabled={grades[GRADE_TYPE.E]}
                        onClick={() => {
                          setGrades({
                            ...grades,
                            [GRADE_TYPE.E]: !grades[GRADE_TYPE.E],
                          });
                        }}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={3} className={'text-end'}>
                    <Button variant="primary" size={'sm'} onClick={addStep}>
                      <RiAddFill /> {t('common:buttonLabels.newReport')}
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Grid rows={filterData(reports)} columns={columns}>
                      <ImageTypeProvider for={['type']} />

                      <DateTypeProviderReport for={['creationDate']} />
                      <GradeTypeProvider for={['grade']} />
                      {/*<ReportNameProvider for={['name']} />*/}
                      <AnalysisStatusProvider for={['analysis_status']} />
                      <QuickReportProvider for={['quickReport']} />

                      <DataTypeProvider
                        for={['name']}
                        formatterComponent={({
                          row,
                        }: DataTypeProvider.ValueFormatterProps) => {
                          const isPending =
                            row?.status === REPORT_STATUS.PENDING ||
                            row?.status === REPORT_STATUS.RUNNING;

                          if (isPending) {
                            return (
                              <span
                                className={'linkType reportLink'}
                                onClick={() =>
                                  handleSetModal(
                                    { id: row?.id },
                                    MODAL_TYPE.STATUS
                                  )
                                }
                              >
                                {row.name}
                              </span>
                            );
                          }

                          return row.status !== REPORT_STATUS.FAILED ? (
                            <Link
                              to={'/reports/' + row.id + ROUTES.OVERVIEW}
                              className={'reportLink'}
                            >
                              {row.name}
                            </Link>
                          ) : (
                            row.name
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['actions']}
                        formatterComponent={({
                          row,
                        }: DataTypeProvider.ValueFormatterProps) => {
                          return row.rowNum;
                        }}
                      />

                      <DataTypeProvider
                        for={['favourite']}
                        formatterComponent={({
                          row,
                        }: DataTypeProvider.ValueFormatterProps) => {
                          return (
                            <div>
                              {
                                <BSFavourite
                                  report={row}
                                  onChange={refreshData}
                                />
                              }
                            </div>
                          );
                        }}
                      />

                      <DataTypeProvider
                        for={['archived']}
                        formatterComponent={({
                          row,
                        }: DataTypeProvider.ValueFormatterProps) => {
                          return (
                            <div>
                              {
                                <BSArchived
                                  report={row}
                                  onChange={refreshData}
                                />
                              }
                            </div>
                          );
                        }}
                      />

                      <PagingState
                        defaultPageSize={10}
                        defaultCurrentPage={0}
                      />
                      <SortingState
                        columnExtensions={[
                          { columnName: 'actions', sortingEnabled: false },
                          { columnName: 'grade', sortingEnabled: false },
                          {
                            columnName: 'analysis_status',
                            sortingEnabled: false,
                          },
                          { columnName: 'favourite', sortingEnabled: false },
                        ]}
                      />
                      <IntegratedFiltering />
                      <IntegratedSorting />
                      <IntegratedPaging />

                      <Table columnExtensions={tableColumnExtensions} />
                      <TableHeaderRow showSortingControls={true} />

                      <PagingPanel pageSizes={pageSizes} />
                    </Grid>
                  </Col>
                </Row>
              </Container>
            ) : null}
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col xs={12} className={'reportFooterContainer'}>
            <Footer />
          </Col>
        </Row>
      </Container>
      {modalType === MODAL_TYPE.STATUS ? (
        <ReportStatusModal onClose={handleSetModal} />
      ) : null}
    </div>
  );
};

export default BSReportList;
