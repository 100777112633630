import React, { CSSProperties } from 'react';
import { Form } from 'react-bootstrap';
import styles from './styles.module.scss';
import formStyles from '../styles.module.scss';

type TextareaProps = {
  label?: string;
  placeholder?: string;
  value?: string | number;
  onChange?: any;
  rows?: number;
  readOnly?: boolean;
  className?: string;
  style?: CSSProperties;
  helper?: string;
  hint?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>((props, ref) => {
  const pre = 'textarea-';

  const { label, helper = '', hint = '', placeholder = '', rows = 6, className = '', style = {}, ...rest } = props;
  return (
    <Form.Group className={`${styles[`${pre}container`]} ${className}`} style={style}>
      <div className='d-flex flex-row justify-content-between align-items-center mb-4'>
        {label && <Form.Label className={formStyles['form-label']}>{label}</Form.Label>}
        {helper && <span className={formStyles['form-mono']}>{helper}</span>}
      </div>
      {/*<div className={`${styles[`${pre}wrapper`]}`}>*/}
      <Form.Control as='textarea' {...rest} ref={ref} className={`${styles[`${pre}text`]} ${formStyles['input-control']} mb-2`} placeholder={placeholder}
                    rows={rows} />
      {/*</div>*/}
      {hint && <span className={formStyles['form-hint']}>{hint}</span>}
    </Form.Group>
  );
});

export default Textarea;