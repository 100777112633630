import Table from '@components/TableNew/Table';
import useUefiSecurityScanAnalysis from '@hooks/analysis/useUefiSecurityScanAnalysis';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useFilters, usePagination, useTable } from 'react-table';
import passHashStyle from '../../../pages/Report/Linux/PasswordHash/styles.module.scss';
import pdfGlobalStyles from '../componentToPrint.module.scss';
import styles from './styles.module.scss';

const SecurityScan = () => {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data } = useUefiSecurityScanAnalysis(reportId);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'name',
        width: 50,
      },
      {
        Header: 'Module',
        accessor: 'module',
        width: 50,
      },
      {
        Header: 'Guide',
        accessor: 'guid',
      },
      {
        Header: '',
        accessor: 'severity',
        Cell: (props: any) => {
          return (
            <div className={'d-flex align-items-center justify-content-end'}>
              <div
                className={`${passHashStyle['severity-wrap-table-accessor']} ${
                  passHashStyle[props.value]
                }`}
              >
                <span>{`${props.value} severity`}</span>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const memoizedData = React.useMemo(() => {
    if (!data || data.length === 0) return [];
    return data;
  }, [data]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
    },
    useFilters,
    usePagination
  );

  return (
    <div className={styles['container']}>
      <div className={pdfGlobalStyles['title']}>Security Scan</div>

      {data?.length ? (
        <>
          <div className={pdfGlobalStyles['paragraph']}>
            The Malware Scan analysis looks for known malicious files (such as
            malware, trojan, etc.) within the file system.
          </div>
          <div className={styles['content']}>
            <div className={styles['malicious-count']}>
              {`+ ${data?.length}`}
            </div>
            <div className={styles['table-container']}>
              <Table tableInstance={tableInstance} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={pdfGlobalStyles['paragraph']}>
            The Malware Scan analysis looks for known malicious files (such as
            malware, trojan, etc.) within the file system.
          </div>
          <div className={pdfGlobalStyles['paragraph-transparent']}>
            No malicious files found. <br /> Everything looks good here! There
            are no malicious files in your device.
          </div>
        </>
      )}
    </div>
  );
};
export default SecurityScan;
