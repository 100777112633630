export default function ConfigAnalyzerFill({
  color,
  size,
  ...rest
}: any): JSX.Element {
  return (
    <svg
      {...rest}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
        fill={color}
      >
        <g
          id="Icons/alert"
          transform="translate(-1.000000, -2.000000)"
          fill={color}
        >
          <path
            d="M17.5,2.474 L23,12 L17.5,21.526 L6.5,21.526 L1,12 L6.5,2.474 L17.5,2.474 Z M8.634,8.17 L13.634,16.83 L15.366,15.83 L10.366,7.17 L8.634,8.17 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
