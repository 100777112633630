import GradeBadge, { GRADE_SIZE, GRADE_TYPE } from '@components/GradeBadge';
import ModalRating from '@components/ModalRating';
import React, { CSSProperties } from 'react';
import styles from './styles.module.scss';

type OverviewGradeProps = {
  cta: any;
  title?: any;
  description?: any;
  grade: GRADE_TYPE;
  style?: CSSProperties;
};

const OverviewGrade = ({
  cta,
  title,
  description,
  grade,
  style = {},
}: OverviewGradeProps) => {
  const [shouldShowModalRating, setShouldShowModalRating] =
    React.useState<boolean>(false);
  return (
    <div
      className={`${styles['badge-container']} py-5 d-flex flex-row align-items-center justify-content-center`}
      style={style}
    >
      <div
        className="d-flex align-items-center justify-content-center mb-4"
        style={{ margin: '0 5rem' }}
      >
        <GradeBadge grade={grade} size={GRADE_SIZE.BIG} />
      </div>
      <div
        className={`${styles['grade-content']}`}
        style={{ flex: '1 1 100%' }}
      >
        {title && <p className={styles['grade-title']}>{title}</p>}
        {description && (
          <p className={styles['grade-description']}>{description}</p>
        )}
        <p
          className={`${styles['grade-cta']} w-100 text-right`}
          onClick={() => setShouldShowModalRating(true)}
        >
          {cta}
        </p>
      </div>
      {shouldShowModalRating ? (
        <ModalRating onClose={() => setShouldShowModalRating(false)} />
      ) : null}
    </div>
  );
};

export default OverviewGrade;
