import React, { CSSProperties } from 'react';
import styles from '@components/Forms/Select/styles.module.scss';
import { Form } from 'react-bootstrap';
import formStyles from '../styles.module.scss';

type SelectProps = {
  label?: string;
  placeholder?: string;
  type?: string;
  value?: string | number;
  onChange?: any;
  iconLeft?: JSX.IntrinsicElements;
  iconRight?: React.ReactNode;
  options?: any;
  readOnly?: boolean;
  className?: string;
  style?: CSSProperties;
  helper?: string;
  hint?: string;
};

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const pre = 'select-';
    const {
      label,
      placeholder = '',
      helper = '',
      hint = '',
      options,
      className = '',
      style = {},
      ...rest
    } = props;

    return (
      <Form.Group
        className={`${styles[`${pre}container`]} ${className}`}
        style={style}
      >
        <div className="d-flex flex-row justify-content-between align-items-center mb-4">
          {label && (
            <Form.Label className={formStyles['form-label']}>
              {label}
            </Form.Label>
          )}
          {helper && <span className={formStyles['form-mono']}>{helper}</span>}
        </div>
        <Form.Select
          className="mb-2"
          {...rest}
          ref={ref}
          placeholder={placeholder}
        >
          {options?.length &&
            options.map((o: any, i: number) => (
              <option key={`${o.value}-${i}`} value={o.value}>
                {o.label}
              </option>
            ))}
        </Form.Select>
        {hint && <span className={formStyles['form-hint']}>{hint}</span>}
      </Form.Group>
    );
  }
);

export default Select;
