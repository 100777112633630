import React from 'react';
import { WithChildren } from '@utils/types/helpers';
import { noop } from 'lodash';
import type { Report } from '@utils/types/report';
import { reportDescription } from '@utils/index';

type ContextType = {
  report: Report | undefined;
  setReport: typeof noop;
  setIsFetching: typeof noop;
  isFetching: boolean;
  description: string
};
const CurrentReportContext = React.createContext<ContextType>({
  report: undefined,
  setReport: noop,
  setIsFetching: noop,
  isFetching: false,
  description: ''
});

const CurrentReportProvider = ({ children }: WithChildren) => {
  const [report, setReport] = React.useState<Report>();
  const [isFetching, setIsFetching] = React.useState<boolean>(false);
  return (
    <CurrentReportContext.Provider
      value={{ report, setReport, isFetching, setIsFetching, description: reportDescription(report as Report) }}
    >
      {children}
    </CurrentReportContext.Provider>
  );
};

export { CurrentReportProvider, CurrentReportContext };
