import EmptyResults from '@components/EmptyResults';
import GenericError from '@components/ErrorState';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import ModalReportVxWorksDetails from '@components/ModalReportVxWorksDetails';
import ReportHeading from '@components/ReportHeading';
import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import { GlobalContext } from '@contexts/GlobalContext';
import useRtosFunctionsAnalysis from '@hooks/analysis/useRtosFunctionsAnalysis';
import { THEMES } from '@hooks/useDarkMode';
import usePaginationCounter, { UsePaginationCounterState } from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import InfoBox from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxMessage';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import { getCssVariable, padNumber } from '@utils/index';
import { noop } from 'lodash';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiArrowLeftSLine, RiArrowRightSLine, RiFunctionLine, RiSearch2Line } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import styles from '../styles.module.scss';

// const MIN_ROW_LENGTH = 10;
//
// export enum RTOS_FUNCTIONS_PROPERTY {
//   NAME = 'name',
//   SIZE = 'size',
//   OFFSET = 'offset',
// }

export default function ReportRtosFunctions() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isError, error } =
    useRtosFunctionsAnalysis(reportId);
  const { data: reportData } = useReportOverview(reportId);

  const { t } = useTranslation(['common', 'pages', 'tables', 'emptyReports']);

  // const tableCols = React.useMemo(() => {
  //   return Object.keys(get(data, '[0]', {}))?.map((label: string) => ({
  //     Header: () => <Trans t={t}>{label}</Trans>,
  //     accessor: label,
  //     sortable: false,
  //     minWidth: 300,
  //     width: 260,
  //     sortType: 'alphanumeric',
  //   }));
  // }, [data, t]);

  const [search, setSearch] = React.useState('');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'name',
        Cell: ({ row, value }: { row: any; value: boolean }) => {
          return (
            <div className='d-flex flex-row align-items-center'>
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400')
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {value}
            </div>
          );
        }
      },
      {
        Header: 'Size',
        accessor: 'size'
      },
      {
        Header: 'Offset',
        accessor: 'offset'
      }
    ],
    []
  );

  const memoizedData = React.useMemo(() => {
    if (!data || data.length === 0) return [];
    return data;
  }, [data]);

  const memoizedSearch = React.useMemo(() => search, [search]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { globalFilter: memoizedSearch, pageSize: 10 }
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisSimple />}
      {!isLoading && !isError && memoizedData?.length < 1 && (
        <EmptyResults
          title={t('emptyReports:functionsAnalysis.title')}
          description={t('emptyReports:functionsAnalysis.subtitle')}
        // buttonLabel={t('emptyReports:functionsAnalysis.buttonLabel')}
        // onClick={console.log}
        />
      )}
      {isError && <GenericError error={error} />}
      {memoizedData && memoizedData?.length > 0 && !isLoading && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <SectionHeader
            title={t('pages:functionsAnalysisPage.title')}
            leadingIcon={RiFunctionLine}
            tooltipMessage={t('pages:functionsAnalysisPage.buttonLabel')}
            bannerText={t('pages:functionsAnalysisPage.bannerMessage')}
          />
          <Container fluid>
            <Row>
              <Col className="px-0" xs={12}>
                <InfoBox style={{
                  padding: 20,
                  height: 'auto'
                }}>
                  <InfoBoxContent style={{
                    alignItems: 'flex-start', background: isDarkMode ? '#202226' : 'none',
                  }}>
                    <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <InfoBoxMessage
                        message={t('pages:functionsAnalysisPage.boxMessage')}
                        style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                      />
                    </div>
                    <div className={styles['info-box-count']}>{memoizedData?.length || 0}</div>
                  </InfoBoxContent>-
                </InfoBox>
                <Container fluid style={{
                  border: '1px solid var(--neutral300)',
                  padding: 0,
                  borderRadius: '2px'
                }}>
                  <Row>
                    <Col xs={12}>
                      <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>Functions Analysis
                      </div>
                      <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total functions: {memoizedData?.length || 0}</div>
                      <Table tableInstance={tableInstance} borderless>
                        <Table.Footer>
                          <p className={tableStyles['table__paginationState']}>
                            {paginationMessage}
                          </p>
                          <div className={tableStyles['table__paginationButtons']}>
                            <button
                              className={
                                tableInstance?.canPreviousPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.previousPage()}
                            >
                              <RiArrowLeftSLine size={20} />
                            </button>
                            <button
                              className={
                                tableInstance?.canNextPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.nextPage()}
                            >
                              <RiArrowRightSLine size={20} />
                            </button>
                          </div>
                          <TablePageSize
                            maxResultChunkSize={maxResultChunkSize}
                            pageSize={tableInstance?.state?.pageSize || 10}
                            onPageSizeChange={onPageSizeChange}
                          />
                        </Table.Footer>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {shouldShowModalDetail ? (
        <ModalReportVxWorksDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData?.reportDetails
          }}
        />
      ) : null}
    </LayoutPage>
  );

  // return (
  //   <LayoutPage>
  //     {functionsResultLength > 0 ? (
  //       <>
  //         <LayoutSection spacer={24}>
  //           <ReportHeading title={t('pages:functionsAnalysisPage.title')} />
  //         </LayoutSection>
  //         <LayoutSection spacer={16} layoutType={LAYOUT_TYPE.FULL}>
  //           <LayoutBox>
  //             <LayoutBoxContent>
  //               <DataHighlight
  //                 label={t('pages:functionsAnalysisPage.boxMessage')}
  //                 size={SIZE.SMALL}
  //                 numberOfIssue={functionsResultLength}
  //               />
  //             </LayoutBoxContent>
  //           </LayoutBox>
  //         </LayoutSection>
  //         <LayoutSection>
  //           <Table
  //             hasSearch
  //             onSearch={{
  //               fuzzyText: fuzzyTextFilterFn,
  //             }}
  //             hasPagination={functionsResultLength > MIN_ROW_LENGTH}
  //             columns={tableCols}
  //             data={tableRows}
  //           />
  //         </LayoutSection>
  //       </>
  //     ) : !isLoading && isSuccess ? (
  //       <EmptyResults
  //         title={t('emptyReports:functionsAnalysis.title')}
  //         description={t('emptyReports:functionsAnalysis.subtitle')}
  //         // buttonLabel={t('emptyReports:functionsAnalysis.buttonLabel')}
  //         onClick={console.log}
  //       />
  //     ) : !isLoading && isError ? (
  //       <GenericError error={error} />
  //     ) : (
  //       <SkeletonAnalysisSimple />
  //     )}
  //   </LayoutPage>
  // );
}
