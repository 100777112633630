import React from 'react';

const UserContext = React.createContext('');

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}
const UserProvider = ({ children }: IProps) => {
  const [url, setURL] = React.useState<string>('');
  const [file, setFile] = React.useState(null);
  return (
    <UserContext.Provider value={{ url, setURL, file, setFile } as any}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
