import EmptyResults from '@components/EmptyResults';
import GenericError from '@components/ErrorState';
import { Input } from '@components/Forms/Input';
import GraphLegend from '@components/GraphLegend';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import ModalReportLinuxDetails from '@components/ModalReportLinuxDetails';
import ReportHeading from '@components/ReportHeading';
import SkeletonAnalysisWithBlocks from '@components/SkeletonAnalysisWithBlocks';
import TableModal from '@components/TableModal';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import TabsProvider from '@components/Tabs/TabsProvider';
import { TabsHeader } from '@components/TabsHeader';
import tabsStyles from '@components/TabsHeader/styles.module.scss';
import { GlobalContext } from '@contexts/GlobalContext';
import useCodeAnalysis from '@hooks/analysis/useCodeAnalysis';
import { THEMES } from '@hooks/useDarkMode';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionContentRight from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentRight';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import CodeAnalysisGraph from '@pages/Report/Linux/ReportOverviewLinux/Graphs/CodeAnalysisGraph';
import Utils from '@pages/Report/Linux/ReportOverviewLinux/Utils/Utils';
import styles from '@pages/Report/Linux/ReportOverviewLinux/styles.module.scss';
import reportStyles from '@pages/Report/styles.module.scss';
import { getCssVariable, getScanTime } from '@utils/index';
import { format } from 'date-fns';
import { noop } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiDoorLockBoxLine,
  RiSearch2Line,
} from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import SectionContentFooter from '../ReportOverviewLinux/Components/SectionContentFooter';
import BarGraph from '../ReportOverviewLinux/Graphs/BarGraph';
import CodeAnalysisTable from '../ReportOverviewLinux/Tables/CodeAnalysisTable';
import codeAnalysisStyles from './styles.module.scss';

//
// export enum CODE_ANALYSIS_PROPERTY {
//   FILENAME = 'filename',
//   TYPES = 'types',
//   OCCURENCES = 'occurences',
// }
//

export default function CodeAnalysis() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { t } = useTranslation(['pages', 'tables', 'emptyReports', 'common']);

  const {
    data,
    isLoading: isLoadingCodeAnalysis,
    isError: isErrorCodeAnalysis,
    error: errorCodeAnalysis,
  } = useCodeAnalysis(reportId);
  const {
    data: reportData,
    isLoading: isLoadingOverview,
    isError: isErrorOverview,
    error: errorOverview,
  } = useReportOverview(reportId);

  const isLoading = isLoadingCodeAnalysis || isLoadingOverview;
  const isError = isErrorCodeAnalysis || isErrorOverview;
  const error = errorOverview || errorCodeAnalysis;
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const [activeIndex, setActiveIndex] = React.useState<string>('0');
  const [search, setSearch] = React.useState('');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);
  const { theme } = useContext(GlobalContext);
  const isDarkMode = useMemo(() => theme === THEMES.DARK, [theme]);

  const tabsState = React.useMemo(
    () => ({
      id: 'controlled-tab',
      activeKey: activeIndex,
      className: `mb-3 ${styles['section-content-right']} w-75`,
      style: { backgroundColor: '#F6F8FA' },
      onSelect: (k: string) => setActiveIndex(k),
      tabs: [
        {
          eventKey: 0,
          tabClassName: `${tabsStyles['tab-header']} ${activeIndex === '0'
            ? tabsStyles['active-tab']
            : tabsStyles['inactive-tab']
            }`,
          title: (
            <TabsHeader
              title="Files affected"
              number={data?.length || 0}
              // variation={'5.21'}
              color={getCssVariable('bs-red')}
              tooltipMessage="Since the last check"
            />
          ),
        },
      ],
    }),
    [activeIndex, data]
  );

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'filename',
      },
      {
        Header: 'Type',
        accessor: 'types',
        Cell: ({ value: types }: any) => {
          return (
            <div className="d-flex align-items-center w-100">
              {types.map((type: string, i: number) => (
                <span
                  key={`${type}-${i}`}
                  style={{
                    padding: '.35rem',
                    marginRight: '1rem',
                    color: getCssVariable(
                      Utils.getCodeAnalysisChipColorByLabel(type)
                    ),
                    borderRadius: '4px',
                  }}
                >
                  {type}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        Header: 'Occurences',
        accessor: 'occurrences',
        Cell: ({ value }: any) => {
          return (
            <div className="d-flex align-items-center w-100">
              <span>{`${value} vulnerabilities`}</span>
            </div>
          );
        },
      },
    ],
    []
  );

  const memoizedSearch = React.useMemo(() => search, [search]);
  const memoizedData = React.useMemo(() => {
    if (!data || data.length === 0) return [];
    return data;
  }, [data]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { globalFilter: memoizedSearch, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const childrenColumns: any = React.useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'format',
        width: 100,
        Cell: ({ value }: any) => {
          return (
            <div className="d-flex align-items-center w-100">
              <span
                style={{
                  padding: '.35rem',
                  marginRight: '1rem',
                  background: getCssVariable(
                    Utils.getCodeAnalysisChipColorByLabel(value)
                  ),
                  color: 'black',
                  textTransform: 'uppercase',
                  borderRadius: '4px',
                }}
              >
                {value}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 1000,
      },
      {
        Header: 'Code',
        accessor: 'code',
        width: 1000,
      },
    ],
    []
  );

  const renderChildrenTableHeader = () => {
    if (selectedRow?.filename) {
      return selectedRow.filename;
    } else {
      if (selectedRow?.original?.filename) return selectedRow.original.filename;
    }
    return '';
  };

  const memoizedChildrenData = React.useMemo(() => {
    if (selectedRow?.children) {
      return selectedRow.children;
    } else {
      if (
        !selectedRow?.original?.children ||
        selectedRow?.original?.children?.length === 0
      )
        return [];
      return selectedRow.original.children;
    }
  }, [selectedRow]);

  const childrenTableInstance = useTable(
    {
      columns: childrenColumns,
      data: memoizedChildrenData,
      initialState: { pageSize: 999 },
    },
    useFilters,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);
  const {
    paginationMessage: childrenPaginationMessage,
  }: UsePaginationCounterState = usePaginationCounter(childrenTableInstance);

  const scanTime = React.useMemo(
    () => (reportData ? getScanTime(reportData) : null),
    [reportData]
  );



  const calculateTypeCounts = (array: any) => {
    const typeCounts = {};

    // Iterate through the array and count occurrences of each type
    array?.forEach((item: any) => {
      item.types.forEach((type: any) => {
        //@ts-ignore
        if (typeCounts[type]) {
          //@ts-ignore
          typeCounts[type]++;
        } else {
          //@ts-ignore
          typeCounts[type] = 1;
        }
      });
    });

    // Convert typeCounts object into the desired output format
    const result = [];
    for (const type in typeCounts) {
      // @ts-ignore
      result.push({ key: type, count: typeCounts[type] });
    }

    return result;
  };


  const typeCounts = useMemo(() => calculateTypeCounts(data), [data]);
  const graphColors = useMemo(() => typeCounts.map((type) => getCssVariable(Utils.getCodeAnalysisChipColorByLabel(type.key))), [typeCounts]);
  const legendData: any = useMemo(() => {
    const transformedObject: any = {};

    // Iterate through the array and assign key-value pairs to the transformed object
    typeCounts.forEach(item => {
      transformedObject[item.key] = item.count;
    });

    return transformedObject;

  }, [typeCounts]);

  console.log({ selectedRow });

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisWithBlocks />}
      {isError && <GenericError error={error} />}
      {data && Object.keys(data).length === 0 && (
        <EmptyResults
          title={t('emptyReports:binaryScan.title')}
          description={t('emptyReports:binaryScan.subtitle')}
        // buttonLabel={t('emptyReports:binaryScan.buttonLabel')}
        //onClick={console.log}
        />
      )}
      {data && Object.keys(data).length > 0 && !isLoading && !isError && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <SectionLayout>
            <SectionHeader
              title={t('pages:overviewPage.sections.codeAnalysis.title')}
              leadingIcon={RiDoorLockBoxLine}
              tooltipMessage={t(
                'pages:overviewPage.sections.codeAnalysis.tooltipMessage'
              )}
            ></SectionHeader>
            <SectionContent style={{
              padding: 15
            }}>
              <SectionContentLeft style={{
                background: isDarkMode ? '#1f2226' : 'none'
              }}>
                <SectionContentLeft style={{ borderRight: 'none' }} hasGraph={false}>
                  <div className={styles['left-title']}>
                    <div>vulnerabilities</div>
                  </div>
                  <div className={styles.count}>{reportData?.analysis.code?.filesAffected}</div>
                  <GraphLegend items={legendData} colors={graphColors} direction='row'
                    customClassName={codeAnalysisStyles.graphLegend}
                  />
                </SectionContentLeft>
              </SectionContentLeft>
              <SectionContentRight>
                <BarGraph data={typeCounts} colors={graphColors} margins={{
                  top: 50, right: 20, bottom: 40, left: 20
                }} />
              </SectionContentRight>
            </SectionContent >
            <SectionContentFooter className="px-3 py-3" style={{ border: 'none' }}>
              <span className={styles['sub-text']}>
                Last CVE Check scan{' '}
                {reportData?.analysis?.cveCheck?.lastCheck
                  ? format(
                    new Date(reportData.analysis.cveCheck.lastCheck),
                    'dd/MM/yy HH:mm'
                  )
                  : null}
              </span>
            </SectionContentFooter>
          </SectionLayout>
          <Container fluid style={{
            border: '1px solid var(--neutral300)',
            borderRadius: '2px'
          }}>
            <Row>
              <Col className="px-0" xs={12}>
                <div style={{ fontSize: 20, padding: '40px 40px 5px 40px' }}>Code Analysis</div>
                <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px', textTransform: 'uppercase' }}>files affected: {reportData?.analysis.code?.filesAffected}</div>
                <Table
                  tableInstance={tableInstance}
                  onClickRow={(row: any) => setSelectedRow(row.original || [])}
                  rowStyle={{ cursor: 'pointer' }}
                >
                  <Table.Footer>
                    <p className={tableStyles['table__paginationState']}>
                      {paginationMessage}
                    </p>
                    <div className={tableStyles['table__paginationButtons']}>
                      <button
                        className={
                          tableInstance?.canPreviousPage
                            ? tableStyles['table__paginationActiveButton']
                            : ''
                        }
                        onClick={() => tableInstance.previousPage()}
                      >
                        <RiArrowLeftSLine size={20} />
                      </button>
                      <button
                        className={
                          tableInstance?.canNextPage
                            ? tableStyles['table__paginationActiveButton']
                            : ''
                        }
                        onClick={() => tableInstance.nextPage()}
                      >
                        <RiArrowRightSLine size={20} />
                      </button>
                    </div>
                    <TablePageSize
                      maxResultChunkSize={maxResultChunkSize}
                      pageSize={tableInstance?.state?.pageSize || 10}
                      onPageSizeChange={onPageSizeChange}
                    />
                  </Table.Footer>
                </Table>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {selectedRow ? (
        <TableModal title={'Code Analysis'} onClose={() => setSelectedRow(null)}>
          <Table
            className="border-0 h-100"
            tableInstance={childrenTableInstance}
            wrapperStyle={{ flex: '1 1 100%', }}
          >
            <Table.Header style={{ height: 'auto', flex: 'auto' }}>
              <h6 className={codeAnalysisStyles['titleContainer']}>
                <span className={codeAnalysisStyles['codeAnalysis']}>
                  {'Code Analysis / '}
                </span>
                <span className={codeAnalysisStyles['fileName']}>
                  {renderChildrenTableHeader()}
                </span>
              </h6>
            </Table.Header>
          </Table>
        </TableModal>
      ) : null}
      {reportData && shouldShowModalDetail ? (
        <ModalReportLinuxDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData.reportDetails,
            submitted: format(
              new Date(`${reportData.startDate ?? new Date()}`),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: reportData.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}