import React, { CSSProperties } from 'react';
import styles from '../styles.module.scss';

type SectionContentRightProps = {
  data?: any;
  children: React.ReactNode;
  style?: CSSProperties;
  verticalMessage?: string;
};

const SectionContentRight = (props: SectionContentRightProps) => {
  const { verticalMessage, style = {} } = props;
  return (
    <div className={styles['section-content-right-container']} style={style}>
      {verticalMessage && (
        <div className={styles['left-label']}>
          <span>{verticalMessage}</span>
        </div>
      )}
      {props.children}
    </div>
  );
};

export default SectionContentRight;
