import GraphLegend from '@components/GraphLegend';
import { TabsHeader } from '@components/TabsHeader';
import tabsStyles from '@components/TabsHeader/styles.module.scss';
import CveCheckTable from '@pages/Report/Linux/ReportOverviewLinux/Tables/CveCheckTable';
import { ROUTES } from '@routes/routes';
import { getCssVariable } from '@utils/index';
import React, { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BsGithub } from 'react-icons/bs';
import { RiSpamLine } from 'react-icons/ri';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import SectionContent from '../Components/SectionContent';
import SectionContentLeft from '../Components/SectionContentLeft';
import SectionContentRight from '../Components/SectionContentRight';
import SectionContentRightBody from '../Components/SectionContentRightBody';
import SectionHeader from '../Components/SectionHeader';
import SectionLayout from '../Components/SectionLayout';
import BarGraph from '../Graphs/BarGraph';
import styles from '../styles.module.scss';

// type SubProperties = {
//   preview: [];
//   severity: number;
// };
// type CveCheckData = {
//   high: SubProperties;
//   low: SubProperties;
//   medium: SubProperties;
//   lastCheck: string;
// };
type CveCheckContainerProps = {
  data: any;
};

const CveCheckContainer = (props: CveCheckContainerProps) => {
  const { data } = props;

  const { reportId = '' }: { reportId: string } = useParams();
  const history = useHistory();
  const { t } = useTranslation(['common', 'pages']);

  const affectedFilesCount = useMemo(() => data?.severity?.critical + data?.severity?.high + data?.severity?.medium + data?.severity?.low, [data]);

  const allData = useMemo(() => [...data?.critical.preview, ...data?.high.preview, ...data?.medium.preview, ...data?.low.preview], [data]);

  const typeCounts = useMemo(() => [
    {
      'key': 'critical',
      'count': data?.critical.severity
    }, {
      'key': 'high',
      'count': data?.high.severity
    }, {
      'key': 'medium',
      'count': data?.medium.severity
    }, {
      'key': 'low',
      'count': data?.low.severity
    }], [data]);

  const legendData: any = useMemo(() => {
    const transformedObject: any = {};

    // Iterate through the array and assign key-value pairs to the transformed object
    typeCounts.forEach(item => {
      transformedObject[item.key] = item.count;
    });

    return transformedObject;

  }, [typeCounts]);

  return (
    <SectionLayout>
      <SectionHeader
        title={t('pages:overviewPage.sections.cveCheck.title')}
        leadingIcon={RiSpamLine}
        tooltipMessage={t(
          'pages:overviewPage.sections.cveCheck.tooltipMessage'
        )}
      // additionalInfo="Powered by Kepler"
      >
        {/* <button className={`${styles['button-with-icon']}`}>
          <div>
            <BsGithub />
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
            href="https://github.com/Exein-io/kepler"
          >
            KEPLER
          </a>
        </button> */}
      </SectionHeader>
      <SectionContent>
        <SectionContentLeft>
          <div className={styles['left-title']}>
            <div>Cve check vulnerabilities</div>
            <Button
              onClick={() =>
                history.push(`${ROUTES.BASE}/${reportId}${ROUTES.CVE_CHECK}`)
              }
              // @ts-ignore
              className={styles['button']}
            >
              VIEW ALL
            </Button>
          </div>
          <div className={styles.count}>{affectedFilesCount}</div>
          <GraphLegend items={legendData} colors={[getCssVariable('bs-purple'), '#F86767', '#D0D7DE', '#59CCD9']} />
          <div style={{ position: 'relative', height: '250px' }}>
            <BarGraph data={typeCounts} colors={[getCssVariable('bs-purple'), '#F86767', '#D0D7DE', '#59CCD9']} hasBackground={false} />
          </div>
        </SectionContentLeft>
        <SectionContentRight>
          <SectionContentRightBody
            className={styles['section-content-right-body']}
            style={{ height: 'auto' }}
          >
            <CveCheckTable data={allData} />
          </SectionContentRightBody>
        </SectionContentRight>
      </SectionContent>
    </SectionLayout>
  );
};

export default CveCheckContainer;
