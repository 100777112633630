import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { RiFileCodeFill, RiEditLine } from 'react-icons/ri';
import {
  REPORT_TYPE,
  REPORT_LINUX_SUB_TYPE,
  REPORT_CONTAINER_SUB_TYPE,
  REPORT_UEFI_SUB_TYPE,
  REPORT_RTOS_SUB_TYPE,
} from '@utils/types/report';

import Input from '@components/Input';
import Select from '@components/Select';
import Button, { BUTTON_TYPE } from '@components/Button';

import styles from './styles.module.scss';

type Props = {
  fileName: string;
  fileSize?: number;
  onChangeFile: (file: any) => void;
  onSubmit: (formData: { type: string; subtype: string; name: string }) => void;
};

export default function SubmitReport({
  fileName,
  fileSize = 0,
  onChangeFile,
  onSubmit,
}: Props) {
  const { t } = useTranslation(['scanModal', 'common']);

  const formatSize = (bytes: number): string => {
    const k = 1024;
    return `${parseFloat((bytes / Math.pow(k, 2)).toFixed(1))}`;
  };
  const formatName = (name: string): string => {
    return name.split('.')[0].replace('_', ' ');
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      type: '',
      name: formatName(fileName),
    },
  });

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted: (files: any[]) => {
      onChangeFile(files[0]);
    },
    multiple: false,
    noDrag: true,
    noClick: true,
  });

  const { ref: typeRef, ...typeProps } = register('type', {
    required: t('scanModal:content.reportDetails.typeError'),
  });
  const { ref: nameRef, ...nameProps } = register('name', {
    required: t('scanModal:content.reportDetails.nameError'),
  });

  const reportTypeOptions = [
    ...Object.values(REPORT_LINUX_SUB_TYPE).map((value) => {
      return {
        id: `${REPORT_TYPE.LINUX}:${value}`,
        label: `${t(`common:reportTypes.${REPORT_TYPE.LINUX}`)} - ${t(
          `common:reportTypes.${value as REPORT_LINUX_SUB_TYPE}`
        )}`,
      };
    }),
    ...Object.values(REPORT_CONTAINER_SUB_TYPE).map((value) => {
      return {
        id: `${REPORT_TYPE.CONTAINER}:${value}`,
        label: `${t(`common:reportTypes.${REPORT_TYPE.CONTAINER}`)} - ${t(
          `common:reportTypes.${value as REPORT_CONTAINER_SUB_TYPE}`
        )}`,
      };
    }),
    ...Object.values(REPORT_RTOS_SUB_TYPE).map((value) => {
      return {
        id: `${REPORT_TYPE.VXWORKS}:${value}`,
        label: `${t(`common:reportTypes.${REPORT_TYPE.VXWORKS}`)} - ${t(
          `common:reportTypes.${value as REPORT_RTOS_SUB_TYPE}`
        )}`, // (only available on the Enterprise plan)
        disabled: false
      };
    }),
    ...Object.values(REPORT_UEFI_SUB_TYPE).map((value) => {
      return {
        id: `${REPORT_TYPE.UEFI}:${value}`,
        label: `${t(`common:reportTypes.${REPORT_TYPE.UEFI}`)} - ${t(
          `common:reportTypes.${value as REPORT_UEFI_SUB_TYPE}`
        )}`, // (only available on the Enterprise plan)
        disabled: false
      };
    }),
  ];

  const prepareFormData = (formData: { type: string; name: string }): void => {
    const [type, subtype] = formData.type.split(':');
    onSubmit({ type, subtype, name: formData.name });
  };

  return (
    <div className={styles.container}>
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />

        <div className={styles.fileicon}>
          <RiFileCodeFill size={24} />
        </div>
        <div className={styles.fileinfo}>
          <h3>{fileName}</h3>
          <p>
            {t('scanModal:content.reportDetails.filesize', {
              size: formatSize(fileSize),
              bytes: fileSize,
            })}
          </p>
        </div>
        <button className={styles.edit} onClick={open}>
          <RiEditLine size={24} />
        </button>
      </div>
      <form onSubmit={handleSubmit(prepareFormData)}>
        <Select
          label={t('scanModal:content.reportDetails.typeLabel')}
          placeholder={t('scanModal:content.reportDetails.typePlaceholder')}
          inputRef={typeRef}
          error={errors?.type?.message}
          options={reportTypeOptions}
          compact
          {...typeProps}
        />
        <Input
          label={t('scanModal:content.reportDetails.nameLabel')}
          type="text"
          placeholder={t('scanModal:content.reportDetails.namePlaceholder')}
          inputRef={nameRef}
          error={errors?.name?.message}
          compact
          {...nameProps}
        />
        <fieldset className={styles.footer}>
          <Button type={BUTTON_TYPE.GREEN}>
            {t('scanModal:content.reportDetails.scanButton')}
          </Button>
        </fieldset>
      </form>
    </div>
  );
}
