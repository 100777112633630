import React from 'react';

const NewScanContext = React.createContext(0);

interface IProps {
  children: React.ReactElement | React.ReactElement[];
}
const NewScanProvider = ({ children }: IProps) => {
  const [step, setStep] = React.useState<number>(0);
  const [modal, setModal] = React.useState<boolean>(true);

  function addStep(modal = true): void {
    setStep((cur) => cur + 1);
    setModal(modal);
  }
  function resetStep(): void {
    setStep(0);
  }

  return (
    <NewScanContext.Provider value={{ modal, step, addStep, resetStep } as any}>
      {children}
    </NewScanContext.Provider>
  );
};

export { NewScanProvider, NewScanContext };
