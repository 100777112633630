import animationData from '@assets/animations/sign-in.json';
import HeaderOnBoardingWrapper from '@components/HeaderOnBoardingWrapper';
import LayoutOnBoarding from '@components/LayoutOnBoarding';
import SignInForm from '@components/SignInForm';
import Canvas from '@pages/SignIn/animations';
import { getAllOrganizations, getUser, http } from '@services/api';
import { setTokenOnLocalstorage } from '@utils/index';
import { OrganizationType } from '@utils/types/organization';
import axios from 'axios';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/logo/Exein-logo.gif';
import styles from './styles.module.scss';

export default function SignIn() {
  const history = useHistory();
  const { t } = useTranslation(['forms', 'pages']);

  const [error, setError] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onSaveDefaultOrganizationToLocalStorage = async (
    organizations: OrganizationType[]
  ) => {
    const defaultOrg = organizations.find(
      (org: OrganizationType) => org.built_in
    );
    if (defaultOrg) {
      await localStorage.setItem('organizationUID', defaultOrg.id);
    }
  };

  const onSignIn = async (email: string, password: string) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/login`,
        {
          email,
          password,
        },
        { withCredentials: true }
      );
      const userData: any = await getUser();
      setTokenOnLocalstorage(userData.id);
      const organizations = await getAllOrganizations();
      await onSaveDefaultOrganizationToLocalStorage(organizations);

      history.push('/');
    } catch (err) {
      console.log(err, 'error');
      if (err instanceof Error) {
        setError(err?.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  function onSubmitForm({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): void {
    setIsLoading(true);
    onSignIn(email, password);
  }

  React.useEffect(() => {
    new Canvas(document.getElementById('canvas'), '#d4d4d4');
  }, []);

  return (
    <React.Fragment>
      <div className={'sign-container'}>
        <LayoutOnBoarding animationData={animationData}>
          <Row className="px-5">
            <Col className="px-5 mb-5">
              <img src={logo} alt="exein_logo" style={{ width: '200px' }} />
            </Col>
            <Col xs={12} className="px-5">
              <HeaderOnBoardingWrapper
                title={t('pages:signIn.title')}
                description={t('pages:signIn.subtitle')}
              />
              <SignInForm onSubmit={onSubmitForm} isLoading={isLoading} />
              {error.length ? (
                <p className={styles.errorMessage}>{error}</p>
              ) : null}
            </Col>
            {/* TODO: will be returent in the future: */}
            {/* <Col xs={12} className="px-5">
              <Row className="pt-4">
                <Col xs={8}>
                  <p className={styles.texts}>
                    {t('forms:notRegistered')}{' '}
                    <Link to={'/sign-up'}>
                      <span style={{ color: '#41DE21' }}>{t('forms:signUp')}</span>
                    </Link>
                  </p>
                </Col>
                <Col xs={4} className="d-flex justify-content-end">
                  <p className={styles.texts}>
                    <Link to={ROUTES.RESET_PASSWORD}>
                      <span style={{ color: '#41DE21' }}>{t('forms:forgotPassword')}</span>
                    </Link>
                  </p>
                </Col>
              </Row>
            </Col> */}
          </Row>
        </LayoutOnBoarding>
      </div>
      <canvas id={'canvas'}></canvas>
    </React.Fragment>
  );
}
