import { useQuery, useQueryClient } from 'react-query';
import { getReportFromApi } from '@services/api';
import type { Report } from '@utils/types/report';
import { KEY as REPORTS_KEY } from './useReports';
export const KEY = 'report';

export default function useReport(reportId: string) {
  const queryClient = useQueryClient();

  return useQuery<Report, Error>(
    [KEY, reportId],
    () => getReportFromApi(reportId),
    {
      retry: false,
      initialData: () =>
        queryClient
          .getQueryData<Report[]>(REPORTS_KEY)
          ?.find((report: Report) => report.id === reportId),
    }
  );
}
