import { getGraphTheme } from '@components/Graph/theme';
import { GlobalContext } from '@context/GlobalContext';
import { ResponsiveChord } from '@nivo/chord';
import Utils from '@pages/Report/Linux/ReportOverviewLinux/Utils/Utils';
import { getCssVariable } from '@utils/index';
import React from 'react';
import { useTranslation } from 'react-i18next';

type CodeAnalysisGraphProps = {
  data?: any;
};

const getUniqueLabels = (data: any): any => {
  if (data?.analysis && Object.keys(data.analysis).length) {
    return Object.keys(data?.analysis);
  }
  return [];
};

const buildData = (data: any): any => {
  const labels = [...getUniqueLabels(data)];
  const flawsOccourrences = [...(data.flawsOccourrences || [])]; //vidi za izbrisem ovo ako ne bude valjalo
  const result = [] as any;
  labels.forEach((_) => {
    result.push(new Array(labels.length).fill(0));
  });

  if (flawsOccourrences?.length) {
    for (const f of flawsOccourrences) {
      let sourceItem = '';
      let destItem = '';
      let sourceItemIndex = NaN;
      let destItemIndex = NaN;
      if (f?.flaws?.length === 2) {
        sourceItem = f.flaws[0];
        destItem = f.flaws[1];
        sourceItemIndex = labels.indexOf(sourceItem);
        destItemIndex = labels.indexOf(destItem);
        if (sourceItemIndex > -1 && destItemIndex > -1) {
          result[sourceItemIndex][destItemIndex] =
            result[sourceItemIndex][destItemIndex] + f.occourrence;
          result[destItemIndex][sourceItemIndex] =
            result[destItemIndex][sourceItemIndex] + f.occourrence;
        }
      }
    }
  }
  return result;
};

const CodeAnalysisGraph = ({ data }: CodeAnalysisGraphProps) => {
  const { t } = useTranslation('common');

  const { theme } = React.useContext(GlobalContext);
  const graphTheme = getGraphTheme(theme);
  if (!data || data.length === 0) return <p>{t('noData')}</p>;
  const transformedData = buildData(data);
  if (!transformedData || transformedData.length === 0)
    return <p>{t('noData')}</p>;
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ResponsiveChord
        theme={graphTheme}
        data={transformedData}
        keys={getUniqueLabels(data) || []}
        margin={{ top: 50, right: 50, bottom: 100, left: 50 }}
        enableLabel={false}
        // valueFormat='.2f'
        padAngle={0.02}
        innerRadiusRatio={0.96}
        innerRadiusOffset={0.02}
        inactiveArcOpacity={0.25}
        colors={({ id }) =>
          getCssVariable(Utils.getCodeAnalysisChipColorByLabel(id))
        }
        arcBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.6]],
        }}
        activeRibbonOpacity={0.75}
        inactiveRibbonOpacity={0.25}
        ribbonBorderColor={{
          from: 'color',
          modifiers: [['darker', 0.6]],
        }}
        labelRotation={-90}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1]],
        }}
        motionConfig="stiff"
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: true,
            translateX: 0,
            translateY: 60,
            itemWidth: 80,
            itemHeight: 25,
            itemsSpacing: -30,
            itemTextColor: '#999',
            itemDirection: 'top-to-bottom',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default CodeAnalysisGraph;
