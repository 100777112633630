import React, { CSSProperties, ReactNode } from 'react';
import { getCssVariable } from '@utils/index';

type InputRightProps = {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
}
const InputRight = ({ className = '', style = {}, children }: InputRightProps) => {
  return (
    <span className={className} style={{
      position: 'absolute', right: '7px',
      top: '3px',
      fontSize: '22px',
      color: getCssVariable('neutral300'), ...style
    }}>{children}</span>
  );
};

export default InputRight;