import React from 'react';
import { RiCheckFill, RiCloseFill } from 'react-icons/ri';

type CellTableIconProps = {
  value: boolean;
};

const CellTableIcon = (props: CellTableIconProps) => {
  const { value } = props;
  return (
    <>
      {value ? (
        <RiCheckFill
          style={{ color: '#D0D7DE', fontSize: '1.5rem' }}
        />
      ) : (
        <RiCloseFill style={{ color: '#E60000', fontSize: '1.5rem' }} />
      )}
    </>
  );
};

export default CellTableIcon;
