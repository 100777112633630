import GraphLegend from '@components/GraphLegend';
import useCodeAnalysis from '@hooks/analysis/useCodeAnalysis';
import { ROUTES } from '@routes/routes';
import { differenceByGivenDate, getCssVariable } from '@utils/index';
import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiDoorLockBoxLine } from 'react-icons/ri';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import SectionContent from '../Components/SectionContent';
import SectionContentLeft from '../Components/SectionContentLeft';
import SectionContentRight from '../Components/SectionContentRight';
import SectionContentRightBody from '../Components/SectionContentRightBody';
import SectionHeader from '../Components/SectionHeader';
import SectionLayout from '../Components/SectionLayout';
import BarGraph from '../Graphs/BarGraph';
import CodeAnalysisTable from '../Tables/CodeAnalysisTable';
import Utils from '../Utils/Utils';
import styles from '../styles.module.scss';

type CodeAnalysisContainerProps = {
  data: any;
};

const CodeAnalysisContainer = (props: CodeAnalysisContainerProps) => {
  const { data } = props;
  const { reportId = '' }: { reportId: string } = useParams();

  const {
    data: dataCodeAnalysis
    // isLoading: isLoadingCodeAnalysis,
    // isError: isErrorCodeAnalysis,
    // error: errorCodeAnalysis
  } = useCodeAnalysis(reportId);

  const history = useHistory();
  const { t } = useTranslation(['common', 'pages']);

  const calculateTypeCounts = (array: any) => {
    const typeCounts = {};

    // Iterate through the array and count occurrences of each type
    array?.forEach((item: any) => {
      item.types.forEach((type: any) => {
        //@ts-ignore
        if (typeCounts[type]) {
          //@ts-ignore
          typeCounts[type]++;
        } else {
          //@ts-ignore
          typeCounts[type] = 1;
        }
      });
    });

    // Convert typeCounts object into the desired output format
    const result = [];
    for (const type in typeCounts) {
      // @ts-ignore
      result.push({ key: type, count: typeCounts[type] });
    }

    return result;
  };


  const cveCheckLastScan = differenceByGivenDate(
    new Date(data?.analysis?.cveCheck?.lastCheck ?? '')
  );

  const typeCounts = useMemo(() => calculateTypeCounts(dataCodeAnalysis), [dataCodeAnalysis]);
  const graphColors = useMemo(() => typeCounts.map((type) => getCssVariable(Utils.getCodeAnalysisChipColorByLabel(type.key))), [typeCounts]);
  const legendData: any = useMemo(() => {
    const transformedObject: any = {};

    // Iterate through the array and assign key-value pairs to the transformed object
    typeCounts.forEach(item => {
      transformedObject[item.key] = item.count;
    });

    return transformedObject;

  }, [typeCounts]);

  return (
    <SectionLayout>
      <SectionHeader
        title={t('pages:overviewPage.sections.codeAnalysis.title')}
        leadingIcon={RiDoorLockBoxLine}
        tooltipMessage={t(
          'pages:overviewPage.sections.codeAnalysis.tooltipMessage'
        )}
      >
        <p>
          {cveCheckLastScan?.value
            ? t('common:generic.lastScan') +
            ' ' +
            cveCheckLastScan?.value +
            ' ' +
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            t(`common:${cveCheckLastScan.type as string}`) +
            ' ' +
            t('common:generic.ago')
            : ''}
        </p>
      </SectionHeader>
      <SectionContent>
        <SectionContentLeft verticalMessage={'Code Analysis'}>
          <div className={styles['left-title']}>
            <div>vulnerabilities</div>
            <Button
              onClick={() =>
                history.push(`${ROUTES.BASE}/${reportId}${ROUTES.CODE_ANALYSIS}`)
              }
              // @ts-ignore
              className={styles['button']}
            >
              VIEW ALL
            </Button>
          </div>
          <div className={styles.count}>{data?.filesAffected}</div>
          <GraphLegend items={legendData} colors={graphColors} />
          <div style={{ position: 'relative', height: '250px' }}>
            <BarGraph data={typeCounts} colors={graphColors} hasBackground={false} />
          </div>
        </SectionContentLeft>
        <SectionContentRight>
          <SectionContentRightBody
            className={styles['section-content-right-body']}
          >
            <CodeAnalysisTable />
          </SectionContentRightBody>
        </SectionContentRight>
      </SectionContent>
    </SectionLayout>
  );
};

export default CodeAnalysisContainer;
