import { useQuery, useQueryClient } from 'react-query';
import { getAllOrganizations, getOrganization } from '@services/api';
import useToken from './useToken';
import {
  OrganizationFullType,
  OrganizationType,
  returnTypeAllOrganizations,
} from '@utils/types/organization';
import React from 'react';

export const KEY = 'organizations';

export function useOrganizations(onSuccess?: any) {
  useToken();
  const opts = {
    refetchOnWindowFocus: false,
  };

  if (onSuccess) {
    Object.assign(opts, {
      onSuccess: onSuccess,
    });
  }

  return useQuery<OrganizationType[], Error>(
    KEY,
    () => getAllOrganizations(),
    opts
  );
}

export function useSingleOrganization(organizationId: string, onSuccess?: any) {
  useToken();
  const opts = {
    refetchOnWindowFocus: false,
  };

  if (onSuccess) {
    Object.assign(opts, {
      onSuccess: onSuccess,
    });
  }

  return useQuery<any, Error>(
    `${KEY}/${organizationId}`,
    () => getOrganization(organizationId),
    opts
  );
}

export function useFullOrganizations(): returnTypeAllOrganizations {
  const queryClient = useQueryClient();
  const [allOrganizations, setAllOrganization] = React.useState<
    OrganizationFullType[]
  >([]);
  const [isLoading, setIsLoading] = React.useState(true);
  useOrganizations(
    async (data: OrganizationType[]) => {
      for (const organization of data) {
        try {
          const dataSingle = await getOrganization(organization.id);
          setAllOrganization((prev) => [...prev, dataSingle]);

          await queryClient.setQueryData(
            `${KEY}/${organization.id}`,
            dataSingle
          );
        } catch (error) {
          console.log(error);
        }
      }
      setIsLoading(false);
    }
  );
  return {
    data: isLoading
      ? []
      : allOrganizations,
    isLoading: isLoading,
  };
}
