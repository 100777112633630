import * as React from 'react';
// import styles from './styles.module.scss';
import type { IconType } from 'react-icons';
// import { NavLink } from 'react-router-dom';
import Spinner, { SPINNER_TYPE, SPINNER_SIZE } from '@components/Spinner';
import { useRouteMatch } from 'react-router-dom';
import { useMouse } from 'beautiful-react-hooks';
import { GlobalContext } from '@contexts/GlobalContext';
import { Nav } from 'react-bootstrap';
//import { ROUTES } from '@routes/routes';
import { useHistory } from 'react-router';
import { getCssVariable } from '@utils/index';

type IProps = {
  leadingIcon?: IconType;
  trailingIcon?: IconType | null;
  activeIcon?: IconType;
  path: string;
  title: string;
  isPending?: boolean;
  activeIconColor?: string;
  defaultIconColor?: string;
  hoverIconColor?: string;
  onClick?: () => void;
  navStep?: number;
};

export default function NavigationLink({
  activeIcon,
  leadingIcon,
  trailingIcon,
  onClick,
  title,
  path,
  isPending,
  activeIconColor = 'neutral700',
  defaultIconColor = 'neutral300',
  hoverIconColor = 'neutral700',
}: IProps) {
  const match = useRouteMatch(path);
  const ref = React.useRef<any>();
  const TrailingIcon = trailingIcon ? trailingIcon : null;
  const LeadingIcon = leadingIcon ? leadingIcon : null;
  const ActiveIcon = activeIcon ? activeIcon : null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { onMouseEnter, onMouseLeave }] = useMouse(ref);
  const [isHover, setHover] = React.useState(false);
  onMouseEnter(() => setHover(true));
  onMouseLeave(() => setHover(false));

  const { theme } = React.useContext(GlobalContext);

  React.useEffect(() => {
    setHover(false);
  }, [theme]);
  const history = useHistory();

  return (
    <Nav.Item as={'li'}>
      <Nav.Link
        ref={ref}
        eventKey={`${path}`}
        onClick={() => onClick ? onClick() : history.push(`${path}`)}>
        {LeadingIcon && !match && (
          <LeadingIcon
            color={getCssVariable(
              isHover ? hoverIconColor : defaultIconColor
            )}
            className={'icon-item'}
          />
        )}
        {ActiveIcon && match && (
          <ActiveIcon
            color={getCssVariable(
              match ? 'secondary' : isHover ? hoverIconColor : defaultIconColor
            )}
            className={'icon-item'}
          />
        )}
        <p>
          {title}

          {isPending && (
            <Spinner
              type={SPINNER_TYPE.BLACK}
              size={SPINNER_SIZE.DEFAULT}
              className={'icon-item float-end'}
            />
          )}
          {!isPending && TrailingIcon && (
            // <div className={styles.trailingIcon}>
            <TrailingIcon
              // color={getCssVariable(
              //   match ? 'secondary' : isHover ? hoverIconColor : defaultIconColor
              // )}
              className={'icon-item float-end'}
            />
            // </div>
          )}
        </p>
      </Nav.Link>
    </Nav.Item>
    // <NavLink
    //   exact
    //   to={path}
    //   activeClassName={styles.active}
    //   className={styles.linkItem}
    //   onClick={onClick}
    //   ref={ref}
    // >
    //   <div className={styles.wrap}>
    //     {LeadingIcon && !match && (
    //       <div className={styles.leadingIcon}>
    //         <LeadingIcon
    //           size={24}
    //           color={getCssVariable(
    //             isHover ? hoverIconColor : defaultIconColor
    //           )}
    //         />
    //       </div>
    //     )}
    //     {ActiveIcon && match && (
    //       <div className={styles.leadingIcon}>
    //         <ActiveIcon size={24} color={getCssVariable(activeIconColor)} />
    //       </div>
    //     )}
    //     <p>{title}</p>
    //   </div>
    //   {isPending && (
    //     <Spinner type={SPINNER_TYPE.BLACK} size={SPINNER_SIZE.DEFAULT} />
    //   )}
    //   {!isPending && TrailingIcon && (
    //     <div className={styles.trailingIcon}>
    //       <TrailingIcon
    //         size={20}
    //         color={getCssVariable(
    //           match ? 'secondary' : isHover ? hoverIconColor : defaultIconColor
    //         )}
    //       />
    //     </div>
    //   )}
    // </NavLink>
  );
}
