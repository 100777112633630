import Button from '@components/Button';
import { ROUTES } from '@routes/routes';
import React, { CSSProperties } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styles from '../styles.module.scss';

type SectionContentLeftProps = {
  children?: React.ReactNode;
  className?: string;
  verticalMessage?: string;
  verticalMessageColor?: string;
  style?: CSSProperties;
  hasGraph?: boolean;
};

const SectionContentLeft = ({
  children,
  className = '',
  style = {},
  hasGraph
}: SectionContentLeftProps) => {
  const history = useHistory();
  const { reportId = '' }: { reportId: string } = useParams();

  return (
    <div className={`${styles['left-content-box']} ${className}`} style={style}>
      {/* Cve check vulnerabilities
      <Button
        onClick={() =>
          history.push(`${ROUTES.BASE}/${reportId}${ROUTES.CVE_CHECK}`)
        }
        // @ts-ignore
        className={styles['button']}
      >
        VIEW ALL
      </Button> */}
      {/* {verticalMessage && (
        <div className={styles['left-label']}>
          <span style={{ color: verticalMessageColor }}>{verticalMessage}</span>
        </div>
      
      )} */}

      <div className={`${styles['box']} ${hasGraph && styles['graph-box']}`}>{children}</div>
    </div>
  );
};

export default SectionContentLeft;
