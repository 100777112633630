import { ROUTES } from '@routes/routes';
import * as React from 'react';
import { useHistory } from 'react-router';
// import LayoutWrapper from '@components/LayoutWrapper';
// import Header from '@components/Header';
// import Sidebar from '@components/Sidebar';
// import useReports from '@hooks/useReports';
// import { removeTokenOnLocalstorage } from '@utils/index';
// import ReportList from '@components/ReportList';
// import { NewScanContext } from '@contexts/NewScanContext';
// import Button, { BUTTON_TYPE } from '@components/Button';
// import Navigation from '@components/Navigation';
// import NavigationLink from '@components/NavigationLink';
// import NavigationReportDynamic from '@components/NavigationReportDynamic';
import { useTranslation } from 'react-i18next';
// import styles from './styles.module.scss';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// import ReportPages from '@pages/Report';
// import illustration from '@assets/img/static_illustrations/reports_home.svg';
// import illustrationDm from '@assets/img/static_illustrations/reports_home_dm.svg';
import { ReportActionsProvider } from '@contexts/ReportActionsContext';
import {
  // RiFileChartLine,
  // RiStarFill,
  // RiStarLine,
  // RiArchiveDrawerFill,
  // RiArchiveDrawerLine,
  // RiSettingsFill,
  // RiSettingsLine,
  // RiAddFill,
  RiArchiveDrawerLine,
  RiFileChartFill,
} from 'react-icons/ri';
// import { REPORT_STATUS } from '@utils/types/report';
// import EmptyResults from '@components/EmptyResults';
// import SkeletonNavigation from '@components/SkeletonNavigation';
// import SkeletonReportList from '@components/SkeletonReportList';
import NotificationPage from '@pages/Notification';
// import { GlobalContext } from '@contexts/GlobalContext';
import BSReportList from '@components/BSReportList';
import { Badge, Breadcrumb, Nav } from 'react-bootstrap';
import { AiOutlineStar } from 'react-icons/all';
// import { useState } from 'react';
import ReportPages from '@pages/Report';
// import Footer from '@components/Footer';

// import SkeletonNavigation from '@components/SkeletonNavigation';

import { ModalPendingOrganizationInvite } from '@components/ModalPendingOrganizationInvite';
import useReports from '@hooks/useReports';
import useUser from '@hooks/useUser';
import Main from '@pages/Main';
import UserSettings from '@pages/UserSettings';
import { getAllOrganizations } from '@services/api';
import { setTokenOnLocalstorage } from '@utils/index';
import { OrganizationType } from '@utils/types/organization';
import { Report } from '@utils/types/report';
import { useEffect, useState } from 'react';
// import SideNav from '@pages/UserSettings/Sidenav';

// import { BsFileEarmark, FaFileInvoice, MdPayment } from 'react-icons/all';
// import BSidebarWrapper from '@components/Sidebar/BSidebarWrapper';
// import LayoutWrapper from '@components/LayoutWrapper';
// import Sidebar from '@components/Sidebar';
// import NavigationReportDynamic from '@components/NavigationReportDynamic';
// import Sidenav from '@pages/UserSettings/Sidenav';

export default function Dashboard() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const { data: user } = useUser();
  const { t } = useTranslation(['common', 'emptyReports']);
  const basePath = path + ROUTES.REPORT_ID;
  const notificationPath = path + ROUTES.NOTIFICATIONS;
  const favouritesPath = path + ROUTES.FAVOURITES;
  const archivedPath = path + ROUTES.ARCHIVED;
  const match = useRouteMatch(`${ROUTES.BASE}`);
  const isReportF = useRouteMatch(`${ROUTES.BASE}${ROUTES.FAVOURITES}`);
  const isReportA = useRouteMatch(`${ROUTES.BASE}${ROUTES.ARCHIVED}`);
  const [data, setData] = useState<Report[]>([]);

  function getDefaultActiveKey(): string {
    if (match?.isExact) return 'all';

    if (isReportF?.isExact) return 'favourites';

    if (isReportA?.isExact) return 'archived';

    return '';
  }

  const onSaveDefaultOrganizationToLocalStorage = async (
    organizations: OrganizationType[]
  ) => {
    const defaultOrg = organizations.find(
      (org: OrganizationType) => org.built_in
    );
    if (defaultOrg) {
      await localStorage.setItem('organizationUID', defaultOrg.id);
    }
  };

  React.useEffect(() => {
    const onFetchOrganizations = async (user: any) => {
      const token = user.id;
      setTokenOnLocalstorage(token);
      const organizations = await getAllOrganizations();
      await onSaveDefaultOrganizationToLocalStorage(organizations);
    };

    if (user && !!user.email) {
      onFetchOrganizations(user);
    }
  }, [user]);

  function getBreadCrumbItem() {
    if (match?.isExact)
      return (
        <Breadcrumb.Item active>
          {t('common:navigation.primaryNavigation.allReports')}{' '}
          <RiFileChartFill />
        </Breadcrumb.Item>
      );

    if (isReportF?.isExact)
      return (
        <Breadcrumb.Item active>
          {t('common:navigation.primaryNavigation.favourites')}{' '}
          <AiOutlineStar />
        </Breadcrumb.Item>
      );

    if (isReportA?.isExact)
      return (
        <Breadcrumb.Item active>
          {t('common:navigation.primaryNavigation.archived')}{' '}
          <RiArchiveDrawerLine />
        </Breadcrumb.Item>
      );

    return '';
  }

  function onSuccess(data: Report[]) {
    setData(data);
  }

  function updateReport(id: string, params: any) {
    const reports: Report[] = [];
    for (const report of data) {
      if (report.id === id) {
        Object.assign(report, { ...params });
      }

      reports.push(report);
    }

    setData(reports);
  }

  const {
    data: theData,
    isLoading,
    isSuccess,
    isFetching,
  } = useReports(onSuccess);

  useEffect(() => {
    if (theData) {
      setData(theData);
    }
  }, [theData]);

  const [openInvite, setOpenInvite] = React.useState<boolean>(false);
  const isVerified = !!user?.email || false;

  React.useEffect(() => {
    const invitationToken = localStorage.getItem('organizationInvitationToken');
    if (invitationToken) setOpenInvite(true);
  }, []);

  const activeItem = getDefaultActiveKey();
  const allReports = data?.filter((item) => !item.archived);
  const favourites = data?.filter((item) => item.favourite && !item.archived);
  const archived = data?.filter((item) => item.archived);

  return (
    <div className={'main-container'}>
      <ReportActionsProvider>
        <Main>
          {activeItem !== '' && !isLoading && isSuccess && data ? (
            <div className={'submenu-container'}>
              <Breadcrumb className={'pt-3 pb-2'}>
                <Breadcrumb.Item href={path}>Cosmo</Breadcrumb.Item>
                {getBreadCrumbItem()}
              </Breadcrumb>
              <Nav
                className={'nav-cosmo'}
                variant="tabs"
                activeKey={activeItem}
                defaultActiveKey={'all'}
              >
                <Nav.Item>
                  <Nav.Link eventKey={'all'} onClick={() => history.push('/')}>
                    <RiFileChartFill />{' '}
                    {t('common:navigation.primaryNavigation.allReports')}{' '}
                    <Badge pill bg="secondary">
                      {allReports?.length}
                    </Badge>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={'favourites'}
                    onClick={() => history.push(favouritesPath)}
                  >
                    <AiOutlineStar />{' '}
                    {t('common:navigation.primaryNavigation.favourites')}{' '}
                    <Badge pill bg="secondary">
                      {favourites?.length}
                    </Badge>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey={'archived'}
                    onClick={() => history.push(archivedPath)}
                  >
                    <RiArchiveDrawerLine />{' '}
                    {t('common:navigation.primaryNavigation.archived')}{' '}
                    <Badge pill bg="secondary">
                      {archived?.length}
                    </Badge>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          ) : null}
          <Switch>
            <Route exact path={path}>
              <BSReportList
                reportType={'all'}
                reports={allReports}
                isLoading={isLoading}
                isFetching={isFetching}
                isSuccess={isSuccess}
                updateReport={updateReport}
              />
            </Route>
            <Route path={`${path}${ROUTES.USER_SETTINGS}`}>
              <UserSettings />
            </Route>
            <Route exact path={favouritesPath}>
              <BSReportList
                reportType={'favourites'}
                reports={favourites}
                isSuccess={isSuccess}
                isFetching={isFetching}
                isLoading={isLoading}
                updateReport={updateReport}
              />
            </Route>
            <Route exact path={archivedPath}>
              <BSReportList
                reportType={'archived'}
                reports={archived}
                isSuccess={isSuccess}
                isFetching={isFetching}
                isLoading={isLoading}
                updateReport={updateReport}
              />
            </Route>
            <Route path={notificationPath}>
              <NotificationPage />
            </Route>
            <Route path={basePath}>
              <ReportPages path={path} />
              {/*<LayoutWrapper*/}
              {/*  sidebar={*/}
              {/*    <Sidenav>*/}
              {/*      <NavigationReportDynamic path={path} />*/}
              {/*    </Sidenav>*/}
              {/*  }*/}
              {/*  content={*/}
              {/*    */}
              {/*  }*/}
              {/*/>*/}
            </Route>
          </Switch>

          {/*<LayoutWrapper*/}
          {/*  sidebar={*/}
          {/*    <Switch>*/}
          {/*      <Sidebar>*/}
          {/*        <Route exact path={`(${path}|${notificationPath})`}>*/}
          {/*          <div className={styles.navigationButton}>*/}
          {/*            <Button*/}
          {/*              type={BUTTON_TYPE.LIGHT_BORDER}*/}
          {/*              onClick={addStep}*/}
          {/*              leadingIcon={RiAddFill}*/}
          {/*              style={{ paddingLeft: '12px' }}*/}
          {/*            >*/}
          {/*              {t('common:buttonLabels.newScan')}*/}
          {/*            </Button>*/}
          {/*          </div>*/}
          {/*          <Navigation>*/}
          {/*            <NavigationLink*/}
          {/*              title={t(*/}
          {/*                'common:navigation.primaryNavigation.allReports'*/}
          {/*              )}*/}
          {/*              activeIcon={RiFileChartFill}*/}
          {/*              leadingIcon={RiFileChartLine}*/}
          {/*              path={ROUTES.BASE}*/}
          {/*              isPending={oneReportIsPending > 0}*/}
          {/*            />*/}
          {/*            <NavigationLink*/}
          {/*          title={t('navigation.primaryNavigation.favourites')}*/}
          {/*          activeIcon={RiStarFill}*/}
          {/*          leadingIcon={RiStarLine}*/}
          {/*          path={ROUTES.FAVOURITES}*/}
          {/*        />*/}
          {/*        <NavigationLink*/}
          {/*          title={t('navigation.primaryNavigation.archived')}*/}
          {/*          activeIcon={RiArchiveDrawerFill}*/}
          {/*          leadingIcon={RiArchiveDrawerLine}*/}
          {/*          path={ROUTES.ARCHIVED}*/}
          {/*        />*/}
          {/*        <NavigationLink*/}
          {/*          title={t('navigation.primaryNavigation.codeAnalysis')}*/}
          {/*          activeIcon={RiSettingsFill}*/}
          {/*          leadingIcon={RiSettingsLine}*/}
          {/*          path={ROUTES.CODE_ANALYSIS}*/}
          {/*        /> */}
          {/*          </Navigation>*/}

          {/*          <Navigation*/}
          {/*        title={t('navigation.primaryNavigation.folders')}*/}
          {/*        trailingIcon={RiAddFill}*/}
          {/*      ></Navigation> */}
          {/*        </Route>*/}
          {/*        <Route path={basePath}>*/}
          {/*          {isLoading ? (*/}
          {/*            <SkeletonNavigation />*/}
          {/*          ) : (*/}
          {/*            <NavigationReportDynamic path={path} />*/}
          {/*          )}*/}
          {/*        </Route>*/}
          {/*      </Sidebar>*/}
          {/*    </Switch>*/}
          {/*  }*/}
          {/*  content={*/}
          {/*    <>*/}
          {/*      <Switch>*/}
          {/*        <Route exact path={path}>*/}
          {/*          {isLoading && !isSuccess ? (*/}
          {/*            <SkeletonReportList />*/}
          {/*          ) : !data || data?.length === 0 ? (*/}
          {/*            <div className={styles.emptyState}>*/}
          {/*              <EmptyResults*/}
          {/*                image={theme === 'dark' ? illustrationDm : illustration}*/}
          {/*                title={t('emptyReports:dashboard.title')}*/}
          {/*                description={t('emptyReports:dashboard.subtitle')}*/}
          {/*                buttonLabel={t('emptyReports:dashboard.buttonLabel')}*/}
          {/*                onClick={addStep}*/}
          {/*              />*/}
          {/*            </div>*/}
          {/*          ) : (*/}
          {/*            // <ReportList list={data} />*/}
          {/*            <BSReportList list={data} key={tabKey} />*/}
          {/*          )}*/}
          {/*        </Route>*/}
          {/*        <Route path={notificationPath}>*/}
          {/*          <NotificationPage />*/}
          {/*        </Route>*/}
          {/*        <Route path={basePath}>*/}
          {/*          <ReportPages />*/}
          {/*        </Route>*/}
          {/*      </Switch>*/}
          {/*    </>*/}
          {/*  }*/}
          {/*/>*/}
        </Main>
        {isVerified && openInvite && (
          <ModalPendingOrganizationInvite
            onClose={() => setOpenInvite(false)}
          />
        )}
      </ReportActionsProvider>
    </div>
  );
}
