import { useQuery } from 'react-query';
import type { ReportUefiIntelBootGuardAnalysis } from '@utils/types/report';
import { getReportUefiIntelBootGuardAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'intel-boot-guard-analysis';

export default function useUefiIntelBootGuardAnalysis(id: string) {
  useToken();
  return useQuery<ReportUefiIntelBootGuardAnalysis, Error>([KEY, id], () =>
    getReportUefiIntelBootGuardAnalysis(id),
    {
      retry: false
    }
  );
}
