import AccountPlan from '@components/BSAccountPlan';
import Footer from '@components/Footer';
import { validateEmail } from '@components/Forms/FormUtils';
import TextInput from '@components/Forms/TextInput';
import useApiKey from '@hooks/useApiKey';
import Appearance from '@pages/UserSettings/Appearance';
import { ROUTES } from '@routes/routes';
import { removeTokenOnLocalstorage } from '@utils/index';
import React, { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { Field, Form as RForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import ApiToken from './ApiToken';

const MyAccount = () => {
  const [changeEmail, setChangeEmail] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const { t } = useTranslation(['common', 'userSettings']);
  const history = useHistory();
  const {
    data: apiKeyData,
    // isLoading: apiKey,
    // isError: iseErrorApiKey,
  } = useApiKey();

  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const deleteUserAccount = async () => {
    try {
      signOut();
    } catch (err) {
      if (err instanceof Error) {
        console.error('user', err.message);
      }
    }
  };

  const signOut = () => {
    removeTokenOnLocalstorage();
    history.push(ROUTES.SIGN_IN);
  };

  // const initialValues = {
  //   Name: user?.displayName,
  //   Email: user?.email,
  // };

  const validate = (values: any) => {
    const errors = {};
    if (!values.Name) {
      Object.assign(errors, { Name: t('common:formValidation.required') });
    }

    if (!values.Email) {
      Object.assign(errors, { Email: t('common:formValidation.required') });
    }

    if (values.Email && !validateEmail(values.Email)) {
      Object.assign(errors, { Email: t('common:formValidation.invalidEmail') });
    }

    if (changeEmail) {
      if (!values.CurrentPassword) {
        Object.assign(errors, {
          CurrentPassword: t('common:formValidation.required'),
        });
      }
    }

    if (changePassword) {
      if (!values.Password) {
        Object.assign(errors, {
          Password: t('common:formValidation.required'),
        });
      }

      if (!values.NewPassword) {
        Object.assign(errors, {
          NewPassword: t('common:formValidation.required'),
        });
      }

      if (values.Password !== values.NewPassword) {
        Object.assign(errors, {
          NewPassword: t('common:formValidation.passwordMatch'),
        });
      }

      if (!values.OldPassword) {
        Object.assign(errors, {
          OldPassword: t('common:formValidation.required'),
        });
      }
    }

    return errors;
  };

  const onUpdateInfo = async (values: any) => {
    try {
      // if (user) {
      //   if (values.Name) {
      //     await user.updateProfile({ displayName: values.Name });
      //   }

      //   let credential = null;
      //   if (changeEmail) {
      //     firebase.auth.EmailAuthProvider.credential(
      //       values.Email as string,
      //       values.CurrentPassword
      //     );
      //   }

      //   if (changePassword) {
      //     credential = firebase.auth.EmailAuthProvider.credential(
      //       values.Email as string,
      //       values.OldPassword
      //     );

      //     await user.reauthenticateWithCredential(credential);
      //     await user.updatePassword(values.NewPassword);
      //   }
      // }

      toast.success(t('userSettings:myAccount.successUpdate'));
      setChangePassword(false);
      setChangeEmail(false);
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err?.message);
      }
    }
  };

  const confirmDelete = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    setOpenDelete(true);
  };

  return (
    <Container fluid className={'profilePage'}>
      <Row>
        <Col xs={12}>
          <h1 className={'sectionTitle'}>
            {t('common:navigation.accountNavigation.account')}
          </h1>
        </Col>
      </Row>
      <AccountPlan />
      <Row className={'mt-5'}>
        <Col xs={12} md={12} lg={1} xl={1} />
        <Col xs={12} md={12} lg={11} xl={11}>
          <RForm
            onSubmit={onUpdateInfo}
            validate={validate}
            // initialValues={{
            //   ...initialValues,
            // }}
            render={({
              handleSubmit,
              values,
              valid,
              form,
              // initialValues,
              errors,
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Container fluid>
                    <Row>
                      <Col xs={12} lg={4} xl={4} xxl={5}>
                        <Field
                          name={'Name'}
                          placeholder={'John Doe'}
                          component={TextInput}
                          type="text"
                          label={t('userSettings:myAccount.name')}
                        />
                      </Col>
                      {changeEmail ? (
                        <React.Fragment>
                          <Col xs={12} lg={4} xl={4} xxl={4}>
                            <Field
                              name={'Email'}
                              placeholder={'john@example.com'}
                              component={TextInput}
                              type="email"
                              label={t('userSettings:myAccount.email')}
                            />
                          </Col>
                          <Col xs={12} lg={4} xl={4} xxl={3}>
                            <Field
                              name={'CurrentPassword'}
                              placeholder={'******'}
                              component={TextInput}
                              type="password"
                              label={t('userSettings:myAccount.password')}
                            />
                          </Col>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Col xs={12} lg={4} xl={4} xxl={4}>
                            <Field
                              name={'Email'}
                              placeholder={'john@example.com'}
                              component={TextInput}
                              type="email"
                              label={t('userSettings:myAccount.email')}
                            />
                          </Col>
                          <Col xs={12} lg={4} xl={4} xxl={3}>
                            <div className="d-grid gap-2 pt-1">
                              <Button
                                variant="outline-secondary"
                                onClick={() => setChangeEmail((cur) => !cur)}
                                className={'mt-4'}
                              >
                                {t('userSettings:buttons.changeEmail')}
                              </Button>
                            </div>
                          </Col>
                        </React.Fragment>
                      )}
                    </Row>
                    <Row>
                      {changePassword ? (
                        <React.Fragment>
                          <Col xs={12} lg={4} xl={4} xxl={5}>
                            <Field
                              name={'Password'}
                              placeholder={'*******'}
                              component={TextInput}
                              type="password"
                              label={t('userSettings:myAccount.password')}
                            />
                          </Col>
                          <Col xs={12} lg={4} xl={4} xxl={4}>
                            <Field
                              name={'NewPassword'}
                              placeholder={'*******'}
                              component={TextInput}
                              type="password"
                              label={t('userSettings:myAccount.newPassword')}
                            />
                          </Col>
                          <Col xs={12} lg={4} xl={4} xxl={3}>
                            <Field
                              name={'OldPassword'}
                              placeholder={'*******'}
                              component={TextInput}
                              type="password"
                              label={t(
                                'userSettings:myAccount.currentPassword'
                              )}
                            />
                          </Col>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Col xs={12} lg={4} xl={4} xxl={5}>
                            <Field
                              name={'Password'}
                              placeholder={'*******'}
                              component={TextInput}
                              type="password"
                              label={t('userSettings:myAccount.password')}
                            />
                          </Col>
                          <Col xs={12} lg={4} xl={4} xxl={4}>
                            <Field
                              name={'NewPassword'}
                              placeholder={'*******'}
                              component={TextInput}
                              type="password"
                              label={t('userSettings:myAccount.newPassword')}
                            />
                          </Col>
                          <Col xs={12} lg={4} xl={4} xxl={3}>
                            <div className="d-grid gap-2 pt-1">
                              <Button
                                variant="outline-secondary"
                                onClick={() => setChangePassword(true)}
                                className={'mt-4'}
                              >
                                {t('userSettings:buttons.changePassword')}
                              </Button>
                            </div>
                          </Col>
                        </React.Fragment>
                      )}
                    </Row>
                    <Row>
                      <Col md={3} lg={2} xl={4}>
                        <div className="d-grid gap-2 pt-1">
                          <a
                            href={'/'}
                            className={'text-danger mt-2'}
                            onClick={confirmDelete}
                            rel="noreferrer"
                          >
                            {t('userSettings:buttons.delete')}
                          </a>
                        </div>
                      </Col>
                      <Col md={3} lg={6} xl={4} />
                      <Col md={3} lg={2} xl={2}>
                        <div className="d-grid gap-2 pt-1">
                          <Button
                            variant={'outline-secondary'}
                            type={'reset'}
                            onClick={() => {
                              // form.reset(initialValues);
                              setChangeEmail(false);
                              setChangePassword(false);
                            }}
                          >
                            {t('userSettings:buttons.cancel')}
                          </Button>
                        </div>
                      </Col>
                      <Col md={3} lg={2} xl={2}>
                        <div className="d-grid gap-2 pt-1">
                          <Button variant={'primary'} type={'submit'}>
                            {t('userSettings:buttons.update')}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Form>
              );
            }}
          />
        </Col>
        <Col xs={12} md={12} lg={1} xl={1} />
        <Col xs={12} md={12} lg={11} xl={11}>
          <ApiToken data={apiKeyData} />
        </Col>
        <Col xs={12} md={12} lg={1} xl={1} />
        <Col xs={12} md={12} lg={11} xl={11}>
          <Appearance />
        </Col>
      </Row>

      <Modal
        show={openDelete}
        onHide={() => setOpenDelete(false)}
        onEscapeKeyDown={() => setOpenDelete(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className={'fw-bold'}>
            {t('userSettings:buttons.delete')}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{t('userSettings:myAccount.confirmDelete')}</p>
        </Modal.Body>

        <Modal.Footer className={'border-top-0 justify-content-start'}>
          <Button variant="danger" onClick={() => deleteUserAccount()}>
            {t('userSettings:buttons.confirmDelete')}
          </Button>
          <Button
            variant={'link'}
            onClick={() => setOpenDelete(false)}
            className={'text-dark'}
          >
            {t('userSettings:buttons.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className={'mt-4 pb-5'}>
        <Col xs={12}>
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default MyAccount;
