import { GRADE_TYPE } from '@components/GradeBadge';
import { OverviewGradeRow } from '@pages/Report/Linux/ReportOverviewLinux/Components/OverviewGrade';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiHomeFill } from 'react-icons/ri';
import containerStyles from '../styles.module.scss';

type OverviewContainerProps = {
  data: any;
};

const OverviewContainer = ({ data }: OverviewContainerProps) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  //prettier-ignore
  const overviewTitleAsString = t(`pages:overviewPage.sections.reportOverview.gradesStatus.${data?.grade.toString()}.title`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  //prettier-ignore
  const overviewDescriptionAsString = t(`pages:overviewPage.sections.reportOverview.gradesStatus.${data?.grade.toString()}.description`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  //prettier-ignore
  const overviewCtaAsString = t('pages:overviewPage.sections.reportOverview.modalCtaLabel');

  return (
    <div className="d-flex flex-column">
      <SectionHeader
        title={t('pages:overviewPage.sections.overview.title')}
        leadingIcon={RiHomeFill}
        tooltipMessage={''}
      />
      <div className={containerStyles['overview-container']}>
        <OverviewGradeRow
          cta={overviewCtaAsString}
          title={overviewTitleAsString}
          description={overviewDescriptionAsString}
          grade={data?.grade as GRADE_TYPE}
        />
      </div>
    </div>
  );
};

export default OverviewContainer;
