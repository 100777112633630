import * as React from 'react';
import { useLocalStorage } from 'beautiful-react-hooks';
import { useRouteMatch } from 'react-router';
import { ROUTES } from '@routes/routes';

export enum THEMES {
  SYSTEM = 'system',
  DARK = 'dark',
  LIGHT = 'light',
}

const MATCH_QUERY = '(prefers-color-scheme: dark)';

function getTheme() {
  if (window.matchMedia(MATCH_QUERY).matches) {
    return THEMES.DARK;
  } else {
    return THEMES.LIGHT;
  }
}

export default function useDarkMode() {
  const [localThemeSetting, setLocalThemeSetting] = useLocalStorage<THEMES>(
    'theme',
    THEMES.SYSTEM
  );

  const isInternalRoute = useRouteMatch(ROUTES.BASE);
  const [theme, setTheme] = React.useState<THEMES.DARK | THEMES.LIGHT>(
    THEMES.LIGHT
  );

  const changeThemeStatus = React.useCallback(
    (themeUpdated: THEMES.DARK | THEMES.LIGHT) => {
      setTheme(themeUpdated);
    },
    [setTheme]
  );

  const handleThemeSetting = React.useCallback(() => {
    changeThemeStatus(getTheme());
  }, [changeThemeStatus]);

  React.useEffect(() => {
    (isInternalRoute) &&
      document.querySelector('html')?.setAttribute('data-theme', theme);
  }, [theme, isInternalRoute]);

  React.useEffect(() => {
    const getThemeBasedOnSystem = () => {
      window
        .matchMedia(MATCH_QUERY)
        .addEventListener('change', handleThemeSetting);
    };
    if (localThemeSetting === THEMES.SYSTEM) {
      getThemeBasedOnSystem();
      setTheme(getTheme());
    } else {
      setTheme(localThemeSetting);
    }
  }, [handleThemeSetting, localThemeSetting]);

  return { theme, setThemeDetection: setLocalThemeSetting };
}
