
export default function SecureBootLine({
  color,
  size,
  ...rest
}: any): JSX.Element {
  return (
    <svg
      {...rest}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
        fill={color}
      >
        <g
          id="Icons/alert"
          transform="translate(-1.000000, -2.000000)"
          fill={color}
        >
          <path
            d="M12.414,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,20 C22,20.5522847 21.5522847,21 21,21 L3,21 C2.44771525,21 2,20.5522847 2,20 L2,4 C2,3.44771525 2.44771525,3 3,3 L10.414,3 L12.414,5 Z M4,5 L4,19 L20,19 L20,7 L11.586,7 L9.586,5 L4,5 Z M15,13 L16,13 L16,17 L8,17 L8,13 L9,13 L9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 L15,13 L15,13 Z M13,13 L13,12 C13,11.4477153 12.5522847,11 12,11 C11.4477153,11 11,11.4477153 11,12 L11,13 L13,13 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
