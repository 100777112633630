import { ROUTES } from '@routes/routes';
import { getTokenOnLocalstorage } from '@utils/index';
import type { WrapperRouteProps } from '@utils/types/helpers';
import { Redirect, Route } from 'react-router-dom';

export default function PrivateRoute({ children, ...rest }: WrapperRouteProps) {
  const token = getTokenOnLocalstorage();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!token) {
          return (
            <Redirect
              to={{
                pathname: ROUTES.SIGN_IN,
                state: { from: location },
              }}
            />
          );
        } else {
          return children;
        }
      }}
    />
  );
  // const { data, status } = useSigninCheck();

  // return (
  //   <Route
  //     {...rest}
  //     render={({ location }) => {
  //       if (status === 'loading') {
  //         return <MainLoading />;
  //       }
  //       if (status === 'success' && !data.user) {
  //         return (
  //           <Redirect
  //             to={{
  //               pathname: ROUTES.SIGN_IN,
  //               state: { from: location },
  //             }}
  //           />
  //         );
  //       }
  //       if (status === 'success' && data.user && data.user.emailVerified) {
  //         return children;
  //       }
  //       if (status === 'success' && data.user && !data.user.emailVerified) {
  //         return (
  //           <Redirect
  //             to={{
  //               pathname: ROUTES.VERIFY_EMAIL,
  //               state: { from: location },
  //             }}
  //           />
  //         );
  //       }
  //       return '';
  //     }}
  //   />
  // );
}
