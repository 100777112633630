import useReport from '@hooks/useReport';
import { getRandomInt } from '@utils/index';
import { REPORT_STATUS } from '@utils/types/report';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import useReports from '@hooks/useReports';
import { useMemo } from 'react';
//@ts-ignore
import Spinner from 'react-cli-spinners';
type Props = {
  reportId: string;
};

const getRandomNumber = Array.from(Array(50).keys(), () =>
  getRandomInt(420542, 430542)
);
const randomNumberSorted = getRandomNumber.sort(
  (a: number, b: number) => a - b
);

export default function FakeReportMessage({ reportId }: Props) {
  const { t } = useTranslation(['common', 'scanModal']);
  const { data } = useReport(reportId);
  const { data: reports } = useReports();
  const analysisStatus = data?.analysisStatus;

  // TODO: remove after /projects/reportId is returning the correct status for the failed report (currently it returns "prending")
  const reportData = useMemo(
    () => reports?.filter((report) => report.id === reportId)?.[0],
    [reports, reportId]
  );

  return (
    <div className={styles.container}>
      {data?.status === REPORT_STATUS.PENDING ? (
        <>
          <p className={styles.message}>
            [ 0.420136 ] {t('scanModal:content.uploadingAndScan.unpackage')}:{' '}
            {t('scanModal:content.uploadingAndScan.started')}
          </p>
          <p className={styles.message}>
            [ 0.420185 ] {t('scanModal:content.uploadingAndScan.notifyByMail')}
          </p>
        </>
      ) : null}

      {data?.status === REPORT_STATUS.COMPLETED ? (
        <>
          <p className={styles.message}>
            [ 0.420136 ] {t('scanModal:content.uploadingAndScan.unpackage')}:{' '}
            {t('scanModal:content.uploadingAndScan.completed')}
          </p>
        </>
      ) : null}

      {data?.status === REPORT_STATUS.RUNNING && data?.analysisStatus ? (
        <>
          <p className={styles.message}>
            [ 0.420236 ] {t('scanModal:content.uploadingAndScan.unpackage')}:{' '}
            {t('scanModal:content.uploadingAndScan.completed')}
          </p>
        </>
      ) : null}

      {analysisStatus
        ? Object.keys(analysisStatus).reduce(
            (acc: any, item, index: number) => {
              return [
                ...acc,
                analysisStatus[item] ? (
                  <p key={index} className={styles.message}>
                    [ 0.{randomNumberSorted[index]} ]{' '}
                    {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      t(`common:typeOfAnalysis.${item}`)
                    }{' '}
                    {t('scanModal:content.uploadingAndScan.analysis')}:{' '}
                    {t('scanModal:content.uploadingAndScan.completed')}
                  </p>
                ) : null,
              ];
            },
            []
          )
        : null}

      {data?.status === REPORT_STATUS.RUNNING && analysisStatus ? (
        <>
          <p className={styles.message}>
            <span className={styles.spinner}>
              <Spinner type={'dots2'} />
            </span>
            {t('scanModal:content.uploadingAndScan.analyzing')}...
          </p>
        </>
      ) : null}
      {reportData?.status === REPORT_STATUS.FAILED ||
      (data?.status === REPORT_STATUS.FAILED && analysisStatus) ? (
        <p className={styles.message}>
          [ 0.530502 ] {t('scanModal:content.uploadingAndScan.analysisFailed')}
        </p>
      ) : null}

      {data?.status === REPORT_STATUS.COMPLETED && analysisStatus ? (
        <p className={styles.message}>
          [ 0.560601 ]{' '}
          {t('scanModal:content.uploadingAndScan.analysisCompleted')}
        </p>
      ) : null}
    </div>
  );
}
