import React from 'react';
import { RiArrowDropUpFill } from 'react-icons/all';
import styles from './styles.module.scss';

import TableFirstRow from '@components/TableNew/Components/TableFirstRow';
import { useTranslation } from 'react-i18next';
import TableFooter from './Components/TableFooter';
import TableHeader from './Components/TableHeader';

const Table = (props: any) => {
  const {
    children,
    tableInstance,
    onClickRow = () => null,
    rowStyle = {},
    className = '',
    wrapperStyle = {},
    borderless
  } = props;
  const pre = 'table';
  const { t } = useTranslation('common');

  const renderChildrensHandler = (compName: string, props: any) => {
    return React.Children.map(children, (child) => {
      if (child.type === TableHeader && compName === 'TableHeader') {
        return React.cloneElement(child, { ...child.props, ...props });
      }
      if (child.type === TableFooter && compName === 'TableFooter') {
        return React.cloneElement(child, { ...child.props, ...props });
      }
      if (child.type === TableFirstRow && compName === 'TableFirstRow') {
        return React.cloneElement(child, { ...child.props, ...props });
      }
    });
  };

  return (
    <div style={{ border: borderless && 0 }} className={`${styles[`${pre}__container`]} ${className}`}>
      {renderChildrensHandler('TableHeader', {
        className: styles[`${pre}__header`],
      })}
      <div className={styles['table-wrapper']} style={wrapperStyle}>
        <table
          {...tableInstance?.getTableProps()}
          style={{
            width: '100%',
            minHeight: '30px',
          }}
        >
          {renderChildrensHandler('TableFirstRow', {})}
          {tableInstance &&
            tableInstance.page &&
            tableInstance.page.length > 0 && (
              <thead>
                {tableInstance?.headerGroups?.map((headerGroup: any) => (
                  <tr {...headerGroup?.getHeaderGroupProps()}>
                    {headerGroup?.headers.map((column: any) => {
                      return (
                        <th
                          {...column.getHeaderProps({
                            style: {
                              width: `${column.width}px`,
                              padding: 0,
                              paddingLeft: 10
                            },
                          })}
                        >
                          <span>
                            {column.render('Header')}
                            <span
                              style={{
                                height: '24px',
                                opacity:
                                  column?.isSorted && column.canSort ? 1 : 0,
                                transform: `rotate(${column?.isSortedDesc ? '0' : '180deg'
                                  })`,
                              }}
                            >
                              <RiArrowDropUpFill size={24} />
                            </span>
                          </span>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
            )}
          {tableInstance &&
            tableInstance.page &&
            tableInstance.page.length > 0 ? (
            <tbody {...tableInstance?.getTableBodyProps()}>
              {tableInstance.page.map((row: any) => {
                tableInstance?.prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() => onClickRow && onClickRow(row)}
                    style={rowStyle}
                  >
                    {row.cells.map((cell: any) => {
                      return (
                        <td
                          {...cell.getCellProps({
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tr className="text-center">
              <span className="my-3 h-100">{t('noData')}</span>
            </tr>
          )}
        </table>
      </div>
      {renderChildrensHandler('TableFooter', {
        className: styles[`${pre}__footer`],
      })}
    </div>
  );
};

Table.Header = TableHeader;
Table.Footer = TableFooter;
Table.FirstRow = TableFirstRow;

export default Table;
