import Table from '@components/TableNew/Table';
import useCodeAnalysis from '@hooks/analysis/useCodeAnalysis';
import Utils from '@pages/Report/Linux/ReportOverviewLinux/Utils/Utils';
import { getCssVariable, padNumber } from '@utils/index';
import React from 'react';
import { RiFile3Line } from 'react-icons/ri';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useFilters, usePagination, useTable } from 'react-table';


const CodeAnalysisTable = () => {
  const { reportId = '' }: { reportId: string } = useParams();
  const {
    data: dataCodeAnalysis
    // isLoading: isLoadingCodeAnalysis,
    // isError: isErrorCodeAnalysis,
    // error: errorCodeAnalysis
  } = useCodeAnalysis(reportId);


  const memoizedData = React.useMemo(() => {
    if (!dataCodeAnalysis) return [];
    return dataCodeAnalysis;
  }, [dataCodeAnalysis]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'filename',
        Cell: ({ row, value }: { row: any, value: string }) => {
          return <div className='d-flex flex-row align-items-center'>
            <span
              className='d-flex flex-row align-items-center'
              style={{
                // marginRight: '.5rem',
                color: getCssVariable('neutral400')
              }}
            >
              {padNumber(row.index + 1)}
              <RiFile3Line className='px-1' size={24} color={getCssVariable('neutral400')} />
            </span>
            {value}
          </div>;
        }
      },
      {
        Header: 'Type',
        accessor: 'types',
        Cell: ({ value }: { value: string[] }) => {
          return (
            <div className='d-flex align-items-center w-100'>
              {value.map((t: string, i: number) => <span
                key={`${t}-${i}`}
                style={{
                  padding: '.20rem',
                  marginRight: '1rem',
                  color: getCssVariable(Utils.getCodeAnalysisChipColorByLabel(t)),
                  borderRadius: '4px'
                }}
              >
                {t}
              </span>)
              }
            </div >
          );
        }
      },
      {
        Header: 'Occurrences',
        accessor: 'occurrences',
        Cell: ({ value }: any) => {
          return (
            <div className='d-flex align-items-center w-100'>
              <span>{`${value} vulnerabilities`}</span>
            </div>
          );
        }
      }
    ],
    []
  );

  const tableInstance = useTable({
    columns,
    data: memoizedData,
    initialState: { pageSize: 10 }
  },
    useFilters,
    usePagination,
    // useInfiniteQuery
  );

  return <Table className='border-0' tableInstance={tableInstance} />;
};

export default CodeAnalysisTable;
