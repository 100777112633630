import tableStyles from '@components/Table/styles.module.scss';
import Table from '@components/TableNew/Table';
import { getCssVariable, padNumber } from '@utils/index';
import React from 'react';
import { RiCheckFill, RiCloseFill, RiExternalLinkFill } from 'react-icons/ri';
import { useFilters, usePagination, useTable } from 'react-table';

type CveCheckTableProps = {
  data?: any;
  columns?: any;
  hasPagination?: boolean;
  hasPageSize?: boolean;
};
const CveCheckTable = (props: CveCheckTableProps) => {
  const { data } = props;

  const applySeverityClassname = (severity: string) => {
    switch (severity) {
      case 'critical':
        return <span style={{ color: getCssVariable('bs-purple'), fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>C</span>;
      case 'high':
        return <span style={{ color: '#F86767', fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>H</span>;
      case 'medium':
        return <span style={{ color: '#D0D7DE', fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>M</span>;
      case 'low':
        return <span style={{ color: '#59CCD9', fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>L</span>;

      default:
        break;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'CVE ID',
        accessor: 'cveid',
        Cell: (props: any) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400'),
                }}
              >
                {padNumber(props.row?.index + 1)}
              </span>
              <div>
                {applySeverityClassname(props.row?.original.severity?.toLowerCase())}
                <span>{props.value}</span>
              </div>
              <div className={'px-2'}>
                <a
                  className={`${tableStyles['show-icon-link']}`}
                  href={props.row?.original?.patch}
                  target="_blank"
                  rel={'noopener noreferrer'}
                >
                  <RiExternalLinkFill />
                </a>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Product',
        accessor: 'product',
      },
      {
        Header: 'Version',
        accessor: 'version',
      },
      {
        Header: 'Patch',
        accessor: 'patch',
        Cell: (props: any) => {
          if (props.value) {
            return (
              <div className={'d-flex align-items-center'}>
                <RiCheckFill
                  style={{ color: '#D0D7DE', fontSize: '1.5rem' }}
                />
                <span style={{ marginLeft: '.5rem' }}>Available</span>
              </div>
            );
          } else {
            return (
              <div className="d-flex align-items-center">
                <RiCloseFill style={{ color: '#E60000', fontSize: '1.5rem' }} />
                <span style={{ marginLeft: '.5rem' }}>Unavailable</span>
              </div>
            );
          }
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      data,
      columns,
      initialState: { pageSize: 10 },
      // ovdje velicini mjenjam i trebat ce dodat infinite scroll
    },
    useFilters,
    usePagination
  );

  return <Table className="border-0" tableInstance={tableInstance} />;
};

export default CveCheckTable;
