import React, { ReactNode } from 'react';
import styles from '../styles.module.scss';

type InfoBoxFooterProps = {
  children?: ReactNode;
};

const InfoBoxFooter = (props: InfoBoxFooterProps) => {
  const { children } = props;
  return <div className={styles['info-box-footer']}>{children}</div>;
};

export default InfoBoxFooter;
