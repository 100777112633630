import React from 'react';
import { getCssVariable } from '@utils/index';
import ContentLoader from 'react-content-loader';
import styles from './styles.module.scss';

const Loader = (props?: any) => {
  const random = Math.random() * (1 - 0.7) + 0.7;

  return <ContentLoader
    speed={2}
    width={1060}
    height={40}
    backgroundColor={getCssVariable('neutral200')}
    foregroundColor={getCssVariable('neutral100')}
    {...props}
  >
    <rect x="30" y="15" rx="4" ry="4" width="6" height="6.4" />
    <rect x="64" y="13" rx="6" ry="6" width={200 * random} height="12" />
    <rect x="643" y="13" rx="6" ry="6" width={23 * random} height="12" />
    <rect x="683" y="13" rx="6" ry="6" width={78 * random} height="12" />
    <rect x="785" y="13" rx="6" ry="6" width={117 * random} height="12" />
    <rect x="968" y="13" rx="6" ry="6" width={83 * random} height="12" />
    <rect x="0" y="39" rx="6" ry="6" width="1060" height=".3" />
  </ContentLoader>;
};

function renderSkeletonCard() {
  return (
    <React.Fragment>
      {Array(20)
        .fill('')
        .map((e, i) => (
          <Loader key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
        ))}
    </React.Fragment>
  );
}
export default function SkeletonReportList(props: any) {
  return (
    <div className={styles.container}>
      {renderSkeletonCard()}
    </div>
  );
}
