import Table from '@components/TableNew/Table';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import React from 'react';
import { useFilters, usePagination, useTable } from 'react-table';
import styles from './styles.module.scss';

interface IProps {
  data?: any;
  cveData: any;
}

const breakPointsLeft = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const breakPointsRight = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
};

const CveCheckTable: React.FC<IProps> = ({ data, cveData }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'CVE ID',
        accessor: 'cveid1',
        Cell: () =>
          cveData?.critical?.preview.map((elem: any) => (
            <div style={{ padding: '10px 0' }}>{elem.cveid}</div>
          )),
      },
      {
        Header: 'Product',
        accessor: 'product1',
        Cell: () =>
          cveData?.critical?.preview.map((elem: any, i: number) => (
            <div style={{ padding: '10px 0' }}>
              <div style={{ position: 'relative', top: 10 * (i + 1) }}>
                {elem.product}
              </div>
            </div>
          )),
      },
      {
        Header: 'CVE ID',
        accessor: 'cveid2',
        Cell: () =>
          cveData?.high?.preview.map((elem: any) => (
            <div style={{ padding: '10px 0' }}>{elem.cveid}</div>
          )),
      },
      {
        Header: 'Product',
        accessor: 'product2',
        Cell: () =>
          cveData?.high?.preview.map((elem: any, i: number) => (
            <div style={{ padding: '10px 0' }}>
              <div style={{ position: 'relative', top: 10 * (i + 1) }}>
                {elem.product}
              </div>
            </div>
          )),
      },
      {
        Header: 'CVE ID',
        accessor: 'cveid3',
        Cell: () =>
          cveData?.medium?.preview.map((elem: any) => (
            <div style={{ padding: '10px 0' }}>{elem.cveid}</div>
          )),
      },
      {
        Header: 'Product',
        accessor: 'product3',
        Cell: () =>
          cveData?.medium?.preview.map((elem: any, i: number) => (
            <div style={{ padding: '10px 0' }}>
              <div style={{ position: 'relative', top: 10 * (i + 1) }}>
                {elem.product}
              </div>
            </div>
          )),
      },
      {
        Header: 'CVE ID',
        accessor: 'cveid4',
        Cell: () =>
          cveData?.low?.preview.map((elem: any) => (
            <div style={{ padding: '10px 0' }}>{elem.cveid}</div>
          )),
      },
      {
        Header: 'Product',
        accessor: 'product4',
        Cell: () =>
          cveData?.low?.preview.map((elem: any, i: number) => (
            <div style={{ padding: '10px 0' }}>
              <div style={{ position: 'relative', top: 10 * (i + 1) }}>
                {elem.product}
              </div>
            </div>
          )),
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      data,
      columns,
      initialState: { pageSize: 999 },
    },
    useFilters,
    usePagination
  );

  return (
    <div className={styles['container']}>
      <SectionLayout className="px-0">
        <SectionContent
          breakPointsLeft={breakPointsLeft}
          breakPointsRight={breakPointsRight}
          style={{ borderColor: '#d0d7de', marginLeft: 0, marginRight: 0 }}
        >
          <SectionContentLeft
            verticalMessage={'Vulnerabilities'}
            verticalMessageColor="#05516f"
            style={{ borderRight: 'none' }}
          >
            <div style={{ width: '100%' }}>
              <div className={styles['severitiesContainer']}>
                <div style={{ width: 200 }}>
                  <div className={styles['severity']}>
                    {cveData?.critical?.severity || 0}
                  </div>
                  <span>Critical</span>
                </div>
                <div style={{ width: 240 }}>
                  <div className={styles['severity']}>
                    {cveData?.high?.severity || 0}
                  </div>
                  <span>High</span>
                </div>
                <div style={{ width: 240 }}>
                  <div className={styles['severity']}>
                    {cveData?.medium?.severity || 0}
                  </div>
                  <span>Medium</span>
                </div>
                <div style={{ width: 240 }}>
                  <div className={styles['severity']}>
                    {cveData?.low?.severity || 0}
                  </div>
                  <span>Low</span>
                </div>
              </div>
              <div className={styles['table-container']}>
                <Table className="border-0" tableInstance={tableInstance} />
              </div>
            </div>
          </SectionContentLeft>
        </SectionContent>
      </SectionLayout>
    </div>
  );
};

export default CveCheckTable;
