import animationData from '@assets/animations/sign-in.json';
import logo from '@assets/logo/Exein-logo.gif';
import HeaderOnBoardingWrapper from '@components/HeaderOnBoardingWrapper';
import LayoutOnBoarding from '@components/LayoutOnBoarding';
import NewPasswordForm from '@components/NewPasswordForm';
import SuccessMessageOnBoarding from '@components/SuccessMessageOnBoarding';
import Canvas from '@pages/SignIn/animations';
import { ROUTES } from '@routes/routes';
import { getCssVariable } from '@utils/index';
import queryString from 'query-string';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiCheckLine } from 'react-icons/ri';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';

export default function NewPassword(props: any) {
  const iconColor = 'neutral600';
  const { t } = useTranslation(['newPassword', 'forms']);

  const history = useHistory();
  const { code }: any = queryString.parse(history.location.search) ?? {
    code: '',
  };

  if (!code) {
    //TODO: will be returent in the future:
    //  history.push(ROUTES.RESET_PASSWORD);
  }

  const [error, setError] = React.useState<string>('');
  const [isSending, setIsSending] = React.useState<boolean>(false);
  const [succesfullySent, setFuccesfullySent] = React.useState<boolean>(false);

  const onSetNewPassword = React.useCallback(
    async (password: string) => {
      try {
        // await auth.confirmPasswordReset(code, password);
        setFuccesfullySent(true);
        setTimeout(() => {
          history.push(ROUTES.SIGN_IN);
        }, 3000);
      } catch (err) {
        if (err instanceof Error) {
          setError(err?.message);
          setFuccesfullySent(false);
        }
      } finally {
        setIsSending(false);
      }
    },
    [history, code]
  );

  function onSubmitNewPasswordForm({ password }: { password: string }): void {
    setIsSending(true);
    onSetNewPassword(password);
  }

  React.useEffect(() => {
    new Canvas(document.getElementById('canvas'), '#d4d4d4');
  }, []);

  return (
    <React.Fragment>
      <div className={'sign-container'}>
        <LayoutOnBoarding animationData={animationData}>
          <Row className="px-5">
            <Col className="px-5 mb-5">
              <img src={logo} alt="exein_logo" style={{ width: '200px' }} />
            </Col>
            <Col xs={12} className="px-5">
              <HeaderOnBoardingWrapper
                title={t('newPassword:title')}
                description={t('newPassword:subtitle')}
              />
              {succesfullySent ? (
                <SuccessMessageOnBoarding
                  iconSize={34}
                  iconColor={getCssVariable(iconColor)}
                  leadingIcon={RiCheckLine}
                  message={t('newPassword:newPassword')}
                />
              ) : (
                <React.Fragment>
                  <NewPasswordForm
                    onSubmit={onSubmitNewPasswordForm}
                    isSending={isSending}
                  />

                  {error.length ? (
                    <p className={styles.errorMessage}>{error}</p>
                  ) : null}
                </React.Fragment>
              )}
            </Col>
          </Row>
        </LayoutOnBoarding>
      </div>
      <canvas id={'canvas'}></canvas>
    </React.Fragment>
  );
}
