import { InputHTMLAttributes } from 'react';
import type { IconType } from 'react-icons';
import styles from './styles.module.scss';
import { getCssVariable } from '@utils/index';
import { noop, omit } from 'lodash';
import { RefCallBack } from 'react-hook-form';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  leadingIcon?: IconType;
  trailingIcon?: IconType;
  label?: string;
  iconColor?: string;
  iconSize?: number;
  placeholder?: string;
  inputRef?: RefCallBack; // this should be mandatory if onChange prop is not provided.
  onChange?: typeof noop; // this should be mandatory if inputRef prop is not provided.
  error?: string;
  hasValue?: boolean;
  compact?: boolean;
}

export default function Input(props: InputProps) {
  const {
    leadingIcon,
    trailingIcon,
    iconSize = 20,
    iconColor = 'neutral400',
    error = '',
    label = '',
    hasValue = false,
    compact = false,
    onChange = noop,
  } = props;
  const LeadingIcon = leadingIcon ? leadingIcon : null;
  const TrailingIcon = trailingIcon ? trailingIcon : null;
  return (
    <div className={styles.container}>
      <div
        className={`${styles.wrapper} ${error ? styles.error : ''}  ${
          hasValue ? styles.isDirty : ''
        } ${leadingIcon ? styles.hasLeadingIcon : ''} ${
          trailingIcon ? styles.hasTrailingIcon : ''
        } 
          `}
      >
        {label ? <label>{label}</label> : null}
        <div
          className={`${styles.inputWrapper} ${compact ? styles.compact : ''}`}
        >
          <input
            tabIndex={0}
            ref={props.inputRef}
            onChange={onChange}
            {...omit(props, [
              'inputRef',
              'icon',
              'hasValue',
              'leadingIcon',
              'error',
              'compact',
            ])}
          />
          <div className={styles.wrapperHelper}>
            {LeadingIcon && (
              <div className={styles.leadingIcon}>
                <LeadingIcon
                  size={iconSize}
                  color={getCssVariable(iconColor)}
                />
              </div>
            )}
            {TrailingIcon && (
              <div className={styles.trailingIcon}>
                <TrailingIcon
                  size={iconSize}
                  color={getCssVariable(iconColor)}
                />
              </div>
            )}
            <div className={styles.onFocusBorder} />
          </div>
        </div>
      </div>
      {error ? <p>{error}</p> : null}
    </div>
  );
}
