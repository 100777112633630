import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import BinaryAnalysisCapabilitiesGraph from '@pages/Report/Linux/ReportOverviewLinux/Graphs/BinaryAnalysisCapabilitiesGraph';
import { ReportOverview } from '@utils/types/report';
import BinaryAnalysisSeverityGraph from './SeveritiesGraph';
import styles from './styles.module.scss';

const breakPoints = {
  xs: 12,
  sm: 12,
  md: 6,
  lg: 6,
  xl: 6,
};

interface IProps {
  data: ReportOverview;
}

const BinaryAnalysis: React.FC<IProps> = ({ data }) => {
  return (
    <div style={{ color: '#000' }}>
      <SectionLayout>
        <SectionContent
          breakPointsLeft={breakPoints}
          breakPointsRight={breakPoints}
          style={{
            borderColor: '#d0d7de',
          }}
        >
          <SectionContentLeft
            verticalMessage="Severities"
            verticalMessageColor="#05516f"
          >
            <div className={styles['severities-content']}>
              <BinaryAnalysisSeverityGraph data={data} />
            </div>
          </SectionContentLeft>
          <SectionContentLeft
            verticalMessage="Capabilities"
            verticalMessageColor="#05516f"
            style={{ flexDirection: 'row', padding: '1rem 0' }}
          >
            <div className={styles['capabilities-content']}>
              <BinaryAnalysisCapabilitiesGraph
                data={data}
                itemTextColor="black"
              />
            </div>
          </SectionContentLeft>
        </SectionContent>
      </SectionLayout>
    </div>
  );
};
export default BinaryAnalysis;
