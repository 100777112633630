import illustration2 from '@assets/animations/Almost_There.json';
import illustration from '@assets/animations/Almost_There2.json';
import Button from '@components/Button';
import HeaderOnBoardingWrapper from '@components/HeaderOnBoardingWrapper';
import LayoutOnBoarding from '@components/LayoutOnBoarding';
import Spinner, { SPINNER_TYPE } from '@components/Spinner';
import useUser from '@hooks/useUser';
import Canvas from '@pages/SignIn/animations';
import { ROUTES } from '@routes/routes';
import { getCssVariable } from '@utils/index';
import queryString from 'query-string';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiCheckLine } from 'react-icons/ri';
import { useHistory } from 'react-router';
import logo from '../../assets/logo/Exein-logo.gif';
import styles from './styles.module.scss';

const returnUrl = `${window.location.protocol}//${window.location.host}/sign-in`;

export default function VerifyEmail() {
  const { t } = useTranslation(['pages', 'forms']);
  const { data: user } = useUser();
  const isVerified = !!user?.email || false;
  const history = useHistory();
  const { code }: any = queryString.parse(history.location.search) ?? {
    code: '',
  };

  const [succesfullyVerification, setFuccesfullyVerification] =
    React.useState<boolean>(false);

  const [error, setError] = React.useState<string>('');
  const [pending, setPending] = React.useState<boolean>(false);
  const [isSent, setIsSent] = React.useState<boolean>(false);

  const onResendValidationEmail = React.useCallback(async () => {
    try {
      setPending(true);
      // await user?.sendEmailVerification({ url: returnUrl });
      setIsSent(true);
    } catch (err: any) {
      setError(err?.message);
      setIsSent(false);
    } finally {
      setPending(false);
    }
  }, [user]);

  React.useEffect(() => {
    async function verifyEmail() {
      setPending(true);
      try {
        // await auth.applyActionCode(code);
        setFuccesfullyVerification(true);
        // await user?.reload();
      } catch (err) {
        if (err instanceof Error) {
          setError(err?.message);
          setFuccesfullyVerification(false);
        }
      } finally {
        setPending(false);
      }
    }
    code && verifyEmail();
  }, [history, code, user]);

  React.useEffect(() => {
    if (isVerified) {
      history.push(ROUTES.BASE);
    }
  }, [isVerified]);

  React.useEffect(() => {
    new Canvas(document.getElementById('canvas'), '#d4d4d4');
  }, []);

  // console.log('pending', pending);
  // console.log('succesfullyVerification', succesfullyVerification);
  // console.log('isSent', isSent);

  // {isSent ? (
  //   <p className={styles.emailSent}>
  //     Email sent{' '}
  //     <RiCheckLine color={getCssVariable('green')} size={24} />
  //   </p>
  // ) : pending ? (
  //   <Spinner type={SPINNER_TYPE.BLACK} />
  // ) : (
  //   <Button onClick={onResendValidationEmail}>
  //     {t('pages:signUp.labelButtonVerificationEmail').toUpperCase()}
  //   </Button>
  // )}

  return (
    <>
      <div style={{ position: 'absolute' }}>
        <LayoutOnBoarding animationData={illustration}>
          <Row className="px-5">
            <Col xs={12} className="px-5 mb-5">
              <img src={logo} alt="exein_logo" style={{ width: '200px' }} />
            </Col>
            <Col xs={12} className="px-5">
              <HeaderOnBoardingWrapper
                title={t('pages:almostDone.title')}
                subTitle={t('pages:almostDone.subtitle')}
                description={t('pages:almostDone.description')}
              />
            </Col>
            <Col xs={12} className="px-5">
              <Row>
                <Col xs={12} className="pt-2">
                  <div className={styles.registrationSteps}>
                    <span>{t('forms:labels.stepsRegistration')}</span>
                    <div></div>
                    <div className={styles.activeStep}></div>
                  </div>
                </Col>
                <Col xs={12} className="pt-4">
                  <div className={styles.texts}>
                    <span>{t('forms:notReceivedEmail')}</span>
                    <span
                      onClick={onResendValidationEmail}
                      style={{ color: '#44E921', cursor: 'pointer' }}
                    >
                      {t('forms:resendVerificationEmail')}
                    </span>
                  </div>
                </Col>
                <Col xs={12}>
                  {isSent ? (
                    <p className={styles.emailSent}>
                      Email sent{' '}
                      <RiCheckLine color={getCssVariable('green')} size={24} />
                    </p>
                  ) : (
                    pending && <Spinner type={SPINNER_TYPE.BLACK} />
                  )}
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="px-5 pt-2">
              {error ? <p className={styles.errorMessage}>{error}</p> : null}
            </Col>
          </Row>
        </LayoutOnBoarding>
      </div>
      <canvas id={'canvas'}></canvas>
    </>
  );

  return code ? (
    <>
      {
        <LayoutOnBoarding animationData={illustration}>
          <div className={styles.wrapper}>
            {pending ? (
              <>
                <HeaderOnBoardingWrapper
                  title={t('pages:verifyEmail.pendingTitle')}
                  description={t('pages:verifyEmail.pendingMessage')}
                />
              </>
            ) : (
              <>
                {succesfullyVerification ? (
                  <>
                    <HeaderOnBoardingWrapper
                      title={t('pages:verifyEmail.successTitle')}
                      description={t('pages:verifyEmail.successMessage')}
                    />
                  </>
                ) : (
                  <>
                    <HeaderOnBoardingWrapper
                      title={t('pages:verifyEmail.errorTitle')}
                      description={error}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </LayoutOnBoarding>
      }
    </>
  ) : (
    <LayoutOnBoarding animationData={illustration2}>
      <div className={styles.wrapper}>
        <>
          <HeaderOnBoardingWrapper
            title={t('pages:signUp.titleVerification')}
            description={t('pages:signUp.subtitleVerification')}
          />

          {isSent ? (
            <p className={styles.emailSent}>
              Email sent{' '}
              <RiCheckLine color={getCssVariable('green')} size={24} />
            </p>
          ) : pending ? (
            <Spinner type={SPINNER_TYPE.BLACK} />
          ) : (
            <Button onClick={onResendValidationEmail}>
              {t('pages:signUp.labelButtonVerificationEmail').toUpperCase()}
            </Button>
          )}
          {error ? <p className={styles.error}>{error}</p> : ''}
        </>
      </div>
    </LayoutOnBoarding>
  );
}
