import LayoutPage from '@components/LayoutPage';
import * as React from 'react';
// import LayoutSection, { LAYOUT_TYPE } from '@components/LayoutSection';
import useBinaryAnalysis from '@hooks/analysis/useBinaryAnalysis';
import { useParams } from 'react-router-dom';

// import type { SetFilter } from '@components/Table';
import { useTranslation } from 'react-i18next';
// import { matchSorter } from 'match-sorter';

// import queryString from 'query-string';
// import {
//   ReportBinaryContentAnalysis,
//   REPORT_SEVERITY,
// } from '@utils/types/report';
// import { get, omit, startCase, toLower } from 'lodash';
// import Chip from '@components/Chip';
// import LayoutBox from '@components/LayoutBox';
// import DataHighlight, {
//   SIZE as DATA_HIGHLIGHT_SIZE,
// } from '@components/DataHighlight';
// import LayoutBoxContent, { PADDING_SIZE } from '@components/LayoutBoxContent';
// import ReportHeading from '@components/ReportHeading';
// import TableFilter from '@components/TableFilter';
import EmptyResults from '@components/EmptyResults';
// import GoodBadIconCheck from '@components/GoodBadIconCheck';
// import TableModal from '@components/TableModal';
// import ModalVerticalReport from '@components/ModalVerticalReport';
import SkeletonAnalysisWithBlocks from '@components/SkeletonAnalysisWithBlocks';
// import BannerMessage from '@components/BannerMessage';

// import { getCssVariable } from '@utils/index';
import GenericError from '@components/ErrorState';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import TabsProvider from '@components/Tabs/TabsProvider';
import { TabsHeader } from '@components/TabsHeader';
import tabsStyles from '@components/TabsHeader/styles.module.scss';
import BinaryAnalysisContainer from '@pages/Report/Linux/ReportOverviewLinux/Containers/BinaryAnalysisContainer';
import { getCssVariable, getScanTime, padNumber } from '@utils/index';
import { REPORT_SEVERITY } from '@utils/types/report';
import { Col, Container, Row } from 'react-bootstrap';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiSearch2Line,
} from 'react-icons/ri';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import reportStyles from '../../styles.module.scss';
import styles from '../ReportOverviewLinux/styles.module.scss';
import CellTableIcon from './Components/CellTableIcon';

import { Input } from '@components/Forms/Input';
import LayoutSection from '@components/LayoutSection';
import ModalReportLinuxDetails from '@components/ModalReportLinuxDetails';
import ModalVerticalReport from '@components/ModalVerticalReport';
import ReportHeading from '@components/ReportHeading';
import TableModal from '@components/TableModal';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import { format } from 'date-fns';
import { noop, omit } from 'lodash';

export enum BINARY_PROPERTY {
  FILENAME = 'filename',
  CANARY = 'canary',
  NX = 'nx',
  PIE = 'pie',
  RELRO = 'relro',
  FORTIFY = 'fortify',
  SEVERITY = 'severity',
  CAPABILITIES = 'capabilities',
}

export default function Binary() {
  const { reportId = '' }: { reportId: string; capabilities: string } =
    useParams();
  const { data: reportData } = useReportOverview(reportId);

  const [activeIndex, setActiveIndex] = React.useState<string>('0');
  const [search, setSearch] = React.useState('');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const { data, isLoading, isError, error } = useBinaryAnalysis(reportId);

  const [modalData, setModalData] = React.useState<{
    [key: string]: string;
  } | null>();

  const { t } = useTranslation(['tables', 'common', 'emptyReports', 'pages']);

  const handleActiveIndexDefault = (data: any): string => {
    if (!data) return '0';
    if (
      data.high.severity > 0 &&
      data.medium.severity > 0 &&
      data.low.severity > 0
    ) {
      return '0';
    }
    if (data.high.severity > 0) {
      return '0';
    }
    if (data.medium.severity > 0) {
      return '1';
    }
    if (data.low.severity > 0) {
      return '2';
    }
    return '0';
  };

  React.useEffect(() => {
    setActiveIndex(handleActiveIndexDefault(reportData?.analysis?.binary));
  }, [reportData]);


  const applySeverityClassname = (severity: string) => {
    switch (severity) {
      case 'critical':
        return <span style={{ color: getCssVariable('bs-purple'), fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>C</span>;
      case 'high':
        return <span style={{ color: '#F86767', fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>H</span>;
      case 'medium':
        return <span style={{ color: '#D0D7DE', fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>M</span>;
      case 'low':
        return <span style={{ color: '#59CCD9', fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>L</span>;

      default:
        break;
    }
  };



  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'filename',
        Cell: ({ row, value }: { row: any; value: boolean }) => {
          return (
            <div className="d-flex flex-row align-items-center">
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400'),
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {applySeverityClassname(row?.original.severity?.toLowerCase())}
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Canary',
        accessor: 'canary',
        Cell: ({ value }: any) => <CellTableIcon value={value} />,
      },
      {
        Header: 'Nx',
        accessor: 'nx',
        Cell: ({ value }: any) => <CellTableIcon value={value} />,
      },
      {
        Header: 'Pie',
        accessor: 'pie',
      },
      {
        Header: 'Relro',
        accessor: 'relro',
      },
      {
        Header: 'Fortify',
        accessor: 'fortify',
        Cell: ({ value }: any) => <CellTableIcon value={value} />,
      },
    ],
    []
  );

  const memoizedData = React.useMemo(() => {
    if (!data || !data.content) return [];

    return data.content;
  }, [data]);

  const memoizedSearch = React.useMemo(() => search, [search]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { globalFilter: memoizedSearch, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  const scanTime = React.useMemo(
    () => (reportData ? getScanTime(reportData) : null),
    [reportData]
  );

  function onCloseModal() {
    setModalData(null);
  }

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisWithBlocks />}
      {isError && <GenericError error={error} />}
      {data && Object.keys(data).length === 0 && (
        <EmptyResults
          title={t('emptyReports:binaryScan.title')}
          description={t('emptyReports:binaryScan.subtitle')}
        // buttonLabel={t('emptyReports:binaryScan.buttonLabel')}
        // onClick={console.log}
        />
      )}
      {data && Object.keys(data).length > 0 && !isLoading && !isError && (
        <>
          <>
            <LayoutSection spacer={32}>
              <ReportHeading
                chipLabel={t('common:generic.completed')}
                buttonLabel={t('common:generic.downloadReport')}
                onButtonClick={noop}
                descriptionButtonLabel={t('common:generic.viewDetails')}
                onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
              />
            </LayoutSection>
            <BinaryAnalysisContainer
              data={reportData?.analysis?.binary || []}
              bannerText="The Binary analysis checks that the compiler settings for all executables conform to secure coding practices (i.e. Stack Canaries, etc.)."
            />
            <Container fluid style={{
              border: '1px solid var(--neutral300)',
              marginTop: 40,
              borderRadius: '2px'
            }}>
              <Row>
                <Col className="px-0" xs={12}>
                  <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>Binary Analysis</div>
                  <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total: {memoizedData?.length || 0}</div>
                  <Table
                    tableInstance={tableInstance}
                    rowStyle={{ cursor: 'pointer' }}
                    onClickRow={(row: any) => {
                      setModalData({
                        title: row?.original?.filename,
                        compiler:
                          row?.original?.compiler ?? t('common:generic.none'),
                        auxInfo:
                          row?.original?.auxInfo ?? t('common:generic.none'),
                        capabilities:
                          row?.original?.capabilities?.join(', ') ??
                          t('common:generic.none'),
                        warnings:
                          row?.original?.warnings ?? t('common:generic.none'),
                      });
                    }}
                  >
                    <Table.Footer>
                      <p className={tableStyles['table__paginationState']}>
                        {paginationMessage}
                      </p>
                      <div className={tableStyles['table__paginationButtons']}>
                        <button
                          className={
                            tableInstance?.canPreviousPage
                              ? tableStyles['table__paginationActiveButton']
                              : ''
                          }
                          onClick={() => tableInstance.previousPage()}
                        >
                          <RiArrowLeftSLine size={20} />
                        </button>
                        <button
                          className={
                            tableInstance?.canNextPage
                              ? tableStyles['table__paginationActiveButton']
                              : ''
                          }
                          onClick={() => tableInstance.nextPage()}
                        >
                          <RiArrowRightSLine size={20} />
                        </button>
                      </div>
                      <TablePageSize
                        maxResultChunkSize={maxResultChunkSize}
                        pageSize={tableInstance?.state?.pageSize || 10}
                        onPageSizeChange={onPageSizeChange}
                      />
                    </Table.Footer>
                  </Table>
                </Col>
              </Row>
            </Container>
          </>
        </>
      )}
      {modalData ? (
        <TableModal title={modalData?.title} onClose={onCloseModal}>
          <ModalVerticalReport report={omit(modalData, 'title')} />
        </TableModal>
      ) : null}
      {reportData && shouldShowModalDetail ? (
        <ModalReportLinuxDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData.reportDetails,
            submitted: format(
              new Date(`${reportData.startDate ?? new Date()}`),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: reportData.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
