import React, { ReactNode } from 'react';
import styles from '../styles.module.scss';

type InfoBoxMessageProps = {
  message?: string | ReactNode;
  style?: React.CSSProperties;
};

const InfoBoxMessage = (props: InfoBoxMessageProps) => {
  const { message, style = {} } = props;
  return (
    <div className={styles['info-box-message-container']} style={style}>
      <>{message}</>
    </div>
  );
};

export default InfoBoxMessage;
