// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Form } from 'react-bootstrap';
import formStyles from './styles.module.scss';

const TextInput = (props) => {
  const {
    input: { name, onChange, onBlur, value, type },
    meta,
    readOnly = false,
    label,
    placeholder,
    helperText = '',
    ...propsField
  } = props;

  const isInvalid = meta.touched && meta.error;
  const bProps = {};
  if (propsField.size) {
    Object.assign(bProps, {
      size: propsField.size,
    });
  }

  if (isInvalid) {
    Object.assign(bProps, {
      isInvalid: true,
    });
  }

  return (
    <Form.Group className="mb-3" controlId={`form_${name}`}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        className={`${formStyles['input-control']}`}
        type={type}
        placeholder={placeholder || label}
        readOnly={readOnly}
        name={name}
        value={propsField.value ? propsField.value : value}
        onChange={(...params) => {
          if (onChange) {
            onChange(...params);
          }
          if (propsField.onChange) {
            propsField.onChange(...params);
          }
        }}
        onBlur={(...params) => {
          if (onBlur) {
            onBlur(...params);
          }

          if (propsField.onBlur) {
            propsField.onBlur(...params);
          }
        }}
        {...bProps}
      />
      {helperText ? (
        <Form.Text className="text-muted">{helperText}</Form.Text>
      ) : null}
      {isInvalid ? (
        <Form.Text className="text-danger">{meta.error}</Form.Text>
      ) : null}
    </Form.Group>
  );
};

export default TextInput;
