import {
  allObjectValueAndKeysToString,
  checkIfValidUUID,
  truncate,
} from '@utils/index';
import { convertScoreToGrade } from '@utils/normalizers';
import {
  Report,
  ReportBinaryAnalysis,
  ReportBinaryContentAnalysis,
  ReportBomViewerAnalysis,
  ReportConfigAnalyzerAnalysis,
  ReportConfigAnalyzerEnv,
  // ReportOverviewCapabilities,
  // ReportOverviewCapabilities,
  // REPORT_CAPABILITIES_TYPE,
  ReportConfigAnalyzerHistory,
  ReportCryptoAnalyzerAnalysis,
  ReportCryptoAnalyzerContentAnalysis,
  ReportCryptoAnalyzerOverviewAnalysis,
  ReportCveCheckAnalysis,
  ReportCveCheckSpecificSeverity,
  ReportFilesystemAnalysis,
  ReportFilesystemAnalysisContent,
  ReportKernelSecurityAnalysis,
  ReportNvramAnalysis,
  ReportOverview,
  ReportOverviewSeverity,
  ReportPasswordHashAnalysis,
  ReportPeimDxeAnalysis,
  ReportPeimDxeAnalysisContent,
  ReportPeimDxeOverviewAnalysis,
  ReportRtosCapabilitiesContentAnalysis,
  ReportRtosFunctionsAnalysis,
  ReportRtosSymbolsAnalysis,
  ReportRtosTasksAnalysis,
  ReportSecureBootAnalysis,
  ReportSecureBootEntries,
  ReportSecurityScanAnalysis,
  ReportStaticCodeAnalysis,
  ReportStaticCodeContentChildAnalysis,
  ReportUefiAccessRightsAnalysis,
  ReportUefiAttackSurfaceAnalysis,
  ReportUefiIntelBootGuardAnalysis,
  ReportUefiIntelBootGuardAnalysisContent,
  ReportUefiSecurityScanAnalysis,
  REPORT_ATTACK_SURFACE_TYPE,
  // ReportRtosCapabilitiesAnalysis,
  REPORT_CAPABILITIES_TYPE,
  REPORT_CODE_ANALYSIS_TYPES,
  REPORT_CRYPTO_ANALYZER_TYPE,
  REPORT_SEVERITY,
  REPORT_UEFI_TYPE_MODULE,
} from '@utils/types/report';
import byteSize from 'byte-size';
import {
  camelCase,
  every,
  flatten,
  get,
  groupBy,
  isArray,
  last,
  sortBy,
  startCase,
  toLower,
  union,
  uniq,
} from 'lodash';

function add(accumulator: number, a: number): number {
  return accumulator + a;
}

function getSeverityByScore(score: number): REPORT_SEVERITY | undefined {
  if (score < 2) return REPORT_SEVERITY.HIGH;
  if (score <= 4) return REPORT_SEVERITY.MEDIUM;
  if (score > 4) return REPORT_SEVERITY.LOW;
}

function normalizeAnalysisStatus(
  status = ''
): { [key: string]: boolean } | undefined {
  if (!status) {
    return;
  }

  const objectStatus = JSON.parse(status);

  return Object.keys(objectStatus).reduce(
    (acc, item) => ({
      ...acc,
      [camelCase(item)]: objectStatus[item],
    }),
    {}
  );
}

export function normalizeReportListFromRaw(report: any): Report {
  return {
    id: report?.id,
    workspaceId: report?.workspace_id,
    name: report?.name,
    description: report?.description,
    archived: report?.archived,
    favourite: report?.favourite,
    type: report?.project_type?.toLowerCase(),
    subType: report?.project_subtype,
    originalName: report?.original_name?.replace(' ', 'T'),
    creationDate: report?.creation_date?.replace(' ', 'T'),
    startDate: report?.start_date?.replace(' ', 'T'),
    extractionEndDate: report?.extraction_end_date?.replace(' ', 'T'),
    analysisEndDate: report?.analysis_end_date?.replace(' ', 'T'),
    hash: report?.hash ?? 'none',
    size: report?.size,
    continuousScan: Boolean(report?.continuous_scan),
    notification: Boolean(report?.notification),
    status: report?.status,
    analysisStatus: normalizeAnalysisStatus(report?.analysis_status ?? ''),
    score: report?.score,
    quickOverview: report?.quickOverview,
    grade: convertScoreToGrade(report?.score, report?.status),
  };
}

type FlawTypes = {
  [key: string]: number;
};

export type IntRoundType = {
  [key: string]: {
    int: number;
    rounded: number;
  };
};

function normalizeCodeAnalysisFlawTypeFromNumberToPercent(
  flawTypes: FlawTypes
): IntRoundType {
  const totalCount = Object.keys(flawTypes)
    .map((f) => Math.round(flawTypes[f]))
    .reduce(add, 0);

  return {
    ...Object.keys(flawTypes).reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: {
          int: ((flawTypes[currentValue] / totalCount) * 100).toFixed(2),
          rounded: Math.round((flawTypes[currentValue] / totalCount) * 100),
        },
      }),
      {}
    ),
  };
}

function normalizeReportOverviewHistory(
  history: any
): ReportConfigAnalyzerHistory {
  return {
    created: history?.created ?? '',
    createdBy: history?.created_by ?? '',
    emptyLayer: history?.empty_layers ?? false,
  };
}

function normalizeReportOverviewEnv(value: string): ReportConfigAnalyzerEnv {
  return {
    name: 'PATH',
    value: value ?? '',
  };
}

export function normalizeReportOverviewFromRaw(report: any): ReportOverview {
  const capabilites = JSON.parse(get(report, 'info.capabilities', '{}'));
  return {
    ...normalizeReportListFromRaw(report?.project),
    reportDetails: {
      kernel: report?.info?.kernel ?? 'none',
      arch: report?.info?.arch ?? 'none',
      libc: report?.info?.libc ?? 'none',
      size: report?.project?.size ?? 'none',
      hash: report?.project?.hash ?? 'none',
      name: report?.project?.name ?? 'none',
      firmwareId: report?.info?.id ?? 'none',
      kernelCompiler: report?.project?.kernelc ?? 'none',
      dxeNo: report?.info?.dxe_no ?? 0,
      peiNo: report?.info?.pei_no ?? 0,
      endianness: report?.info?.endianness ?? 'none',
      wordSize: report?.info?.word_size ?? 0,
      os: report?.info?.os ?? 'none',
      manufacturer: report?.info?.manufacturer ?? 'none',
      s3mit: report?.info?.s3mit ?? 'none',
    },
    analysis: {
      kernelSecurity: report?.kernel_security ?? 0,
      passwordHash: report?.password_hash ?? 0,
      securityScan: report?.security_scan ?? 0,
      intelBootGuard: report?.ibg === 'Enabled' ? true : false,
      secureBoot: report?.secureboot ?? false,
      peimDxe: report?.peimdxe_unknown_dependencies ?? 0,
      accessRights: report?.access_rw ?? 0,
      networkFunctions: capabilites?.network_functions ?? 0,
      packetSniff: capabilites?.packet_sniff ?? 0,
      functions: report?.info?.functions_no,
      tasks: report?.info?.tasks_no,
      symbols: report?.info?.symbols_no,
      env: report?.info?.env
        ? JSON.parse(report?.info?.env ?? [])?.map(normalizeReportOverviewEnv)
        : [],
      history: report?.info?.history
        ? JSON.parse(report?.info?.history ?? [])?.map(
            normalizeReportOverviewHistory
          )
        : [],
      cveCheck: report?.cve_check
        ? {
            critical: {
              severity: report?.cve_check?.severity?.critical ?? 0,
              preview:
                report?.cve_check?.preview[
                  REPORT_SEVERITY.CRITICAL.toUpperCase()
                ]?.map(normalizeCveCheckSpecificSeverityFromRaw) ?? [],
            },
            high: {
              severity: report?.cve_check?.severity?.high ?? 0,
              preview:
                report?.cve_check?.preview[
                  REPORT_SEVERITY.HIGH.toUpperCase()
                ]?.map(normalizeCveCheckSpecificSeverityFromRaw) ?? [],
            },
            medium: {
              severity: report?.cve_check?.severity?.medium ?? 0,
              preview:
                report?.cve_check?.preview[
                  REPORT_SEVERITY.MEDIUM.toUpperCase()
                ]?.map(normalizeCveCheckSpecificSeverityFromRaw) ?? [],
            },
            low: {
              severity: report?.cve_check?.severity?.low ?? 0,
              preview:
                report?.cve_check?.preview[
                  REPORT_SEVERITY.LOW.toUpperCase()
                ]?.map(normalizeCveCheckSpecificSeverityFromRaw) ?? [],
            },

            lastCheck: report?.cve_check?.last_check,
            severityByYear: report?.cve_check?.severity_by_year,
            severity: report?.cve_check?.severity,
          }
        : undefined,
      binary: report?.binary
        ? {
            critical: {
              severity: report?.binary?.severity?.critical ?? 0,
              preview:
                report?.binary?.preview[
                  REPORT_SEVERITY.CRITICAL.toUpperCase()
                ]?.map((item: any) => ({
                  filename: item?.file ?? '',
                  severity: REPORT_SEVERITY.CRITICAL,
                })) ?? [],
            },
            high: {
              severity: report?.binary?.severity?.high ?? 0,
              preview:
                report?.binary?.preview[
                  REPORT_SEVERITY.HIGH.toUpperCase()
                ]?.map((item: any) => ({
                  filename: item?.file ?? '',
                  severity: REPORT_SEVERITY.HIGH,
                })) ?? [],
            },
            medium: {
              severity: report?.binary?.severity?.medium ?? 0,
              preview:
                report?.binary?.preview[
                  REPORT_SEVERITY.MEDIUM.toUpperCase()
                ]?.map((item: any) => ({
                  filename: item?.file ?? '',
                  severity: REPORT_SEVERITY.MEDIUM,
                })) ?? [],
            },
            low: {
              severity: report?.binary?.severity?.low ?? 0,
              preview:
                report?.binary?.preview[REPORT_SEVERITY.LOW.toUpperCase()]?.map(
                  (item: any) => ({
                    filename: item?.file ?? '',
                    severity: REPORT_SEVERITY.LOW,
                  })
                ) ?? [],
            },
            sslVulns: report?.binary?.ssl_vuln,
            networking: report?.binary?.networking,
            capabilitiesOccourrences: report?.binary?.capabilities_occourrences,
            lastCheck: report?.binary?.last_check,
          }
        : undefined,

      code: {
        vulnerabilities: report?.code?.vulnerabilities ?? 0,
        filesAffected: report?.code?.files_affected ?? 0,
        analysis: normalizeCodeAnalysisFlawTypeFromNumberToPercent(
          report?.code?.flaw_type ?? {}
        ),
        flawsOccourrences: report?.code?.flaws_occourrences ?? 0,
      },
      networking: report?.binary?.networking?.count ?? 0,
      sslVulnerabilities: report?.binary?.ssl_vuln?.count ?? 0,
    },
  };
}

function getSeverityOverview(
  accumlator: any,
  item: any
): ReportOverviewSeverity {
  const currentSeverity: string =
    item?.severity || getSeverityByScore(item?.score) || '';
  const lowerCaseSeverity = currentSeverity?.toLowerCase();

  return {
    ...accumlator,
    [lowerCaseSeverity]:
      lowerCaseSeverity ===
      REPORT_SEVERITY[
        currentSeverity.toUpperCase() as keyof typeof REPORT_SEVERITY
      ].toLowerCase()
        ? accumlator[lowerCaseSeverity] + 1
        : accumlator[lowerCaseSeverity],
  };
}

function getAttackSurfaceOverview(
  accumlator: any,
  item: any
): ReportOverviewSeverity {
  const currentType: string = item?.type.split(' ')[0];
  const lowerCaseType = currentType && currentType?.toLowerCase();

  return {
    ...accumlator,
    [lowerCaseType]:
      lowerCaseType ===
      REPORT_ATTACK_SURFACE_TYPE[
        currentType as keyof typeof REPORT_ATTACK_SURFACE_TYPE
      ].toLowerCase()
        ? accumlator[lowerCaseType] + 1
        : accumlator[lowerCaseType],
  };
}

export function normalizeCveCheckFromRaw(
  reports: any[]
): ReportCveCheckAnalysis {
  return {
    overview: reports.reduce(getSeverityOverview, {
      low: 0,
      medium: 0,
      high: 0,
      critical: 0,
    }),
    severities: sortBy(
      reports.map(normalizeCveCheckSpecificSeverityFromRaw),
      (item) => {
        return [
          REPORT_SEVERITY.HIGH,
          REPORT_SEVERITY.MEDIUM,
          REPORT_SEVERITY.LOW,
        ].indexOf(item.severity);
      }
    ),
  };
}

export function normalizePasswordHashFromRaw(
  report: any
): ReportPasswordHashAnalysis {
  return {
    username: report?.username ?? '',
    password: report?.password ?? '',
    severity: REPORT_SEVERITY.HIGH,
  };
}

export function normalizeSecurityScanFromRaw(
  report: any
): ReportSecurityScanAnalysis {
  return {
    filename: report?.filename ?? '',
    description: report?.desc ?? '',
    severity: REPORT_SEVERITY.HIGH,
  };
}

export function normalizeNvramFromRaw(report: any): ReportNvramAnalysis {
  return {
    fun: report?.fun ?? '',
    exe: report?.exe,
    name: report?.name ?? '',
  };
}

export function normalizeBomViewerFromRaw(
  report: any
): ReportBomViewerAnalysis {
  return {
    filename: report?.filename ?? '',
    resolve: report?.resolve,
    occurrences: report?.occurrences ?? 0,
    license: report?.license ?? '',
  };
}

export function normalizeKernelSecurityFromRaw(
  report: any
): ReportKernelSecurityAnalysis {
  return {
    name: startCase(toLower(report?.name.replace('_', ' ') ?? '')),
    enabled: report?.enabled ?? false,
  };
}

export function normalizeCveCheckSpecificSeverityFromRaw(
  report: any
): ReportCveCheckSpecificSeverity {
  const patch = JSON.parse(report.patch);
  return {
    cveid: report?.cveid,
    product: report?.product,
    version: report?.version ?? '*',
    patch: get(patch, '[0]', null),
    severity: report?.severity,
  };
}

export function normalizeCryptoAnalyzerFromRaw(
  report: any
): ReportCryptoAnalyzerAnalysis {
  return {
    overview: report.reduce(normalizeCryptoAnalyzerOverviewFromRaw, {
      certificate: 0,
      publicKey: 0,
      privateKey: 0,
    }),
    content: report.map(normalizeCryptoAnalyzerContentFromRaw),
  };
}

const normalizeKeyEntry =
  (name: string) =>
  (entry: any): ReportSecureBootEntries => {
    return {
      name,
      issuedTo: get(entry, '[0]', ''),
      issuedBy: get(entry, '[1]', ''),
    };
  };

const normalizeDatabaseCertEntry =
  (database: string) =>
  (entry: any): ReportSecureBootEntries => {
    return {
      database,
      issuedTo: get(entry, '[0]', ''),
      issuedBy: get(entry, '[1]', ''),
    };
  };

const normalizeDatabaseHashesEntry =
  (database: string) =>
  (entry: any): ReportSecureBootEntries => {
    return {
      database,
      signature: get(entry, '[0]', ''),
      hashes: get(entry, '[1]', ''),
    };
  };

export function normalizeSecureBootFromRaw(
  report: any
): ReportSecureBootAnalysis {
  return {
    keys: Object.keys(report?.certs).reduce((acc: any, item: string): any => {
      const currentItem: [] = report?.certs[item];
      const normalizedKey: any = every(currentItem, isArray)
        ? currentItem?.map(normalizeKeyEntry(item))
        : [normalizeKeyEntry(item)(currentItem)];

      return [...acc, ...normalizedKey];
    }, []),

    databaseCerts: Object.keys(report?.databases?.certs).reduce(
      (acc: any, item: string): any => {
        const currentItem: [] = report?.databases?.certs[item];
        const normalizedKey: any = every(currentItem, isArray)
          ? currentItem?.map(normalizeDatabaseCertEntry(item))
          : [normalizeDatabaseCertEntry(item)(currentItem)];

        return [...acc, ...normalizedKey];
      },
      []
    ),
    databaseHashes: Object.keys(report?.databases?.hashes).reduce(
      (acc: any, item: string): any => {
        const currentItem: [] = report?.databases?.hashes[item];
        const normalizedKey: any = every(currentItem, isArray)
          ? currentItem?.map(normalizeDatabaseHashesEntry(item))
          : [normalizeDatabaseHashesEntry(item)(currentItem)];

        return [...acc, ...normalizedKey];
      },
      []
    ),
  };
}

export function normalizeCryptoAnalyzerContentFromRaw(
  report: any
): ReportCryptoAnalyzerContentAnalysis {
  return {
    filename: report?.filename ?? '-',
    path: report?.parent ?? '-',
    keysize: report?.pubsz ?? 0,
    issuer: report?.aux ?? '-',
    shodan: report?.shodan_total || '-',
    type: report?.type.toLowerCase(),
  };
}

function normalizeCryptoAnalyzerOverviewFromRaw(
  acc: any,
  item: any
): ReportCryptoAnalyzerOverviewAnalysis {
  return {
    ...acc,
    certificate:
      item?.type?.toLowerCase() === REPORT_CRYPTO_ANALYZER_TYPE.CERTIFICATE
        ? acc.certificate + 1
        : acc.certificate,
    privateKey:
      item?.type?.toLowerCase() === REPORT_CRYPTO_ANALYZER_TYPE.PRIVATE_KEY
        ? acc.privateKey + 1
        : acc.privateKey,

    publicKey:
      item?.type?.toLowerCase() === REPORT_CRYPTO_ANALYZER_TYPE.PUBLIC_KEY
        ? acc.publicKey + 1
        : acc.publicKey,
  };
}

function normalizeAuxInfoFromRaw(auxInfo: []): string {
  const firstElement = get(auxInfo, [0], 'none');
  const thirdElement = get(auxInfo, [2], '');

  const objectOrStringElement =
    typeof thirdElement === 'string'
      ? thirdElement
      : allObjectValueAndKeysToString(thirdElement);

  return objectOrStringElement
    ? `${firstElement}, ${objectOrStringElement}`
    : firstElement;
}

export function normalizeBinaryContentFromRaw(
  report: any
): ReportBinaryContentAnalysis {
  const auxInfo = get(report?.aux?.map(normalizeAuxInfoFromRaw), '[0]', '');
  const capabilities = get(report, 'capabilities', []);
  const warnings = get(report, 'warnings', []);
  const hasCapabilites = capabilities.length;
  const hasWarnings = warnings.length;
  const hasAuxInfo = auxInfo.length;
  return {
    filename: report?.filename ?? '',
    canary: Boolean(report?.canary ?? false),
    nx: Boolean(report?.nx ?? false),
    pie: report?.pie ?? '',
    relro: report?.relro ?? '',
    fortify: Boolean(report?.fortify ?? false),
    compiler: report?.compiler,
    auxInfo: hasAuxInfo ? auxInfo : null,
    capabilities: hasCapabilites ? capabilities : null,
    warnings: hasWarnings ? capabilities : null,
    severity: getSeverityByScore(report?.score),
  };
}

export function normalizeBinaryFromRaw(reports: any): ReportBinaryAnalysis {
  const capabilities: string[] = uniq(
    flatten(reports.map((r: any) => get(r, 'capabilities', [])))
  );

  return {
    overview: {
      severity: reports.reduce(getSeverityOverview, {
        low: 0,
        medium: 0,
        high: 0,
        critical: 0,
      }),
      capabilities: capabilities || [],
    },
    content: reports.map(normalizeBinaryContentFromRaw),
  };
}

function normalizeReportStaticCodeContentChild({
  flaws,
}: any): ReportStaticCodeContentChildAnalysis {
  return {
    format: flaws.flaw_type,
    description: flaws.descr,
    code: flaws.line,
  };
}

const mergeStaticCodeContent =
  (content: any) =>
  (acc: ReportStaticCodeAnalysis[], label: any): ReportStaticCodeAnalysis[] => {
    const currentContent = content[label];
    const types: REPORT_CODE_ANALYSIS_TYPES[] = currentContent.map((c: any) =>
      c.flaws.flaw_type.toLowerCase()
    );
    return [
      ...acc,
      {
        filename: label,
        types: union(types),
        occurrences: currentContent.length,
        children: [
          ...currentContent.map(normalizeReportStaticCodeContentChild),
        ],
      },
    ];
  };

export function normalizeContentFromStringToJson(report: any): any {
  return {
    ...report,
    flaws: JSON.parse(report?.flaws ?? ''),
  };
}

export function normalizeCodeFromRaw(reports: any): ReportStaticCodeAnalysis[] {
  const groupedContent = groupBy(
    reports.map(normalizeContentFromStringToJson),
    (report) => report.filename
  );
  return Object.keys(groupedContent).reduce(
    mergeStaticCodeContent(groupedContent),
    []
  );
}

const filesystemCreator =
  (row: any) =>
  (label: string): ReportFilesystemAnalysisContent => {
    const currentItem = row[label];
    const type = get(currentItem, '[1]', '');
    const size = get(currentItem, '[2]', 0);
    const description = get(currentItem, '[0]', '');
    const normalizedSize = byteSize(size);
    return {
      label: last(label?.split('/')) ?? '',
      description,
      type,
      isAFolder: type === 'inode/directory',
      filesize: size
        ? { unit: normalizedSize?.unit, value: normalizedSize?.value }
        : undefined,
    };
  };

export function normalizeFilesystemFromRaw(
  report: any,
  isRoot: boolean
): ReportFilesystemAnalysis {
  return {
    isRoot,
    content: Object.keys(report).map(filesystemCreator(report)),
  };
}

export function normalizeUefiSecurityScanFromRaw(
  report: any
): ReportUefiSecurityScanAnalysis {
  return {
    name: report?.name ?? '',
    module: report?.module ?? '',
    guid: report?.guid,
    severity: REPORT_SEVERITY.HIGH,
  };
}

export function normalizeUefiIntelBootGuardContentFromRaw(
  report: any
): ReportUefiIntelBootGuardAnalysisContent {
  return {
    name: get(report, '[0]', ''),
    value: truncate(get(report, '[1]', ''), 60),
    content: get(report, '[1]', ''),
  };
}

export function normalizeUefiIntelBootGuardFromRaw(
  report: any
): ReportUefiIntelBootGuardAnalysis {
  return {
    enabled: report?.acm ? true : false,
    content: report?.rsa.map(normalizeUefiIntelBootGuardContentFromRaw),
  };
}

export function normalizeUefiAccessRightsScanFromRaw(
  report: any
): ReportUefiAccessRightsAnalysis {
  return {
    name: report?.region,
    read: report?.read?.toLowerCase() === 'yes' ? true : false,
    write: report?.write?.toLowerCase() === 'yes' ? true : false,
  };
}

export function normalizeAttackSurfaceFromRaw(
  report: any
): ReportUefiAttackSurfaceAnalysis {
  return {
    overview: report.reduce(getAttackSurfaceOverview, {
      nvar: 0,
      vss: 0,
      evsa: 0,
    }),
    content: report.map((el: any) => {
      return {
        name: el?.name,
        guid: el?.guid,
        type: el?.type.split(' ')[0].toLowerCase(),
      };
    }),
  };
}

function normalizeCertificate(acc: any, certificate: any) {
  return {
    issuers: [...acc.issuers, certificate?.issuer].filter(Boolean),
    subjects: [...acc.subjects, certificate?.subject].filter(Boolean),
  };
}

function normalizePeimDxeContentFromRaw(
  content: any
): ReportPeimDxeAnalysisContent {
  return {
    name: content?.name ?? '',
    uefiType: content?.type,
    filetype: content?.filetype,
    format: content?.format,
    machine: content?.machine,
    signed: content?.sign,
    dependencies: content?.dependencies ?? [],
    certificates:
      content?.certificates?.reduce(normalizeCertificate, {
        issuers: [],
        subjects: [],
      }) ?? [],
    hasUnknown: content?.dependencies?.map(checkIfValidUUID)?.some(Boolean),
  };
}

function normalizePeimDxeOverviewFromRaw(
  previousValue: any,
  currentValue: ReportPeimDxeAnalysisContent
): any {
  return {
    ...previousValue,
    [currentValue.uefiType]: previousValue[currentValue?.uefiType] + 1,
  };
}

export function normalizePeimDxeFromRaw(reports: []): ReportPeimDxeAnalysis {
  const normalizedContent = reports.map(normalizePeimDxeContentFromRaw);
  const numberOfIssues = normalizedContent.filter(
    (content) => content.hasUnknown
  );
  const countOfModules = normalizedContent.reduce(
    normalizePeimDxeOverviewFromRaw,
    {
      [REPORT_UEFI_TYPE_MODULE.PEI_CORE]: 0,
      [REPORT_UEFI_TYPE_MODULE.PEI_MODULE]: 0,
      [REPORT_UEFI_TYPE_MODULE.DXE_DRIVER]: 0,
      [REPORT_UEFI_TYPE_MODULE.DXE_CORE]: 0,
      [REPORT_UEFI_TYPE_MODULE.COMBINED]: 0,
    }
  );

  const contentLength = normalizedContent?.length ?? 0;
  return {
    overview: {
      issues: numberOfIssues.length,
      modulePercent: Object.keys(countOfModules).reduce(
        (
          allModule: any,
          module: string
        ): ReportPeimDxeOverviewAnalysis['modulePercent'] => {
          const currentValue = (countOfModules[module] / contentLength) * 100;

          return {
            ...allModule,
            [module]: {
              int: currentValue ? currentValue?.toFixed(2) : 0,
              rounded: Math.round(currentValue * 100),
            },
          };
        },
        {}
      ),
    },
    content: normalizedContent,
  };
}

export function normalizeRtosFunctionsScanFromRaw(
  report: any
): ReportRtosFunctionsAnalysis {
  return {
    name: report?.name,
    size: report?.size,
    offset: report?.offset,
  };
}

export function normalizeRtosSymbolsScanFromRaw(
  report: any
): ReportRtosSymbolsAnalysis {
  return {
    name: report?.name,
    offset: report?.offset,
  };
}

export function normalizeRtosTasksScanFromRaw(
  report: any
): ReportRtosTasksAnalysis {
  return {
    name: report?.task_name && report?.task_name.replace(/['"]+/g, ''),
    address: `0x${report?.task_addr}`,
    createdBy: report?.fcn_name,
  };
}

export function normalizeRtosCapabilitiesScanContentFromRaw(
  report: any
): ReportRtosCapabilitiesContentAnalysis {
  return report?.caps.map((item: string) => {
    function isAlert() {
      if (
        item === REPORT_CAPABILITIES_TYPE.NETWORK_FUNCTIONS ||
        item === REPORT_CAPABILITIES_TYPE.PACKET_SNIFF
      ) {
        return true;
      } else {
        return false;
      }
    }

    return {
      name: truncate(report.name, 60),
      capabilities: item,
      alert: isAlert(),
      value: report.name,
    };
  });
}

// function normalizeCapabilitiesOverviewFromRaw(
//   previousValue: any,
//   currentValue: ReportRtosCapabilitiesContentAnalysis
// ): any {
//   return {
//     ...previousValue,
//     [currentValue.capabilities]: previousValue[currentValue?.capabilities] + 1
//   };
// }

// export function normalizeRtosCapabilitiesScanFromRaw(
//   report: []
// ): ReportRtosCapabilitiesAnalysis {
//   const normalizedContent = report
//     .map(normalizeRtosCapabilitiesScanContentFromRaw)
//     .flat();
//
//   const countOfModules = normalizedContent.reduce(
//     normalizeCapabilitiesOverviewFromRaw,
//     {
//       [REPORT_CAPABILITIES_TYPE.FILE_FUNCTIONS]: 0,
//       [REPORT_CAPABILITIES_TYPE.TASK_MANIPULATION]: 0,
//       [REPORT_CAPABILITIES_TYPE.INFORMATION_GATHERING]: 0,
//       [REPORT_CAPABILITIES_TYPE.NETWORK_FUNCTIONS]: 0,
//       [REPORT_CAPABILITIES_TYPE.SYSTEM_LOG]: 0,
//       [REPORT_CAPABILITIES_TYPE.PACKET_SNIFF]: 0,
//       [REPORT_CAPABILITIES_TYPE.PIPE_FUNCTIONS]: 0,
//       [REPORT_CAPABILITIES_TYPE.RANDOM_FUNCTIONS]: 0,
//       [REPORT_CAPABILITIES_TYPE.SHELL]: 0,
//       [REPORT_CAPABILITIES_TYPE.ENVIRONMENT_VARIABLES]: 0,
//       [REPORT_CAPABILITIES_TYPE.PERMISSIONS]: 0,
//       [REPORT_CAPABILITIES_TYPE.HOOKING]: 0
//     }
//   );
//
//   const contentLength = report?.length ?? 0;
//   return {
//     content: normalizedContent.flat(),
//     overview: {
//       networkIssues: countOfModules[REPORT_CAPABILITIES_TYPE.NETWORK_FUNCTIONS],
//       packetIssues: countOfModules[REPORT_CAPABILITIES_TYPE.PACKET_SNIFF],
//       modulePercent: Object.keys(countOfModules).reduce(
//         (
//           allModule: any,
//           module: string
//         ): ReportOverviewCapabilities['modulePercent'] => {
//           const currentValue = (countOfModules[module] / contentLength) * 100;
//
//           return {
//             ...allModule,
//             [module]: {
//               int: currentValue ? currentValue?.toFixed(2) : 0,
//               rounded: Math.round(currentValue * 100)
//             }
//           };
//         },
//         {}
//       )
//     }
//   };
// }

export function normalizeConfigAnalyzerFromRaw(
  reports: any
): ReportConfigAnalyzerAnalysis {
  return {
    envs: [],
    history: [],
  };
}
