import { useQuery } from 'react-query';
import type { ReportSecurityScanAnalysis } from '@utils/types/report';
import { getReportSecurityScanAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'security-scan-analysis';

export default function useSecurityScanAnalysis(id: string) {
  useToken();
  return useQuery<ReportSecurityScanAnalysis[], Error>([KEY, id], () =>
    getReportSecurityScanAnalysis(id),
    {
      retry: false
    }
  );
}
