import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { get } from 'react-hook-form';
import { AiOutlineEdit } from 'react-icons/all';

import { GlobalContext } from '@context/GlobalContext';
import { UserContext } from '@context/UserContext';
import usePlaceholders from '@hooks/usePlaceholders';
import { OrganizationFullType } from '@utils/types/organization';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type OrganizationPlanProps = {
  editMode?: boolean;
  picture?: string;
  people?: any[];
  organizationData: OrganizationFullType;
  showUpdate?: boolean;
  onClickUpdate?: () => void;
};

type OrganizationMemberTypes = {
  organizationData: OrganizationFullType;
};

const OrganizationPlan = (props: OrganizationPlanProps) => {
  const {
    editMode = false,
    showUpdate = false,
    organizationData,
    onClickUpdate = () => null,
  } = props;

  const { theme } = React.useContext<any>(GlobalContext);
  console.log('organizationData', organizationData);
  const { organization } = organizationData;

  const { t } = useTranslation(['common', 'userSettings']);
  const { getUserPlaceholder, getOrganizationPlaceholder } = usePlaceholders();

  const { setFile } = React.useContext<any>(UserContext);
  const [orgImage, setOrgImage] = React.useState<string>('');

  // React.useEffect(() => {
  //   init();
  // }, [organization?.id, theme]);

  // const init = async () => {
  //   if (organization?.id) {
  //     let orgImage = null;
  //     try {
  //       orgImage = await firebase
  //         .storage()
  //         .ref(`organization/${organization?.id}/profile.jpg`)
  //         .getDownloadURL();
  //       setOrgImage(orgImage);
  //     } catch (error) {
  //       setOrgImage(getOrganizationPlaceholder());
  //     }
  //   }
  // };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = get(e, 'target.files[0]', null);
    if (file) {
      setFile(file);
      // const ref = firebase
      //   .storage()
      //   .ref(`organization/${organization?.id}/profile.jpg`);
      // const uploadTask = ref.put(file);
      // uploadTask.on('state_changed', console.log, console.error, () => {
      //   ref.getDownloadURL().then((url) => {
      //     setOrgImage(url);
      //   });
      // });
    }
  };

  const firstElementsTeam = [...organizationData?.members].slice(0, 6);

  return (
    <div className={styles.organizationContainer}>
      <div className={styles.organizationImageContainer}>
        <div className={styles.organizationImageWrapper}>
          <img
            style={{ background: 'white' }}
            src={orgImage || getOrganizationPlaceholder()}
            className={styles.organizationImage}
            alt="organization_image"
          />
          {editMode && (
            <div style={{ position: 'absolute', bottom: -10, left: 40 }}>
              <input
                id={'uploadPhoto'}
                type="file"
                accept=".jpg, .jpeg"
                onChange={handleChange}
                className={styles.inputField}
              />
              <label htmlFor={'uploadPhoto'} className={styles.labelBtn}>
                <AiOutlineEdit className={'editIcon'} />{' '}
                {t('userSettings:buttons.photo')}
              </label>
            </div>
          )}
        </div>
      </div>
      {/*<div className={styles.organizationDetailsWrapper}>*/}
      {/*<div className={styles.organizationDetailContainer}>*/}
      <div className={styles.organizationInfosLeft}>
        <p>Name</p>
        <span>{organization?.name}</span>
        <p className={styles.description}>Organization</p>
      </div>
      <div className={styles.organizationInfosRight}>
        <div className={styles.peopleContainerWrapper}>
          <p>People</p>
          <div className={styles.peopleContainer}>
            {firstElementsTeam.map((member) => (
              <OverlayTrigger
                placement="top"
                rootClose={true}
                overlay={
                  member?.email ? (
                    <Tooltip id={`name-${member.id}`} {...props}>
                      {member.email}
                    </Tooltip>
                  ) : (
                    <></>
                  )
                }
              >
                <div>
                  <img
                    src={
                      member?.image_url
                        ? member.image_url
                        : getUserPlaceholder()
                    }
                    className={styles.organizationImage}
                    alt={member?.email || ''}
                  />
                </div>
              </OverlayTrigger>
            ))}
            {organizationData?.members.length > 6 && (
              <OrganizationMemberPopUp organizationData={organizationData} />
            )}
          </div>
          <p className={styles.description}>Members</p>
        </div>
        {/*</div>*/}
      </div>

      <div className={styles['button-container']}>
        {showUpdate && (
          <Button variant={'primary'} size={'sm'} onClick={onClickUpdate}>
            UPDATE ORGANIZATION
          </Button>
        )}
      </div>

      {/*</div>*/}
    </div>
  );
};

const OrganizationMemberPopUp = (props: OrganizationMemberTypes) => {
  const { getUserPlaceholder } = usePlaceholders();
  const { organizationData } = props;
  return (
    <div
      className={'d-flex flex-column justify-content-center align-items-center'}
    >
      <OverlayTrigger
        placement="bottom"
        trigger="click"
        popperConfig={{
          modifiers: [
            {
              name: 'offset',
              enabled: true,
              options: { offset: [0, 10] },
            },
          ],
        }}
        rootClose={true}
        overlay={
          <div
            {...props}
            className={'show'}
            style={{
              position: 'absolute',
            }}
          >
            <div
              className={`d-flex align-items-center bg-body ${styles['organization-team-popup']}`}
            >
              <div className={styles.teamContainerWrapper}>
                <p>All people in this organization</p>
                <div className={styles.teamContainer}>
                  {organizationData?.members?.map((member) => {
                    // const roboAvatar = `https://robohash.org/${
                    //   member.email[0].toUpperCase() + member.email[1].toLowerCase()
                    // }?size=50x50`;
                    return (
                      <OverlayTrigger
                        placement="top"
                        rootClose={true}
                        overlay={
                          <Tooltip id={`name-${member.id}`} {...props}>
                            {member?.email}
                          </Tooltip>
                        }
                      >
                        <img
                          src={member?.image_url || getUserPlaceholder()}
                          className={styles.teamImage}
                          alt={member?.email}
                        />
                      </OverlayTrigger>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        }
      >
        <button>
          <span style={{ fontSize: '.8rem' }}>+ 8</span>
        </button>
      </OverlayTrigger>
    </div>
  );
};

export default OrganizationPlan;
