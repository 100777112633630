import React from 'react';
import styles from './styles.module.scss';

type ProgressBarProps = {
  value: number,
  min?: number,
  max?: number,
  color?: string
}
const ProgressBar = ({ value, color = 'inherit', min = 0, max = 100 }: ProgressBarProps) => {
  const percent = Math.ceil((value / (max - min)) * 100 - 1);

  return (
    <div className={styles['progress-bar-container']}>
      <div className={styles['progress-bar-wrapper']}
           style={{ width: `${percent}%`, backgroundColor: color }}>
      </div>
      <div className={styles['progress-bar-dot']} style={{ backgroundColor: color }} />
    </div>
  );
};

export default ProgressBar;