import { getGraphTheme } from '@components/Graph/theme';
import { GlobalContext } from '@context/GlobalContext';
import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import { getCssVariable } from '@utils/index';
import React from 'react';

type PeimDxeGraphProps = {
  data: any;
};

type PeimDxeData = {
  certificates: Array<any>;
  dependencies: Array<string>;
  filetype: string;
  format: string;
  machine: string;
  name: string;
  signed: boolean;
  uefiType: string;
  hasUnknown: boolean;
  loc?: number;
  color: string;
};

type ChildrenGraph = {
  name: string;
  children: Array<PeimDxeData>;
  color: string;
};

type PeimDxeGraphData = {
  name: string;
  color: string;
  children: Array<ChildrenGraph>;
};

const PeimDxeGraph = ({ data }: PeimDxeGraphProps) => {
  const { theme } = React.useContext(GlobalContext);
  const graphTheme = getGraphTheme(theme);

  const prepareChartData = React.useCallback(
    (dataArray: PeimDxeData[]) => {
      const base: PeimDxeGraphData = {
        name: 'data',
        color: `${theme === 'dark' ? 'rgba(255 , 255 , 255 ,0.1)' : '#F6F8FA'}`,

        children: [
          {
            name: 'DXE',
            children: [],
            color: `${theme === 'dark' ? '#0D1117' : '#dbe5f0'}`,
          },
          {
            name: 'PEI',
            children: [],
            color: `${theme === 'dark' ? '#0D1117' : '#dbe5f0'}`,
          },
        ],
      };
      if (!dataArray || dataArray.length < 1) return [];
      dataArray.forEach((el: PeimDxeData) => {
        if (el.uefiType && el.uefiType.split(' ')[0] === 'DXE') {
          base.children[0].children.push({
            ...el,
            loc: el.dependencies.length,
            color: getCssVariable('violet'),
          });
        }
        if (el.uefiType && el.uefiType.split(' ')[0] === 'PEI') {
          base.children[1].children.push({
            ...el,
            loc: el.dependencies.length,
            color: getCssVariable('cyan'),
          });
        }
      });
      return base;
    },
    [data, theme]
  );

  return (
    <ResponsiveCirclePacking
      leavesOnly={false}
      data={prepareChartData(data)}
      margin={{ top: 20, right: 40, bottom: 20, left: 0 }}
      theme={graphTheme}
      id="name"
      value="loc"
      // colors={{ scheme: 'nivo' }}
      colors={({ data }: any) => data.color}
      colorBy="depth"
      childColor={{
        from: 'color',
        modifiers: [['brighter', 0.4]],
      }}
      padding={4}
      enableLabels={false}
      labelsFilter={function (n) {
        return 2 === n.node.depth;
      }}
      labelsSkipRadius={10}
      labelTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.5]],
      }}
      defs={[
        {
          id: 'lines',
          type: 'patternLines',
          background: 'none',
          color: 'inherit',
          rotation: -45,
          lineWidth: 5,
          spacing: 8,
        },
      ]}
      fill={[
        {
          match: {
            depth: 1,
          },
          id: 'lines',
        },
      ]}
    />
  );
};

export default PeimDxeGraph;
