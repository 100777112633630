import Table from '@components/TableNew/Table';
import { getCssVariable, padNumber } from '@utils/index';
import React from 'react';
import { useFilters, usePagination, useTable } from 'react-table';
import styles from '../styles.module.scss';

type BinaryTableProps = {
  data?: any;
  columns?: any;
};

const BinaryTable = ({ data }: BinaryTableProps) => {
  const applySeverityClassname = (severity: string) => {
    switch (severity) {
      case 'critical':
        return <span style={{ color: getCssVariable('bs-purple'), fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>C</span>;
      case 'high':
        return <span style={{ color: '#F86767', fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>H</span>;
      case 'medium':
        return <span style={{ color: '#D0D7DE', fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>M</span>;
      case 'low':
        return <span style={{ color: '#59CCD9', fontSize: 12, fontWeight: 600, padding: '0 10px 0 5px' }}>L</span>;

      default:
        break;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'filename',
        width: 1500,
        Cell: (props: any) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400'),
                }}
              >
                {padNumber(props.row?.index + 1)}
              </span>
              <div>
                {applySeverityClassname(props.row?.original.severity?.toLowerCase())}
                <span>{props.value}</span>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Severity',
        accessor: 'severity',
        width: 50,
        Cell: (props: any) => {
          return (
            <div
              className={`${styles['severity-wrap-table-accessor']}`}
            >
              <span>{`${props.value} severity`}</span>
            </div>
          );
        }
      }
    ],
    []
  );

  const memoizedData = React.useMemo(() => data && data.length > 0 ? data : [], [data]);

  const tableInstance = useTable({
    columns,
    data: memoizedData,
    pageCount: 999
  }, useFilters, usePagination);

  return <Table className='border-0' tableInstance={tableInstance} />;
};

export default BinaryTable;
