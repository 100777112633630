import GradeBadge, { GRADE_SIZE, GRADE_TYPE } from '@components/GradeBadge';
import ModalRating from '@components/ModalRating';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type OverviewGradeProps = {
  grade: GRADE_TYPE;
};

const OverviewGrade = ({ grade }: OverviewGradeProps) => {
  const [shouldShowModalRating, setShouldShowModalRating] =
    React.useState<boolean>(false);

  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  //prettier-ignore
  const title = t(`pages:overviewPage.sections.reportOverview.gradesStatus.${grade?.toString()}.title`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  //prettier-ignore
  const description = t(`pages:overviewPage.sections.reportOverview.gradesStatus.${grade?.toString()}.description`);

  return (
    <div className={styles['badge-container']}>
      <div className="w-100 text-center">
        <GradeBadge grade={grade} size={GRADE_SIZE.BIG} />
      </div>
      <div className={styles['grade-content']}>
        {title && <p className={styles['grade-title']}>{title}</p>}
        {description && (
          <p className={styles['grade-description']}>{description}</p>
        )}
      </div>
      {shouldShowModalRating ? (
        <ModalRating onClose={() => setShouldShowModalRating(false)} />
      ) : null}
    </div>
  );
};

export default OverviewGrade;
