import styles from './styles.module.scss';

interface IProps {
    items: { [key: string]: number }
    colors: string[]
    customClassName?: any;
    direction?: 'row' | 'column'
}

const GraphLegend: React.FC<IProps> = ({ items, colors, customClassName, direction = 'column' }) => {
    return (
        <div className={`${styles.container} ${styles[direction]}`}>
            {Object.keys(items).map((key: keyof typeof items, i: number) => <div className={`${styles.item} ${customClassName}`} style={{ borderBottom: `2px solid ${colors[i]}` }}>
                <div className={styles.label}>{key}</div>
                <div>{items[key]}</div>
            </div>)}
        </div>
    );
};

export default GraphLegend;