import React from 'react';
import ModalWrapper from '@components/ModalWrapper';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useCreateOrganization from '@hooks/organizations/useCreateOrganization';
import { Input } from '@components/Forms/Input';
import { Textarea } from '@components/Forms/Textarea';

type ModalCreateOrganizationProps = {
  onClose: () => void;
};

export type CreateOrganizationFormData = {
  name: string;
  domain: string;
  description: string;
};

const validationSchema = yup
  .object({
    name: yup.string().trim().required(),
    domain: yup.string().trim().required(),
    description: yup.string().trim().required(),
  })
  .required();

const initialValues: CreateOrganizationFormData = {
  name: '',
  domain: '',
  description: '',
};

const ModalCreateOrganization = ({ onClose }: ModalCreateOrganizationProps) => {
  const { t } = useTranslation(['organizations']);
  const { mutate } = useCreateOrganization();
  const {
    register,
    handleSubmit,
    // formState,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data: CreateOrganizationFormData) => {
    await mutate(data);
    onClose();
  };

  return (
    <ModalWrapper
      onClose={onClose}
      title={t('organizations:createOrganizationModal.title')}
      className="w-100"
    >
      <Container
        as="form"
        className="p-0 w-100"
        onSubmit={handleSubmit(onSubmit)}
        fluid
      >
        <Row>
          <Col xs={12}>
            <Input
              {...register('name')}
              label={t(
                'organizations:createOrganizationModal.form.fields.name'
              )}
              className="mb-4"
            />
            <Input
              {...register('domain')}
              label={t(
                'organizations:createOrganizationModal.form.fields.domain'
              )}
              className="mb-4"
            />
            <Textarea
              {...register('description')}
              label={t(
                'organizations:createOrganizationModal.form.fields.description'
              )}
              className="mb-4"
            />
            <div className="d-flex flex-row justify-content-end">
              <button className={'btn btn-primary btn-sm'} type="submit">
                <span>
                  {t('organizations:createOrganizationModal.form.sendButton')}
                </span>
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </ModalWrapper>
  );
};

export default ModalCreateOrganization;
