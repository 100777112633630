import queryString from 'query-string';
import { useHistory } from 'react-router';
import { ROUTES } from '@routes/routes';

export default function AuthPage() {
  const history = useHistory();
  const { oobCode, mode }: any = queryString.parse(history.location.search) ?? {
    oobCode: '',
  };

  if (mode === 'verifyEmail') {
    if (oobCode) {
      history.push(`${ROUTES.VERIFY_EMAIL}?code=${oobCode}`);
    }
  }

  if (mode === 'resetPassword') {
    if (oobCode) {
      history.push(`${ROUTES.NEW_PASSWORD}?code=${oobCode}`);
    }
  }

  return null;
}
