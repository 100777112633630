import { ReportFilesystemAnalysisContent } from '@utils/types/report';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine, RiFile3Line, RiFolderFill,
  RiSearch2Line, RiSpamLine
} from 'react-icons/ri';
// import { getCssVariable } from '@utils/index';
import { noop } from 'lodash';
// import { ARCHIVE_LIST } from '@utils/index';
import { useTranslation } from 'react-i18next';
// import SkeletonFilesystem from '@components/SkeletonFilesystem';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
// import Spinner from 'react-cli-spinners';
import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import reportStyles from '@pages/Report/styles.module.scss';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
// import EmptyResults from '@components/EmptyResults';
// import GenericError from '@components/ErrorState';
import { Input } from '@components/Forms/Input';
import LayoutPage from '@components/LayoutPage';
import usePaginationCounter, { UsePaginationCounterState } from '@hooks/usePaginationCounter';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import { getCssVariable, padNumber } from '@utils/index';
import { RiMoreFill } from 'react-icons/ri';
import styles from './styles.module.scss';

type Props = {
  data: ReportFilesystemAnalysisContent[];
  goDown: (arg0: string) => void;
  goUp: () => void;
  isInAInnerLevel?: boolean;
  isLoading: boolean;
  currentLevel: string;
};

export default function FilesystemExplorer({
  data,
  currentLevel = '',
  isLoading = false,
  goDown = noop,
  goUp = noop,
  isInAInnerLevel = false
}: Props) {
  const { t } = useTranslation(['common', 'pages']);

  // const renderRow = React.useCallback(
  //   (item: ReportFilesystemAnalysisContent, key: number) => {
  //     const extension = last(item?.label.split('.')) ?? '';
  //     return (
  //       <li key={key}>
  //         <div
  //           className={`${styles.label} ${item?.isAFolder ? styles.link : ''}`}
  //         >
  //           {item?.isAFolder ? (
  //             <>
  //               <RiFolderFill size={24} color={getCssVariable('cyan')} />
  //               <span
  //                 className={styles.labelContent}
  //                 onClick={() => goDown(item?.label)}
  //               >
  //                 {item.label}{' '}
  //                 {currentLevel === item.label ? (
  //                   <span className={styles.spinner}>
  //                     <Spinner type={'line'} />
  //                   </span>
  //                 ) : (
  //                   ''
  //                 )}
  //               </span>
  //             </>
  //           ) : (
  //             <>
  //               {ARCHIVE_LIST.includes(extension as string) ? (
  //                 <RiFileZipLine
  //                   size={24}
  //                   color={getCssVariable('neutral400')}
  //                 />
  //               ) : (
  //                 <RiFile3Line size={24} color={getCssVariable('neutral400')} />
  //               )}
  //               <span>{item.label}</span>
  //             </>
  //           )}
  //         </div>
  //         <div className={styles.description}>
  //           {!item?.isAFolder
  //             ? item.description
  //             : `${t('common:generic.folder')} `}
  //         </div>
  //
  //         {item?.filesize?.value && (
  //           <div className={styles.filesize}>
  //             {item?.filesize?.value + ' ' + item?.filesize?.unit}
  //           </div>
  //         )}
  //       </li>
  //     );
  //   },
  //   [currentLevel, t, goDown]
  // );

  const getIcon = (isFolder: boolean): React.ReactNode => {
    return isFolder ? <RiFolderFill size={24} color={getCssVariable('cyan')} /> :
      <RiFile3Line size={24} color={getCssVariable('neutral400')} />;
  };

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'label',
        Cell: ({ row, value }: { row: any, value: boolean }) => {
          return <div className='d-flex flex-row align-items-center'>
            <span
              style={{ marginRight: '.5rem', color: getCssVariable('neutral400') }}>{padNumber(row.index + 1)}</span>
            {getIcon(row.original.isAFolder)}<span style={{ marginLeft: '.5rem' }}>{value}</span>
          </div>;
        },
        width: 50
      },
      {
        Header: '',
        accessor: 'description'
      }
    ],
    []
  );

  const [search, setSearch] = React.useState('');

  const handleClickRow = (row: any) => {
    if (row.original.isAFolder) {
      goDown(row.original.label);
    }
  };

  const memoizedData = React.useMemo(() => data, [data]);
  const memoizedSearch = React.useMemo(() => search, [search]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { globalFilter: memoizedSearch, pageSize: 10 }
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState = usePaginationCounter(tableInstance);

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisSimple />}
      {/*{!isLoading && !isError && !data && (*/}
      {/*  <EmptyResults*/}
      {/*    title={t('emptyReports:passwordHash.title')}*/}
      {/*    description={t('emptyReports:passwordHash.subtitle')}*/}
      {/*    // buttonLabel={t('emptyReports:passwordHash.buttonLabel')}*/}
      {/*    onClick={console.log}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{isError && <GenericError error={error} />}*/}
      {data && Object.keys(data).length > 0 && !isLoading && (<>
        <div className='d-flex'>
          <SectionHeader
            title={t('pages:filesystemPage.title')}
            leadingIcon={RiSpamLine}
            tooltipMessage={t('pages:filesystemPage.buttonLabel')}
            bannerText={t('pages:filesystemPage.bannerMessage')}
          />
          {/* <div className={`${reportStyles['input-container']} pl-2`} style={{ flexBasis: '30%' }}>
            <Input
              placeholder='Search'
              value={search}
              onChange={onSearchHandler}
            >
              <Input.Right><RiSearch2Line /></Input.Right>
            </Input>
          </div> */}
        </div>
        <Container fluid style={{
          border: '1px solid var(--neutral300)',
          marginTop: 40,
          borderRadius: '2px'
        }}>
          <Row>
            <Col className='px-0' xs={12}>
              <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>Filesystem Navigation</div>
              <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total files: {memoizedData?.length || 0}</div>
              <Table tableInstance={tableInstance} onClickRow={(row: any) => handleClickRow(row)}
                rowStyle={{ cursor: 'pointer' }}>
                <Table.Header style={{ height: 'auto' }}>
                </Table.Header>
                <Table.FirstRow className={styles['first-row']}>
                  <div className='d-flex flex-row align-items-center justify-content-start p-2 w-100'><RiMoreFill
                    style={{ fontSize: '1.2rem', cursor: 'pointer' }} onClick={goUp} /></div>
                </Table.FirstRow>
                <Table.Footer>
                  <p className={tableStyles['table__paginationState']}>
                    {paginationMessage}
                  </p>
                  <div className={tableStyles['table__paginationButtons']}>
                    <button
                      className={
                        tableInstance?.canPreviousPage
                          ? tableStyles['table__paginationActiveButton']
                          : ''
                      }
                      onClick={() => tableInstance.previousPage()}
                    >
                      <RiArrowLeftSLine size={20} />
                    </button>
                    <button
                      className={
                        tableInstance?.canNextPage
                          ? tableStyles['table__paginationActiveButton']
                          : ''
                      }
                      onClick={() => tableInstance.nextPage()}
                    >
                      <RiArrowRightSLine size={20} />
                    </button>
                  </div>
                  <TablePageSize
                    maxResultChunkSize={maxResultChunkSize}
                    pageSize={tableInstance?.state?.pageSize || 10}
                    onPageSizeChange={onPageSizeChange}
                  />
                </Table.Footer>
              </Table>
            </Col>
          </Row>
        </Container>
      </>)}
    </LayoutPage>);
}