import * as React from 'react';
import ModalWrapper from '@components/ModalWrapper';
import { useTranslation } from 'react-i18next';
import { ReportActionsContext } from '@contexts/ReportActionsContext';
import Button, { BUTTON_TYPE } from '@components/Button';
import useUpdateReport from '@hooks/useUpdateReport';
import useReport from '@hooks/useReport';
import Spinner, { SPINNER_TYPE, SPINNER_SIZE } from '@components/Spinner';
import styles from './styles.module.scss';
import { noop } from 'lodash';
import Input from '@components/Input';

type Props = {
  onClose?: typeof noop;
};

export default function RenameReportModal({ onClose = noop }: Props) {
  const {
    data: { id, name },
    resetReportData,
  } = React.useContext(ReportActionsContext);
  const { t } = useTranslation('common');
  const handleModalClose = () => {
    resetReportData();
    onClose(null);
  };

  const { mutate, isLoading } = useUpdateReport(handleModalClose);
  const { data: report } = useReport(id);
  const [newName, setNewName] = React.useState<string>(name);

  const handleConfirmClick = () => {
    if (!isLoading)
      mutate({
        id,
        payload: {
          name: newName,
          description: report?.description ?? '',
          notification: report?.notification,
          continuous_scan: report?.continuousScan,
        },
      });
  };

  function onInputChange(event: React.FormEvent<HTMLInputElement>) {
    setNewName(event.currentTarget.value);
  }
  return id ? (
    <ModalWrapper
      onClose={handleModalClose}
      className={styles.modal}
      title={t('reportActions.renameTitle')}
    >
      <div className={styles.container}>
        <p className={styles.message}>{t('reportActions.renameMessage')}</p>
        <Input type="text" onChange={onInputChange} value={newName} />
        <div className={styles.actions}>
          <Button type={BUTTON_TYPE.GREEN} onClick={handleConfirmClick}>
            <div className={!isLoading ? '' : styles.hide}>
              {t('reportActions.renameTitle')}
            </div>
            {isLoading && (
              <div className={styles.spinnerContainer}>
                <Spinner
                  type={SPINNER_TYPE.BLACK_GREEN_BKG}
                  size={SPINNER_SIZE.DEFAULT}
                />
              </div>
            )}
          </Button>
          <Button type={BUTTON_TYPE.NO_BORDER} onClick={handleModalClose}>
            {t('buttonLabels.cancel')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  ) : null;
}
