import * as React from 'react';
import ModalWrapper from '@components/ModalWrapper';
import { useTranslation, Trans } from 'react-i18next';
import { ReportActionsContext } from '@contexts/ReportActionsContext';
import Button, { BUTTON_TYPE } from '@components/Button';
import useDeleteReport from '@hooks/useDeleteReport';
import Spinner, { SPINNER_TYPE, SPINNER_SIZE } from '@components/Spinner';

import styles from './styles.module.scss';
import { noop } from 'lodash';

type Props = {
  onClose?: typeof noop;
  onSuccess?: typeof noop;
};
export default function DeleteReportModal({
  onClose = noop,
  onSuccess = noop,
}: Props) {
  const {
    data: { id, name },
    resetReportData,
  } = React.useContext(ReportActionsContext);
  const { t } = useTranslation(['common']);

  const handleModalClose = () => {
    resetReportData();
    onSuccess();
    onClose(null);
  };
  const { mutate, isLoading } = useDeleteReport(handleModalClose);
  const handleConfirmClick = () => {
    if (!isLoading) mutate(id);
  };

  return id ? (
    <ModalWrapper
      onClose={handleModalClose}
      className={styles.modal}
      title={t('common:reportActions.deleteTitle')}
    >
      <div className={styles.container}>
        <p className={styles.message}>
          <Trans
            defaults={t('common:reportActions.deleteMessage', {
              replace: { name },
            })}
            components={[<strong>name</strong>]}
          />
        </p>
        <div className={styles.actions}>
          <Button type={BUTTON_TYPE.RED} onClick={handleConfirmClick}>
            <div className={!isLoading ? '' : styles.hide}>
              {t('common:reportActions.deleteButton')}
            </div>
            {isLoading && (
              <div className={styles.spinnerContainer}>
                <Spinner
                  type={SPINNER_TYPE.WHITE_RED_BKG}
                  size={SPINNER_SIZE.DEFAULT}
                />
              </div>
            )}
          </Button>
          <Button type={BUTTON_TYPE.NO_BORDER} onClick={handleModalClose}>
            {t('common:buttonLabels.cancel')}
          </Button>
        </div>
      </div>
    </ModalWrapper>
  ) : null;
}
