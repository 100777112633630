import * as React from 'react';
import { Nav } from 'react-bootstrap';
import { IconType } from 'react-icons';

type IProps = {
  defaultIcon: IconType;
  activeIcon: IconType;
  onClick?: () => void;
  isActive: boolean;
  label: string;
  keyItem: string;
};

const OverviewTab = ({
  defaultIcon,
  activeIcon,
  onClick,
  isActive,
  label,
  keyItem
}: IProps) => {
  const Icon = isActive ? activeIcon : defaultIcon;

  return (
    <Nav.Link
      className={isActive ? 'active' : ''}
      eventKey={keyItem}
      onClick={onClick}
    >
      <Icon />{' '}{label}
    </Nav.Link>
  );
};

export default OverviewTab;
