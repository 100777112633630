import EmptyResults from '@components/EmptyResults';
import GenericError from '@components/ErrorState';
import LayoutPage from '@components/LayoutPage';
import Table from '@components/TableNew/Table';
import useUefiIntelBootGuardAnalysis from '@hooks/analysis/useUefiIntelBootGuardAnalysis';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  usePagination,
  useTable
} from 'react-table';

import IntelBootGuardFill from '@components/Icons/IntelBootGuardFill';
import LayoutSection from '@components/LayoutSection';
import ModalReportUefiDetails from '@components/ModalReportUefiDetails';
import ReportHeading from '@components/ReportHeading';
import useReportOverview from '@hooks/useReportOverview';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import BootGuardStatus from '@pages/Report/Uefi/IntelBootGuard/BootGuardStatus';
import { getCssVariable, getScanTime, padNumber } from '@utils/index';
import { format, parse } from 'date-fns';
import { noop } from 'lodash';

// const MIN_ROW_LENGTH = 10;

// export enum UEFI_INTEL_BOOT_GUARD_ANALYSIS_PROPERTY {
//   NAME = 'name',
//   VALUE = 'value',
//   CONTENT = 'content',
// }

export default function ReportUefiIntelBootGuard() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isSuccess, isError, error } =
    useUefiIntelBootGuardAnalysis(reportId);
  const { data: reportData } = useReportOverview(reportId);

  const { t } = useTranslation(['pages', 'tables', 'emptyReports', 'common']);

  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const memoizedData = React.useMemo(() => {
    if (!data || !data.content || data.content.length === 0) return [];
    return data.content;
  }, [data]);

  const memoizedCols: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'name',
        Cell: ({ row, value }: { row: any; value: boolean }) => {
          return (
            <div className='d-flex flex-row align-items-center'>
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400')
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {value}
            </div>
          );
        }
      },
      {
        Header: 'Value',
        accessor: 'value'
      }
    ],
    []
  );

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: memoizedCols,
      data: memoizedData,
      initialState: { pageSize: 10 }
    },
    // useFilters,
    // useGlobalFilter,
    usePagination
  );

  const scanTime = React.useMemo(() => reportData ? getScanTime(reportData) : null, [reportData]);

  return (
    <LayoutPage>
      {/* {isLoading && <SkeletonAnalysisSimple />} */}
      {!isLoading && !isError && !data && !isSuccess && (
        <EmptyResults
          title={t('emptyReports:intelBootGuard.title')}
          description={t('emptyReports:intelBootGuard.subtitle')}
        // buttonLabel={t('emptyReports:intelBootGuard.buttonLabel')}
        // onClick={console.log}
        />
      )}
      {isError && <GenericError error={error} />}
      {data && data?.enabled && isSuccess && !isError && !isLoading && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <SectionHeader
            title={t('pages:intelBootGuardPage.title')}
            leadingIcon={IntelBootGuardFill}
            tooltipMessage={t('pages:intelBootGuardPage.buttonLabel')}
            bannerText={t('pages:intelBootGuardPage.bannerMessage')}
          />
          <Container fluid style={{
            padding: 0,
            borderRadius: '2px'
          }}>
            <Row>
              <Col xs={12}>
                <Table tableInstance={tableInstance}>
                  <Table.Header className='shadow-sm' style={{ padding: '2rem', borderBottom: `1px solid ${getCssVariable('neutral300')}` }}>
                    <BootGuardStatus enabled={data?.enabled} />
                  </Table.Header>
                  <Table.Footer>{/* <div>Ciaone</div> */}</Table.Footer>
                </Table>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {reportData && shouldShowModalDetail ? (
        <ModalReportUefiDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData?.reportDetails,
            submitted: format(
              parse('', '', new Date(`${reportData?.startDate ?? new Date()}`)),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime:
              scanTime ? scanTime.value +
                ' ' +
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                t(`common:${scanTime.type as string}`) : '',
            hash: reportData?.hash || ''
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
