import { getCssVariable } from '@utils/index';
import ContentLoader from 'react-content-loader';

export default function SkeletonOverview(props: any) {
  return (
    <ContentLoader
      speed={2}
      width={878}
      height={370}
      viewBox="0 0 878 370"
      backgroundColor={getCssVariable('neutral100')}
      foregroundColor={getCssVariable('neutral200')}
      {...props}
    >
      <rect x="342" y="170" rx="4" ry="4" width="260" height="16" />
      <rect x="342" y="244" rx="4" ry="4" width="300" height="16" />
      <rect x="342" y="292" rx="4" ry="4" width="220" height="16" />
      <rect x="342" y="340" rx="4" ry="4" width="260" height="16" />
      <rect x="342" y="194" rx="4" ry="4" width="220" height="16" />
      <rect x="578" y="194" rx="4" ry="4" width="180" height="16" />
      <rect x="618" y="170" rx="4" ry="4" width="260" height="16" />
      <rect x="342" y="130" rx="4" ry="4" width="176" height="24" />
      <rect x="0" y="130" rx="4" ry="4" width="280" height="240" />
      <rect x="0" y="37" rx="4" ry="4" width="220" height="16" />
      <rect x="236" y="37" rx="4" ry="4" width="100" height="16" />
      <rect x="0" y="0" rx="4" ry="4" width="180" height="24" />
    </ContentLoader>
  );
}
