import { useTranslation } from 'react-i18next';
import categories from '@locales/en/categories.json';
import { Category } from '@utils/types/report';

export const KEY = 'categories';

export default function useReadCategories(): Category[] {
  const { t } = useTranslation(['categories']);

  const categoriesResult: Category[] = Object.keys(categories).map((c: string) => {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      title: t(`categories:${c}.title`, c) || '',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      subtitle: t(`categories:${c}.subtitle`, c) || ''
    } as Category;
  });

  return categoriesResult;
}