import Chip from '@components/Chip';
import EmptyResults from '@components/EmptyResults';
import GenericError from '@components/ErrorState';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import ModalReportUefiDetails from '@components/ModalReportUefiDetails';
import ReportHeading from '@components/ReportHeading';
import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import { GlobalContext } from '@contexts/GlobalContext';
import useUefiAccessRightsAnalysis from '@hooks/analysis/useUefiAccessRightsAnalysis';
import { THEMES } from '@hooks/useDarkMode';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import InfoBox from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxMessage';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import { getCssVariable, getScanTime, padNumber } from '@utils/index';
import { format, parse } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiLockPasswordLine,
} from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { useFilters, usePagination, useTable } from 'react-table';
import styles from '../styles.module.scss';

const breakPointsLeft = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const breakPointsRight = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
};

export default function ReportUefiAccessRights() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isError } = useUefiAccessRightsAnalysis(reportId);
  const { data: reportData } = useReportOverview(reportId);

  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  // const {
  //   data: overviewData,
  //   isLoading: isLoadingOverviewData,
  //   // isSuccess: isSuccessOverviewData,
  //   // isSuccess: isErrorOverviewData,
  //   error: errorOverview
  // } = useReportOverview(reportId);
  const { t } = useTranslation(['tables', 'pages', 'emptyReports', 'common']);

  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);
  // const error = errorOverview || errorSecureBoot;
  //
  // const tableRowsSecureBootKeys =
  //   React.useMemo(() => data?.keys, [data?.keys]) || [];
  //
  // const tableRowsSecureBootDatabaseCerts =
  //   React.useMemo(() => data?.databaseCerts, [data?.databaseCerts]) || [];
  //
  // const tableRowsSecureBootDatabaseHashes =
  //   React.useMemo(() => data?.databaseHashes, [data?.databaseHashes]) || [];
  //
  // const tableColsSecureBootKeys = React.useMemo(() => {
  //   return (
  //     isSuccess &&
  //     Object.keys(get(data, 'keys[0]', {}))?.map((label: string) => ({
  //       Header: () => <Trans t={t}>{label}</Trans>,
  //       accessor: label,
  //       sortable: false,
  //       minWidth: 300,
  //       width: 260,
  //       sortType: 'alphanumeric',
  //     }))
  //   );
  // }, [data, t, isSuccess]);

  // const tableColsSecureBootDatabaseCerts = React.useMemo(() => {
  //   return (
  //     isSuccess &&
  //     Object.keys(get(data, 'databaseCerts[0]', {}))?.map((label: string) => ({
  //       Header: () => <Trans t={t}>{label}</Trans>,
  //       accessor: label,
  //       sortable: false,
  //       minWidth: 300,
  //       width: 260,
  //       sortType: 'alphanumeric',
  //     }))
  //   );
  // }, [data, t, isSuccess]);

  // const tableColsSecureBootDatabaseHashes = React.useMemo(() => {
  //   return (
  //     isSuccess &&
  //     Object.keys(get(data, 'databaseHashes[0]', {}))?.map((label: string) => ({
  //       Header: () => <Trans t={t}>{label}</Trans>,
  //       accessor: label,
  //       sortable: false,
  //       minWidth: 300,
  //       width: 260,
  //       sortType: 'alphanumeric',
  //     }))
  //   );
  // }, [data, t, isSuccess]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row, value }: { row: any; value: boolean }) => {
          return (
            <div className="d-flex flex-row align-items-center">
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400'),
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Read',
        accessor: 'read',
        Cell: (props: any) =>
          props.row.original.read.toString()
      },
      {
        Header: 'Write',
        accessor: 'write',
        Cell: (props: any) =>
          props.row.original.write.toString()
      },
    ],
    []
  );

  const memoizedData = React.useMemo(() => {
    return data || [];

  }, [data]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { pageSize: 10 },
    },
    useFilters,
    usePagination
  );

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  const scanTime = React.useMemo(
    () => (reportData ? getScanTime(reportData) : null),
    [reportData]
  );

  console.log({ data });

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisSimple />}
      {!isLoading && !isError && !data && (
        <EmptyResults
          title={t('emptyReports:accessRights.title')}
          description={t('emptyReports:accessRights.subtitle')}
        // buttonLabel={t('emptyReports:accessRights.buttonLabel')}
        // onClick={console.log}
        />
      )}
      {isError && <GenericError error={isError} />}
      {data && !isLoading && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <SectionHeader
            title={t('pages:accessRightsPage.title')}
            leadingIcon={RiLockPasswordLine}
            tooltipMessage={t('pages:accessRightsPage.buttonLabel')}
            bannerText={t('pages:accessRightsPage.bannerMessage')}
          />
          <Container fluid>
            <Row>
              <Col className="px-0">
                {/* <SectionLayout>
                  <SectionContent
                    style={{ minHeight: '400px' }}
                    breakPointsLeft={breakPointsLeft}
                    breakPointsRight={breakPointsRight}
                  >
                    <SectionContentLeft
                      className={pageStyles['section-left']}
                      verticalMessage={t('pages:secureBoot.title')}
                      style={{ borderRight: 'none' }}
                    >
                      <InfoBox
                        style={{ border: 0, width: '100%', height: '100%' }}
                      >
                        <InfoBoxContent
                          style={{
                            paddingTop: '0',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <AccessRightsChart
                            allValue={0}
                            readValue={0}
                            writeValue={0}
                          />
                          <InfoBoxMessage
                            message={t('pages:accessRightsPage.boxMessage')}
                          />
                        </InfoBoxContent>
                      </InfoBox>
                    </SectionContentLeft>
                  </SectionContent>
                </SectionLayout> */}
                <InfoBox style={{
                  padding: 20,
                  height: 'auto',
                }}>
                  <InfoBoxContent style={{
                    alignItems: 'flex-start', background: isDarkMode ? '#202226' : 'none', flexDirection: 'row', gap: 60, position: 'relative'
                  }}>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='all'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{memoizedData?.length || 0}</div>
                    </div>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='read'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{memoizedData?.filter(item => item.read).length || 0}</div>
                    </div>
                    <div>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='write'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{memoizedData?.filter(item => item.write).length || 0}</div>
                    </div>
                  </InfoBoxContent>-
                </InfoBox>
                <Container fluid style={{
                  border: '1px solid var(--neutral300)',
                  marginTop: 40,
                  padding: 0,
                  borderRadius: '2px'
                }}>
                  <Row>
                    <Col xs={12} >
                      <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>Access Rights Analysis</div>
                      <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total: {memoizedData?.length || 0}</div>
                      <Table tableInstance={tableInstance} borderless>
                        <Table.Footer>
                          <p className={tableStyles['table__paginationState']}>
                            {paginationMessage}
                          </p>
                          <div className={tableStyles['table__paginationButtons']}>
                            <button
                              className={
                                tableInstance?.canPreviousPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.previousPage()}
                            >
                              <RiArrowLeftSLine size={20} />
                            </button>
                            <button
                              className={
                                tableInstance?.canNextPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.nextPage()}
                            >
                              <RiArrowRightSLine size={20} />
                            </button>
                          </div>
                          <TablePageSize
                            maxResultChunkSize={maxResultChunkSize}
                            pageSize={tableInstance?.state?.pageSize || 10}
                            onPageSizeChange={onPageSizeChange}
                          />
                        </Table.Footer>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {reportData && shouldShowModalDetail ? (
        <ModalReportUefiDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData?.reportDetails,
            submitted: format(
              parse('', '', new Date(`${reportData?.startDate ?? new Date()}`)),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: reportData?.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}

//
// const MIN_ROW_LENGTH = 10;
//
// export enum UEFI_ACCESS_RIGHTS_PROPERTY {
//   NAME = 'name',
//   READ = 'read',
//   WRITE = 'write',
// }
//
// export default function ReportUefiAccessRights() {
//   const { reportId = '' }: { reportId: string } = useParams();
//   const { data, isLoading, isSuccess, isError, error } =
//     useUefiAccessRightsAnalysis(reportId);
//   const accessRightsResultLength = data?.length || 0;
//   const { t } = useTranslation(['pages', 'tables', 'emptyReports']);
//
//   const tableRows =
//     React.useMemo(
//       () =>
//         data?.map(
//           (row) =>
//             ({
//               ...row,
//               write: (
//                 <span
//                   style={{
//                     width: 'auto',
//                     display: 'flex',
//                     justifyContent: 'flex-start',
//                   }}
//                 >
//                   <Chip
//                     leadingIcon={!row.write ? RiCloseFill : undefined}
//                     color={row.write ? CHIP_COLOR.CRIMSON : CHIP_COLOR.DEFAULT}
//                   >
//                     {UEFI_ACCESS_RIGHTS_PROPERTY.WRITE.toUpperCase()}
//                   </Chip>
//                 </span>
//               ),
//               read: (
//                 <span
//                   style={{
//                     width: 'auto',
//                     display: 'flex',
//                     justifyContent: 'flex-start',
//                   }}
//                 >
//                   <Chip
//                     leadingIcon={!row.read ? RiCloseFill : undefined}
//                     color={row.read ? CHIP_COLOR.CRIMSON : CHIP_COLOR.DEFAULT}
//                   >
//                     {UEFI_ACCESS_RIGHTS_PROPERTY.READ.toUpperCase()}
//                   </Chip>
//                 </span>
//               ),
//             } ?? [])
//         ),
//       [data]
//     ) || [];
//
//   const tableCols = React.useMemo(() => {
//     return Object.keys(get(data, '[0]', {}))?.map((label: string) => ({
//       Header: () => <Trans t={t}>{label}</Trans>,
//       accessor: label,
//       sortable: false,
//       minWidth: 300,
//       width: 260,
//       disableSortBy: ![
//         UEFI_ACCESS_RIGHTS_PROPERTY.WRITE,
//         UEFI_ACCESS_RIGHTS_PROPERTY.READ,
//       ].includes(label as UEFI_ACCESS_RIGHTS_PROPERTY)
//         ? false
//         : true,
//       sortType: 'alphanumeric',
//     }));
//   }, [data, t]);
//
//   return (
//     <LayoutPage>
//       {accessRightsResultLength > 0 ? (
//         <>
//           <LayoutSection spacer={24}>
//             <ReportHeading title={t('pages:accessRightsPage.title')} />
//           </LayoutSection>
//           <LayoutSection spacer={16} layoutType={LAYOUT_TYPE.FULL}>
//             <LayoutBox>
//               <LayoutBoxContent>
//                 <DataHighlight
//                   label={t('pages:accessRightsPage.boxMessage')}
//                   size={SIZE.SMALL}
//                   numberOfIssue={''}
//                   color={'orange'}
//                 />
//               </LayoutBoxContent>
//             </LayoutBox>
//           </LayoutSection>
//           <LayoutSection>
//             <Table
//               hasPagination={accessRightsResultLength > MIN_ROW_LENGTH}
//               columns={tableCols}
//               data={tableRows}
//             />
//           </LayoutSection>
//         </>
//       ) : !isLoading && isSuccess ? (
//         <EmptyResults
//           title={t('emptyReports:accessRights.title')}
//           description={t('emptyReports:accessRights.subtitle')}
//           // buttonLabel={t('emptyReports:accessRights.buttonLabel')}
//           onClick={console.log}
//         />
//       ) : !isLoading && isError ? (
//         <GenericError error={error} />
//       ) : (
//         <SkeletonAnalysisSimple />
//       )}
//     </LayoutPage>
//   );
// }
