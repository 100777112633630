import { ROUTES } from '@routes/routes';
import { http } from '@services/api';

const setupInterceptors = () => {
  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('organizationUID');
        //return to the login screen if the session is expired
        window.location = ROUTES.SIGN_IN as Location | (string & Location);
      }
      return Promise.reject(error);
    }
  );
};

export { setupInterceptors };
