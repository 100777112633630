import LayoutPage from '@components/LayoutPage';
import Table from '@components/TableNew/Table';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { CHIP_COLOR } from '@components/Chip';

import { REPORT_CRYPTO_ANALYZER_TYPE } from '@utils/types/report';

import useCryptoAnalyzerAnalysis from '@hooks/analysis/useCryptoAnalyzerAnalysis';

import EmptyResults from '@components/EmptyResults';
import GenericError from '@components/ErrorState';
import LayoutSection from '@components/LayoutSection';
import ModalReportLinuxDetails from '@components/ModalReportLinuxDetails';
import ReportHeading from '@components/ReportHeading';
import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import { GlobalContext } from '@contexts/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import { getCssVariable, getScanTime } from '@utils/index';
import { format } from 'date-fns';
import { noop } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiSpamLine,
} from 'react-icons/ri';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import InfoBox from '../ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '../ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '../ReportOverviewLinux/Components/InfoBoxMessage';
import SectionHeader from '../ReportOverviewLinux/Components/SectionHeader';
import cryptoStyles from './styles.module.scss';

export enum CRYPTO_ANALYZER_PROPERTY {
  FILENAME = 'filename',
  PATH = 'path',
  KEYSIZE = 'keysize',
  ISSUER = 'issuer',
  TYPE = 'type',
}

function getColorFromType(type: string): string {
  if (type === REPORT_CRYPTO_ANALYZER_TYPE.CERTIFICATE) {
    return getCssVariable('violet');
  }

  if (type === REPORT_CRYPTO_ANALYZER_TYPE.PUBLIC_KEY) {
    return getCssVariable('cyan');
  }

  if (type === REPORT_CRYPTO_ANALYZER_TYPE.PRIVATE_KEY) {
    return getCssVariable('light-violet');
  } else {
    return CHIP_COLOR.DEFAULT;
  }
}

export default function CryptoAnalyzer() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isError, error } =
    useCryptoAnalyzerAnalysis(reportId);
  const { data: reportData } = useReportOverview(reportId);

  const { t } = useTranslation(['tables', 'pages', 'emptyReports', 'common']);
  const [activeIndex, setActiveIndex] = React.useState<string>('0');
  const [search, setSearch] = React.useState('');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  const handleActiveIndexDefault = (data: any): string => {
    if (!data) return '0';
    if (data.certificate > 0 && data.privateKey > 0 && data.publicKey > 0) {
      return '0';
    }
    if (data.certificate > 0) {
      return '0';
    }
    if (data.privateKey > 0) {
      return '1';
    }
    if (data.publicKey > 0) {
      return '2';
    }
    return '0';
  };

  React.useEffect(() => {
    setActiveIndex(handleActiveIndexDefault(data?.overview));
  }, []);

  // {
  //   filename: 'prova',
  //   path: 'prova',
  //   keySize: '4949',
  //   issuer: 'prova codice',
  //   type: 'private',
  // },

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'filename',
      },
      {
        Header: 'Path',
        accessor: 'path',
      },
      {
        Header: 'Key Size',
        accessor: 'keySize',
      },
      {
        Header: 'Issuer',
        accessor: 'issuer',
      },
      {
        Header: '',
        accessor: 'type',
        Cell: (props: any) => {
          return (
            <div className="d-flex align-items-center justify-content-end w-100">
              <span
                style={{
                  padding: '.35rem',
                  marginRight: '1rem',
                  color: getColorFromType(props.value),
                  borderRadius: '4px',
                  textTransform: 'capitalize'
                }}
              >
                {`${props?.value} key`}
              </span>
            </div>
          );
        },
      },
    ],
    []
  );

  const memoizedData = React.useMemo(() => {
    if (!data) return [];
    if (activeIndex === '0') {
      return data?.content?.filter(
        (el) => el.type === REPORT_CRYPTO_ANALYZER_TYPE.CERTIFICATE
      );
    }
    if (activeIndex === '1') {
      return data?.content?.filter(
        (el) => el.type === REPORT_CRYPTO_ANALYZER_TYPE.PUBLIC_KEY
      );
    }
    if (activeIndex === '2') {
      return data?.content?.filter(
        (el) => el.type === REPORT_CRYPTO_ANALYZER_TYPE.PRIVATE_KEY
      );
    }
  }, [data, activeIndex]);

  const memoizedSearch = React.useMemo(() => search, [search]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      // @ts-expect-error TODO : FIX IT WITH REAL DATA
      data: memoizedData,
      initialState: { globalFilter: memoizedSearch, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  const scanTime = React.useMemo(
    () => (reportData ? getScanTime(reportData) : null),
    [reportData]
  );

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisSimple />}
      {!isLoading && !isError && !data && (
        <EmptyResults
          title={t('emptyReports:cryptoAnalyzer.title')}
          description={t('emptyReports:cryptoAnalyzer.subtitle')}
        // buttonLabel={t('emptyReports:cryptoAnalyzer.buttonLabel')}
        // onClick={console.log}
        />
      )}
      {isError && <GenericError error={error} />}
      {data && Object.keys(data).length > 0 && !isLoading && !isError && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <SectionHeader
            title={t('pages:cryptoAnalyzerPage.title')}
            leadingIcon={RiSpamLine}
            tooltipMessage={t('pages:cryptoAnalyzerPage.buttonLabel')}
            bannerText={t('pages:cryptoAnalyzerPage.bannerMessage')}
          />
          <Container fluid>
            <Row>
              <Col className="px-0" xs={12}>
                <InfoBox style={{
                  padding: 20,
                  height: 'auto'
                }}>
                  <InfoBoxContent style={{
                    alignItems: 'flex-start', background: isDarkMode ? '#202226' : 'none', flexDirection: 'row', gap: 60
                  }}>
                    <div>
                      <div className={cryptoStyles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='certificates'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={cryptoStyles['info-box-count']}>{data?.overview?.certificate || 0}</div>
                    </div>
                    <div>
                      <div className={cryptoStyles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='private keys'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={cryptoStyles['info-box-count']}>{data?.overview?.privateKey || 0}</div>
                    </div>
                    <div>
                      <div className={cryptoStyles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message='public keys'
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={cryptoStyles['info-box-count']}>{data?.overview?.publicKey || 0}</div>
                    </div>
                  </InfoBoxContent>-
                </InfoBox>
                <Container fluid style={{
                  border: '1px solid var(--neutral300)',
                  marginTop: 40,
                  padding: 0,
                  borderRadius: '2px'
                }}>
                  <Row>
                    <Col xs={12} >
                      <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>Crypto Analyzer</div>
                      <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total: {memoizedData?.length || 0}</div>
                      <Table className="border-0" tableInstance={tableInstance}>
                        <Table.Footer>
                          <p className={tableStyles['table__paginationState']}>
                            {paginationMessage}
                          </p>
                          <div className={tableStyles['table__paginationButtons']}>
                            <button
                              className={
                                tableInstance?.canPreviousPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.previousPage()}
                            >
                              <RiArrowLeftSLine size={20} />
                            </button>
                            <button
                              className={
                                tableInstance?.canNextPage
                                  ? tableStyles['table__paginationActiveButton']
                                  : ''
                              }
                              onClick={() => tableInstance.nextPage()}
                            >
                              <RiArrowRightSLine size={20} />
                            </button>
                          </div>
                          <TablePageSize
                            maxResultChunkSize={maxResultChunkSize}
                            pageSize={tableInstance?.state?.pageSize || 10}
                            onPageSizeChange={onPageSizeChange}
                          />
                        </Table.Footer>
                      </Table>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {reportData && shouldShowModalDetail ? (
        <ModalReportLinuxDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData.reportDetails,
            submitted: format(
              new Date(`${reportData.startDate ?? new Date()}`),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: reportData.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
