import GraphLegend from '@components/GraphLegend';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import ModalReportLinuxDetails from '@components/ModalReportLinuxDetails';
import { ProgressBar } from '@components/ProgressBar';
import ReportHeading from '@components/ReportHeading';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import { GlobalContext } from '@contexts/GlobalContext';
import useCveCheckAnalysis from '@hooks/analysis/useCveCheckAnalysis';
import { THEMES } from '@hooks/useDarkMode';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import { customSeveritySort, getCssVariable, getScanTime, padNumber } from '@utils/index';
import { format } from 'date-fns';
import _, { noop } from 'lodash';
import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCheckFill,
  RiCloseFill,
  RiExternalLinkFill,
  RiSpamLine,
} from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import SectionContent from '../ReportOverviewLinux/Components/SectionContent';
import SectionContentFooter from '../ReportOverviewLinux/Components/SectionContentFooter';
import SectionContentLeft from '../ReportOverviewLinux/Components/SectionContentLeft';
import SectionContentRight from '../ReportOverviewLinux/Components/SectionContentRight';
import SectionHeader from '../ReportOverviewLinux/Components/SectionHeader';
import SectionLayout from '../ReportOverviewLinux/Components/SectionLayout';
import BarGraph from '../ReportOverviewLinux/Graphs/BarGraph';
import styles from '../ReportOverviewLinux/styles.module.scss';
import cveCheckReportStyles from './styles.module.scss';

export enum CVE_CHECK_PROPERTY {
  PATCH = 'patch',
  CVE_ID = 'cveid',
  SEVERITY = 'severity',
  PRODUCT = 'product',
  VERSION = 'version',
}

type ProgressBarState = {
  min: number;
  max: number;
};

export default function CveCheck() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data } = useCveCheckAnalysis(reportId);
  const { data: reportData } = useReportOverview(reportId);

  const { t } = useTranslation(['tables', 'common', 'emptyReports', 'pages']);

  const [activeIndex, setActiveIndex] = React.useState<string>('0');
  const [search, setSearch] = React.useState<string>('');

  const [progressBarValue, setProgressBarValue] =
    React.useState<ProgressBarState>({
      min: 0,
      max: 0,
    });

  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  // const handleActiveIndexDefault = (data: any): string => {
  //   if (!data) return '0';
  //   if (
  //     data.critical.severity > 0 &&
  //     data.high.severity > 0 &&
  //     data.medium.severity > 0 &&
  //     data.low.severity > 0
  //   ) {
  //     return '0';
  //   }
  //   if (data.critical.severity > 0) {
  //     return '0';
  //   }
  //   if (data.high.severity > 0) {
  //     return '1';
  //   }
  //   if (data.medium.severity > 0) {
  //     return '2';
  //   }
  //   if (data.low.severity > 0) {
  //     return '3';
  //   }
  //   return '0';
  // };

  // React.useEffect(() => {
  //   setActiveIndex(handleActiveIndexDefault(reportData?.analysis?.cveCheck));
  // }, [reportData]);


  const tableDetailCols: any = React.useMemo(
    () => [
      {
        Header: 'CVE ID',
        accessor: 'cveid',
        Cell: (props: any) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <span
                  style={{
                    marginRight: '.5rem',
                    color: getCssVariable('neutral400'),
                  }}
                >
                  {padNumber(props.row?.index + 1)}
                </span>
                <span style={{ fontWeight: 'bold' }}>{props.value}</span>
              </div>
              <div className={'px-2'}>
                <a
                  className={`${tableStyles['show-icon-link']}`}
                  href={props.row?.original?.patch}
                  target="_blank"
                  rel={'noopener noreferrer'}
                >
                  <RiExternalLinkFill />
                </a>
              </div>
            </div>
          );
        },
      },
      {
        Header: 'Product',
        accessor: 'product',
      },
      {
        Header: 'Version',
        accessor: 'version',
      },
      {
        Header: 'Patch',
        accessor: 'patch',
        Cell: (props: any) => {
          if (props.value) {
            return (
              <div className={'d-flex align-items-center'}>
                <RiCheckFill
                  style={{ color: '#D0D7DE', fontSize: '1.5rem' }}
                />
                <span style={{ marginLeft: '.5rem' }}>Available</span>
              </div>
            );
          } else {
            return (
              <div className="d-flex align-items-center">
                <RiCloseFill style={{ color: '#E60000', fontSize: '1.5rem' }} />
                <span style={{ marginLeft: '.5rem' }}>Unavailable</span>
              </div>
            );
          }
        },
      },
      {
        Header: '',
        accessor: 'severity',
        sortType: customSeveritySort,
        disableSortBy: true,
        Cell: (props: any) => {
          return (
            <div className={'d-flex align-items-center justify-content-end'}>
              <div
                className={`${cveCheckReportStyles['severity-wrap-table-accessor']
                  } ${cveCheckReportStyles[props.value]}`}
              >
                <span>{`${props.value?.toLowerCase()} severity`}</span>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'year',
        accessor: 'year',
        Cell: ({ value }: { value: any }) => (
          <div>
            <span style={{ fontWeight: 700 }}>{value}</span>
          </div>
        ),
      },
      {
        Header: 'Critical Severity',
        accessor: 'CRITICAL',
        Cell: ({ value }: { value: any }) => (
          <div className="d-flex justify-content-between align-items-end px-2">
            <ProgressBar
              color={getCssVariable('bs-purple')}
              value={value}
              min={progressBarValue.min}
              max={progressBarValue.max}
            />
            <h4
              style={{ color: getCssVariable('bs-purple'), fontSize: '2rem' }}
            >{`${value || 0}`}</h4>
          </div>
        ),
      },
      {
        Header: 'High Severity',
        accessor: 'HIGH',
        Cell: ({ value }: { value: any }) => (
          <div className="d-flex justify-content-between align-items-end px-2">
            <ProgressBar
              color={'#F86767'}
              value={value}
              min={progressBarValue.min}
              max={progressBarValue.max}
            />
            <h4
              style={{ color: '#F86767', fontSize: '2rem' }}
            >{`${value || 0}`}</h4>
          </div>
        ),
      },
      {
        Header: 'Medium Severity',
        accessor: 'MEDIUM',
        Cell: ({ value }: { value: any }) => (
          <div className="d-flex justify-content-between align-items-end px-2">
            <ProgressBar
              color={'#D0D7DE'}
              value={value}
              min={progressBarValue.min}
              max={progressBarValue.max}
            />
            <h4
              style={{ color: '#D0D7DE', fontSize: '2rem' }}
            >{`${value || 0}`}</h4>
          </div>
        ),
      },
      {
        Header: 'Low Severity',
        accessor: 'LOW',
        Cell: ({ value }: { value: any }) => (
          <div className="d-flex justify-content-between align-items-end px-2">
            <ProgressBar
              color={'#59CCD9'}
              value={value}
              min={progressBarValue.min}
              max={progressBarValue.max}
            />
            <h4
              style={{ color: '#59CCD9', fontSize: '2rem' }}
            >{`${value || 0}`}</h4>
          </div>
        ),
      },
    ],
    [progressBarValue]
  );

  const memoizedData = React.useMemo(() => {
    if (!data) return [];

    return data.severities;
  }, [data]);

  const severitiesMemoizedData = React.useMemo(() => {
    if (reportData?.analysis?.cveCheck?.severityByYear) {
      const severitiesMap = reportData.analysis.cveCheck.severityByYear.map(
        (s: any, i: number) => Object.values(s.severity)
      );
      const flatValues: number[] = _.flatten(severitiesMap) || [];
      const minValue: number = Math.min(...flatValues) || 0;
      const maxValue: number = Math.max(...flatValues) || 0;
      setProgressBarValue({
        min: minValue,
        max: maxValue,
      });

      const rawData = reportData.analysis.cveCheck.severityByYear.sort(
        (a: any, b: any): any => parseFloat(b.year) - parseFloat(a.year)
      );

      const final: any[] = [];
      rawData.map((s: any, i: number) => {
        final.push({
          year: s.year,
          ...s.severity,
        });
      });
      return final;
    }
    return [];
  }, [reportData]);

  const memoizedSearch = React.useMemo(() => search, [search]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: tableDetailCols,
      data: memoizedData,
      initialState: {
        globalFilter: memoizedSearch, pageSize: 10, sortBy: [
          {
            id: 'severity',
            desc: false
          }
        ]
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const severitiesTableInstance = useTable(
    {
      columns,
      data: severitiesMemoizedData,
      initialState: { pageSize: 10 },
    },
    useFilters,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);
  const {
    paginationMessage: severitiesPaginationMessage,
  }: UsePaginationCounterState = usePaginationCounter(severitiesTableInstance);

  const scanTime = React.useMemo(
    () => (reportData ? getScanTime(reportData) : null),
    [reportData]
  );

  const affectedFilesCount = React.useMemo(() => {
    if (data) {
      return data?.overview?.critical + data?.overview.high + data?.overview?.medium + data?.overview?.low;
    }
    else return 0;
  }, [data]);

  const typeCounts = React.useMemo(() => [
    {
      'key': 'critical',
      'count': data?.overview.critical,
    }, {
      'key': 'high',
      'count': data?.overview.high,
    }, {
      'key': 'medium',
      'count': data?.overview.medium,
    }, {
      'key': 'low',
      'count': data?.overview.low,
    }], [data]);

  const legendData: any = React.useMemo(() => {
    const transformedObject: any = {};

    // Iterate through the array and assign key-value pairs to the transformed object
    typeCounts.forEach(item => {
      transformedObject[item.key] = item.count;
    });

    return transformedObject;

  }, [typeCounts]);

  // const allData = React.useMemo(() => data?.severities || [], [data]);

  return (
    <LayoutPage>
      <LayoutSection spacer={32}>
        <ReportHeading
          chipLabel={t('common:generic.completed')}
          buttonLabel={t('common:generic.downloadReport')}
          onButtonClick={noop}
          descriptionButtonLabel={t('common:generic.viewDetails')}
          onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
        />
      </LayoutSection>
      <SectionLayout>
        <SectionHeader
          title={t('pages:cveCheckPage.title')}
          leadingIcon={RiSpamLine}
          tooltipMessage={t('pages:cveCheckPage.buttonLabel')}
          bannerText={t('pages:cveCheckPage.bannerMessage')}
        >
          {/* <button className={`${styles['button-with-icon']}`}>
            <div>
              <BsGithub />
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'none' }}
              href="https://github.com/Exein-io/kepler"
            >
              KEPLER
            </a>
          </button> */}
        </SectionHeader>
        <SectionContent style={{
          padding: 15
        }}>
          <SectionContentLeft verticalMessage={'CVE Check vulnerabilities'} style={{
            background: isDarkMode ? '#1f2226' : 'none'
          }}>
            <SectionContentLeft style={{
              borderRight: 'none'
            }} hasGraph={false}>
              <div className={styles['left-title']}>
                <div>Cve check vulnerabilities</div>
              </div>
              <div className={styles.count}>{affectedFilesCount}</div>
              <GraphLegend items={legendData} colors={[getCssVariable('bs-purple'), '#F86767', '#D0D7DE', '#59CCD9']} direction='row' customClassName={cveCheckReportStyles.graphLegend} />
            </SectionContentLeft>
          </SectionContentLeft>
          <SectionContentRight>
            <BarGraph data={typeCounts} colors={[getCssVariable('bs-purple'), '#F86767', '#D0D7DE', '#59CCD9']} margins={{
              top: 50, right: 20, bottom: 40, left: 20
            }} />
          </SectionContentRight>
        </SectionContent >
        <SectionContentFooter className="px-3 py-3" style={{ border: 'none' }}>
          <span className={styles['sub-text']}>
            Last CVE Check scan{' '}
            {reportData?.analysis?.cveCheck?.lastCheck
              ? format(
                new Date(reportData.analysis.cveCheck.lastCheck),
                'dd/MM/yy HH:mm'
              )
              : null}
          </span>
        </SectionContentFooter>
      </SectionLayout>
      <Container fluid style={{
        border: '1px solid var(--neutral300)',
        borderRadius: '2px',
      }}>
        <Row>
          <Col className="px-0" xs={12}>
            <div style={{ fontSize: 20, padding: '40px 40px 5px 40px' }}>CVE Check</div>
            <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px', textTransform: 'uppercase' }}>total: {affectedFilesCount}</div>
            <Table tableInstance={tableInstance}>
              <Table.Footer>
                <p className={tableStyles['table__paginationState']}>
                  {paginationMessage}
                </p>
                <div className={tableStyles['table__paginationButtons']}>
                  <button
                    className={
                      tableInstance?.canPreviousPage
                        ? tableStyles['table__paginationActiveButton']
                        : ''
                    }
                    onClick={() => tableInstance.previousPage()}
                  >
                    <RiArrowLeftSLine size={20} />
                  </button>
                  <button
                    className={
                      tableInstance?.canNextPage
                        ? tableStyles['table__paginationActiveButton']
                        : ''
                    }
                    onClick={() => tableInstance.nextPage()}
                  >
                    <RiArrowRightSLine size={20} />
                  </button>
                </div>
                <TablePageSize
                  maxResultChunkSize={maxResultChunkSize}
                  pageSize={tableInstance?.state?.pageSize || 10}
                  onPageSizeChange={onPageSizeChange}
                />
              </Table.Footer>
            </Table>
          </Col>
        </Row>
      </Container>
      {
        reportData && shouldShowModalDetail ? (
          <ModalReportLinuxDetails
            onClose={() => setShouldShowModalDetail(false)}
            details={{
              ...reportData.reportDetails,
              submitted: format(
                new Date(`${reportData.startDate ?? new Date()}`),
                'yyyy-MM-d HH:MM:SS'
              ),
              scanTime: scanTime
                ? scanTime.value +
                ' ' +
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                t(`common:${scanTime.type as string}`)
                : '',
              hash: reportData.hash || '',
            }}
          />
        ) : null
      }
    </LayoutPage >
  );
}
