import { useQuery } from 'react-query';
import type { ReportUefiAttackSurfaceAnalysis } from '@utils/types/report';
import { getReportUefiAttackSurfaceAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'attack-surface-analysis';

export default function useUefiAttackSurfaceAnalysis(id: string) {
  useToken();
  return useQuery<ReportUefiAttackSurfaceAnalysis, Error>([KEY, id], () =>
    getReportUefiAttackSurfaceAnalysis(id),
    {
      retry: false
    }
  );
}
