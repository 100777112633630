import { useQuery } from 'react-query';
import type { ReportSecureBootAnalysis } from '@utils/types/report';
import { getReportSecureBootAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'secure-boot-analysis';

export default function useSecureBootAnalysis(id: string) {
  useToken();
  return useQuery<ReportSecureBootAnalysis, Error>([KEY, id], () =>
    getReportSecureBootAnalysis(id),
    {
      retry: false
    }
  );
}
