import ModalWrapper from '@components/ModalWrapper';
import { ReportDetails } from '@utils/types/report';
import byteSize from 'byte-size';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
type Props = {
  details?: ReportDetails;
  onClose: typeof noop;
};

export default function ModalReportUefiDetails({
  details,
  onClose = noop,
}: Props) {
  const { t } = useTranslation(['common']);
  const filesize = byteSize(Number(details?.size || 0));
  return (
    <div className={styles.container}>
      <ModalWrapper
        onClose={onClose}
        title={t('common:modalReportDetails.title')}
      >
        <ul className={styles.horizontalDetails}>
          <li>
            <strong>{t('common:modalReportDetails.peiNo')}</strong>
            {details?.peiNo}
          </li>
          <li>
            <strong>{t('common:modalReportDetails.dxeNo')}</strong>
            {details?.dxeNo}
          </li>
          <li>
            <strong>{t('common:modalReportDetails.s3mit')}</strong>
            {details?.s3mit}
          </li>
          <li>
            <strong>{t('common:modalReportDetails.manufacturer')}</strong>
            {details?.manufacturer}
          </li>
        </ul>
        <ul className={styles.verticalDetails}>
          <li>
            <strong>{t('common:modalReportDetails.firmwareId')}</strong>{' '}
            {details?.firmwareId}
          </li>
          <li>
            <strong>{t('common:modalReportDetails.filename')}</strong>{' '}
            {details?.name}
          </li>
          <li>
            <strong>{t('common:modalReportDetails.submitted')}</strong>{' '}
            {details?.submitted}
          </li>
          <li>
            <strong>{t('common:modalReportDetails.scanTime')}</strong>{' '}
            {details?.scanTime}
          </li>
          <li>
            <strong>{t('common:modalReportDetails.hash')}</strong>{' '}
            {details?.hash}
          </li>
          <li>
            <strong>{t('common:modalReportDetails.filesize')}</strong>{' '}
            {filesize?.value} {filesize?.unit} ({details?.size} bytes)
          </li>
          <ul className={styles.complienceContainer}>
            <li>
              <strong>Nist Compliance Check</strong> Failed. Review CVE, Malware
              and Password analyses.
            </li>
            <li>
              <strong>CRA Compliance Check</strong>
              Failed. Review CVE, Malware and Password analyses.
            </li>
          </ul>
        </ul>
      </ModalWrapper>
    </div>
  );
}
