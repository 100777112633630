import React from 'react';
import styles from './styles.module.scss';
import Button, { BUTTON_TYPE } from '@components/Button';
import { noop } from 'lodash';
import illustrationDm from '@assets/animations/Nothing_Found_DARK.json';
import illustration from '@assets/animations/Nothing_Found_LIGHT.json';

import { GlobalContext } from '@contexts/GlobalContext';
import Lottie from 'react-lottie';

type Props = {
  image?: string | null;
  title: string;
  description: string;
  buttonLabel?: string;
  buttonType?: BUTTON_TYPE;
  onClick?: typeof noop;
};

export default function EmptyResults({
  image,
  title,
  description,
  buttonLabel,
  buttonType = BUTTON_TYPE.LIGHT_BORDER,
  onClick,
}: Props) {
  const { theme } = React.useContext<any>(GlobalContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: theme === 'dark' ? illustrationDm : illustration,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {image !== null ? (
          <Lottie
            options={defaultOptions}
            height={250}
            width={250}
            isStopped={false}
            isPaused={false}
          />
        ) : null}
        <h1>{title}</h1>
        <p>{description}</p>
        {buttonLabel ? (
          <Button type={buttonType} onClick={onClick}>
            {buttonLabel.toUpperCase()}
          </Button>
        ) : null}
      </div>
    </div>
  );
}
