import byteSize from 'byte-size';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
} from 'date-fns';
import { startCase } from 'lodash';
import { REPORT_SEVERITY, Report, ReportOverview } from './types/report';

export const getCssVariable = (variableName: string): string => {
  const value = getComputedStyle(document.documentElement).getPropertyValue(
    `--${variableName}`,
  );
  if (!value) {
    return '';
  }
  return value;
};

export const emailRegex = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/,
  'i',
);

export function setTokenOnLocalstorage(token: string): void {
  window.localStorage.setItem('token', token);
}

export function getTokenOnLocalstorage(): string {
  return window.localStorage.getItem('token') || '';
}

export function removeTokenOnLocalstorage(): void {
  return window.localStorage.removeItem('token');
}

export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export function padNumber(num: number, size = 2): string {
  let numString = num.toString();
  while (numString.length < size) numString = '0' + num;
  return numString;
}

export function getSeverityColor(severity: REPORT_SEVERITY): string {
  if (severity === REPORT_SEVERITY.HIGH) {
    return getCssVariable('crimson');
  } else if (severity === REPORT_SEVERITY.MEDIUM) {
    return getCssVariable('yellow');
  } else if (severity === REPORT_SEVERITY.LOW) {
    return getCssVariable('green');
  } else {
    return '';
  }
}

export function getColors() {
  return [
    getCssVariable('bs-purple'),
    getCssVariable('bs-red'),
    getCssVariable('bs-orange'),
    getCssVariable('bs-yellow'),
    getCssVariable('bs-green'),
    'pink',
    'blue',
    'grey',
    'lime',
    'violet',
  ];
}

export function normalizeDescription(
  type: string,
  size: number,
  date: string,
): string {
  const fileSize = byteSize(size || 0);
  return `${type} · ${fileSize.value}${fileSize.unit} · ${format(
    new Date(date),
    'MMM d, yyyy',
  )}`;
}

export function reportDescription(reportData: Report): string {
  return reportData?.name
    ? normalizeDescription(
        startCase(`${reportData?.type} ${reportData?.subType}`.toLowerCase()),
        reportData?.size ?? 0,
        reportData?.creationDate,
      )
    : '';
}

export function allObjectValueAndKeysToString(elements: any): string | null {
  return elements
    ? Object.keys(elements)
        ?.reduce((acc: any, item: string) => [...acc, item, elements[item]], [])
        .join(', ')
    : null;
}

export function getScanTime(data: ReportOverview) {
  return differenceByGivenDate(
    new Date(`${data?.startDate}`),
    new Date(`${data?.analysisEndDate}`),
  );
}

enum DIFFERENCE {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

export function differenceByGivenDate(
  date: Date,
  comparisonDate = new Date(),
): { type: DIFFERENCE; value: number } {
  const diffInSeconds = Math.abs(differenceInSeconds(date, comparisonDate));
  const diffInMinutes = Math.abs(differenceInMinutes(date, comparisonDate));
  const diffInHours = Math.abs(differenceInHours(date, comparisonDate));
  const diffInDays = Math.abs(differenceInDays(date, comparisonDate));

  if (diffInSeconds < 60) {
    return { type: DIFFERENCE.SECONDS, value: diffInSeconds };
  } else if (diffInMinutes < 60) {
    return { type: DIFFERENCE.MINUTES, value: diffInMinutes };
  } else if (diffInHours < 24) {
    return { type: DIFFERENCE.HOURS, value: diffInHours };
  } else {
    return { type: DIFFERENCE.DAYS, value: diffInDays };
  }
}

export const ARCHIVE_LIST = [
  'a',
  'ar',
  'cpio',
  'shar',
  'lbr',
  'iso',
  'lbr',
  'mar',
  'sbx',
  'tar',
  'bz2',
  'F',
  '?XF',
  'gz',
  'lz',
  'lz4',
  'lzma',
  'lzo',
  'rz',
  'sfark',
  'sz',
  '?Q?',
  '?Z?',
  'xz',
  'z',
  'zst',
  '??_',
  '7z',
  's7z',
  'ace',
  'afa',
  'alz',
  'alz',
  'apk',
  'arc',
  'ark',
  'cdx',
  'arj',
  'b1',
  'b6z',
  'ba',
  'bh',
  'cab',
  'car',
  'cfs',
  'cpt',
  'dar',
  'dd',
  'dgc',
  'dmg',
  'ear',
  'gca',
  'genozip',
  'ha',
  'hki',
  'ice',
  'jar',
  'kgb',
  'lzh',
  'lha',
  'lzx',
  'pak',
  'partimg',
  'paq6',
  'paq7',
  'paq8',
  'pea',
  'phar',
  'pim',
  'pit',
  'qda',
  'rar',
  'rk',
  'sda',
  'sea',
  'sen',
  'sfx',
  'shk',
  'sit',
  'sitx',
  'tar.gz',
  'tgz',
  'tar.Z',
  'tar.bz2',
  'tbz2',
  'tar.lz',
  'tlz',
  'tar.xz',
  'txz',
  'tar.zst ',
  'uc',
  'uc0',
  'uc2',
  'ucn',
  'ur2',
  'ue2',
  'uca',
  'uha',
  'war',
  'wim',
  'xar',
  'xp3',
  'yz1',
  'zip',
  'zipx',
  'zoo',
  'zpaq',
  'zz',
];

export function truncate(input: string, length: number) {
  if (input.length > length) {
    return input.substring(0, length) + '...';
  }
  return input;
}

export function checkIfValidUUID(str: string) {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
}

const severityOrder = {
  critical: 1,
  high: 2,
  medium: 3,
  low: 4,
};

//@ts-ignore
export const customSeveritySort = (rowA, rowB, columnId) => {
  const a = rowA.values[columnId]?.toLowerCase();
  const b = rowB.values[columnId]?.toLowerCase();

  //@ts-ignore
  return severityOrder[a] - severityOrder[b];
};
