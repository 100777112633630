import { GRADE_TYPE } from '@components/GradeBadge';
import { IntRoundType } from '@services/normalize';

export enum REPORT_STATUS {
  PENDING = 'PENDING',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export enum REPORT_TYPE {
  LINUX = 'linux',
  CONTAINER = 'container',
  VXWORKS = 'vxworks',
  UEFI = 'uefi',
}

export enum REPORT_SEVERITY {
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum REPORT_NVRAM_FUN_TYPE {
  SET = 'set',
  GET = 'get',
}

export enum REPORT_LINUX_SUB_TYPE {
  GENERIC = 'generic',
  BUILDROOT = 'buildroot',
  OPENWRT = 'openwrt',
  YOCTO = 'yocto',
}

export enum REPORT_CONTAINER_SUB_TYPE {
  DOCKER = 'docker',
  LXC = 'lxc',
}

export enum REPORT_UEFI_SUB_TYPE {
  GENERIC = 'generic',
}

export enum REPORT_UEFI_TYPE_MODULE {
  PEI_MODULE = 'PEI module',
  PEI_CORE = 'PEI core',
  DXE_DRIVER = 'DXE driver',
  DXE_CORE = 'DXE core',
  COMBINED = 'Combined PEI/DXE',
}

export enum REPORT_RTOS_SUB_TYPE {
  GENERIC = 'generic',
}

export enum REPORT_CRYPTO_ANALYZER_TYPE {
  CERTIFICATE = 'certificate',
  PRIVATE_KEY = 'privatekey',
  PUBLIC_KEY = 'publickey',
}

export enum REPORT_BINARY_FIELD_TYPE {
  NO = 'No',
  DSO = 'Dso',
  PIE = 'PIE',
  FULL_PARTIAL = 'Full Partial',
}

export enum REPORT_CODE_ANALYSIS_TYPES {
  FORMAT = 'format',
  RACE = 'race',
  BUFFER = 'buffer',
  SHELL = 'shell',
  CRYPTO = 'crypto',
  TMPFILE = 'tmpfile',
}

export enum REPORT_ATTACK_SURFACE_TYPE {
  EVSA = 'evsa',
  VSS = 'vss',
  NVAR = 'nvar',
}

export enum REPORT_CAPABILITIES_TYPE {
  FILE_FUNCTIONS = 'File Functions',
  TASK_MANIPULATION = 'Task Manipulation',
  INFORMATION_GATHERING = 'Information Gathering',
  NETWORK_FUNCTIONS = 'Network Functions',
  SYSTEM_LOG = 'System Log',
  PACKET_SNIFF = 'Packet Sniff',
  RANDOM_FUNCTIONS = 'Random Functions',
  PIPE_FUNCTIONS = 'Pipe Functions',
  SHELL = 'Shell',
  ENVIRONMENT_VARIABLES = 'Environmant Variables',
  PERMISSIONS = 'Permissions',
  HOOKING = 'Hooking',
}

export interface Report {
  id: string;
  workspaceId: string;
  name: string;
  description: string;
  type: REPORT_TYPE;
  subType:
    | REPORT_LINUX_SUB_TYPE
    | REPORT_CONTAINER_SUB_TYPE
    | REPORT_UEFI_SUB_TYPE
    | REPORT_RTOS_SUB_TYPE;
  originalName: string;
  archived: boolean;
  favourite: boolean;
  creationDate: string;
  startDate: string;
  extractionEndDate: string;
  analysisEndDate: string;
  hash: string;
  size: number;
  continuousScan: boolean;
  notification: boolean;
  status: REPORT_STATUS;
  analysisStatus?: { [key: string]: boolean };
  score: number;
  quickOverview: any;
  grade: GRADE_TYPE;
}

export type ReportCreator = {
  name: string;
  description: string;
  type: string;
  subtype: string;
  file: unknown;
};

export type ReportDetails = {
  [key: string]: string | number;
};

export type ReportSeverityCheck = {
  severity: number;
  preview: [{ [key: string]: string | number }];
};

export interface ReportCheck {
  critical: ReportSeverityCheck;
  high: ReportSeverityCheck;
  medium: ReportSeverityCheck;
  low: ReportSeverityCheck;
  lastCheck?: string;
}

export type ExesListType = {
  count: number;
  exes: any[];
};

type CveCheckReportSeverity = {
  low: number;
  medium: number;
  high: number;
  critical: number;
};

type CveCheckReportSeverityByYear = any;

export interface CveCheckReport extends ReportCheck {
  critical: ReportSeverityCheck;
  high: ReportSeverityCheck;
  medium: ReportSeverityCheck;
  low: ReportSeverityCheck;
  lastCheck?: string;
  severity: CveCheckReportSeverity;
  severityByYear: CveCheckReportSeverityByYear;
}

export interface BinaryReportCheck extends ReportCheck {
  critical: ReportSeverityCheck;
  high: ReportSeverityCheck;
  medium: ReportSeverityCheck;
  low: ReportSeverityCheck;
  networking: ExesListType;
  sslVulns: ExesListType;
  capabilitiesOccourrences: any[];
  lastCheck?: string;
}

export type FlawType = {
  flaws: string[];
  occourrence: number;
};

export type ReportCodeAnalysis = {
  vulnerabilities: number;
  filesAffected: number;
  analysis?: IntRoundType;
  flawsOccourrences: Array<FlawType>;
};

export interface ReportOverview extends Report {
  reportDetails: ReportDetails;
  analysis: {
    cveCheck?: CveCheckReport;
    passwordHash?: number;
    securityScan?: number;
    kernelSecurity?: number;
    code?: ReportCodeAnalysis;
    binary?: BinaryReportCheck;
    peimDxe?: number;
    env?: ReportConfigAnalyzerEnv[];
    history?: ReportConfigAnalyzerHistory[];
    intelBootGuard?: boolean;
    secureBoot?: boolean;
    accessRights?: number;
    networkFunctions?: number;
    packetSniff?: number;
    firmwareOverview?: any;
    tasks?: number;
    symbols?: number;
    functions?: number;
    networking?: number;
    sslVulnerabilities?: number;
  };
}

export type ReportOverviewSeverity = {
  low: number;
  medium: number;
  high: number;
  critical: number;
};

export type ReportOverviewBinary = {
  critical: ReportSeverityCheck;
  high: ReportSeverityCheck;
  low: ReportSeverityCheck;
  medium: ReportSeverityCheck;
};

export type ReportBinaryOverviewSeverity = {
  critical: {
    severity: number;
    preview: [{ [key: string]: string | number }];
  };
  high: {
    severity: number;
    preview: [{ [key: string]: string | number }];
  };
  low: {
    severity: number;
    preview: [{ [key: string]: string | number }];
  };
  medium: {
    severity: number;
    preview: [{ [key: string]: string | number }];
  };
};

export type ReportCveCheckSpecificSeverity = {
  cveid: string;
  product: string;
  version?: string;
  severity: REPORT_SEVERITY;
  patch?: string;
};

export type ReportCveCheckAnalysis = {
  overview: ReportOverviewSeverity;
  severities: ReportCveCheckSpecificSeverity[];
};

export type ReportPasswordHashAnalysis = {
  username: string;
  password: string;
  severity: REPORT_SEVERITY.HIGH;
};

export type ReportSecurityScanAnalysis = {
  filename: string;
  description: string;
  severity: REPORT_SEVERITY.HIGH;
};

export type ReportNvramAnalysis = {
  fun: REPORT_NVRAM_FUN_TYPE;
  exe: string;
  name: string;
};

export type ReportBomViewerAnalysis = {
  filename: string;
  resolve: string;
  license: string;
  occurrences: number;
};

export type ReportPeimDxeCertificate = {
  issuers: string[];
  subjects: string[];
};

export type ReportPeimDxeOverviewAnalysis = {
  issues: number;
  modulePercent: IntRoundType;
};

export type ReportPeimDxeAnalysis = {
  overview: ReportPeimDxeOverviewAnalysis;
  content: ReportPeimDxeAnalysisContent[];
};
export type ReportPeimDxeAnalysisContent = {
  name: string;
  uefiType: REPORT_UEFI_TYPE_MODULE;
  filetype: string;
  format: string;
  machine: string;
  signed: boolean;
  dependencies: string[];
  certificates: ReportPeimDxeCertificate;
  hasUnknown: boolean;
};

export type ReportKernelSecurityAnalysis = {
  name: string;
  enabled: boolean;
};

export type ReportCryptoAnalyzerAnalysis = {
  overview: ReportCryptoAnalyzerOverviewAnalysis;
  content: ReportCryptoAnalyzerContentAnalysis[];
};

export type ReportCryptoAnalyzerContentAnalysis = {
  filename: string;
  path: string;
  keysize?: number;
  issuer: string;
  type: REPORT_CRYPTO_ANALYZER_TYPE;
  shodan?: string;
};

export type ReportCryptoAnalyzerOverviewAnalysis = {
  certificate: number;
  privateKey: number;
  publicKey: number;
};

export type ReportAttackSurfaceOverviewAnalysis = {
  nvar: number;
  vss: number;
  evsa: number;
};

export type ReportAttackSurfaceContentAnalysis = {
  name: string;
  type: string;
  guid: string;
};

export type ReportBinaryOverview = {
  capabilities?: string[];
  severity: ReportOverviewSeverity;
};

export type ReportBinaryAnalysis = {
  overview: ReportBinaryOverview;
  content: ReportBinaryContentAnalysis[];
};

export type ReportBinaryContentAnalysis = {
  filename: string;
  canary: boolean;
  pie: REPORT_BINARY_FIELD_TYPE;
  relro: REPORT_BINARY_FIELD_TYPE;
  nx: boolean;
  fortify: boolean;
  severity?: REPORT_SEVERITY;
  compiler?: string;
  auxInfo?: string;
  capabilities?: string[];
  warnings?: string;
};

export type ReportStaticCodeContentChildAnalysis = {
  code: string;
  description: string;
  format: REPORT_CODE_ANALYSIS_TYPES;
};
export type ReportStaticCodeAnalysis = {
  filename: string;
  occurrences: number;
  types: REPORT_CODE_ANALYSIS_TYPES[];
  children: ReportStaticCodeContentChildAnalysis[];
};

export type ReportFilesystemAnalysis = {
  isRoot: boolean;
  content: ReportFilesystemAnalysisContent[];
};
export type ReportFilesystemAnalysisContent = {
  label: string;
  description?: string;
  type: string;
  isAFolder?: boolean;
  filesize?: { unit: string; value: string };
};

export type ReportUefiSecurityScanAnalysis = {
  name: string;
  module: string;
  guid: string;
  severity: REPORT_SEVERITY.HIGH;
};

export type ReportUefiIntelBootGuardAnalysis = {
  enabled: boolean;
  content: ReportUefiIntelBootGuardAnalysisContent[];
};

export type ReportUefiIntelBootGuardAnalysisContent = {
  name: string;
  value: string;
  content: string;
};

export type ReportUefiAccessRightsAnalysis = {
  name: string;
  read: boolean;
  write: boolean;
};

export type ReportUefiAttackSurfaceAnalysis = {
  overview: ReportAttackSurfaceOverviewAnalysis;
  content: ReportAttackSurfaceContentAnalysis[];
};

export type ReportSecureBootEntries = {
  database?: string;
  name?: string;
  signature?: string;
  hashes?: string;

  issuedTo?: string;
  issuedBy?: string;
};

export type ReportSecureBootAnalysis = {
  keys: ReportSecureBootEntries[];
  databaseCerts: ReportSecureBootEntries[];
  databaseHashes: ReportSecureBootEntries[];
};
export type ReportRtosFunctionsAnalysis = {
  name: string;
  size: string;
  offset: string;
};

export type ReportRtosSymbolsAnalysis = {
  name: string;
  offset: string;
};

export type ReportRtosTasksAnalysis = {
  name: string;
  address: string;
  createdBy: string;
};

// export type ReportOverviewCapabilities = {
//   FileFunctions: number;
//   TaskManipulation: number;
//   InformationGathering: number;
//   NetworkFunctions: number;
//   SystemLog: number;
//   PacketSniff: number;
//   RandomFunction: number;
//   PipeFunction: number;
// };

export type ReportOverviewCapabilities = {
  networkIssues: number;
  packetIssues: number;
  modulePercent: IntRoundType;
};

export type ReportRtosCapabilitiesContentAnalysis = {
  name: string;
  capabilities: string;
  alert: boolean;
  value: string;
};

// export type ReportRtosCapabilitiesAnalysis = {
//   overview: ReportOverviewCapabilities;
//   content: ReportRtosCapabilitiesContentAnalysis[];
// };

// export type ReportRtosCapabilitiesAnalysis = {
//   data: ReportRtosCapabilitiesContentAnalysis[];
// };

export type ReportConfigAnalyzerHistory = {
  created: string;
  createdBy: string;
  emptyLayer: boolean;
};

export type ReportConfigAnalyzerEnv = {
  name: string;
  value: string;
};

// unused
export type ReportConfigAnalyzerAnalysis = {
  envs: any[];
  history: any[];
};

export type Category = {
  title: string;
  subtitle: string;
};
