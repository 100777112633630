import React from 'react';
import styles from './styles.module.scss';

type NumberIndicatorProps = {
  label: string
  value: number
  color?: string
}
const NumberIndicator = ({ label, value, color }: NumberIndicatorProps) => {
  const pre = 'number-indicator';

  return (
    <div className={styles[`${pre}__container`]} >
      <span className={styles[`${pre}__number`]}>{value}</span>
      <div className={styles[`${pre}__divider`]} style={{ backgroundColor: color ? color : 'grey' }}/>
      <span className={styles[`${pre}__label`]}>{label}</span>
    </div>
  );
};

export default NumberIndicator;