//@ts-nocheck
import { getUser } from '@services/api';
import { getTokenOnLocalstorage, setTokenOnLocalstorage } from '@utils/index';
import React from 'react';
export default function useToken() {
  const [token, setToken] = React.useState('');
  React.useEffect(() => {
    async function getToken() {
      const token = getTokenOnLocalstorage();
      setToken(token);
      setTokenOnLocalstorage(token);
    }
    getToken();
  }, []);
  return token;
}
