import { GRADE_TYPE } from '@components/GradeBadge';
import { OverviewGrade } from '@pages/Report/Linux/ReportOverviewLinux/Components/OverviewGrade';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentFooter from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentFooter';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import OverviewGraph from '@pages/Report/Linux/ReportOverviewLinux/Graphs/OverviewGraph';
import styles from '@pages/Report/Linux/ReportOverviewLinux/styles.module.scss';
import { getQuickReport } from '@services/api';
import { format } from 'date-fns';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiHomeFill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import containerStyles from './styles.module.scss';

type OverviewContainerProps = {
  data: any;
};

const breakPointsLeft = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const breakPointsRight = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
};

const OverviewContainer = ({ data }: OverviewContainerProps) => {
  const { reportId = '' }: { reportId: string } = useParams();
  const [chartData, setChartData] = React.useState({});
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  //prettier-ignore
  const overviewTitleAsString = t(`pages:overviewPage.sections.reportOverview.gradesStatus.${data?.grade.toString()}.title`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  //prettier-ignore
  const overviewDescriptionAsString = t(`pages:overviewPage.sections.reportOverview.gradesStatus.${data?.grade.toString()}.description`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  //prettier-ignore
  const overviewCtaAsString = t('pages:overviewPage.sections.reportOverview.modalCtaLabel');

  // const getSeveritiesCount = (item: any) => {
  //   const result = {
  //     high: 0,
  //     highColor: getCssVariable('bs-red'),
  //     medium: 0,
  //     mediumColor: getCssVariable('bs-orange'),
  //     low: 0,
  //     lowColor: getCssVariable('bs-yellow')
  //   };
  //   if (item.high.severity > -1) result.high = item.high.severity || 0;
  //   if (item.medium.severity > -1) result.medium = item.medium.severity || 0;
  //   if (item.low.severity > -1) result.low = item.high.low || 0;
  //   return result;
  // };

  // const getGraphData = React.useCallback(() => {
  //   if (data?.analysis && Object.keys(data.analysis).length > 0) {
  //     const items = { ...data.analysis } as any;
  //     return Object.keys(items).filter((item) => {
  //       if (items[item]?.high || items[item]?.medium || items[item]?.low) return items[item];
  //     }).map((d: string) => {
  //       return {
  //         'item': d,
  //         ...getSeveritiesCount(items[d])
  //       };
  //     });
  //   }
  //   return [];
  // }, [data]);

  const getQuick = async () => {
    if (reportId) {
      const data = await getQuickReport(reportId);
      if (data && data['quick-overview']) {
        setChartData(data['quick-overview']);
      }
    }
  };

  React.useEffect(() => {
    getQuick();
  }, [reportId]);

  // border: 1px solid var(--neutral300);


  return (
    <>
      <SectionHeader
        title={t('pages:overviewPage.sections.overview.title')}
        leadingIcon={RiHomeFill}
        tooltipMessage={''}
      />
      <Row className={'flex-column-reverse flex-xxl-row ms-0 me-0 ps-0 pe-0 '} style={{
        border: '1px solid var(--neutral300)',
        marginBottom: 20,
        borderRadius: '2px'
      }}>
        <Col xxl={8} lg={12}>
          <div className={containerStyles['overview-container']}>
            <SectionLayout className="px-0 py-0">
              <SectionContent
                breakPointsLeft={breakPointsLeft}
                breakPointsRight={breakPointsRight}
                style={{ border: 'none' }}
              >
                <SectionContentLeft
                  verticalMessage={'Overview'}
                  style={{ borderRight: 'none' }}
                >
                  <div className={containerStyles['chart-container']}>
                    <div className={containerStyles['chart-legend']}>
                      <div><span>High</span></div>
                      <div><span>Medium</span></div>
                      <div><span>Low</span></div>
                    </div>
                    <OverviewGraph data={chartData} />
                  </div>
                </SectionContentLeft>
              </SectionContent>
              <SectionContentFooter className="px-3 py-3" style={{ border: 'none' }}>
                <span className={styles['sub-text']}>
                  Last CVE Check scan{' '}
                  {data?.analysis?.cveCheck?.lastCheck
                    ? format(
                      new Date(data.analysis.cveCheck.lastCheck),
                      'dd/MM/yy HH:mm'
                    )
                    : null}
                </span>
              </SectionContentFooter>
            </SectionLayout>
          </div>
        </Col>
        <Col
          xxl={4}
          lg={12}
          className={
            'ps-xxl-3 ps-lg-3 ps-sm-0 ps-md-0 ps-xs-0 d-flex align-items-stretch px-0'
          }
        >
          <OverviewGrade
            cta={overviewCtaAsString}
            title={overviewTitleAsString}
            description={overviewDescriptionAsString}
            grade={data?.grade as GRADE_TYPE}
          />
        </Col>
      </Row >
    </>
  );
};

export default OverviewContainer;
