type Props = {
  color: string;
  size: number;
};
export default function ContainerIcon({ color, size }: Props) {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icons/container"
          transform="translate(-3.000000, -3.000000)"
          fill={color}
        >
          <path
            d="M5,3 C3.9069372,3 3,3.9069372 3,5 L3,19 C3,20.093063 3.9069372,21 5,21 L19,21 C20.093063,21 21,20.093063 21,19 L21,5 C21,3.9069372 20.093063,3 19,3 L5,3 Z M5,5 L19,5 L19,19 L5,19 L5,5 Z M7,7 L7,9 L9,9 L9,7 L7,7 Z M11,7 L11,9 L13,9 L13,7 L11,7 Z M15,7 L15,9 L17,9 L17,7 L15,7 Z M7,11 L7,13 L9,13 L9,11 L7,11 Z M11,11 L11,13 L13,13 L13,11 L11,11 Z M15,11 L15,13 L17,13 L17,11 L15,11 Z M7,15 L7,17 L9,17 L9,15 L7,15 Z M11,15 L11,17 L13,17 L13,15 L11,15 Z M15,15 L15,17 L17,17 L17,15 L15,15 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
