import * as React from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RiDoorLockBoxLine } from 'react-icons/ri';
import Input from '@components/InputSign';
import Button, { BUTTON_TYPE } from '@components/Button';
import Spinner, { SPINNER_TYPE, SPINNER_SIZE } from '@components/Spinner';

interface SubmitProps {
  password: string;
}

interface FormProps extends SubmitProps {
  password: string;
  confirmPassword: string;
}

type Props = {
  isSending: boolean;
  onSubmit: SubmitHandler<SubmitProps>;
};

export default function NewPasswordForm({ isSending, onSubmit }: Props) {
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormProps>({ mode: 'onSubmit' });

  const password = React.useRef({});
  password.current = watch('password', '');
  const { t } = useTranslation('forms');

  const [isDirty, setIsDirty] = React.useState<{
    confirmPassword: boolean;
    password: boolean;
  }>({
    confirmPassword: false,
    password: false,
  });

  const { ref: passwordRef, ...passwordInputProps } = register('password', {
    required: t('errorsState.invalid.password'),
  });

  const { ref: confirmPasswordRef, ...confirmPasswordInputProps } = register(
    'confirmPassword',
    {
      required: t('errorsState.invalid.password'),
      validate: (value) =>
        value === password.current || t('errorsState.invalid.passwordMismatch'),
      minLength: {
        value: 6,
        message: t('errorsState.invalid.passwordMinLength'),
      },
    }
  );

  function handleOnSubmit({
    confirmPassword,
    password,
  }: FormProps) {
    setIsDirty({
      confirmPassword: confirmPassword.length > 0,
      password: password.length > 0,
    });
    onSubmit({ password });
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(handleOnSubmit)} noValidate>
        <Input
          leadingIcon={RiDoorLockBoxLine}
          hasValue={isDirty?.password ?? false}
          label={t('labels.password')}
          type="password"
          placeholder={'••••••••'}
          inputRef={passwordRef}
          {...passwordInputProps}
          error={errors?.password?.message}
        />
        <Input
          leadingIcon={RiDoorLockBoxLine}
          hasValue={isDirty?.confirmPassword ?? false}
          label={t('labels.password')}
          type="password"
          placeholder={'••••••••'}
          inputRef={confirmPasswordRef}
          {...confirmPasswordInputProps}
          error={errors?.confirmPassword?.message}
        />

        <Button type={BUTTON_TYPE.GREEN}>
          <div className={!isSending ? styles.label : styles.hide}>
            {t('labels.setNewPassword')}
          </div>
          {isSending && (
            <div className={styles.spinnerContainer}>
              <Spinner
                type={SPINNER_TYPE.BLACK_GREEN_BKG}
                size={SPINNER_SIZE.DEFAULT}
              />
            </div>
          )}
        </Button>
      </form>
    </div>
  );
}
