export default function IntelBootGuardFill({
  color,
  size,
  ...rest
}: any): JSX.Element {
  return (
    <svg
      {...rest}
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
        fill={color}
      >
        <g
          id="Icons/alert"
          transform="translate(-1.000000, -2.000000)"
          fill={color}
        >
          <path
            d="M4,20 L4,14 C4,9.581722 7.581722,6 12,6 C16.418278,6 20,9.581722 20,14 L20,20 L21,20 L21,22 L3,22 L3,20 L4,20 Z M6,14 L8,14 C8,11.790861 9.790861,10 12,10 L12,8 C8.6862915,8 6,10.6862915 6,14 L6,14 Z M11,2 L13,2 L13,5 L11,5 L11,2 Z M19.778,4.808 L21.192,6.222 L19.072,8.343 L17.657,6.929 L19.778,4.808 Z M2.808,6.222 L4.222,4.808 L6.343,6.928 L4.93,8.344 L2.808,6.222 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
