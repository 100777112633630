import * as React from 'react';

export enum UseDialogActions {
  SET_ITEM_ID = 'SET_ITEM_ID',
  SET_ITEM_DATA = 'SET_ITEM_DATA',
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

export interface IOpenModalPayload {
  selectedId?: string;
  itemData?: any;
}

export interface IUseDialogAction {
  type: UseDialogActions;
  payload?: IOpenModalPayload;
}

export interface IUseDialogState {
  id: string;
  opened: boolean;
  selectedId?: string;
  itemData?: any;
}

const dialogInitialState = {
  opened: false,
  selectedId: undefined,
  itemData: undefined
};

const dialogReducer = (state: IUseDialogState, action: IUseDialogAction): IUseDialogState => {
  const { type, payload } = action;
  switch (type) {
    case UseDialogActions.SET_ITEM_DATA:
      return { ...state, opened: true, itemData: payload?.itemData };
    case UseDialogActions.SET_ITEM_ID:
      return { ...state, opened: true, selectedId: payload?.selectedId };
    case UseDialogActions.OPEN_MODAL:
      return { ...state, opened: true };
    case UseDialogActions.CLOSE_MODAL:
      return { ...state, opened: false, selectedId: undefined, itemData: undefined };
    default:
      return state;
  }
};

const useDialog = (id: string) => {
  const [state, dispatch] = React.useReducer(dialogReducer, { ...dialogInitialState, id });

  const open = () => dispatch({ type: UseDialogActions.OPEN_MODAL });
  const close = () => dispatch({ type: UseDialogActions.CLOSE_MODAL });
  const setSelectedId = (selectedId: string) =>
    dispatch({ type: UseDialogActions.SET_ITEM_ID, payload: { selectedId } });
  const setItemData = (itemData: any) => dispatch({ type: UseDialogActions.SET_ITEM_DATA, payload: { itemData } });

  return React.useMemo(() => {
    return {
      ...state,
      open,
      close,
      setSelectedId,
      setItemData
    };
  }, [state]);
};

export default useDialog;