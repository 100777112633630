import BSidebarWrapper from '@components/Sidebar/BSidebarWrapper';
import CustomTooltip from '@components/Tooltip/Tooltip';
import { BSMenuProvider } from '@context/BSMenuContext';
import MyAccount from '@pages/UserSettings/MyAccount';
import Organization from '@pages/UserSettings/Organization';
import OrganizationDetails from '@pages/UserSettings/OrganizationDetails';
import { ROUTES } from '@routes/routes';
import React, { useMemo } from 'react';
import { Breadcrumb, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  AiOutlineFile,
  BsFileEarmark,
  FaFileInvoice,
  MdPayment,
} from 'react-icons/all';
import { RiBuilding4Line, RiFileUserFill } from 'react-icons/ri';
import { Redirect, useHistory } from 'react-router';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import styles from './styles.module.scss';

const UserSettings = () => {
  const { t } = useTranslation(['common']);
  const { path } = useRouteMatch();
  const history = useHistory();

  const pathSubscription = path + ROUTES.SUBSCRIPTION;
  const pathInvoice = pathSubscription + ROUTES.INVOICE;
  const pathPayments = pathSubscription + ROUTES.PAYMENTS;
  const pathPlans = pathSubscription + ROUTES.PLANS;

  const pathOrganization = path + ROUTES.ORGANIZATION;
  const pathOrganizationOverview =
    pathOrganization + ROUTES.ORGANIZATION_OVERVIEW;
  const pathOrganizationSettings =
    pathOrganization + ROUTES.ORGANIZATION_SETTINGS;

  const isProfile = useRouteMatch(path);
  const isPlans = useRouteMatch(pathPlans);
  const isInvoice = useRouteMatch(pathInvoice);
  const isPayments = useRouteMatch(pathPayments);
  const isSubscription = useRouteMatch(pathSubscription);

  const isOrganization = useRouteMatch(pathOrganization);

  const shouldHideOrganization = useMemo(
    () => false,
    // TODO: unhide once it's ready
    // !!(
    //   customerSubData?.length === 0 ||
    //   customerSubData?.find((sub) => sub.plan.key === 'pro')
    // ),
    []
  );

  function getBreadcrumbPage() {
    if (isProfile?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('common:navigation.accountNavigation.account')} <RiFileUserFill />
        </Breadcrumb.Item>
      );
    }

    if (isPlans?.isExact) {
      return (
        <React.Fragment>
          <Breadcrumb.Item active className={'not-clickable'}>
            {t('common:navigation.accountNavigation.subscription')}{' '}
            <AiOutlineFile />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t('common:navigation.accountNavigation.plans')} <BsFileEarmark />
          </Breadcrumb.Item>
        </React.Fragment>
      );
    }

    if (isInvoice?.isExact) {
      return (
        <React.Fragment>
          <Breadcrumb.Item active className={'not-clickable'}>
            {t('common:navigation.accountNavigation.subscription')}{' '}
            <AiOutlineFile />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t('common:navigation.accountNavigation.invoice')} <FaFileInvoice />
          </Breadcrumb.Item>
        </React.Fragment>
      );
    }

    if (isPayments?.isExact) {
      return (
        <React.Fragment>
          <Breadcrumb.Item active className={'not-clickable'}>
            {t('common:navigation.accountNavigation.subscription')}{' '}
            <AiOutlineFile />
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            {t('common:navigation.accountNavigation.paymentInfo')} <MdPayment />
          </Breadcrumb.Item>
        </React.Fragment>
      );
    }

    if (isSubscription?.isExact) {
      return (
        <Breadcrumb.Item active>
          {t('common:navigation.accountNavigation.subscription')}{' '}
          <AiOutlineFile />
        </Breadcrumb.Item>
      );
    }
    return null;
  }

  function getDefaultActiveKey(): string {
    if (isProfile?.isExact) return 'myAccount';

    if (
      isSubscription?.isExact ||
      isPlans?.isExact ||
      isInvoice?.isExact ||
      isPayments?.isExact
    )
      return 'subscription';

    return '';
  }

  const activeItem = getDefaultActiveKey();
  return (
    <BSMenuProvider>
      <div className={'submenu-container'}>
        <Breadcrumb className={'pt-3 pb-2'}>
          <Breadcrumb.Item href={ROUTES.BASE}>Cosmo</Breadcrumb.Item>
          <Breadcrumb.Item active className={'not-clickable'}>
            {t('common:navigation.accountNavigation.profile')}
          </Breadcrumb.Item>
          {getBreadcrumbPage()}
        </Breadcrumb>
        <Nav
          className={'nav-cosmo'}
          variant="tabs"
          activeKey={activeItem}
          defaultActiveKey={'myAccount'}
        >
          <Nav.Item>
            <Nav.Link eventKey={'myAccount'} onClick={() => history.push(path)}>
              <RiFileUserFill />{' '}
              {t('common:navigation.accountNavigation.account')}
            </Nav.Link>
          </Nav.Item>
          {/* TODO: unhide once it's ready */}
          {/* <Nav.Item>
            <Nav.Link
              eventKey={'subscription'}
              onClick={() => history.push(pathSubscription)}
            >
              <AiOutlineFile />{' '}
              {t('common:navigation.accountNavigation.subscription')}
            </Nav.Link>
          </Nav.Item> */}
          {shouldHideOrganization ? (
            <CustomTooltip label="Available only in BUSINESS and ENTERPRISE plans">
              <Nav.Item>
                <Nav.Link
                  eventKey={'organizations'}
                  onClick={() => history.push(pathOrganization)}
                  className={styles.disabled}
                  disabled
                >
                  <RiBuilding4Line />{' '}
                  {t('common:navigation.accountNavigation.organization')}
                </Nav.Link>
              </Nav.Item>
            </CustomTooltip>
          ) : (
            <Nav.Item>
              <Nav.Link
                eventKey={'organizations'}
                onClick={() => history.push(pathOrganization)}
              >
                <RiBuilding4Line />{' '}
                {t('common:navigation.accountNavigation.organization')}
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </div>
      <BSidebarWrapper>
        <Switch>
          <Route exact path={path}>
            <MyAccount />
          </Route>
          {/* TODO: unhide subscription page when ready */}
          {/* <Route path={pathSubscription}>
            <Sidenav>
              <Route path={pathPlans}>
                <Plans />
              </Route>
              <Route path={pathInvoice}>
                <Invoice />
              </Route>
              <Route path={pathPayments}>
                <PaymentInfo />
              </Route>
              {isSubscription?.isExact ? (
                <Redirect from={pathSubscription} to={pathPlans} />
              ) : null}
            </Sidenav>
          </Route> */}
          <Route path={pathOrganizationOverview}>
            <Organization />
          </Route>
          <Route path={pathOrganizationSettings}>
            <OrganizationDetails />
          </Route>
          {/*<Route path={pathOrganization}>*/}
          {/*  <Sidenav>*/}
          {/*    <Route path={pathOrganizationOverview}>*/}
          {/*      <Organization />*/}
          {/*    </Route>*/}
          {/*    <Route path={pathOrganizationSettings}>*/}
          {/*      <OrganizationDetails />*/}
          {/*    </Route>*/}
          {/*    {isOrganization?.isExact ? (*/}
          {/*      <Redirect from={pathOrganization} to={pathOrganizationOverview} />*/}
          {/*    ) : null}*/}
          {/*  </Sidenav>*/}
          {/*</Route>*/}
          {isOrganization?.isExact ? (
            <Redirect from={pathOrganization} to={pathOrganizationOverview} />
          ) : null}
        </Switch>
      </BSidebarWrapper>
    </BSMenuProvider>
  );
};

export default UserSettings;
