import { useQuery } from 'react-query';
import type { ReportBomViewerAnalysis } from '@utils/types/report';
import { getReportBomViewerAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'bom-viewer-analysis';

export default function useBomViewerAnalysis(id: string) {
  useToken();
  return useQuery<ReportBomViewerAnalysis[], Error>([KEY, id], () =>
    getReportBomViewerAnalysis(id),
    {
      retry: false
    }
  );
}
