import React, { ReactNode, CSSProperties } from 'react';

type SectionContentRightBodyProps = {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
};

const SectionContentRightBody = (props: SectionContentRightBodyProps) => {
  const { children, className, style } = props;
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

export default SectionContentRightBody;
