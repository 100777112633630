import LayoutPage from '@components/LayoutPage';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// import { CHIP_COLOR } from '@components/Chip';
import EmptyResults from '@components/EmptyResults';
import useRtosCapabilitiesAnalysis from '@hooks/analysis/useRtosCapabilitiesAnalysis';
// import {
//   REPORT_CAPABILITIES_TYPE,
// } from '@utils/types/report';
import { RiArrowLeftSLine, RiArrowRightSLine, RiSearch2Line, RiSpam2Fill } from 'react-icons/ri';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
// import { getCssVariable } from '@utils/index';
import Chip from '@components/Chip';
import chipStyles from '@components/Chip/styles.module.scss';
import GenericError from '@components/ErrorState';
import { Input } from '@components/Forms/Input';
import LayoutSection from '@components/LayoutSection';
import ModalReportVxWorksDetails from '@components/ModalReportVxWorksDetails';
import ReportHeading from '@components/ReportHeading';
import SkeletonAnalysisWithBlocks from '@components/SkeletonAnalysisWithBlocks';
import Table from '@components/TableNew/Table';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import TabsProvider from '@components/Tabs/TabsProvider';
import { TabsHeader } from '@components/TabsHeader';
import tabsStyles from '@components/TabsHeader/styles.module.scss';
import usePaginationCounter, { UsePaginationCounterState } from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import styles from '@pages/Report/Linux/ReportOverviewLinux/styles.module.scss';
import CapabilitiesContainer from '@pages/Report/VxWorks/Overview/Containers/CapabilitiesContainer';
import { getColorFromType } from '@pages/Report/VxWorks/utils';
import reportStyles from '@pages/Report/styles.module.scss';
import { getCssVariable, padNumber } from '@utils/index';
import { REPORT_CAPABILITIES_TYPE } from '@utils/types/report';
import { noop } from 'lodash';
import { Col, Container, Row } from 'react-bootstrap';

// export enum RTOS_CAPABILITIES_PROPERTY {
//   NAME = 'name',
//   CAPABILITIES = 'capabilities',
//   ALERT = 'alert',
// }

export default function ReportRtosCapabilities() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isError, error } =
    useRtosCapabilitiesAnalysis(reportId);
  const { data: reportData } = useReportOverview(reportId);

  const { t } = useTranslation(['pages', 'tables', 'emptyReports', 'common']);

  const [activeIndex, setActiveIndex] = React.useState<string>('0');
  const [search, setSearch] = React.useState('');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  // function typeFilterFn(rows: Row[], id: string[], filterValue: string) {
  //   return matchSorter(rows, filterValue, {
  //     keys: [(row: Row) => row.values[id[0]]?.props?.children?.props?.children],
  //   });
  // }

  // function renderFiltersTypeForSpecificCols(currentLabel: string) {
  //   if (!currentLabel) {
  //     return {};
  //   }
  //   if (currentLabel === RTOS_CAPABILITIES_PROPERTY.CAPABILITIES) {
  //     return { filter: 'typeFilter' };
  //   }
  // }

  // const renderRow = React.useCallback(
  //   (row: ReportRtosCapabilitiesContentAnalysis, label: string) => {
  //     if (label === RTOS_CAPABILITIES_PROPERTY.ALERT && row.alert) {
  //       const value = get(row, label, false);
  //
  //       return value ? (
  //         <RiSpamFill size={24} color={getCssVariable('crimson')} />
  //       ) : null;
  //     }
  //
  //     if (label === RTOS_CAPABILITIES_PROPERTY.CAPABILITIES) {
  //       const value = get(row, label, '');
  //
  //       return (
  //         <span
  //           style={{
  //             width: 'auto',
  //             display: 'flex',
  //             justifyContent: 'flex-start',
  //           }}
  //         >
  //           <Chip color={getColorFromType(value as any)}>{value}</Chip>
  //         </span>
  //       );
  //     } else if (label === RTOS_CAPABILITIES_PROPERTY.NAME) {
  //       return (
  //         <span>
  //           {row.name}{' '}
  //           {row.value.length > 60 && (
  //             <span
  //               style={{
  //                 whiteSpace: 'nowrap',
  //               }}
  //               onClick={() =>
  //                 onShowModal({ title: row?.capabilities, content: row?.value })
  //               }
  //               className={'classic-link'}
  //             >
  //               {t('common:generic.more')}
  //             </span>
  //           )}
  //         </span>
  //       );
  //     } else {
  //       return get(row, label, '');
  //     }
  //   },
  //   [onShowModal, t]
  // );

  // const tableRows = React.useMemo(
  //   () =>
  //     data?.content.map((row) => {
  //       return Object.keys(row).reduce(
  //         (acc: any, label: string) => ({
  //           ...acc,
  //           [label]: renderRow(row, label),
  //         }),
  //         {}
  //       );
  //     }) ?? [],
  //   [data?.content, renderRow]
  // );

  // const tableCols = React.useMemo(() => {
  //   const headerColsToHide: string[] = [RTOS_CAPABILITIES_PROPERTY.ALERT];
  //   const allowedCols: string[] = [
  //     RTOS_CAPABILITIES_PROPERTY.NAME,
  //     RTOS_CAPABILITIES_PROPERTY.CAPABILITIES,
  //     RTOS_CAPABILITIES_PROPERTY.ALERT,
  //   ];
  //   return Object.keys(get(data, 'content[0]', {}))
  //     ?.filter((label: string) =>
  //       allowedCols.includes(label as keyof typeof RTOS_CAPABILITIES_PROPERTY)
  //     )
  //     .map((label: string) => {
  //       return {
  //         ...renderFiltersTypeForSpecificCols(label),
  //         Header: () =>
  //           !headerColsToHide.includes(label as RTOS_CAPABILITIES_PROPERTY) ? (
  //             <Trans t={t}>{label}</Trans>
  //           ) : null,
  //         accessor: label,
  //         sortable: false,
  //         minWidth: 300,
  //         width: 260,
  //         disableSortBy: ![RTOS_CAPABILITIES_PROPERTY.CAPABILITIES].includes(
  //           label as RTOS_CAPABILITIES_PROPERTY
  //         )
  //           ? false
  //           : true,
  //         sortType: 'alphanumeric',
  //       };
  //     });
  // }, [t, data]);

  // const initialState = React.useMemo(() => {
  //   return {
  //     filters: [
  //       { id: RTOS_CAPABILITIES_PROPERTY.CAPABILITIES, value: capabilities },
  //     ],
  //   };
  // }, [capabilities]);
  //
  // const [filterByTypeValue, setFilterByTypeValue] = React.useState<
  //   string | null
  // >(capabilities);
  //
  // const onFilterTypeChange =
  //   (onSetFilter: SetFilter) => (e: React.ChangeEvent<HTMLSelectElement>) => {
  //     setFilterByTypeValue(e.target?.value.toLowerCase());
  //     onSetFilter(
  //       RTOS_CAPABILITIES_PROPERTY.CAPABILITIES,
  //       e.target?.value.toLowerCase()
  //     );
  //   };
  //
  // const renderFilterByTypeSelect = React.useCallback(
  //   (onSetFilter: SetFilter) => {
  //     return (
  //       <TableFilter
  //         value={filterByTypeValue}
  //         placeholder={t('tables:filters.type')}
  //         options={Object.keys(data?.overview.modulePercent ?? {})?.map(
  //           (value = '') => ({
  //             value: toLower(value),
  //             label: startCase(toLower(value)),
  //           })
  //         )}
  //         onChange={onFilterTypeChange(onSetFilter)}
  //       />
  //     );
  //   },
  //   [data, filterByTypeValue, t]
  // );
  //
  // function renderTableFilters(onSetFilter: SetFilter) {
  //   return [renderFilterByTypeSelect(onSetFilter)];
  // }

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'name',
        Cell: ({ row, value }: { row: any; value: boolean }) => {
          return (
            <div className="d-flex flex-row align-items-center">
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400')
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Capabilities',
        accessor: 'caps',
        width: 500,
        Cell: ({ value }: any) => value?.length > 0 ?
          <div className={chipStyles['list']}>{value.map((v: REPORT_CAPABILITIES_TYPE) => <Chip
            color={getColorFromType(v)}>{v}</Chip>)}</div> :
          null
      },
      {
        Header: '',
        accessor: 'alert',
        width: 50,
        Cell: ({ value }: any) =>
          value ? (
            <div className="d-flex align-items-center justify-content-end">
              <RiSpam2Fill
                style={{ fontSize: '1.275rem', fill: getCssVariable('general-red') }}
              />
            </div>
          ) : null,
      },
    ],
    []
  );

  const memoizedData = React.useMemo(() => {
    if (!data || data.length === 0) return [];
    return data;
  }, [data]);

  const memoizedSearch = React.useMemo(() => search, [search]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { globalFilter: memoizedSearch },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisWithBlocks />}
      {isError && <GenericError error={error} />}
      {data && Object.keys(data).length === 0 && (
        <EmptyResults
          title={t('emptyReports:capabilitiesAnalysisPage.title')}
          description={t('emptyReports:capabilitiesAnalysisPage.subtitle')}
        // buttonLabel={t('emptyReports:capabilitiesAnalysisPage.buttonLabel')}
        // onClick={console.log}
        />
      )}
      {data && Object.keys(data).length > 0 && !isLoading && !isError && (
        <>
          <LayoutSection spacer={32}>
            <ReportHeading
              chipLabel={t('common:generic.completed')}
              buttonLabel={t('common:generic.downloadReport')}
              onButtonClick={noop}
              descriptionButtonLabel={t('common:generic.viewDetails')}
              onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
            />
          </LayoutSection>
          <CapabilitiesContainer
            data={data}
            bannerText={t('pages:capabilitiesAnalysisPage.bannerMessage')}
          />
          <Container fluid style={{ border: '1px solid var(--neutral300)' }}>
            <Row>
              <Col className="px-0" xs={12}>
                <div style={{ fontSize: 20, padding: '40px 40px 5px 40px' }}>Capabilities</div>
                <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px', textTransform: 'uppercase' }}>total: {data?.length || 0}</div>
                <Table tableInstance={tableInstance}>
                  <Table.Footer>
                    <p className={tableStyles['table__paginationState']}>
                      {paginationMessage}
                    </p>
                    <div className={tableStyles['table__paginationButtons']}>
                      <button
                        className={
                          tableInstance?.canPreviousPage
                            ? tableStyles['table__paginationActiveButton']
                            : ''
                        }
                        onClick={() => tableInstance.previousPage()}
                      >
                        <RiArrowLeftSLine size={20} />
                      </button>
                      <button
                        className={
                          tableInstance?.canNextPage
                            ? tableStyles['table__paginationActiveButton']
                            : ''
                        }
                        onClick={() => tableInstance.nextPage()}
                      >
                        <RiArrowRightSLine size={20} />
                      </button>
                    </div>
                    <TablePageSize
                      maxResultChunkSize={maxResultChunkSize}
                      pageSize={tableInstance?.state?.pageSize || 10}
                      onPageSizeChange={onPageSizeChange}
                    />
                  </Table.Footer>
                </Table>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {shouldShowModalDetail ? (
        <ModalReportVxWorksDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData?.reportDetails
          }}
        />
      ) : null}
    </LayoutPage>
  );

  // return (
  //   <LayoutPage>
  //     {capabilitiesResultLength ? (
  //       <>
  //         <LayoutSection spacer={32}>
  //           <ReportHeading
  //             title={t('pages:capabilitiesAnalysisPage.title')}
  //             chipLabel={t('common:generic.experimental')}
  //           />
  //         </LayoutSection>
  //
  //         <LayoutSection>
  //           <LayoutBox>
  //             <LayoutBoxContent padding={PADDING_SIZE.NO_PADDING}>
  //               <CapabilitiesAnalysisHighlight
  //                 color={'crimson'}
  //                 networkFunction={data?.overview.networkIssues ?? 0}
  //                 packetSniff={data?.overview.packetIssues ?? 0}
  //               />
  //               <DataHighlightChart dataChart={data?.overview?.modulePercent} />
  //             </LayoutBoxContent>
  //           </LayoutBox>
  //         </LayoutSection>
  //         <LayoutSection>
  //           <Table
  //             hasSearch
  //             initialState={initialState}
  //             filters={renderTableFilters}
  //             onSearch={{
  //               fuzzyText: fuzzyTextFilterFn,
  //               typeFilter: typeFilterFn,
  //             }}
  //             hasPagination={capabilitiesResultLength > MIN_ROW_LENGTH}
  //             columns={tableCols as any}
  //             data={tableRows}
  //           />
  //           <ReactTooltip
  //             border={true}
  //             arrowColor={getCssVariable('primary')}
  //             borderColor={getCssVariable('neutral300')}
  //             textColor={getCssVariable('secondary')}
  //             backgroundColor={getCssVariable('primary')}
  //             effect="solid"
  //             className={'tooltip'}
  //             multiline={true}
  //           />
  //         </LayoutSection>
  //       </>
  //     ) : !isLoading && isSuccess ? (
  //       <EmptyResults
  //         title={t('emptyReports:capabilitiesAnalysisPage.title')}
  //         description={t('emptyReports:capabilitiesAnalysisPage.subtitle')}
  //         // buttonLabel={t('emptyReports:capabilitiesAnalysisPage.buttonLabel')}
  //         onClick={console.log}
  //       />
  //     ) : !isLoading && isError ? (
  //       <GenericError error={error} />
  //     ) : (
  //       <SkeletonAnalysisSimple />
  //     )}
  //     {shouldShowModal ? (
  //       <ModalWrapper onClose={onHideModal} title={modalContent?.title}>
  //         <p>{modalContent?.content}</p>
  //       </ModalWrapper>
  //     ) : null}
  //   </LayoutPage>
  // );
}
