import { useQuery } from 'react-query';
import type { ReportRtosTasksAnalysis } from '@utils/types/report';
import { getReportRtosTasksAnalysis } from '@services/api';
import useToken from '@hooks/useToken';
export const KEY = 'rtos-tasks';

export default function useRtosTasksAnalysis(id: string) {
  useToken();
  return useQuery<ReportRtosTasksAnalysis[], Error>([KEY, id], () =>
    getReportRtosTasksAnalysis(id),
    {
      retry: false
    }
  );
}
