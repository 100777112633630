import React, { useState } from 'react';
import { getCssVariable } from '@utils/index';
import { RiStarFill, RiStarLine } from 'react-icons/ri';
import { Report } from '@utils/types/report';
import { updateReportFromApi } from '@services/api';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Spinner from '@components/Spinner';

type IProps = {
  report: Report,
  onChange: any
};

const BSFavourite = ({
  report,
  onChange
}: IProps) => {
  const { t } = useTranslation(['tables']);
  const [ loading, isLoading ] = useState<boolean>(false);

  async function handleFavourites () {
    try {
      isLoading(true);
      const payload = {
        name: report.name,
        description: report.description,
        notification: report.notification,
        continuous_scan: report.continuousScan,
        favourite: !report.favourite,
        archived: report.archived
      };

      await updateReportFromApi(report.id, payload);
      onChange(report.id, {
        favourite: !report.favourite
      });
    } catch (err) {
    } finally {
      isLoading(false);
    }
  }

  return (
    <React.Fragment>
      { loading ? <Spinner fullScreen={true} /> : null}
      {report.favourite ?
        <OverlayTrigger placement={'top'} overlay={<Tooltip>{t('tables:favourites.remove')}</Tooltip>}>
          <span className={'fav'}><RiStarFill size={20} color={getCssVariable('yellow')} onClick={handleFavourites} /></span>
        </OverlayTrigger>
      :
        <OverlayTrigger placement={'top'} overlay={<Tooltip>{t('tables:favourites.add')}</Tooltip>}>
          <span className={'fav'}><RiStarLine size={20} color={getCssVariable('neutral400')} onClick={handleFavourites} /></span>
        </OverlayTrigger>
      }
    </React.Fragment>
    );
};

export default BSFavourite;
