import { getGraphTheme } from '@components/Graph/theme';
import { GlobalContext } from '@context/GlobalContext';
import { ResponsivePie } from '@nivo/pie';
import { NumberIndicator } from '@pages/Report/Linux/ReportOverviewLinux/Components/NumberIndicator';
import { getCssVariable } from '@utils/index';
import React from 'react';
import styles from './severitiesStyles.module.scss';

type BinaryAnalysisSeverityGraphProps = {
  data: any;
};

const BinaryAnalysisSeverityGraph = ({
  data,
}: BinaryAnalysisSeverityGraphProps) => {
  const getData = () => {
    const dataGraph: any = {
      low: {
        id: 'Low',
        label: 'LOW',
        value: 0,
      },
      medium: {
        id: 'Medium',
        label: 'MEDIUM',
        value: 0,
      },
      high: {
        id: 'High',
        label: 'HIGH',
        value: 0,
      },
    };

    if (!data) {
      return Object.keys(dataGraph).map(function (key) {
        return dataGraph[key];
      });
    }

    for (const i of Object.keys(data)) {
      if (Object.keys(dataGraph).includes(i)) {
        dataGraph[i].value = data[i]?.severity || 0;
      }
    }

    return Object.keys(dataGraph).map(function (key) {
      return dataGraph[key];
    });
  };
  const { theme } = React.useContext(GlobalContext);
  const graphTheme = getGraphTheme(theme);

  return (
    <div className={styles['graph-content']} style={{ height: '100%' }}>
      <ResponsivePie
        theme={graphTheme}
        margin={{ top: 30, right: 150, bottom: 30, left: 25 }}
        // padAngle={0.7}
        // cornerRadius={3}
        // activeOuterRadiusOffset={8}
        // borderWidth={1}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
        data={getData()}
        colors={[
          getCssVariable('yellow'),
          getCssVariable('orange'),
          getCssVariable('crimson'),
          getCssVariable('purple'),
        ]}
        fill={[
          {
            match: {
              id: 'Low',
            },
            id: 'lines',
          },
          {
            match: {
              id: 'Medium',
            },
            id: 'lines',
          },
          {
            match: {
              id: 'High',
            },
            id: 'lines',
          },
        ]}
        legends={[
          {
            anchor: 'bottom-left',
            direction: 'column',
            justify: false,
            translateX: 100,
            translateY: 0,
            itemWidth: 80,
            itemHeight: 20,
            itemsSpacing: 6,
            itemTextColor: 'black',
            itemDirection: 'left-to-right',
            symbolSize: 12,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
      <div
        className="d-flex flex-column align-items-center justify-content-center gap-2"
        style={{ marginLeft: '-4rem' }}
      >
        <NumberIndicator
          label={'High'}
          value={data?.high?.severity || 0}
          color={getCssVariable('bs-red')}
        />
        <NumberIndicator
          label={'Medium'}
          value={data?.medium?.severity || 0}
          color={getCssVariable('bs-orange')}
        />
        <NumberIndicator
          label={'Low'}
          value={data?.low?.severity || 0}
          color={getCssVariable('bs-yellow')}
        />
      </div>
    </div>
  );
};

export default BinaryAnalysisSeverityGraph;
