import ModalWrapper from '@components/ModalWrapper';

import styles from './styles.module.scss';
import { noop } from 'lodash';
import { WithChildren } from '@utils/types/helpers';

type Props = WithChildren<{
  onClose: typeof noop;
  title: string;
}>;

export default function TableModal({
  onClose = noop,
  title = '',
  children,
}: Props) {
  const handleModalClose = () => {
    onClose(null);
  };

  return (
    <ModalWrapper
      onClose={handleModalClose}
      className={styles.modal}
      title={title}
    >
      {children}
    </ModalWrapper>
  );
}
