import * as React from 'react';
import styles from './styles.module.scss';
import { WithChildren } from '@utils/types/helpers';
import Lottie from 'react-lottie';
import { Col, Container, Row } from 'react-bootstrap';

type Props = WithChildren<{
  image?: string;
  animationData?: any;
}>;

export type FeedbackData = {
  feedback: string;
};

export default function LayoutOnBoarding({
  children,
  image,
  animationData,
}: Props) {
  // const { t } = useTranslation('illustration');

  // const { theme } = React.useContext(GlobalContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  // const { t } = useTranslation(['pages']);

  // const isDarkMode = theme === THEMES.DARK;
  return (
    <Container fluid>
      <Row style={{ minHeight: '100vh' }} className="align-items-center">
        <Col xs={12} sm={12} md={12} lg={5} xl={5}>
          {children}
        </Col>
        <Col
          className="d-none d-lg-flex align-items-center justify-content-center"
          xs={0}
          sm={5}
          md={5}
          lg={7}
          xl={7}
        >
          {animationData ? (
            <div className={styles.animation}>
              <Lottie
                options={defaultOptions}
                height={520}
                width={520}
                isStopped={false}
                isPaused={false}
              />
            </div>
          ) : (
            <img src={image} alt="side" />
          )}
        </Col>
      </Row>
    </Container>
  );
}
