import Table from '@components/TableNew/Table';
import useCodeAnalysis from '@hooks/analysis/useCodeAnalysis';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import Utils from '@pages/Report/Linux/ReportOverviewLinux/Utils/Utils';
import { getCssVariable, padNumber } from '@utils/index';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useFilters, usePagination, useTable } from 'react-table';
import styles from './styles.module.scss';

const breakPointsLeft = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const breakPointsRight = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
};

const CodeAnalysisAffectedFilesTable: React.FC = () => {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data: dataCodeAnalysis } = useCodeAnalysis(reportId);

  const memoizedData = React.useMemo(() => {
    if (!dataCodeAnalysis) return [];
    return dataCodeAnalysis;
  }, [dataCodeAnalysis]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'filename',
        width: 50,
        Cell: ({ row, value }: { row: any; value: string }) => {
          return (
            <div className="d-flex flex-row align-items-center">
              <span
                className="d-flex flex-row align-items-center px-1"
                style={{
                  marginRight: '.5rem',
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {value?.replace('static_analysis_files', '')}
            </div>
          );
        },
      },
      {
        Header: 'Type',
        accessor: 'types',
        width: 200,
        Cell: ({ value }: { value: string[] }) => {
          return (
            <div className="d-flex align-items-center w-100">
              {value.map((t: string, i: number) => (
                <span
                  key={`${t}-${i}`}
                  style={{
                    padding: '.35rem',
                    marginRight: '1rem',
                    background: getCssVariable(
                      Utils.getCodeAnalysisChipColorByLabel(t)
                    ),
                    color: 'black',
                    textTransform: 'uppercase',
                    borderRadius: '4px',
                  }}
                >
                  {t}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        Header: 'Occurences',
        accessor: 'occurrences',
        width: 100,
        Cell: ({ value }: any) => {
          return (
            <div className="d-flex align-items-center w-100">
              <span>{`${value} vulnerabilities`}</span>
            </div>
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      data: memoizedData,
      columns,
      initialState: { pageSize: 999 },
    },
    useFilters,
    usePagination
  );

  return (
    <div className={styles['container']}>
      <SectionLayout className="px-0 py-5">
        <SectionContent
          breakPointsLeft={breakPointsLeft}
          breakPointsRight={breakPointsRight}
          style={{ borderColor: '#d0d7de', marginLeft: 0, marginRight: 0 }}
        >
          <SectionContentLeft
            verticalMessage={'Vulnerabilities'}
            verticalMessageColor="#05516f"
            style={{ borderRight: 'none' }}
          >
            <Table className="border-0 px-4" tableInstance={tableInstance} />
          </SectionContentLeft>
        </SectionContent>
      </SectionLayout>
    </div>
  );
};

export default CodeAnalysisAffectedFilesTable;
