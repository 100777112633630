import React from 'react';
import { BSMenuContext } from '@context/BSMenuContext';

interface IProps {
  children: React.ReactNode;
  isReport?: boolean;
}

const BSidebarWrapper = ({ children, isReport = false }: IProps) => {
  const { open } = React.useContext<any>(BSMenuContext);

  return (
    <React.Fragment>
      <div className={`wrapper ${!isReport ? '' : 'wrapper-overview'}`}>
        <div className={open ? 'sidebar-mini' : ''}>{children}</div>
      </div>
    </React.Fragment>
  );
};

export default BSidebarWrapper;
