import React from 'react';
import ModalWrapper from '@components/ModalWrapper';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row, Button } from 'react-bootstrap';
// import styles from '@components/LayoutOnBoarding/styles.module.scss';
import logo from '@assets/logo/Exein-logo.gif';
import logoDark from '@assets/logo/exein-logo-dark.gif';
import { getTokenOnLocalstorage } from '@utils/index';
import { acceptOrganizationInvite } from '@services/api';
import { GlobalContext } from '@context/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import { useQueryClient } from 'react-query';
// import useAcceptInvite from '@hooks/useAcceptInvite';

// type AcceptInviteAction = {
//   type: AcceptInviteType,
//   payload?: string | undefined
// }
// type AcceptInviteReducer = {
//   state: AcceptInviteState,
//   action: AcceptInviteAction
// }
// type AcceptInviteState = {
//   error: string,
//   loading: boolean,
//   sent: boolean
// }

// enum AcceptInviteType {
//   SENDING = 'SENDING',
//   SUCCESS = 'SUCCESS',
//   ERROR = 'ERROR'
// }

// const acceptInviteInitialState = {
//   error: '',
//   loading: false,
//   sent: false
// };
//
// const acceptInviteReducer = (state: AcceptInviteState, action: AcceptInviteAction): AcceptInviteState => {
//   const { type } = action;
//   switch (type) {
//     case AcceptInviteType.SENDING: {
//       return {
//         ...state,
//         loading: true
//       };
//     }
//     case AcceptInviteType.SUCCESS: {
//       return {
//         ...state,
//         sent: true,
//         loading: false
//       };
//     }
//     case AcceptInviteType.ERROR: {
//       return {
//         ...state,
//         sent: false,
//         loading: false,
//         // error: payload
//       };
//     }
//   }
// };

type ModalPendingOrganizationInviteProps = {
  onClose: () => void;
};

const ModalPendingOrganizationInvite = ({
                                          onClose
                                        }: ModalPendingOrganizationInviteProps) => {
  const { t } = useTranslation('common');
  const { theme } = React.useContext(GlobalContext);
  const queryClient = useQueryClient();
  const isDarkMode = theme === THEMES.DARK;

  const handleJoinOrganization = () => {
    const invitationToken = localStorage.getItem('organizationInvitationToken');
    try {
      const authenticationToken = getTokenOnLocalstorage();
      if (authenticationToken && invitationToken)
        acceptOrganizationInvite(invitationToken).then((data: any) => {
          localStorage.removeItem('organizationInvitationToken');
          queryClient.invalidateQueries(['organizations']);
          onClose();
        });
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <ModalWrapper
      onClose={onClose}
      title={t('modalPendingOrganizationInvite.title')}
    >
      <Container fluid>
        <Row>
          <Col className='d-flex flex-column align-items-center' xs={12}>
            <img src={isDarkMode ? logoDark : logo} alt='exein_logo' style={{ width: '200px' }} />
            <h3 className='h3 my-3' style={{ fontSize: '1.2rem' }}>
              {t('modalPendingOrganizationInvite.title')}
            </h3>
            <div className='my-3' style={{ fontSize: '1rem' }}>
              <p>{t('modalPendingOrganizationInvite.invite', { organization: 'an organization' })}</p>
              <p>{t('modalPendingOrganizationInvite.description')}</p>
            </div>
            <Button
              variant='primary'
              className={'btn-primary my-3'}
              onClick={handleJoinOrganization}
            >
              {t('modalPendingOrganizationInvite.joinButton')}
            </Button>
            <p>{t('modalPendingOrganizationInvite.thanks')}</p>
            <p>{t('modalPendingOrganizationInvite.thanksBy')}</p>
          </Col>
        </Row>
      </Container>
    </ModalWrapper>
  );
};

export default ModalPendingOrganizationInvite;
