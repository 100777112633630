import Chip, { CHIP_COLOR, CHIP_SIZE } from '@components/Chip';
import GradeBadge, { GRADE_SIZE } from '@components/GradeBadge';
import ContainerIcon from '@components/Icons/ContainerIcon';
import LinuxIcon from '@components/Icons/LinuxIcon';
import RtosIcon from '@components/Icons/RtosIcon';
import UefiIcon from '@components/Icons/UefiIcon';
import Spinner, { SPINNER_SIZE, SPINNER_TYPE } from '@components/Spinner';
import { GlobalContext } from '@context/GlobalContext';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import { THEMES } from '@hooks/useDarkMode';
import { ROUTES } from '@routes/routes';
import { getCssVariable } from '@utils/index';
import { formatPrice, getCurrency } from '@utils/normalizers';
import { REPORT_STATUS, REPORT_TYPE } from '@utils/types/report';
import { format, parse } from 'date-fns';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BsCreditCard2Back,
  FaCcMastercard,
  FaCcVisa,
  ImFilePdf,
  SiAmericanexpress,
} from 'react-icons/all';
import { Link } from 'react-router-dom';

const ImageFormatter = ({ value }) => {
  let Icon = null;
  if (value === REPORT_TYPE.LINUX) {
    Icon = <LinuxIcon size={20} color={getCssVariable('neutral300')} />;
  } else if (value === REPORT_TYPE.CONTAINER) {
    Icon = <ContainerIcon size={20} color={getCssVariable('neutral300')} />;
  } else if (value === REPORT_TYPE.UEFI) {
    Icon = <UefiIcon size={24} color={getCssVariable('neutral300')} />;
  } else if (value === REPORT_TYPE.VXWORKS) {
    Icon = <RtosIcon size={24} color={getCssVariable('neutral300')} />;
  } else {
    Icon = <LinuxIcon size={24} color={getCssVariable('neutral300')} />;
  }
  return (
    <div>
      {Icon} {value.toUpperCase()}
    </div>
  );
};

const GradeProvider = ({ value }) => {
  return <GradeBadge grade={value} size={GRADE_SIZE.EXTRASMALL} />;
};

export const ImageTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={ImageFormatter} {...props} />
);

export const InvoicePriceFormatterProvider = (props) => (
  <DataTypeProvider formatterComponent={InvoicePriceFormatter} {...props} />
);

const InvoicePriceFormatter = ({ value, row }) => {
  const price = formatPrice(value);
  const currency = getCurrency(row.currency);

  return (
    <span>
      <sup>{currency}</sup>
      {price}
    </span>
  );
};

const DataFormatter = ({ value }) => {
  if (value)
    return format(parse('', '', new Date(`${value * 1000}`)), 'dd MM yyyy');
  return '';
};

const DataFormatterAt = ({ value }) => {
  if (value) {
    return (
      format(parse('', '', new Date(value * 1000)), 'yyyy-MM-dd') +
      ' at ' +
      format(parse('', '', new Date(value * 1000)), 'HH:mm')
    );
  }

  return '';
};

const DataFormatterAtReport = ({ value, row }) => {
  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = theme === THEMES.DARK;

  const isPending =
    row?.status === REPORT_STATUS.PENDING ||
    row?.status === REPORT_STATUS.RUNNING;

  if (isPending) {
    return (
      <Spinner
        size={SPINNER_SIZE.SMALL}
        type={isDarkMode ? SPINNER_TYPE.GREEN : SPINNER_TYPE.BLACK}
      />
    );
  }

  if (value) {
    return format(parse('', '', new Date(`${value}`)), 'dd MM yyyy');
  }

  return '';
};

const DownloadPDF = ({ row }) => {
  return (
    <a href={row.invoice_pdf} target={'_blank'} rel="noreferrer">
      <ImFilePdf />
    </a>
  );
};

const PaymentMethod = ({ row }) => {
  let icon = null;
  if (!row.card?.brand) return '';

  switch (row.card?.brand.toLowerCase()) {
    case 'visa':
      icon = <FaCcVisa size={24} />;
      break;
    case 'mastercard':
      icon = <FaCcMastercard size={24} />;
      break;
    case 'american express':
      icon = <SiAmericanexpress size={24} />;
      break;
    default:
      icon = <BsCreditCard2Back size={24} />;
  }

  return (
    <span>
      {icon} {row.card.last4}
    </span>
  );
};

export const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={DataFormatter} {...props} />
);

export const DateTypeProviderReport = (props) => (
  <DataTypeProvider formatterComponent={DataFormatterAtReport} {...props} />
);

export const DownloadInvoiceProvider = (props) => (
  <DataTypeProvider formatterComponent={DownloadPDF} {...props} />
);

export const PaymentMethodProvider = (props) => (
  <DataTypeProvider formatterComponent={PaymentMethod} {...props} />
);

export const DateTypeAtProvider = (props) => (
  <DataTypeProvider formatterComponent={DataFormatterAt} {...props} />
);

export const GradeTypeProvider = (props) => {
  return <DataTypeProvider formatterComponent={GradeProvider} {...props} />;
};

export const ReportNameProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ row }) => {
      const isPending =
        row?.status === REPORT_STATUS.PENDING ||
        row?.status === REPORT_STATUS.RUNNING;

      return (
        <Link to={!isPending ? '/reports/' + row.id + ROUTES.OVERVIEW : ''}>
          {row.name}
        </Link>
      );
    }}
    {...props}
  />
);

export const AnalysisStatusProvider = (props) => {
  const { t } = useTranslation(['common']);

  return (
    <DataTypeProvider
      formatterComponent={({ row }) => {
        const isPending =
          row?.status === REPORT_STATUS.PENDING ||
          row?.status === REPORT_STATUS.RUNNING;

        if (isPending) {
          return (
            <div style={{ float: 'left' }}>
              <Chip size={CHIP_SIZE.SMALL} color={CHIP_COLOR.PRIMARY}>
                {t('common:reportStatus.analyzing') + '…'}
              </Chip>
            </div>
          );
        }

        return (
          <div style={{ float: 'left' }}>
            {row?.status === REPORT_STATUS.COMPLETED ? (
              <Chip size={CHIP_SIZE.SMALL} color={CHIP_COLOR.GREEN}>
                {t('common:reportStatus.complete')}
              </Chip>
            ) : (
              <Chip size={CHIP_SIZE.SMALL} color={CHIP_COLOR.CRIMSON}>
                {t('common:reportStatus.failed')}
              </Chip>
            )}
          </div>
        );
      }}
      {...props}
    />
  );
};

export const QuickReportProvider = (props) => (
  <DataTypeProvider
    formatterComponent={({ row }) => {
      return (
        <div className={'rating-container'} key={row.id}>
          <span className={'rating yellow'}>
            {row.quickOverview?.low ? row.quickOverview.low.length : '-'}
          </span>
          <span className={'rating orange'}>
            {row.quickOverview?.medium ? row.quickOverview.medium.length : '-'}
          </span>
          <span className={'rating red'}>
            {row.quickOverview?.high ? row.quickOverview.high.length : '-'}
          </span>
        </div>
      );
    }}
    {...props}
  />
);
