import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import categories from '@locales/en/categories.json';
import common from '@locales/en/common.json';
import pages from '@locales/en/pages.json';
import forms from '@locales/en/forms.json';
import illustration from '@locales/en/illustration.json';
import passwordReset from '@locales/en/passwordReset.json';
import newPassword from '@locales/en/newPassword.json';
import emptyReports from '@locales/en/emptyReports.json';
import scanModal from '@locales/en/scanModal.json';
import organizations from '@locales/en/organizations.json';
import userDropdown from '@locales/en/userDropdown.json';
import tables from '@locales/en/tables.json';
import userSettings from '@locales/en/userSettings.json';

// import overviewPage from '@locales/en/overviewPage.json';
declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'en';
    resources: {
      categories: typeof categories;
      common: typeof common;
      forms: typeof forms;
      illustration: typeof illustration;
      passwordReset: typeof passwordReset;
      newPassword: typeof newPassword;
      emptyReports: typeof emptyReports;
      scanModal: typeof scanModal;
      organizations: typeof organizations;
      userDropdown: typeof userDropdown;
      tables: typeof tables;
      pages: typeof pages;
      userSettings: typeof userSettings;
    };
  }
}

export const resources = {
  en: {
    categories,
    common,
    forms,
    illustration,
    passwordReset,
    newPassword,
    emptyReports,
    scanModal,
    organizations,
    userDropdown,
    tables,
    pages,
    userSettings,
  },
} as const;

export const defaultNS = 'en';
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    defaultNS,
  });

export default i18n;
