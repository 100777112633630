import Table from '@components/TableNew/Table';
import usePasswordHashAnalysis from '@hooks/analysis/usePasswordHashAnalysis';
import { getCssVariable, padNumber } from '@utils/index';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useFilters, usePagination, useTable } from 'react-table';
import passHashStyle from '../../../pages/Report/Linux/PasswordHash/styles.module.scss';
import pdfGlobalStyles from '../componentToPrint.module.scss';
import styles from './styles.module.scss';

const PasswordHash = () => {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data } = usePasswordHashAnalysis(reportId);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Username',
        accessor: 'username',
        Cell: ({ row, value }: { row: any; value: boolean }) => {
          return (
            <div className="d-flex flex-row align-items-center">
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400'),
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Password',
        accessor: 'password',
      },
      {
        Header: '',
        accessor: 'severity',
        Cell: (props: any) => {
          return (
            <div className={'d-flex align-items-center justify-content-end'}>
              <div
                className={`${passHashStyle['severity-wrap-table-accessor']} ${
                  passHashStyle[props.value]
                }`}
              >
                <span>{`${props.value} severity`}</span>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const memoizedData = React.useMemo(() => {
    if (!data || data.length === 0) return [];
    return data;
  }, [data]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
    },
    useFilters,
    usePagination
  );

  return (
    <div className={styles['container']}>
      <div className={pdfGlobalStyles['title']}>Password Hash</div>

      {data?.length ? (
        <>
          <div className={pdfGlobalStyles['paragraph']}>
            The Weak Password analysis looks for hard-coded weak passwords in
            your firmware.
          </div>
          <div className={styles['content']}>
            <div className={styles['week-password-count']}>
              {`+ ${data?.length}`}
            </div>
            <div className={styles['table-container']}>
              <Table tableInstance={tableInstance} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={pdfGlobalStyles['paragraph']}>
            The Weak Password analysis looks for hard-coded weak passwords in
            your firmware.
          </div>
          <div className={pdfGlobalStyles['paragraph-transparent']}>
            No weak passwords found. <br />
            Everything looks good here! There are no vulnerable password in your
            device.
          </div>
        </>
      )}
    </div>
  );
};
export default PasswordHash;
