import * as React from 'react';
import { GlobalContext } from '@context/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';

const usePlaceholders = () => {
  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = theme === THEMES.DARK;

  const getUserPlaceholder = (): string => isDarkMode ? '/placeholder/user_darkmode.svg' : '/placeholder/user.svg';
  const getOrganizationPlaceholder = (): string => isDarkMode ? '/placeholder/dark-company.svg' : '/placeholder/company.svg';

  return {
    getUserPlaceholder,
    getOrganizationPlaceholder
  };
};

export default usePlaceholders;