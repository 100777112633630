import LayoutSection from '@components/LayoutSection';
import { GlobalContext } from '@contexts/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import InfoBox from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBox';
import InfoBoxContent from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxMessage';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import CapabilitiesAnalysisGraph from '@pages/Report/VxWorks/Overview/Graphs/CapabilitiesAnalysisGraph';
import CapabilitiesGraph from '@pages/Report/VxWorks/Overview/Graphs/CapabilitiesGraph';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiShieldFlashLine } from 'react-icons/ri';
import styles from '../../styles.module.scss';

type CapabilitiesContainerProps = {
  data: any;
  bannerText?: string;
};

const CapabilitiesContainer = ({
  data,
  bannerText = '',
}: CapabilitiesContainerProps) => {
  const { t } = useTranslation(['common', 'pages']);
  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  const breakPoints = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6,
  };


  const getData = () => {
    const res = data.reduce((acc: any, b: any) => {
      const groupKey = b.caps;
      if (groupKey && groupKey.length > 0) {
        for (let i = 0; i < groupKey.length; i++) {
          if (!acc[`${groupKey[i]}`]) {
            acc[`${groupKey[i]}`] = 0;
          }
          acc[`${groupKey[i]}`] = acc[`${groupKey[i]}`] + 1;
        }
      }
      return acc;
    }, []);

    const final = [];
    if (res) {
      for (const key in res) {
        final.push({
          'id': key,
          'label': key,
          'value': res[key]
        });
      }
    }
    return final;
  };

  const graphData = getData();

  console.log({ graphData });

  return (
    <LayoutSection spacer={32}>
      <SectionHeader
        title={t('pages:overviewPage.sections.capabilities.title')}
        leadingIcon={RiShieldFlashLine}
        tooltipMessage={t(
          'pages:overviewPage.sections.capabilities.tooltipMessage'
        )}
        bannerText={bannerText}
      />
      <InfoBox style={{
        padding: 20,
        height: 'auto'
      }}>
        <InfoBoxContent style={{
          background: isDarkMode ? '#202226' : 'none', flexDirection: 'row', gap: 80
        }}>
          <div>
            <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <InfoBoxMessage
                message={'Total Capabilities'}
                style={{ width: 'max-content', textTransform: 'uppercase', fontSize: '9px' }}
              />
            </div>
            <div className={styles['info-box-count']}>{data?.length || 0}</div>
          </div>
          <div style={{ width: '60%', display: 'flex', alignSelf: 'flex-start', gap: 50, flexWrap: 'wrap', }}>
            {graphData?.map(item => <div style={{ flexBasis: '20%', display: 'flex', alignItems: 'center' }}>
              <span style={{ marginRight: 20, color: isDarkMode ? '#858585' : 'black' }}>{item.label}</span>
              <span style={{ color: isDarkMode ? '#D0D7DE' : 'black', fontSize: 16, fontWeight: 500 }}>{item.value}</span>
            </div>)}
          </div>
        </InfoBoxContent>-
      </InfoBox>
      {/* <SectionContent
        breakPointsLeft={breakPoints}
        breakPointsRight={breakPoints}
      >
        <SectionContentLeft verticalMessage={t('pages:overviewPage.sections.capabilities.title')}>
          <CapabilitiesAnalysisGraph data={data} keyToSearch='caps' />
        </SectionContentLeft>
        <SectionContentLeft
          verticalMessage="Capabilities"
          style={{ flexDirection: 'row', padding: '1rem 0' }}
        >
          <CapabilitiesGraph data={data} />
        </SectionContentLeft>
      </SectionContent> */}
    </LayoutSection >
  );
};

export default CapabilitiesContainer;
