import ContextMenu from '@components/ContextMenu';
import UserAppearance, { SIZE } from '@components/UserAppearance';
import UserModal from '@components/UserModal';
import { UserContext } from '@contexts/UserContext';
import usePlaceholders from '@hooks/usePlaceholders';
import useUser from '@hooks/useUser';
import { ROUTES } from '@routes/routes';
import React, { useEffect, useState } from 'react';
import { Item, Separator, useContextMenu } from 'react-contexify';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styles from './styles.module.scss';
type Props = {
  logout: () => void;
};

export default function UserDropdown({ logout }: Props) {
  const { t } = useTranslation('userDropdown');
  const { data: user } = useUser();
  const id = 'userDropdown';
  const { show } = useContextMenu({ id });
  const { getUserPlaceholder } = usePlaceholders();
  const [userData, setUserData] = useState<any | null>(null);
  const { url, setURL, setFile } = React.useContext<any>(UserContext);
  const displayName = user?.family_name;
  const email = user?.email;
  const [isUserSettingsOpen, setIsUserSettingsOpen] = useState<boolean>(false);
  const history = useHistory();
  function openUserSettings() {
    history.push(`${ROUTES.BASE}${ROUTES.USER_SETTINGS}`);
    //setIsUserSettingsOpen(true);
  }

  function closeModal() {
    setIsUserSettingsOpen(false);
  }

  // useEffect(() => {
  //   const ref = firebase.storage().ref(`users/${user?.uid}/profile.jpg`);
  //   ref.getDownloadURL().then((url) => {
  //     setFile(null);
  //     setURL(url);
  //   });

  //   db.collection('users')
  //     .doc(user?.uid)
  //     .get()
  //     .then((doc) => {
  //       if (doc.exists) {
  //         setUserData(doc.data());

  //         storage
  //           .ref(`users/${user?.uid}/profile.jpg`)
  //           .getDownloadURL()
  //           .then((url) => {
  //             setURL(url);
  //           })
  //           .catch((err) => {
  //             console.log('no bucket');
  //           });
  //       } else {
  //         console.error('db', 'User not found');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('db', error.message);
  //     });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [url, displayName, email, user]);

  const triggerRef = React.useRef<any>();

  function getMenuPosition() {
    const { left, bottom } = triggerRef.current.getBoundingClientRect();
    return { x: left - 210, y: bottom + 30 };
  }

  function handleContextMenu(event: any) {
    event.preventDefault();
    show(event, {
      position: { ...getMenuPosition() },
    });
  }

  // const roboAvatar = `https://robohash.org/${
  //   get(user, 'displayName[0]', '') + get(user, 'displayName[1]', '')
  // }`;

  const profileStyle = {
    backgroundImage: `url(${url || getUserPlaceholder()})`,
  };

  function handleItemClick({ event }: any) {
    // this will prevent the Menu from being closed
    event.stopPropagation();
  }

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.avatar}
          onClick={handleContextMenu}
          ref={triggerRef}
          style={profileStyle}
        ></div>

        <div
          className={styles.userMenuContainer}
          style={{ position: 'absolute', top: -9999 }}
        >
          <ContextMenu id={id}>
            <Item onClick={console.log} className={styles.usermail}>
              <div className={styles.avatar} style={profileStyle}></div>
              <div className={styles.details}>
                <p>{displayName}</p>
                <p>{email}</p>
              </div>
            </Item>
            <Separator />
            <Item className={styles.theme} onClick={handleItemClick}>
              <p>{t('theme')}</p>
              <UserAppearance size={SIZE.SMALL} />
            </Item>
            <Separator />
            <Item onClick={openUserSettings} className={styles.settings}>
              {t('accountSettings')}
            </Item>
            <Item onClick={logout} className={styles.logout}>
              {t('logout')}
            </Item>
          </ContextMenu>
        </div>
      </div>
      {isUserSettingsOpen && (
        <UserModal
          name={userData?.firstname}
          email={userData?.email}
          closeModal={closeModal}
          signOut={logout}
        />
      )}
    </>
  );
}
