import GenericError from '@components/ErrorState';
import LayoutPage from '@components/LayoutPage';
import LayoutSection from '@components/LayoutSection';
import ModalReportLinuxDetails from '@components/ModalReportLinuxDetails';
import ReportHeading from '@components/ReportHeading';
import SkeletonOverview from '@components/SkeletonOverview';
import useReportOverview from '@hooks/useReportOverview';
import { getCssVariable, getScanTime } from '@utils/index';
import { format } from 'date-fns';
import { noop } from 'lodash';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

//Containers
import OverviewTab from '@components/OverviewTab';
import { FunctionalComponentWithFunctionalComponentToPrint } from '@pages/Pdf/FunctionalComponentWithFunctionalComponentToPrint';
import BinaryAnalysisContainer from '@pages/Report/Linux/ReportOverviewLinux/Containers/BinaryAnalysisContainer';
import CodeAnalysisContainer from '@pages/Report/Linux/ReportOverviewLinux/Containers/CodeAnalysisContainer';
import { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { BsExclamationOctagonFill, FiAlertOctagon } from 'react-icons/all';
import {
  RiDoorLockBoxFill,
  RiDoorLockBoxLine,
  RiFileCodeFill,
  RiFileCodeLine,
  RiHomeFill,
  RiHomeLine,
  RiShieldCheckFill,
  RiShieldCheckLine,
  RiShieldKeyholeFill,
  RiShieldKeyholeLine,
  RiTerminalBoxFill,
  RiTerminalBoxLine,
} from 'react-icons/ri';
import BinaryAnalysisTableContainer from './Containers/BinaryAnalysisTableContainer';
import BoxesContainer from './Containers/BoxesContainer';
import CveCheckContainer from './Containers/CveCheckContainer';
import OverviewContainer from './Containers/OverviewContainer';
// import useKernelSecurityAnalysis from '@hooks/analysis/useKernelSecurityAnalysis';

export default function ReportOverviewLinux() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isError, error } = useReportOverview(reportId);

  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const MetaTag = React.useCallback(() => {
    return (
      <Helmet>
        <title>Report: {data?.name ?? ''}</title>
      </Helmet>
    );
  }, [data]);

  const { t } = useTranslation(['common', 'pages']);
  const [activeTab, setActiveTab] = useState('overview');

  function goTo(tab: string, section: string) {
    setActiveTab(tab);
    const element = document?.getElementById(section);
    element?.scrollIntoView({ behavior: 'smooth' });
  }

  const scanTime = React.useMemo(
    () => (data ? getScanTime(data) : null),
    [data]
  );

  return (
    <LayoutPage>
      <FunctionalComponentWithFunctionalComponentToPrint />
      {isLoading ? (
        <SkeletonOverview />
      ) : isError ? (
        <GenericError error={error} />
      ) : (
        <>
          <MetaTag />
          <div className="pageBreaker">
            <LayoutSection spacer={32}>
              <ReportHeading
                chipLabel={t('common:generic.completed')}
                buttonLabel={t('common:generic.downloadReport')}
                onButtonClick={noop}
                descriptionButtonLabel={t('common:generic.viewDetails')}
                onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
              />
            </LayoutSection>
            <LayoutSection spacer={24}>
              <Nav
                className={'nav-cosmo nav-details'}
                activeKey={activeTab}
                variant="tabs"
              >
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={RiHomeLine}
                    activeIcon={RiHomeFill}
                    isActive={activeTab === 'overview'}
                    label={t('common:navigation.navigationLabels.overview')}
                    keyItem={'overview'}
                    onClick={() => goTo('overview', 'overview')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={FiAlertOctagon}
                    activeIcon={BsExclamationOctagonFill}
                    isActive={activeTab === 'cveCheck'}
                    label={t('common:navigation.navigationLabels.cveCheck')}
                    keyItem={'cveCheck'}
                    onClick={() => goTo('cveCheck', 'cveCheck')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={RiFileCodeLine}
                    activeIcon={RiFileCodeFill}
                    isActive={activeTab === 'codeAnalysis'}
                    label={t('common:navigation.navigationLabels.codeAnalysis')}
                    keyItem={'codeAnalysis'}
                    onClick={() => goTo('codeAnalysis', 'codeAnalysis')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={RiDoorLockBoxLine}
                    activeIcon={RiDoorLockBoxFill}
                    isActive={activeTab === 'passwordHash'}
                    label={t('common:navigation.navigationLabels.passwordHash')}
                    keyItem={'passwordHash'}
                    onClick={() => goTo('passwordHash', 'passwordHash')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={RiShieldCheckLine}
                    activeIcon={RiShieldCheckFill}
                    isActive={activeTab === 'securityScan'}
                    label={t('common:navigation.navigationLabels.securityScan')}
                    keyItem={'securityScan'}
                    onClick={() => goTo('securityScan', 'passwordHash')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={RiShieldKeyholeLine}
                    activeIcon={RiShieldKeyholeFill}
                    isActive={activeTab === 'kernelSecurity'}
                    label={t(
                      'common:navigation.navigationLabels.kernelSecurity'
                    )}
                    keyItem={'kernelSecurity'}
                    onClick={() => goTo('kernelSecurity', 'passwordHash')}
                  />
                </Nav.Item>
                <Nav.Item>
                  <OverviewTab
                    defaultIcon={RiTerminalBoxLine}
                    activeIcon={RiTerminalBoxFill}
                    isActive={activeTab === 'binaryAnalysis'}
                    label={t(
                      'common:navigation.navigationLabels.binaryAnalysis'
                    )}
                    keyItem={'binaryAnalysis'}
                    onClick={() => goTo('binaryAnalysis', 'binaryAnalysis')}
                  />
                </Nav.Item>
              </Nav>
            </LayoutSection>
            <OverviewContainer data={data} />
            <div id={'cveCheck'}>
              <CveCheckContainer data={data?.analysis?.cveCheck} />
            </div>
            <div id={'codeAnalysis'}>
              <CodeAnalysisContainer data={data?.analysis?.code} />
            </div>
            <div id={'passwordHash'}>
              <BoxesContainer
                passwordHashData={data?.analysis?.passwordHash || 0}
                securityScanData={data?.analysis?.securityScan || 0}
                reportType={data?.type}
              />
            </div>
            <div id={'binaryAnalysis'}>
              {/* <BinaryAnalysisContainer data={data?.analysis?.binary} /> */}
              <BinaryAnalysisTableContainer data={data?.analysis?.binary} />
            </div>
          </div>

          <ReactTooltip
            border={true}
            arrowColor={getCssVariable('primary')}
            borderColor={getCssVariable('neutral300')}
            textColor={getCssVariable('secondary')}
            backgroundColor={getCssVariable('primary')}
            effect="solid"
            className={'tooltip'}
            multiline={true}
          />
        </>
      )}
      {data && shouldShowModalDetail ? (
        <ModalReportLinuxDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...data.reportDetails,
            submitted: format(
              new Date(`${data.startDate ?? new Date()}`),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime: scanTime
              ? scanTime.value +
              ' ' +
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              t(`common:${scanTime.type as string}`)
              : '',
            hash: data.hash || '',
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
