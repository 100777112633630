import GraphLegend from '@components/GraphLegend';
import { GlobalContext } from '@contexts/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import BinaryAnalysisCapabilitiesGraph from '@pages/Report/Linux/ReportOverviewLinux/Graphs/BinaryAnalysisCapabilitiesGraph';
import BinaryAnalysisSeverityGraph from '@pages/Report/Linux/ReportOverviewLinux/Graphs/BinaryAnalysisSeverityGraph';
import { getCssVariable } from '@utils/index';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RiTerminalBoxLine } from 'react-icons/ri';
import SectionContentRight from '../Components/SectionContentRight';
import SectionHeader from '../Components/SectionHeader';
import BarGraph from '../Graphs/BarGraph';
import styles from './styles.module.scss';

type BinaryAnalysisContainerProps = {
  data: any;
  bannerText?: string;
};

const BinaryAnalysisContainer = ({
  data,
  bannerText = '',
}: BinaryAnalysisContainerProps) => {
  const { t } = useTranslation(['common', 'pages']);

  const { theme } = useContext(GlobalContext);
  const isDarkMode = useMemo(() => theme === THEMES.DARK, [theme]);

  const breakPoints = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6,
    xl: 6,
  };

  const affectedFilesCount = useMemo(() => data?.high.severity + data?.medium.severity + data?.low.severity, [data]);


  const typeCounts = useMemo(() => [
    // {
    //   'key': 'critical',
    //   'count': data?.critical,
    // }, 
    {
      'key': 'high',
      'count': data?.high.severity,
    }, {
      'key': 'medium',
      'count': data?.medium.severity,
    }, {
      'key': 'low',
      'count': data?.low.severity,
    }], [data]);

  const legendData: any = useMemo(() => {
    const transformedObject: any = {};

    // Iterate through the array and assign key-value pairs to the transformed object
    typeCounts.forEach(item => {
      transformedObject[item.key] = item.count;
    });

    return transformedObject;

  }, [typeCounts]);

  return (
    <SectionLayout>
      <SectionHeader
        title={t('pages:overviewPage.sections.binaryAnalysis.title')}
        leadingIcon={RiTerminalBoxLine}
        tooltipMessage={t(
          'pages:overviewPage.sections.binaryAnalysis.tooltipMessage'
        )}
        bannerText={bannerText}
      />
      <SectionContent style={{
        padding: 15
      }}>
        <SectionContentLeft verticalMessage={'CVE Check vulnerabilities'} style={{
          background: isDarkMode ? '#1f2226' : 'none'
        }}>
          <SectionContentLeft style={{ borderRight: 'none' }} hasGraph={false}>
            <div className={styles['left-title']}>
              <div>Cve check vulnerabilities</div>
            </div>
            <div className={styles.count}>{affectedFilesCount || 0}</div>
            <GraphLegend items={legendData} colors={['#F86767', '#D0D7DE', '#59CCD9']}
              direction='row' customClassName={styles.graphLegend} />
          </SectionContentLeft>
        </SectionContentLeft>
        <SectionContentRight>
          <BarGraph data={typeCounts} colors={['#F86767', '#D0D7DE', '#59CCD9']} margins={{
            top: 50, right: 20, bottom: 40, left: 20
          }} />
        </SectionContentRight>
      </SectionContent >
    </SectionLayout>
  );
};

export default BinaryAnalysisContainer;
