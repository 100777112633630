import { useMutation } from 'react-query';
import { createSupportRequest } from '@services/api';
import { ContactFormData } from '@components/LayoutOnBoarding/ContactForm';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const KEY = 'help';

export default function useCreateSupportRequest() {
  const {t} = useTranslation('pages');

  return useMutation(
    [KEY],
    async (body: ContactFormData) => await createSupportRequest(body), {
      onSuccess: () => {
        toast.success(t('authPage.help.success'));
      },
      onError: () => {
        toast.error(t('authPage.help.error'));
      }
    }
  );
}