import React, { CSSProperties, ReactNode } from 'react';
import { TableProps } from 'react-bootstrap';

type TableFooterProps = {
  children?: ReactNode;
  parentProps?: TableProps;
  className?: string;
  style?: CSSProperties;
};

const TableFirstRow = (props: TableFooterProps) => {
  const { children, className = '', style = {}} = props;
  return <tr className={className} style={style}>{children}</tr>;
};

export default TableFirstRow;
