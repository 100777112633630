import Table from '@components/TableNew/Table';
import useReportBinaryAnalysis from '@hooks/analysis/useBinaryAnalysis';
import CellTableIcon from '@pages/Report/Linux/Binary/Components/CellTableIcon';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import { getCssVariable, padNumber } from '@utils/index';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useFilters, usePagination, useTable } from 'react-table';
import styles from './styles.module.scss';

const breakPointsLeft = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12,
};

const breakPointsRight = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
};

const BinaryAnalysisTable: React.FC = () => {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data } = useReportBinaryAnalysis(reportId);

  const memoizedData = React.useMemo(() => {
    if (!data) return [];
    return data.content;
  }, [data]);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'filename',
        width: 300,
        Cell: ({ row, value }: { row: any; value: string }) => {
          return (
            <div className="d-flex flex-row align-items-center">
              <span
                style={{
                  marginRight: '.5rem',
                  color: getCssVariable('neutral400'),
                }}
              >
                {padNumber(row.index + 1)}
              </span>
              {value.split('/').slice(1).join('/')}
            </div>
          );
        },
      },
      {
        Header: 'Canary',
        accessor: 'canary',
        width: 50,
        Cell: ({ value }: any) => <CellTableIcon value={value} />,
      },
      {
        Header: 'Nx',
        accessor: 'nx',
        width: 50,
        Cell: ({ value }: any) => <CellTableIcon value={value} />,
      },
      {
        Header: 'Pie',
        accessor: 'pie',
        width: 50,
      },
      {
        Header: 'Relro',
        accessor: 'relro',
        width: 50,
      },
      {
        Header: 'Fortify',
        accessor: 'fortify',
        width: 50,
        Cell: ({ value }: any) => <CellTableIcon value={value} />,
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      data: memoizedData as any,
      columns,
      initialState: { pageSize: 999 },
    },
    useFilters,
    usePagination
  );

  return (
    <div className={styles['container']}>
      <SectionLayout className="px-0">
        <SectionContent
          breakPointsLeft={breakPointsLeft}
          breakPointsRight={breakPointsRight}
          style={{ borderColor: '#d0d7de', marginLeft: 0, marginRight: 0 }}
        >
          <SectionContentLeft
            verticalMessage={'Vulnerabilities'}
            verticalMessageColor="#05516f"
            style={{ borderRight: 'none' }}
          >
            <Table className="border-0 px-5" tableInstance={tableInstance} />
          </SectionContentLeft>
        </SectionContent>
      </SectionLayout>
    </div>
  );
};

export default BinaryAnalysisTable;
