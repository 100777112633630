type Props = {
  color: string;
  size: number;
};
export default function Uefi({ color, size }: Props) {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox={`0 0 ${size} ${size}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Icons"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Icons/uefi"
          transform="translate(-2.000000, -1.000000)"
          fill={color}
        >
          <path
            d="M3,1 L3,3 L5,3 L5,1 L3,1 Z M7,1 L7,3 L9,3 L9,1 L7,1 Z M11,1 L11,3 L13,3 L13,1 L11,1 Z M15,1 L15,3 L17,3 L17,1 L15,1 Z M19,1 L19,3 L21,3 L21,1 L19,1 Z M4,5 C2.9069372,5 2,5.9069372 2,7 L2,17 C2,18.093063 2.9069372,19 4,19 L20,19 C21.093063,19 22,18.093063 22,17 L22,7 C22,5.9069372 21.093063,5 20,5 L4,5 Z M4,7 L20,7 L20,17 L4,17 L4,7 Z M7,13 C6.44771525,13 6,13.4477153 6,14 C6,14.5522847 6.44771525,15 7,15 C7.55228475,15 8,14.5522847 8,14 C8,13.4477153 7.55228475,13 7,13 Z M3,21 L3,23 L5,23 L5,21 L3,21 Z M7,21 L7,23 L9,23 L9,21 L7,21 Z M11,21 L11,23 L13,23 L13,21 L11,21 Z M15,21 L15,23 L17,23 L17,21 L15,21 Z M19,21 L19,23 L21,23 L21,21 L19,21 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
}
