// Particle
class Particle {
  constructor(context, color = '#fff', x, y, d = 2, movement = 50, velocityDecay = 0.2, speed = 0.02) {
    this.context = context;

    this.x = this.currentX = this.targetX = x;
    this.y = this.currentY = this.targetY = y;
    this.velocityX = this.velocityY = 0;
    this.d = d;
    this.speed = speed;
    this.velocityDecay = velocityDecay;
    this.color = color;
    this.movement = movement;
  }

  move() {
    var distanceX = this.targetX - this.currentX,
      distanceY = this.targetY - this.currentY;

    this.velocityX *= this.velocityDecay;
    this.velocityY *= this.velocityDecay;

    this.velocityX += distanceX * this.speed;
    this.velocityY += distanceY * this.speed;

    this.currentX += this.velocityX;
    this.currentY += this.velocityY;
  }

  draw() {
    this.move();

    var r = this.d / 2,
      context = this.context;

    context.fillStyle = this.color;
    context.beginPath();
    context.arc(this.currentX, this.currentY, r, 0, Math.PI * 2, false);
    context.closePath();
    context.fill();
  }

  setTarget(x, y) {
    var r = this.d / 2,
      originX = this.x - r,
      originY = this.y - r;

    if (x !== undefined && y !== undefined) {
      // Yeah, science! http://math.stackexchange.com/questions/175896/finding-a-point-along-a-line-a-certain-distance-away-from-another-point
      let movement = this.movement,
        vectorX = x - originX,
        vectorY = y - originY,
        vector = Math.sqrt(Math.pow(vectorX, 2) + Math.pow(vectorY, 2)),
        distanceRatio = movement / vector;

      //if (distanceRatio < 1) {
      this.targetX = (1 - distanceRatio) * originX + distanceRatio * x;
      this.targetY = (1 - distanceRatio) * originY + distanceRatio * y;
      //} else {
      //	this.targetX = x;
      //	this.targetY = y;
      //}
    } else {
      this.targetX = originX;
      this.targetY = originY;
    }
  }
}


// Canvas
class Canvas {
  constructor(element, color = '#000', particleSpacing = 26, fps = 1000/100) {
    this.canvas = element;
    this.color = color;
    this.context = element.getContext('2d');

    this.particleSpacing = particleSpacing;
    this.fps = fps;

    window.addEventListener('resize', () => this.init());
    this.init();

    window.addEventListener('mousemove', event => {
      this.particles.forEach(particle => particle.setTarget(event.clientX, event.clientY));
    });
    window.addEventListener('mouseleave', () => {
      this.particles.forEach(particle => particle.setTarget());
    });
  }

  init () {
    this.stop();
    this.clear();

    this.resize();

    this.createParticles();
    this.animate();
  }

  resize() {
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
  }

  clear() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  createParticles() {
    var cols = Math.floor(this.canvas.width / this.particleSpacing) + 20,
      rows = Math.floor(this.canvas.height / this.particleSpacing) +20,
      colGutter = (this.particleSpacing + (this.canvas.width - cols * this.particleSpacing)) / 2,
      rowGutter = (this.particleSpacing + (this.canvas.height - rows * this.particleSpacing)) / 2;

    this.particles = [];
    for (let col = 0; col < cols; col++) {
      for (let row = 0; row < rows; row++) {
        let x = col * this.particleSpacing + colGutter,
          y = row * this.particleSpacing + rowGutter,
          particle = new Particle(this.context, this.color, x, y);
        this.particles.push(particle);
      }
    }
  }

  draw() {
    this.clear();
    if (this.particles) {
      for (let i = 0; i < this.particles.length; i++) {
        this.particles[i].draw();
      }
    }
  }

  animate() {
    var now = Date.now();
    if (this.lastFrameDate === undefined || (now - this.lastFrameDate > this.fps)) {
      this.lastFrameDate = now;

      this.draw();
    }

    this.animationFrame = window.requestAnimationFrame(() => this.animate());
  }

  stop() {
    window.cancelAnimationFrame(this.animationFrame);
  }
}

export default Canvas;