import { getGraphTheme, getGraphThemeProps } from '@components/Graph/theme';
import { GlobalContext } from '@context/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import { ResponsiveBar } from '@nivo/bar';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

type OverviewGraphProps = {
  data: any;
};

const getUniqueLabels = (data: any): string[] => {
  const allLabels: any[] = [];
  Object.keys(data).forEach((s: string, i: number) => {
    allLabels.push(data[s]);
  });
  if (allLabels.length > 0) {
    const flatted = _.flatten(allLabels);
    const set = new Set(flatted);
    return Array.from(set);
  }
  return [];
};

const buildData = (labels: string[], data: any) => {
  const final = [];
  if (Object.keys(data).length > 0) {
    const labelData: any = {};
    for (let i = 0; i < labels.length; i++) {
      labelData.LOW = data.low.indexOf(labels[i]) > -1 ? 33.3333 : 0;
      labelData.MEDIUM = data.medium.indexOf(labels[i]) > -1 ? 66.6666 : 0;
      labelData.HIGH = data.high.indexOf(labels[i]) > -1 ? 100 : 0;
      // labelData.CRITICAL = data.critical.indexOf(labels[i]) > -1 ? 100 : 0;

      final.push({
        item: _.startCase(labels[i]),
        ...labelData,
      });
    }

    return final;
  }
  return [];
};

const OverviewGraph = ({ data }: OverviewGraphProps) => {
  const { t } = useTranslation('common');

  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = theme === THEMES.DARK;

  if (!data || Object.keys(data).length === 0) return <p>{t('noData')}</p>;
  const keys: { [key: string]: string } = {
    LOW: '#59CCD9',
    MEDIUM: '#D0D7DE',
    HIGH: '#F86767',
    // CRITICAL: '#6f43c1',
  };

  const labels = getUniqueLabels(data);
  const finalData = buildData(labels, data);
  const graphTheme = getGraphTheme(theme);
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ResponsiveBar
        isInteractive={true}
        tooltip={(props) => {
          const graphLabelProps = getGraphThemeProps(theme, props.color);
          if (props.id === 'FAKEDATA') return <div />;

          return (
            <React.Fragment>
              <div style={graphLabelProps}>
                <div
                  style={{
                    height: 20,
                    width: 20,
                    border: '1px solid transparent',
                    backgroundColor: props.color,
                    float: 'left',
                    marginRight: 10,
                  }}
                ></div>
                <span>{props.id} THREAT LEVEL</span>
              </div>
            </React.Fragment>
          );
        }}
        // theme={graphTheme}
        theme={
          {
            textColor: isDarkMode ? 'white' : 'black',
            grid: { line: { stroke: '#70707034' } },
            background: isDarkMode ? '#202226' : 'white'
          }
        }
        data={finalData}
        keys={Object.keys(keys)}
        indexBy="item"
        margin={{ top: 100, right: 0, bottom: 30, left: 0 }}
        padding={0.985}
        valueScale={{ type: 'linear' }}
        minValue={0}
        maxValue={100}
        colors={({ id, data }) => keys[`${id}`]}
        enableLabel={false}
        axisTop={null}
        axisRight={null}
        animate={false}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={null}
        labelSkipWidth={12}
        labelSkipHeight={12}
        // legends={[
        //   {
        //     dataFrom: 'keys',
        //     anchor: 'bottom-right',
        //     direction: 'column',
        //     justify: false,
        //     translateX: 120,
        //     translateY: 0,
        //     itemsSpacing: 2,
        //     itemWidth: 100,
        //     itemHeight: 20,
        //     itemDirection: 'left-to-right',
        //     itemOpacity: 0.85,
        //     symbolSize: 20,
        //     effects: [
        //       {
        //         on: 'hover',
        //         style: {
        //           itemOpacity: 1,
        //         },
        //       },
        //     ],
        //   },
        // ]}
        role="application"
        ariaLabel="Linux Overview"
        barAriaLabel={function (e) {
          return (
            e.id + ': ' + e.formattedValue + ' in section: ' + e.indexValue
          );
        }}
      />
    </div>
  );
};

export default OverviewGraph;
