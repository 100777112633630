import { GRADE_TYPE } from '@components/GradeBadge';
import { OverviewGrade } from '@pages/Report/Linux/ReportOverviewLinux/Components/OverviewGrade';
import SectionContent from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContent';
import SectionContentFooter from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentFooter';
import SectionContentLeft from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionContentLeft';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import SectionLayout from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionLayout';
import OverviewGraph from '@pages/Report/Linux/ReportOverviewLinux/Graphs/OverviewGraph';
import { getQuickReport } from '@services/api';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiHomeFill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import containerStyles from '../styles.module.scss';

type OverviewContainerProps = {
  data: any
}

const breakPointsLeft = {
  xs: 12,
  sm: 12,
  md: 12,
  lg: 12,
  xl: 12
};

const breakPointsRight = {
  xs: 0,
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0
};

const OverviewContainer = ({ data }: OverviewContainerProps) => {
  const { reportId = '' }: { reportId: string } = useParams();
  const [chartData, setChartData] = React.useState({});

  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const overviewTitleAsString = t(`pages:overviewPage.sections.reportOverview.gradesStatus.${data?.grade.toString()}.title`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const overviewDescriptionAsString = t(`pages:overviewPage.sections.reportOverview.gradesStatus.${data?.grade.toString()}.description`);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const overviewCtaAsString = t('pages:overviewPage.sections.reportOverview.modalCtaLabel');

  // const getSeveritiesCount = (item: any) => {
  //   const result = {
  //     high: 0,
  //     highColor: getCssVariable('bs-red'),
  //     medium: 0,
  //     mediumColor: getCssVariable('bs-orange'),
  //     low: 0,
  //     lowColor: getCssVariable('bs-yellow')
  //   };
  //   if (item.high.severity > -1) result.high = item.high.severity || 0;
  //   if (item.medium.severity > -1) result.medium = item.medium.severity || 0;
  //   if (item.low.severity > -1) result.low = item.high.low || 0;
  //   return result;
  // };
  //
  // const getGraphData = React.useCallback(() => {
  //   if (data?.analysis && Object.keys(data.analysis).length > 0) {
  //     const items = { ...data.analysis } as any;
  //     return Object.keys(items).filter((item) => {
  //       if (items[item]?.high || items[item]?.medium || items[item]?.low) return items[item];
  //     }).map((d: string) => {
  //       return {
  //         'item': d,
  //         ...getSeveritiesCount(items[d])
  //       };
  //     });
  //   }
  //   return [];
  // }, [data]);

  const getQuick = async () => {
    if (reportId) {
      const data = await getQuickReport(reportId);
      if (data && data['quick-overview']) {
        setChartData(data['quick-overview']);
      }
    }
  };

  React.useEffect(() => {
    getQuick();
  }, [reportId]);

  return (<Row className={'flex-column-reverse flex-xxl-row'}>
    <Col xxl={9} lg={12}>
      <div className={containerStyles['overview-container']}>
        <SectionLayout>
          <SectionHeader
            title={t(
              'pages:overviewPage.sections.overview.title'
            )}
            leadingIcon={RiHomeFill}
            tooltipMessage={''}
          />
          <SectionContent breakPointsLeft={breakPointsLeft} breakPointsRight={breakPointsRight}>
            <SectionContentLeft verticalMessage={'Overview'} style={{ borderRight: 'none' }}>
              <div className={containerStyles['chart-container']}>
                <div className={containerStyles['chart-legend']}>
                  <div><span>High</span></div>
                  <div><span>Medium</span></div>
                  <div><span>Low</span></div>
                </div>
                <OverviewGraph data={chartData} />
              </div>
            </SectionContentLeft>
          </SectionContent>
          <SectionContentFooter className='px-3 py-2' style={{ height: '35px' }}>
            {/*<div*/}
            {/*  className={`d-flex justify-content-between align-items-center w-100 px-3 pb-3 ${styles['section-content-footer']}`}*/}
            {/*>*/}
            {/*<span className={styles['sub-text']}>*/}
            {/*      Last CVE Check scan{' '}*/}
            {/*  {data?.lastCheck*/}
            {/*    ? format(new Date(data?.lastCheck), 'dd/MM/yy HH:mm')*/}
            {/*    : null}*/}
            {/*    </span>*/}
            {/*</div>*/}
          </SectionContentFooter>
        </SectionLayout>
      </div>
    </Col>
    <Col xxl={3} lg={12} className={'d-flex align-items-stretch'}>
      <OverviewGrade cta={overviewCtaAsString} title={overviewTitleAsString} description={overviewDescriptionAsString}
        grade={data?.grade as GRADE_TYPE} style={{ marginTop: '48px' }} />
    </Col>
  </Row>
  );
};

export default OverviewContainer;