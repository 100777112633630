import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from '../styles.module.scss';
import SectionContentLeft from './SectionContentLeft';
import SectionContentRight from './SectionContentRight';

type BreakPoints = {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
};

type SectionContentProps = {
  children: ReactNode;
  breakPointsLeft?: BreakPoints;
  breakPointsRight?: BreakPoints;
  style?: any;
};

const SectionContent = (props: SectionContentProps) => {
  const {
    breakPointsLeft = {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 4
    },
    breakPointsRight = {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 8 //ovdje namjestiti velicinu gridova
    },
    style = {}
  } = props;

  const renderChildrenHandler = (name: string) => {
    return React.Children.map(props.children, (child) => {
      if (React.isValidElement(child)) {
        if (
          child.type === SectionContentLeft &&
          name === 'SectionContentLeft'
        ) {
          return (
            <Col className='px-0' {...breakPointsLeft}>
              {React.cloneElement(child)}
            </Col>
          );
        }
        if (
          child.type === SectionContentRight &&
          name === 'SectionContentRight'
        ) {
          return (
            <Col className='px-0' {...breakPointsRight}>
              {React.cloneElement(child)}
            </Col>
          );
        }
      }
    });
  };

  return (
    <Row className={styles['section-content-box']} style={style}>
      {renderChildrenHandler('SectionContentLeft')}
      {renderChildrenHandler('SectionContentRight')}
    </Row>
  );
};

export default SectionContent;
