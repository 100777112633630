import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

type MemberChangeType = {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function MemberChangeRole(props: MemberChangeType) {
  const [role, setRole] = React.useState(props.value);
  const [loading, setLoading] = React.useState(false);
  return (
    <Form
      onSubmit={() => {
        console.log('submit');
      }}
    >
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={1}>
              <Form.Check
                checked={role === 'OWNER'}
                disabled={role === 'OWNER' || loading}
                name="role"
                type="radio"
                isValid
                value="OWNER"
                onClick={async (e: any) => {
                  setRole(e.target.value);
                  setLoading(true);
                  try {
                    await props.onChange(e);
                  } catch (e) {
                    console.log(e);
                  }
                  setLoading(false);
                }}
              />
            </Col>
            <Col xs={10}>
              <p style={{ fontSize: '0.9rem' }}>Owner</p>
              {/* <span style={{ fontSize: '0.7rem' }}>
                Lorem ipsum dolor sit amet, consecterur adipiscing elit.
              </span> */}
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row className="py-3">
            <Col xs={1}>
              <Form.Check
                checked={role === 'MEMBER'}
                name="role"
                disabled={role === 'MEMBER' || loading}
                type="radio"
                value={'MEMBER'}
                isValid
                onClick={async (e: any) => {
                  setRole(e.target.value);
                  setLoading(true);
                  try {
                    await props.onChange(e);
                  } catch (e) {
                    console.log(e);
                  }
                  setLoading(false);
                }}
              />
            </Col>
            <Col xs={10}>
              <p style={{ fontSize: '0.9rem' }}>Member</p>
              {/* <span style={{ fontSize: '0.7rem' }}>
                Lorem ipsum dolor sit amet, consecterur adipiscing elit.
              </span> */}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export default MemberChangeRole;
