import * as React from 'react';

import LayoutPage from '@components/LayoutPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// import { get } from 'lodash';
// import Chip from '@components/Chip';

import EmptyResults from '@components/EmptyResults';
import GenericError from '@components/ErrorState';
import SkeletonAnalysisSimple from '@components/SkeletonAnalysisSimple';
import Table from '@components/TableNew/Table';
import useUefiSecurityScanAnalysis from '@hooks/analysis/useUefiSecurityScanAnalysis';
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiDoorLockBoxLine,
  RiSearch2Line,
} from 'react-icons/ri';

import { Col, Container, Row } from 'react-bootstrap';

import { Input } from '@components/Forms/Input';
import LayoutSection from '@components/LayoutSection';
import ModalReportUefiDetails from '@components/ModalReportUefiDetails';
import ReportHeading from '@components/ReportHeading';
import tableStyles from '@components/TableNew/styles.module.scss';
import TablePageSize from '@components/TablePageSize';
import { GlobalContext } from '@contexts/GlobalContext';
import { THEMES } from '@hooks/useDarkMode';
import usePaginationCounter, {
  UsePaginationCounterState,
} from '@hooks/usePaginationCounter';
import useReportOverview from '@hooks/useReportOverview';
import InfoBox from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBox';
import InfoBoxChart from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxChart';
import InfoBoxContent from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxContent';
import InfoBoxMessage from '@pages/Report/Linux/ReportOverviewLinux/Components/InfoBoxMessage';
import SectionHeader from '@pages/Report/Linux/ReportOverviewLinux/Components/SectionHeader';
import { customSeveritySort, getScanTime } from '@utils/index';
import { format, parse } from 'date-fns';
import { noop } from 'lodash';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import passHashStyle from '../../Linux/PasswordHash/styles.module.scss';
import styles from '../styles.module.scss';

export enum UEFI_SECURITY_SCAN_PROPERTY {
  NAME = 'name',
  MODULE = 'module',
  GUID = 'guid',
  SEVERITY = 'severity',
}

export default function ReportUefiSecurityScan() {
  const { reportId = '' }: { reportId: string } = useParams();
  const { data, isLoading, isSuccess, isError, error } =
    useUefiSecurityScanAnalysis(reportId);
  const { data: reportData } = useReportOverview(reportId);

  const [search, setSearch] = React.useState('');
  const [shouldShowModalDetail, setShouldShowModalDetail] =
    React.useState<boolean>(false);

  const { theme } = React.useContext(GlobalContext);
  const isDarkMode = React.useMemo(() => theme === THEMES.DARK, [theme]);

  const { t } = useTranslation(['common', 'pages', 'tables', 'emptyReports']);

  const columns: any = React.useMemo(
    () => [
      {
        Header: 'Filename',
        accessor: 'name',
      },
      {
        Header: 'Module',
        accessor: 'module',
      },
      {
        Header: 'Guide',
        accessor: 'guid',
      },
      {
        Header: '',
        accessor: 'severity',
        sortType: customSeveritySort,
        disableSortBy: true,
        Cell: (props: any) => {
          return (
            <div className={'d-flex align-items-center justify-content-end'}>
              <div
                className={`${passHashStyle['severity-wrap-table-accessor']} ${passHashStyle[props.value]
                  }`}
              >
                <span>{`${props.value} severity`}</span>
              </div>
            </div>
          );
        },
      },
    ],
    []
  );

  const memoizedData = React.useMemo(() => {
    if (!data || data.length === 0) return [];
    return data;
  }, [data]);

  const memoizedSearch = React.useMemo(() => search, [search]);

  const { setGlobalFilter, ...tableInstance } = useTable(
    {
      columns: columns,
      data: memoizedData,
      initialState: { globalFilter: memoizedSearch, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onSearchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setGlobalFilter(e.target.value);
  };

  function onPageSizeChange(e: React.ChangeEvent<HTMLSelectElement>) {
    tableInstance.setPageSize(Number(e?.target?.value));
  }

  const { maxResultChunkSize, paginationMessage }: UsePaginationCounterState =
    usePaginationCounter(tableInstance);

  const scanTime = React.useMemo(() => reportData ? getScanTime(reportData) : null, [reportData]);

  return (
    <LayoutPage>
      {isLoading && <SkeletonAnalysisSimple />}
      {!isLoading && !isError && memoizedData?.length < 1 && (
        <EmptyResults
          title={t('emptyReports:passwordHash.title')}
          description={t('emptyReports:passwordHash.subtitle')}
        // buttonLabel={t('emptyReports:passwordHash.buttonLabel')}
        // onClick={console.log}
        />
      )}
      {isError && <GenericError error={error} />}
      {memoizedData
        && memoizedData?.length > 0
        && !isLoading && isSuccess && (
          <>
            <LayoutSection spacer={32}>
              <ReportHeading
                chipLabel={t('common:generic.completed')}
                buttonLabel={t('common:generic.downloadReport')}
                onButtonClick={noop}
                descriptionButtonLabel={t('common:generic.viewDetails')}
                onDescriptionButtonClick={() => setShouldShowModalDetail(true)}
              />
            </LayoutSection>
            <SectionHeader
              title={t('pages:securityScanPage.title')}
              leadingIcon={RiDoorLockBoxLine}
              tooltipMessage={''}
              bannerText={t('pages:securityScanPage.bannerMessage')}
            />
            <Container fluid>
              <Row>
                <Col className="px-0" xs={12}>
                  <InfoBox style={{
                    padding: 20,
                    height: 'auto'
                  }}>
                    <InfoBoxContent style={{
                      alignItems: 'flex-start', background: isDarkMode ? '#202226' : 'none',
                    }}>
                      <div className={styles['info-box-chart-container']} style={{ borderLeft: 'none', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <InfoBoxMessage
                          message={t('pages:securityScanPage.boxMessage')}
                          style={{ width: 'auto', textTransform: 'uppercase', fontSize: '9px' }}
                        />
                      </div>
                      <div className={styles['info-box-count']}>{memoizedData?.length || 0}</div>
                    </InfoBoxContent>-
                  </InfoBox>
                  <Container fluid style={{
                    padding: '0 10px',
                    borderRadius: '2px'
                  }}>
                    <Row>
                      <Col className="px-0" xs={12} style={{
                        border: '1px solid var(--neutral300)',
                        marginTop: 40
                      }}>
                        <div style={{ fontSize: 20, padding: '40px 40px 5px 20px' }}>Security scan</div>
                        <div style={{ fontSize: 12, color: '#858585', padding: '0 40px 40px 20px', textTransform: 'uppercase' }}>total: {memoizedData?.length || 0}</div>
                        <Table tableInstance={tableInstance} borderless>
                          <Table.Footer>
                            <p className={tableStyles['table__paginationState']}>
                              {paginationMessage}
                            </p>
                            <div className={tableStyles['table__paginationButtons']}>
                              <button
                                className={
                                  tableInstance?.canPreviousPage
                                    ? tableStyles['table__paginationActiveButton']
                                    : ''
                                }
                                onClick={() => tableInstance.previousPage()}
                              >
                                <RiArrowLeftSLine size={20} />
                              </button>
                              <button
                                className={
                                  tableInstance?.canNextPage
                                    ? tableStyles['table__paginationActiveButton']
                                    : ''
                                }
                                onClick={() => tableInstance.nextPage()}
                              >
                                <RiArrowRightSLine size={20} />
                              </button>
                            </div>
                            <TablePageSize
                              maxResultChunkSize={maxResultChunkSize}
                              pageSize={tableInstance?.state?.pageSize || 10}
                              onPageSizeChange={onPageSizeChange}
                            />
                          </Table.Footer>
                        </Table>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </>
        )}
      {reportData && shouldShowModalDetail ? (
        <ModalReportUefiDetails
          onClose={() => setShouldShowModalDetail(false)}
          details={{
            ...reportData?.reportDetails,
            submitted: format(
              parse('', '', new Date(`${reportData?.startDate ?? new Date()}`)),
              'yyyy-MM-d HH:MM:SS'
            ),
            scanTime:
              scanTime ? scanTime.value +
                ' ' +
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                t(`common:${scanTime.type as string}`) : '',
            hash: reportData?.hash || ''
          }}
        />
      ) : null}
    </LayoutPage>
  );
}
