import * as React from 'react';
import { get } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

type Props = {
  report?: { [key: string]: string };
};
export default function ModalVerticalReport({ report }: Props) {
  const { t } = useTranslation(['tables', 'common']);
  const renderReport = React.useCallback(
    (item: string, key: number) => (
      <li key={key}>
        <strong>
          <Trans t={t}>{item}</Trans>
        </strong>
        <span>{get(report, item, '')}</span>
      </li>
    ),
    [report, t]
  );
  return (
    <div className={styles.container}>
      <ul>{report ? Object.keys(report)?.map(renderReport) : null}</ul>
    </div>
  );
}
