import styles from './styles.module.scss';

type Props = {
  title: string;
  subTitle?: string;
  description: string;
};

export default function HeaderOnBoardingWrapper({
  title = '',
  description = '',
  subTitle = '',
}: Props) {
  return (
    <div className={styles.container}>
      <h1>{title}</h1>
      {subTitle && <span>{subTitle}</span>}
      <p>{description}</p>
    </div>
  );
}
