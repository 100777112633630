import { noop } from 'lodash';
import styles from './styles.module.scss';
import TableSelect from '@components/TableSelect';

type Props = {
  pageSize: number;
  maxResultChunkSize: number;
  onPageSizeChange: typeof noop;
};

export default function TablePageSize({
  pageSize,
  onPageSizeChange,
  maxResultChunkSize,
}: Props) {
  return (
    <div className={styles.container}>
      <TableSelect
        prefix="Show"
        value={pageSize}
        onChange={onPageSizeChange}
        // options={[10, 20, 30, 40, 50].filter(
        //   (s) => s <= maxResultChunkSize || s <= pageSize
        // )}
        options={[10, 20, 30, 40, 50]}
      />
    </div>
  );
}
